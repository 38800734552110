// @ts-nocheck
import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Chrono } from 'react-chrono';
import { Modal, View } from '@material-ui/core';
import { Icon} from '@grafana/ui';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { TimeLineOptions, timelineData } from 'types';
import './css/TimeLinePanel.css';
import { config } from '@grafana/runtime';

interface Props extends PanelProps<TimeLineOptions> {}

export const TimeLinePanel: React.FC<Props> = ({ options, data, width, height, replaceVariables, id }) => {
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);

  const isDark = config.theme.isDark;
  const showTitle = options.showTitle;
  const pluginTitle = replaceVariables(options.pluginTitle);
  var legendPosition = String(options.legendPosition);
  const lineWidth = options.lineSize;
  const hideControls = options.hideControls;
  const flipLayout = options.flipLayout;
  const displayOutline = options.displayOutline;
  const cardHeight = String(options.cardMinHeight);
  var displayReadMore = options.displayReadMore;
  if (legendPosition === 'HORIZONTAL') {
    displayReadMore = false;
  }
  if (legendPosition === 'VERTICAL_ALTERNATING' && width < options.cardMinWidth * 2.5) {
    legendPosition = 'VERTICAL';
  }
  var backgroundColor = '#fff';
  var titleColor = 'black';
  var textColor = '#111';
  var primary = '#0d0deb';
  var secondary = '#ff7600';
  var cardBgColor = '#fafafa';
  var cardForeColor = '#999';

  if (isDark) {
    backgroundColor = '#000';
    titleColor = 'white';
    textColor = '#eee';
    primary = '#0d0deb';
    secondary = '#ff7600';
    cardBgColor = '#111';
    cardForeColor = '#999';
  }
  const traptitle = isDark ? 'traptitle_dark' : 'traptitle';
  var card_link = replaceVariables(options.drillDownLink);
  if (card_link === null || card_link === '') {
    card_link = '/';
  }
  var agent_link = replaceVariables(options.drillDownLinkAgent);
  if (agent_link === null || agent_link === '') {
    agent_link = '/';
  }
  var cardTitle_link = replaceVariables(options.drillDownLinkTitle);
  if (cardTitle_link === null || cardTitle_link === '') {
    cardTitle_link = '/';
  }
  // ----------------------- BASE DATA ACQUISITION -----------------------
  var timeEvents: timelineData[] = [];
  var timeItems: timelineItems[] = [];

  if (width < 400 || height < 250) {
    return (
      <div>
        <div className="gf-form-error">{error4}</div>
      </div>
    );
  } else if (data.state !== 'Error') {
    if(data.series[0].length > 0) {
      data.series.forEach((series) => {
        const frame: GraphSeriesValue[] = series.fields[0].values.toArray();
        const dataLen = frame.length;

        for (let i = 0; i < dataLen; i++) {
          let timeItem: timelineItems = {
            id: i,
            title: series.fields.find(field => field.name === options.titleField)?.values.get(i),
          };
          let timelineEvent: timelineData = {
            id: i,
            title: series.fields.find(field => field.name === options.titleField)?.values.get(i),
            cardTitle: series.fields.find((field) => field.name === options.cardTitleField)?.values.get(i),
            cardSubtitle: series.fields.find((field) => field.name === options.cardSubtitleField)?.values.get(i),
            cardDetailedText: '',
            type: series.fields.find((field) => field.name === options.typeField)?.values.get(i),
            severity: series.fields.find((field) => field.name === options.severityField)?.values.get(i),
            agent: series.fields.find((field) => field.name === options.agentField)?.values.get(i),
            url: card_link,
            agentUrl: agent_link,
            cardTitleUrl: cardTitle_link,
            icon: 'default',
          };
          if (options.cardTitleUrlField && options.cardTitleUrlField !== undefined && options.cardTitleUrlField !== '') {
            if (cardTitle_link !== '/') {
              timelineEvent.cardTitleUrl =
                cardTitle_link +
                series.fields.find((field) => field.name === options.cardTitleUrlField).values.get(i);
            } else {
              timelineEvent.cardTitleUrl = series.fields
                .find((field) => field.name === options.cardTitleUrlField).values.get(i);
            }
          }
          if (options.agentUrlField && options.agentUrlField !== undefined && options.agentUrlField !== '') {
            if (agent_link !== '/') {
              timelineEvent.agentUrl =
                agent_link + series.fields.find((field) => field.name === options.agentUrlField).values.get(i);
            } else {
              timelineEvent.agentUrl = series.fields.find((field) => field.name === options.agentUrlField).values.get(i);
            }
          }
          if (options.usecardDetailedText && options.cardDetailedTextField !== undefined && options.cardDetailedTextField !== '') {
            timelineEvent.cardDetailedText = series.fields
              .find((field) => field.name === options.cardDetailedTextField).values.get(i);
          }
          if (!options.useicon && options.iconField !== undefined && options.iconField !== '') {
            timelineEvent.icon = series.fields.find((field) => field.name === options.iconField).values.get(i);
            if (timelineEvent.icon === ' ' || timelineEvent.icon === '' || timelineEvent.icon === null) {
              timelineEvent.icon = 'default';
            }
          }
          if (options.useUrl && options.urlField !== undefined && options.urlField !== '') {
            if (card_link !== '/') {
              timelineEvent.url =
                card_link + series.fields.find((field) => field.name === options.urlField).values.get(i);
            } else {
              timelineEvent.url = series.fields.find((field) => field.name === options.urlField).values.get(i);
            }
          }
          if (typeof timelineEvent.type === undefined || timelineEvent.type === null) {
            timelineEvent.type = '';
          }
          if (typeof timelineEvent.severity === undefined || timelineEvent.severity === null) {
            timelineEvent.severity = 'normal';
          }
          if (typeof timelineEvent.agent === undefined || timelineEvent.agent === null) {
            timelineEvent.agent = 'NetMonitor';
          }
          timeEvents.push(timelineEvent);
          timeItems.push(timeItem);
        }
      });

      // -----------------------  TIMELINE FIELD VALUES  -----------------------

      const dimensions = {
        width: width,
        height: height + 20,
        marginTop: 0,
        marginRight: 10,
        marginBottom: 10,
        marginLeft: 10,
        cardWidth: width * 0.75,
      };
      if (showTitle) {
        dimensions.height = height - 45;
      }
      const fontSizes = {
        cardSubtitle: String(options.cardFontSize - 1) + 'px',
        cardText: String(options.cardFontSize - 2) + 'px',
        cardTitle: String(options.cardFontSize) + 'px',
        title: String(options.cardFontSize) + 'px',
      }
      const showMore = options.useReadMore;
      const showCardInfo = options.showCardInfo;
      var cardSubtitle = 'cardSubtitle';
      let timeLineContainer = isDark ? 'timeLineContainer_dark' : 'timeLineContainer';
      let timeLinetitle = isDark ? 'timeLinetitle_dark' : 'timeLinetitle';
      let moreInfoContainerId = 'timeline-moreInfoContainer-' + String(id);
      let moreInfoId = 'timeline-moreInfo-' + String(id);
      let moreTitleId = 'timeline-moreInfoTitle-' + String(id);
      let moreCardTitleId = 'timeline-moreInfoCardTitle-' + String(id);
      let moreSubTitleId = 'timeline-moreInfoSubTitle-' + String(id);
      let moreInfoTextId = 'timeline-moreInfoText-' + String(id);
      let moreInfoUrlId = 'timeline-moreInfoUrl-' + String(id);
      let moreAgentId = 'timeline-moreInfoAgent-' + String(id);
      let moreInfoSeverityId = 'timeline-moreInfoSeverity-' + String(id);
      let moreInfoTypeId = 'timeline-moreInfoType-' + String(id);
      if (legendPosition === 'VERTICAL') {
        dimensions.cardWidth = width * 0.6;
      } else 
      if (legendPosition === 'VERTICAL_ALTERNATING') {
        dimensions.cardWidth = width * 0.4;
      }
      return (
        <div className="timeLineChart" id="timelineChart">
          {showTitle && (
            <div className={timeLinetitle}>
              <div className="timeLinetitleText">{pluginTitle}</div>
            </div>
          )}
          <div className={timeLineContainer} style={{width: '100%', height: dimensions.height }}>
            <Chrono
              items={timeItems}
              mode={legendPosition}
              cardHeight={String(cardHeight)}
              cardWidth={String(dimensions.cardWidth)}
              allowDynamicUpdate={true}
              lineWidth="2"
              hideControls={hideControls}
              scrollable={{ scrollbar: true }}
              flipLayout={flipLayout}
              timelineCircleDimension={String(options.circleDimension)}
              theme={{
                primary: primary,
                secondary: secondary,
                cardBgColor: cardBgColor,
                cardForeColor: cardForeColor,
                textColor: textColor,
                titleColor: titleColor,
              }}
              fontSizes={fontSizes}
              enableOutline={displayOutline}
              useReadMore={displayReadMore}
            >
              {cardFactory(timeEvents, showMore, showCardInfo, id)}
              {options.useIcon && <div className="chrono-icons">{iconFactory(timeEvents, options.circleDimension)}</div>}
            </Chrono>
          </div>
          <div id={moreInfoContainerId} className="timeline-moreInfoContainer timeline-hide">
            <div id={moreInfoId} className="timeline-moreInfo">
              <div className="cardTitleContainer">
                <span id={moreTitleId} className="timeline-moreInfoTitles"></span>
                <span id={moreCardTitleId} className="timeline-moreInfoTitles"></span>
              </div>
              <div className="timeline-moreDivs">
                <span className="timeline-moreTitles">{options.subtitleTitle}</span>
                <span id={moreSubTitleId} className="cardSubtitle"></span>
              </div>
              <div className="timeline-moreDivs">
                <span className="timeline-moreTitles">{options.agentTitle}</span>
                <span id={moreAgentId} className="timeline-moreInfoText"></span>
              </div>
              <div className="timeline-moreDivs">
                <span className="timeline-moreTitles">{options.severityTitle}</span>
                <span id={moreInfoSeverityId} className="timeline-moreInfoText"></span>
              </div>
              <div className="timeline-moreDivs">
                <span className="timeline-moreTitles">{options.typeTitle}</span>
                <span id={moreInfoTypeId} className="timeline-moreInfoText"></span>
              </div>
              <div className="timeline-moreDivs">
                <div className="timeline-moreTitles">{options.infoTitle}</div>
                <div id={moreInfoTextId} className="cardDetailedTextZoom"></div>
              </div>
              {options.useUrl && (
                <div className="timeline-url_button">
                  <a id={moreInfoUrlId} target="_blank" style={{ color: '#fff' }} rel="nofollow noopener noreferrer">
                    mas información ...
                  </a>
                </div>
              )}
              <button
                className="timeline-close_button"
                onClick={(e) => {
                  hideMoreInfo(e, id);
                }}
              >
                cerrar
              </button>
            </div>
          </div>
        </div>
      );
    } else {
        return (
          <div className="timeLineChart" id="timelineChart">
            {showTitle && (
              <div className={traptitle}>
                <div className="traptitleText">{pluginTitle}</div>
              </div>
            )}
            <div>
              <div className="noDataMessage">{error1}</div>
            </div>
          </div>
        );
    } 
  } else {
    return (
      <div>
        <div className="gf-form-error">{error3}</div>
      </div>
    );
  }
};

function iconFactory(icons: timelineData[], circleDimension: number) {
  const isDark = config.theme.isDark;
  let iconBackground = 'icon_background';
  if (isDark) {
    iconBackground = 'icon_background_dark';
  }
  let iconSize = 'xs';
  if (circleDimension > 18) {
    iconSize = 'sm';
  } else if (circleDimension > 20) {
    iconSize = 'md';
  } else if (circleDimension > 24) {
    iconSize = 'lg';
  } else if (circleDimension > 28) {
    iconSize = 'xl';
  }

  return icons.map((timelineEvent) => (
    <div className={iconBackground} key={timelineEvent.id}>
      <Icon name={timelineEvent.icon} size={iconSize} title={timelineEvent.severity} />
    </div>
  ));
}

function showMoreInfo(e, timelineEvent, id) {
  let divContainerId = '#timeline-moreInfoContainer-' + String(id);
  let divInfoId = '#timeline-moreInfo-' + String(id);
  let divTitleId = '#timeline-moreInfoTitle-' + String(id);
  let divCardTitleId = '#timeline-moreInfoCardTitle-' + String(id);
  let divSubTitleId = '#timeline-moreInfoSubTitle-' + String(id);
  let divInfoTextId = '#timeline-moreInfoText-' + String(id);
  let divAgentId = '#timeline-moreInfoAgent-' + String(id);
  let divInfoSeverityId = '#timeline-moreInfoSeverity-' + String(id);
  let divInfoTypeId = '#timeline-moreInfoType-' + String(id);
  let divInfoUrlId = '#timeline-moreInfoUrl-' + String(id);

  $(divContainerId).removeClass('timeline-hide');
  $(divTitleId).text(timelineEvent.title);
  $(divCardTitleId).text(timelineEvent.cardTitle);
  $(divSubTitleId).text(timelineEvent.cardSubtitle);
  $(divInfoTextId).text(timelineEvent.cardDetailedText);
  $(divAgentId).text(timelineEvent.agent);
  $(divInfoSeverityId).text(timelineEvent.severity);
  $(divInfoTypeId).text(timelineEvent.type);
  $(divInfoUrlId).attr('href', timelineEvent.url);
}

function hideMoreInfo(e, id) {
  let divID = '#timeline-moreInfoContainer-' + String(id);
  $(divID).addClass('timeline-hide');
}

function cardFactory(cards: timelineData[], showMore, showCardInfo, id) {
  const isDark = config.theme.isDark;
  var cardContainer = 'cardContainer';
  var cardTitle = 'cardTitle';
  var cardSubtitle = 'cardSubtitle';
  var cardDetailedText = 'cardDetailedText';
  var agent = 'timeline-agent';
  var cardTheme = '';
  /*var cardTitleUrl = false;
  if (timelineEvent.cardTitleUrl !== undefined && timelineEvent.cardTitleUrl !== null && timelineEvent.cardTitleUrl !== '') {
    cardTitleUrl = true;
  }*/
  if (isDark) {
    cardTitle = 'cardTitle_dark';
    cardDetailedText = 'cardDetailedText_dark';
    cardSubtitle = 'cardSubtitle_dark';
    cardTheme = '_dark';
  }

  return cards.map((timelineEvent) => (
    <div key={timelineEvent.id} className={cardContainer}>
      <div className="cardTitleContainer">
        <span className="timeline-cardTitle" title={'Severidad: ' + timelineEvent.severity}>
          {timelineEvent.cardTitleUrl !== '' ? (
		    <a
              className={cardTitle + ' timeline-' + timelineEvent.severity + cardTheme}
              href={timelineEvent.cardTitleUrl}
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              {timelineEvent.cardTitle}
            </a>
		  ) : (
		    <b>
			  {timelineEvent.cardTitle}
			</b>
		  )}
        </span>
        <span className={agent}>
          <a href={timelineEvent.agentUrl} rel="nofollow noopener noreferrer" target="_blank" style={{ color: '#fff' }}>
            {timelineEvent.agent}
          </a>
        </span>
      </div>
      {showCardInfo && !showMore && (
        <div className="cardInfoContainer">
          <div className={cardSubtitle}>{timelineEvent.cardSubtitle}
          </div>
          <div id={'timeline-wrapper' + timelineEvent.id} className={cardDetailedText}>
            {timelineEvent.cardDetailedText}
          </div>
        </div>
      )}
      {showCardInfo && showMore && (
        <div className="cardInfoContainer">
          <div className={cardSubtitle}>{timelineEvent.cardSubtitle}
          </div>
          <div id={'timeline-wrapper' + timelineEvent.id} className={cardDetailedText} style={{ display: 'contents' }}>
            {timelineEvent.cardDetailedText}
            <spam className="timeline-buttonBox">
              <button
                className="timeline-button"
                onClick={(e) => {
                  showMoreInfo(e, timelineEvent, id);
                }}
                title="más detalles ..."
              >
                <Icon
                  name="ellipsis-h"
                  size="md"
                />
              </button>
            </spam>
          </div>
        </div>
      )}
      {!showCardInfo && showMore && (
        <div className="cardInfoContainer">
          <div className={cardSubtitle} style={{ display: 'contents' }} >{timelineEvent.cardSubtitle}
            <spam className="timeline-buttonBox">
              <button
                className="timeline-button"
                onClick={(e) => {
                  showMoreInfo(e, timelineEvent, id);
                }}
                title="más detalles ..."
              >
                <Icon
                  name="ellipsis-h"
                  size="md"
                />
              </button>
            </spam>
          </div>
        </div>
      )}
    </div>
  ));
}

import React, { useContext } from 'react';

import { NetMonitorConfig } from '@grafana/data';
import { LocationService } from '@grafana/runtime/src/services/LocationService';
import { BackendSrv } from '@grafana/runtime/src/services/backendSrv';

import { AppChromeService } from '../components/AppChrome/AppChromeService';

export interface NetMonitorContextType {
  backend: BackendSrv;
  location: LocationService;
  config: NetMonitorConfig;
  chrome: AppChromeService;
}

export const NetMonitorContext = React.createContext<NetMonitorContextType | undefined>(undefined);

export function useNetMonitor(): NetMonitorContextType {
  const context = useContext(NetMonitorContext);
  if (!context) {
    throw new Error('No NetMonitorContext found');
  }
  return context;
}

import React from 'react';
import { contextSrv } from 'app/core/services/context_srv';
import { TagList } from '@grafana/ui';

type Props = { labels: Record<string, string>; className?: string };

export const AlertLabels = ({ labels, className }: Props) => {
  const pairs = Object.entries(labels).filter(([key]) => !(key.startsWith('__') && key.endsWith('__')));
  const isAdmin = contextSrv.isNetMonitorAdmin;
  return (
    <div className={className}>
      {isAdmin && <TagList tags={Object.values(pairs).map(([label, value]) => `${label} = ${value}`)} />}
      {!isAdmin && <TagList tags={Object.values(pairs).map(([label, value]) => `${value}`)} />}
    </div>
  );
};

import React from 'react';
import { arc } from "d3-shape";
import { scaleLinear } from "d3-scale";
import { format } from "d3-format";

import GaugeChart from "react-gauge-chart";
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { Tooltip, Icon } from '@grafana/ui';
import { SpeedometerOptions, SpeedData, Intervals, BateryLines } from 'types';
import { config } from '@grafana/runtime';
import './css/speedometerPanel.css';

import LiquidFillGauge from 'react-liquid-gauge/lib/index';

interface Props extends PanelProps<SpeedometerOptions> {}

export const SpeedometerPanel: React.FC<Props> = ({ options, data, width, height, replaceVariables, id }) => {
  const isDark = config.theme.isDark;
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);
  const drillDownLink = replaceVariables(options.drillDownLink);
  const needleColor = replaceVariables(options.needleColor);
  let fontColor = options.fontColor;
  if (options.useThemeFontColor) {
    fontColor = '#111';
    if (isDark) {
      fontColor = '#eee';
    }
  }
  let onfire = false;
  let validUrl = false;
  if (drillDownLink !== null && drillDownLink !== '') {
    validUrl = true;
  }

  let segmentStops: number[] = [0.5, 0.3, 0.2];
  var colors = options.segmentColors;
  if (options.useColorScale) {
    if (options.colorScale === 'Orange') {
      colors = 'Gold,Orange,Red';
    } else if (options.colorScale === 'Blue') {
      colors = 'Violet,MediumPurple,DarkSlateBlue';
    } else {
      colors = 'LightGreen,YellowGreen,DarkGreen';
    }
  }
  const segmentColors = colors.split(',', 3);

  const gaugeData: SpeedData[] = [];

  if (data.state !== 'Error') {
    if(data.series[0].length > 0) {
      data.series.forEach(series => {
        const speedValues: GraphSeriesValue[] = series.fields[0].values.toArray();
        for (let i = 0; i < speedValues.length; i++) {
          let tableData: SpeedData = {
            id: i,
            width: width,
            height: height,
            maxValue: Number(series.fields.find(field => field.name === options.maxValueField)?.values.get(i)),
            minValue: Number(series.fields.find(field => field.name === options.minValueField)?.values.get(i)),
            valueTitle: String(options.valueTitle),
            value: Number(series.fields.find(field => field.name === options.valueField)?.values.get(i)),
            threshold: Number(series.fields.find(field => field.name === options.thresholdField)?.values.get(i)),
            secondThreshold: Number(
              series.fields.find(field => field.name === options.secondThresholdField)?.values.get(i)
            ),
            currentValueText: String(series.fields.find(field => field.name === options.currentValueField)?.values.get(i)),
            info: String(series.fields.find(field => field.name === options.infoField)?.values.get(i)),
            infoTitle: String(options.infoTitle),
            needleColor: String(needleColor),
            needleSize: Number(options.needleSize),
            startColor: String(options.startColor),
            textColor: String(fontColor),
            endColor: String(options.endColor),
            warningColor: String(options.warningColor),
            labelFontSize: options.labelFontSize,
            valueTextFontSize: options.valueFontSize,
            ringWidth: 100 - options.ringWidth,
            showSegments: options.showSegments,
            segmentsLength: 3,
            segmentStops: segmentStops,
            segmentColors: segmentColors,
            displaySegmentsLegends: options.displaySegmentsLegends,
            showText: options.showCurrentValue,
          };
          if (tableData.maxValue === undefined || tableData.maxValue < tableData.minValue) {
            tableData.maxValue = 100;
          }
          if (tableData.minValue === undefined || tableData.minValue > tableData.maxValue) {
            tableData.minValue = 0;
          }
          if (tableData.value !== undefined && tableData.value > tableData.maxValue) {
            tableData.value = tableData.maxValue;
          } else if (tableData.value !== undefined && tableData.value < tableData.minValue) {
            tableData.value = tableData.minValue;
          }

          if (tableData.showSegments === false) {
            if (options.chartType === 'BatteryGauge') {
              tableData.segmentsLength = 10;
            }
          } else if (options.chartType === 'Speedometer') {
            let newSegmentStops: number[] = [];
			let scale = tableData.maxValue - tableData.minValue;
			if (options.segmentsLength > 1 && options.segmentStops !== null && options.segmentStops !== '') {
			  tableData.segmentsLength = options.segmentsLength;
			  const newStops = options.segmentStops.split(',', options.segmentsLength);
			  for (let i = 0; i < newStops.length; i++) {
				let stop = Number(newStops[i]);
				if (i < 1) {
				  newSegmentStops[i] = (stop - tableData.minValue) / scale;
				} else {
				  let previousStop = Number(newStops[i - 1])
				  newSegmentStops[i] = (stop - previousStop) / scale;
				}
			  }
			} else {
              tableData.segmentsLength = 3;
              newSegmentStops[0] = 0.5;
              newSegmentStops[1] = 0.25;
              newSegmentStops[2] = 0.25;
              if (options.useColorScale) {
                if (options.colorScale === 'Orange') {
                  colors = 'Gold,Orange,Red';
                } else if (options.colorScale === 'Blue') {
                  colors = 'Violet,MediumPurple,DarkSlateBlue';
                } else {
                  colors = 'LightGreen,YellowGreen,DarkGreen';
                }
              }
            }
            tableData.segmentColors = colors.split(',', options.segmentsLength);
            tableData.segmentStops = newSegmentStops;
          } else if (options.chartType === 'Thermometer') {
            let newSegmentStops: number[] = [];
			let scale = tableData.maxValue - tableData.minValue;
            if (tableData.showSegments && options.segmentStops !== '') {
              const newStops = options.segmentStops.split(',', options.segmentsLength);
			  for (let i = 0; i < newStops.length; i++) {
				let stop = Number(newStops[i]);
				if (i < 1) {
				  newSegmentStops[i] = (stop - tableData.minValue) / scale;
				} else {
				  let previousStop = Number(newStops[i - 1])
				  newSegmentStops[i] = (stop - previousStop) / scale;
				}
			  }
            } else {
              newSegmentStops[0] = tableData.minValue;
              newSegmentStops[1] = (tableData.maxValue - tableData.minValue) / 2;
              newSegmentStops[2] = tableData.maxValue;
            }
            tableData.segmentStops = newSegmentStops;
          } else if (options.chartType === 'BatteryGauge') {
            tableData.segmentsLength = 10;
		  }

          if (tableData.textColor === '' && tableData.textColor === undefined) {
            tableData.textColor = fontColor;
          }
          if (tableData.threshold === undefined || tableData.threshold > tableData.maxValue) {
            tableData.threshold = tableData.maxValue;
          } else if (tableData.threshold !== undefined && tableData.threshold < tableData.minValue) {
		    tableData.threshold = tableData.minValue;
		  }
          if (tableData.secondThreshold === undefined || tableData.secondThreshold > tableData.maxValue) {
            tableData.secondThreshold = tableData.maxValue;
          } else if (tableData.secondThreshold !== undefined && tableData.secondThreshold < tableData.minValue) {
            tableData.secondThreshold = tableData.minValue;
          }
          if (!options.useSecondThreshold && options.useThreshold) {
            if (options.reverseThreshold && tableData.value < tableData.threshold) {
              onfire = true;
            } else if (!options.reverseThreshold && tableData.value > tableData.threshold) {
              onfire = true;
            } else {
              onfire = false;
            }
          } else if (options.useSecondThreshold && tableData.secondThreshold > tableData.minValue) {
            if (tableData.value < tableData.secondThreshold || tableData.value > tableData.threshold) {
              onfire = true;
            } else {
              onfire = false;
            }
          }
          if (options.chartType !== 'GaugeChart' && onfire) {
            tableData.textColor = String(options.warningColor);
          }
          if (tableData.currentValueText === undefined) {
            tableData.currentValueText = String(tableData.value);
          }
          if (tableData.info === undefined) {
            tableData.info = String(tableData.value);
          }
          gaugeData.push(tableData);
        }
      });

      if (
        ((options.chartType !== 'Thermometer' && width >= 150) || (options.chartType === 'Thermometer' && width >= 100)) &&
        ((options.chartType !== 'Thermometer' && height >= 100) || (options.chartType === 'Thermometer' && height >= 150))
      ) {
        return (
          <div>
            {options.chartType === 'Speedometer' && (
              <div className="speedometer_container" onClick={() => openUrl(drillDownLink)}>
                {speedometerFactory(
                  id,
                  gaugeData,
                  width,
                  height,
                  options.showCurrentValue,
                  options.chartCentered,
                  onfire,
                  validUrl,
                  options.valueUnit,
                  options.arcPadding,
				  options.showInfoField
                )}
              </div>
            )}
            {options.chartType === 'GaugeChart' && (
              <div className="speedometer_container" onClick={() => openUrl(drillDownLink)}>
                {gaugeFactory(
                  gaugeData,
                  width,
                  height,
                  options.showCurrentValue,
                  onfire,
                  validUrl,
                  options.valueUnit,
                  options.backgroundColor,
				  options.showInfoField,
				  options.warningColor
                )}
              </div>
            )}
            {options.chartType === 'Thermometer' && (
              <div className="speedometer_container" onClick={() => openUrl(drillDownLink)}>
                {thermometerFactory(
                  gaugeData,
                  width,
                  height,
                  options.showCurrentValue,
                  options.chartCentered,
                  onfire,
                  validUrl,
                  options.revertColorScale,
                  options.valueUnit,
				  options.showInfoField,
				  options.displaySegmentsLegends
                )}
              </div>
            )}
            {options.chartType === 'LiquidFillGauge' && (
              <div className="speedometer_container" onClick={() => openUrl(drillDownLink)}>
                {liquidFillGaugeFactory(
                  gaugeData,
                  width,
                  height,
                  options.showCurrentValue,
                  options.chartCentered,
                  onfire,
                  validUrl,
                  options.valueUnit,
                  options.needleColor,
                  options.warningColor,
				  options.showInfoField
                )}
              </div>
            )}
            {options.chartType === 'BatteryGauge' && (
              <div className="speedometer_container" onClick={() => openUrl(drillDownLink)}>
                {batteryFactory(
                  gaugeData,
                  width,
                  height,
                  options.showCurrentValue,
                  options.chartCentered,
                  onfire,
                  validUrl,
                  options.revertColorScale,
                  options.valueUnit,
				  options.showInfoField
                )}
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div>
            <div className="gf-form-error">{error4}</div>
          </div>
        );
      }
    } else {
      return (
        <div>
          <div className="gf-form-error">{error1}</div>
        </div>
      );
    }
  } else {
    return (
      <div>
        <div className="gf-form-errorr">{error3}</div>
      </div>
    );
  }
};

function openUrl(url: string) {
  if (url !== null && url !== '') {
    window.open(url, '_self');
  }
}

function speedometerFactory(
  id: string,
  tableData: SpeedData[],
  width: number,
  height: number,
  showText: boolean,
  centered: boolean,
  onfire: boolean,
  validUrl: boolean,
  unit: string,
  arcPadding: number,
  showInfoField: boolean
) {
  const panelMinDimension = Math.min(width, height);
  const panelMaxDimension = Math.max(width, height);
  let borderColor = '#5e5e5e';
  
  const isDark = config.theme.isDark;
  if (isDark) {
    borderColor = '#444';
  }

  var panelWidth = panelMaxDimension;
  var panelHeight = panelMinDimension;
  if (panelWidth > panelHeight * 1.55) {
    panelWidth = panelHeight * 1.55;
  }
  if (height > width) {
    panelWidth = panelMinDimension;
    panelHeight = panelWidth;
  }


  let marginLeft = 0;
  if (centered) {
    marginLeft = (width - panelWidth) / 2;
  }
  let tooltipClass = 'SpeedOdometerTooltip';
  let SpeedOdometerLegend = 'SpeedOdometerLegend';
  if (onfire) {
    tooltipClass = 'SpeedOdometerTooltip SpeedOdometerTooltipWarn';
    SpeedOdometerLegend = 'SpeedOdometerLegend SpeedOdometerLegendWarn';
  }

  const label = tableData.map(data => {
    var tooltipLabel = '<span><p class="SpeedOdometerTooltip_center">';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<i class="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>';
      tooltipLabel = tooltipLabel + '<b class="SpeedOdometerTooltip_title">Alarma</b></p></span>';
    }
    tooltipLabel = tooltipLabel + '<b>' + data.valueTitle + ': </b>' + data.value.toFixed(2) + ' ' + unit + '<br />';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<b>Umbral de alarma: </b>' + data.threshold.toFixed(2) + ' ' + unit + '<br />';
    }
    if (showInfoField) {
	  tooltipLabel = tooltipLabel + '<b>' + data.infoTitle + ' </b>' + data.info;
	}
    if (validUrl) {
      tooltipLabel = tooltipLabel + '<p>Clic para ver más detalles ...</p>';
    }
    return tooltipLabel;
  });
  
  const getMousePos = (e) => {
    let x = e.pageX;
    let y = e.pageY;
    $('#panelTooltip').html(label);
    $('#panelTooltip').addClass(tooltipClass);
    $('#panelTooltip').css({
      'left': x + 10 + 'px',
      'top': y + 'px',
      'visibility': 'visible' 
    });
  };

  const hideTooltip = (e) => {
    $('#panelTooltip').html('');
    $('#panelTooltip').removeClass(tooltipClass);
    $('#panelTooltip').css({
      'visibility': 'hidden' 
    });
  };

  return tableData.map(data => (
    <div key={data.id} className="speedometer_box" id="speedometer_box">
      <div
        style={{
          width: panelWidth,
          height: panelHeight,
          overflow: 'hidden',
          display: 'flex',
          position: 'relative',
          marginLeft: marginLeft,
		  justifyContent: 'center',
        }}
        onMouseMove={getMousePos}
        onMouseOut={hideTooltip}
      >
        <GaugeChart
          id={id}
          style={{ width: panelWidth - 10, height: panelHeight - 10 }}
          percent={((data.value - data.minValue) / (data.maxValue - data.minValue))}
          needleColor={onfire ? data.warningColor : data.needleColor}
          needleBaseColor={onfire ? data.warningColor : data.needleColor}
          arcWidth={(100 - data.ringWidth) / 100}
          textColor={data.textColor}
          hideText={showText}
          animate={false}
          arcPadding={arcPadding / 100}
          nrOfLevels={data.segmentsLength}
          arcsLength={data.segmentStops}
          colors={data.segmentColors}
          cornerRadius={5}
          marginInPercent={0.01}
        />
      </div>
      {showText && (
        <div
          style={{
            color: String(data.textColor),
            marginTop: '-' + String(data.valueTextFontSize) + 'px',
            fontSize: String(data.valueTextFontSize) + 'px',
            lineHeight: String(data.valueTextFontSize) + 'px',
            fontWeight: '700'
          }}
          className={SpeedOdometerLegend}
        >
          {data.currentValueText}
        </div>
      )}
    </div>
  ));
}

function gaugeFactory(
  tableData: SpeedData[],
  width: number,
  height: number,
  showText: boolean,
  onfire: boolean,
  validUrl: boolean,
  unit: string,
  backgroundColor: string,
  showInfoField: boolean,
  warningColor: string
) {
  let theme = 'light';
  const isDark = config.theme.isDark;
  if (isDark) {
    theme = 'dark';
  }
  var marginBottom = 15;
  if (showText) {
    marginBottom = 25;
  }
  let size = 'normal';
  let tooltipClass = 'SpeedOdometerTooltip';
  let SpeedOdometerLegend = 'SpeedOdometerLegend';
  if (onfire) {
    tooltipClass = 'SpeedOdometerTooltip SpeedOdometerTooltipWarn';
    SpeedOdometerLegend = 'SpeedOdometerLegend SpeedOdometerLegendWarn';
  }
  var svgWidth = width - marginBottom;
  var svgHeight = height - (marginBottom * 1.55);
  if (showText) {
    svgHeight = height - marginBottom;
  }

  const label = tableData.map(data => {
    var tooltipLabel = '<span><p class="SpeedOdometerTooltip_center">';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<i class="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>';
      tooltipLabel = tooltipLabel + '<b class="SpeedOdometerTooltip_title">Alarma</b></p></span>';
    }
    tooltipLabel = tooltipLabel + '<b>' + data.valueTitle + ': </b>' + data.value.toFixed(2) + ' ' + unit + '<br />';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<b>Umbral de alarma: </b>' + data.threshold.toFixed(2) + ' ' + unit + '<br />';
    }
    if (showInfoField) {
	  tooltipLabel = tooltipLabel + '<b>' + data.infoTitle + ' </b>' + data.info;
	}
    if (validUrl) {
      tooltipLabel = tooltipLabel + '<p>Clic para ver más detalles ...</p>';
    }
    return tooltipLabel;
  });
  
  const getMousePos = (e) => {
    let x = e.pageX;
    let y = e.pageY;
    $('#panelTooltip').html(label);
    $('#panelTooltip').addClass(tooltipClass);
    $('#panelTooltip').css({
      'left': x + 10 + 'px',
      'top': y + 'px',
      'visibility': 'visible' 
    });
  };

  const hideTooltip = (e) => {
    $('#panelTooltip').html('');
    $('#panelTooltip').removeClass(tooltipClass);
    $('#panelTooltip').css({
      'visibility': 'hidden' 
    });
  };

  const gaugeSVG = tableData.map(data => {
    const currentValue = data.value;
    const backgroundArc = arc()
      .innerRadius(data.ringWidth / 100)
      .outerRadius(1)
      .startAngle(-Math.PI / 1.8)
      .endAngle(Math.PI / 1.8)
      .cornerRadius(0.05)();

    const percentScale = scaleLinear()
      .domain([data.minValue, data.maxValue])
      .range([0, 1]);

    const percent = percentScale(currentValue);

    const angleScale = scaleLinear()
      .domain([0, 1])
      .range([-Math.PI / 1.8, Math.PI / 1.8])
      .clamp(true);

    const angle = angleScale(percent);

    const filledArc = arc()
      .innerRadius(data.ringWidth / 100)
      .outerRadius(1)
      .startAngle(-Math.PI / 1.8)
      .endAngle(angle)
      .cornerRadius(0.05)();

    const colorScale = scaleLinear()
      .domain([0, 1])
      .range([data.startColor, data.endColor]);

    const gradientSteps = colorScale.ticks(data.segmentsLength).map(currentValue => colorScale(currentValue));

    const getCoordsOnArc = (angle, offset = 1) => [
      Math.cos(angle - Math.PI / 2) * offset,
      Math.sin(angle - Math.PI / 2) * offset
    ];

    const markerLocation = getCoordsOnArc(angle, 1 - (1 - (data.ringWidth / 100)) / 1.8);

    return (
      <div key={data.id} className="speedometer_box" id="speedometer_box">
        <div
          style={{
            textAlign: 'center'
          }}
        >
          <svg
            width={svgWidth}
            height={svgHeight}
            viewBox={[-1, -1, 2, 1].join(' ')}
            style={{
              overflow: 'visible'
            }}
            onMouseMove={getMousePos}
            onMouseOut={hideTooltip}
          >
            <defs>
              <linearGradient
                id="Gauge_gradient"
                gradientUnits="userSpaceOnUse"
                x1="-1"
                x2="1"
                y2="0"
              >
                {gradientSteps.map((color, index) => (
                  <stop
                   key={color}
                    stopColor={color}
                    offset={`${index / (gradientSteps.length - 1)}`}
                  />
                ))}
              </linearGradient>
            </defs>
            <path d={backgroundArc} fill={backgroundColor} />
            <path d={filledArc} fill="url(#Gauge_gradient)" />
            <circle
              cx={markerLocation[0]}
              cy={markerLocation[1]}
              r={data.needleSize / 100}
              stroke={data.needleColor}
              strokeWidth="0.01"
              fill={onfire ? warningColor : colorScale(percent)}
            />
          </svg>
          {!showText && (
            <div
              style={{
                color: String(data.textColor),
                marginTop: '-1.4em',
                fontSize: String(data.valueTextFontSize) + 'px',
                lineHeight: String(data.valueTextFontSize) + 'px',
                fontWeight: '900'
              }}
            >
              {format(',')(currentValue)(unit)}
            </div>
          )}
          {showText && (
            <div
              style={{
                color: String(data.textColor),
                fontSize: String(data.valueTextFontSize) + 'px',
                lineHeight: String(data.valueTextFontSize) + 'px',
                fontWeight: '700'
              }}
              className={SpeedOdometerLegend}
            >
              {data.currentValueText}
            </div>
          )}
        </div>
      </div>
    );
  });
  return gaugeSVG;
}

function thermometerFactory(
  tableData: SpeedData[],
  width: number,
  height: number,
  showText: boolean,
  centered: boolean,
  onfire: boolean,
  validUrl: boolean,
  revertColorScale: boolean,
  unit: string,
  showInfoField: boolean,
  showLabels: boolean
) {
  let theme = 'light';
  const isDark = config.theme.isDark;
  if (isDark) {
    theme = 'dark';
  }

  let size = 'normal';
  let tooltipClass = 'SpeedOdometerTooltip';
  let SpeedOdometerLegend = 'SpeedOdometerLegend';
  if (onfire) {
    tooltipClass = 'SpeedOdometerTooltip SpeedOdometerTooltipWarn';
    SpeedOdometerLegend = 'SpeedOdometerLegend SpeedOdometerLegendWarn';
  }

  const label = tableData.map(data => {
    var tooltipLabel = '<span><p class="SpeedOdometerTooltip_center">';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<i class="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>';
      tooltipLabel = tooltipLabel + '<b class="SpeedOdometerTooltip_title">Alarma</b></p></span>';
    }
    tooltipLabel = tooltipLabel + '<b>' + data.valueTitle + ': </b>' + data.value.toFixed(2) + ' ' + unit + '<br />';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<b>Umbral de alarma: </b>' + data.threshold.toFixed(2) + ' ' + unit + '<br />';
    }
    if (showInfoField) {
	  tooltipLabel = tooltipLabel + '<b>' + data.infoTitle + ' </b>' + data.info;
	}
    if (validUrl) {
      tooltipLabel = tooltipLabel + '<p>Clic para ver más detalles ...</p>';
    }
    return tooltipLabel;
  });
  
  const getMousePos = (e) => {
    let x = e.pageX;
    let y = e.pageY;
    $('#panelTooltip').html(label);
    $('#panelTooltip').addClass(tooltipClass);
    $('#panelTooltip').css({
      'left': x + 'px',
      'top': y + 'px',
      'visibility': 'visible' 
    });
  };

  const hideTooltip = (e) => {
    $('#panelTooltip').html('');
    $('#panelTooltip').removeClass(tooltipClass);
    $('#panelTooltip').css({
      'visibility': 'hidden' 
    });
  };

  const thermometer = tableData.map(data => {
    let valstr = String(data.value) + unit;
    let valuePercent = Number((data.value - data.minValue) / (data.maxValue - data.minValue)) * 100;
    let reverseGradient = '';
    if (revertColorScale) {
      reverseGradient = 'Reverse';
    }
    let intervals: Intervals[] = [];
    if (data.segmentsLength > 0) {
      let total = data.minValue;
	  let relTotal = 0;
	  let scale = data.maxValue - data.minValue;
	  for (let i = 0; i <= data.segmentsLength; i++) {  
		total = total + (data.segmentStops[i] * scale);
		relTotal = relTotal + data.segmentStops[i];
        let val = Number(total).toFixed(1);
        let relValue = Number(relTotal).toFixed(1);
        if (!isNaN(val)) {
          let interval: Intervals = {
            percent: relValue * 100,
            label: String(val) + unit,
          };
          intervals.push(interval);
        }
      }
    }
    var marginBottom = 10;
    if (showText) {
      marginBottom = 25;
    }
    const stepIntervals = createIntervalsUI(intervals);
    const thermoTheme = `thermometer--theme-${theme}`;
    const thermoSize = `thermometer--${size}`;
    const thermoHeight = { height: `${height - 30}px`, width: `${width}px` };
    const heightPercent = { height: `${valuePercent}%` };
    const heightBgColor = { height: `calc(${height - 30}px - 57px)` };
    let onfireClass = 'thermometer__onfire';
    if (!onfire) {
      onfireClass = 'thermometer__draw-a';
    }
    return (
      <div key={data.id} className="speedometer_box" id="speedometer_box">
        <div
          style={{
            width: width,
            height: height - marginBottom,
            overflow: 'hidden',
            display: 'flex',
            position: 'relative',
          }}
          onMouseMove={getMousePos}
          onMouseOut={hideTooltip}
        >
          <div style={thermoHeight} className={`thermometer ${thermoSize} ${thermoTheme}`}>
            <div className={onfireClass}></div>
            <div className={`thermometer__draw-b${reverseGradient}`}></div>
            <div className="thermometer__meter">
              {showLabels && (
			    <ul className="thermometer__statistics">{stepIntervals}</ul>
			  )}
              <div style={heightPercent} className="thermometer__mercury">
                <div className="thermometer__percent-current">{valstr}</div>
                <div className="thermometer__mask">
                  <div className={`thermometer__bg-color${reverseGradient}`} style={heightBgColor}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showText && (
          <div
            style={{
              color: String(data.textColor),
              marginTop: String(data.valueTextFontSize / 4) + 'px',
              fontSize: String(data.valueTextFontSize) + 'px',
              lineHeight: String(data.valueTextFontSize) + 'px',
              fontWeight: '700'
            }}
            className={SpeedOdometerLegend}
          >
            {data.currentValueText}
          </div>
        )}
      </div>
    );
  });
  return thermometer;
}

function createIntervalsUI(intervals: Intervals[]) {
  return intervals.map((step, i) => {
    return (
      <li key={i} style={{ bottom: `calc(${step.percent}% - 1px)` }}>
        {step.label}
      </li>
    );
  });
}

function liquidFillGaugeFactory(
  tableData: SpeedData[],
  width: number,
  height: number,
  showText: boolean,
  centered: boolean,
  onfire: boolean,
  validUrl: boolean,
  unit: string,
  needleColor: string,
  warningColor: string,
  showInfoField: boolean
) {
  const panelMinDimension = Math.min(width, height);
  var marginBottom = 10;
  if (showText) {
    marginBottom = 25;
  }
  var panelWidth = panelMinDimension - marginBottom;
  if (height > width + marginBottom) {
    panelWidth = panelMinDimension;
  }
  let marginLeft = 0;
  if (centered) {
    marginLeft = (width - panelWidth) / 2;
  }
  let tooltipClass = 'SpeedOdometerTooltip';
  var SpeedOdometerLegend = 'SpeedOdometerLegend';
  let waveColor = {
    fill: needleColor,
  };
  let waveTextStyle = {
    fill: 'rgb(255, 255, 255)',
  };
  let textColor = {
    fill: 'rgb(0, 0, 0)',
  };
  let circleStyle = {
    fill: 'rgb(15, 15, 180)',
  };
  const isDark = config.theme.isDark;
  if (isDark) {
    waveTextStyle = {
      fill: 'rgb(0, 0, 0)',
    };
    textColor = {
      fill: 'rgb(255, 255, 255)',
    };
    circleStyle = {
      fill: 'rgb(70, 70, 240)',
    };
  }
  if (onfire) {
    tooltipClass = 'SpeedOdometerTooltip SpeedOdometerTooltipWarn';
    waveColor = {
      fill: warningColor,
    };
    SpeedOdometerLegend = 'SpeedOdometerLegend SpeedOdometerLegendWarn';
  }

  const label = tableData.map(data => {
    var tooltipLabel = '<span><p class="SpeedOdometerTooltip_center">';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<i class="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>';
      tooltipLabel = tooltipLabel + '<b class="SpeedOdometerTooltip_title">Alarma</b></p></span>';
    }
    tooltipLabel = tooltipLabel + '<b>' + data.valueTitle + ': </b>' + data.value.toFixed(2) + ' ' + unit + '<br />';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<b>Umbral de alarma: </b>' + data.threshold.toFixed(2) + ' ' + unit + '<br />';
    }
    if (showInfoField) {
	  tooltipLabel = tooltipLabel + '<b>' + data.infoTitle + ' </b>' + data.info;
	}
    if (validUrl) {
      tooltipLabel = tooltipLabel + '<p>Clic para ver más detalles ...</p>';
    }
    return tooltipLabel;
  });
  
  const getMousePos = (e) => {
    let x = e.pageX;
    let y = e.pageY;
    $('#panelTooltip').html(label);
    $('#panelTooltip').addClass(tooltipClass);
    $('#panelTooltip').css({
      'left': x + 10 + 'px',
      'top': y + 'px',
      'visibility': 'visible' 
    });
  };

  const hideTooltip = (e) => {
    $('#panelTooltip').html('');
    $('#panelTooltip').removeClass(tooltipClass);
    $('#panelTooltip').css({
      'visibility': 'hidden' 
    });
  };

  return tableData.map(data => (
    <div key={data.id} className="speedometer_box" id="speedometer_box">
      <div
        style={{
          width: panelWidth,
          height: panelWidth,
          overflow: 'hidden',
          display: 'flex',
          position: 'relative',
          marginLeft: marginLeft,
        }}
        onMouseMove={getMousePos}
        onMouseOut={hideTooltip}
      >
        <LiquidFillGauge
          style={{ margin: '0 auto' }}
          width={panelWidth}
          height={panelWidth}
          value={((data.value - data.minValue) / (data.maxValue - data.minValue)) * 100}
          percent={unit}
          textSize={1}
          textOffsetX={0}
          textOffsetY={0}
          textRenderer={() => {
            const valueStyle = {
              fontSize: data.labelFontSize,
            };
            const percentStyle = {
              fontSize: data.labelFontSize,
            };
            return (
              <tspan>
                <tspan className="value" style={valueStyle}>
                  {data.value}
                </tspan>
                <tspan style={percentStyle}>{unit}</tspan>
              </tspan>
            );
          }}
          riseAnimation
          waveAnimation
          waveStyle={waveColor}
          circleStyle={circleStyle}
          textStyle={textColor}
          waveTextStyle={waveTextStyle}
          waveFrequency={2}
          waveAmplitude={1}
        />
      </div>
      {showText && (
        <div
          style={{
            color: String(data.textColor),
            marginTop: String(data.valueTextFontSize / 4) + 'px',
            fontSize: String(data.valueTextFontSize) + 'px',
            lineHeight: String(data.valueTextFontSize) + 'px',
            fontWeight: '700'
          }}
          className={SpeedOdometerLegend}
        >
          {data.currentValueText}
        </div>
      )}
    </div>
  ));
}

function batteryFactory(
  tableData: SpeedData[],
  width: number,
  height: number,
  showText: boolean,
  centered: boolean,
  onfire: boolean,
  validUrl: boolean,
  revertColorScale: boolean,
  unit: string,
  showInfoField: boolean
) {
  var tooltipClass = 'SpeedOdometerTooltip';
  var SpeedOdometerLegend = 'SpeedOdometerLegend';
  if (onfire) {
    tooltipClass = 'SpeedOdometerTooltip SpeedOdometerTooltipWarn';
    SpeedOdometerLegend = 'SpeedOdometerLegend SpeedOdometerLegendWarn';
  }
  const panelMinDimension = Math.min(width, height);
  const panelMaxDimension = Math.max(width, height);

  var panelWidth = panelMaxDimension;
  var panelHeight = panelMinDimension;
  var marginBottom = 10;
  if (showText) {
    marginBottom = 25;
  }
  if (panelWidth > panelHeight * 1.5 - marginBottom) {
    panelWidth = panelHeight * 1.5 - marginBottom;
  }
  if (height > width) {
    panelWidth = panelMinDimension;
    panelHeight = panelWidth - marginBottom;
  }
  const svgHeight = Math.round(panelWidth * 0.56) - 2;

  const marginLines = Math.round(panelWidth * 0.09);
  let marginLeft = 10;
  if (centered) {
    marginLeft = (width - panelWidth) / 2;
  }

  var backgroundClass = {
    width: '100%',
    height: '87%',
    display: 'flex',
    backgroundImage: `url(/public/img/icons/unicons/gaugebattery-empty.svg)`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  };
  const isDark = config.theme.isDark;
  if (isDark) {
    backgroundClass = {
      width: '100%',
      height: '87%',
      display: 'flex',
      backgroundImage: `url(/public/img/icons/unicons/gaugebattery-empty_dark.svg)`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    };
  }

  const label = tableData.map(data => {
    var tooltipLabel = '<span><p class="SpeedOdometerTooltip_center">';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<i class="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>';
      tooltipLabel = tooltipLabel + '<b class="SpeedOdometerTooltip_title">Alarma</b></p></span>';
    }
    tooltipLabel = tooltipLabel + '<b>' + data.valueTitle + ': </b>' + data.value.toFixed(2) + ' ' + unit + '<br />';
    if (onfire) { 
      tooltipLabel = tooltipLabel + '<b>Umbral de alarma: </b>' + data.threshold.toFixed(2) + ' ' + unit + '<br />';
    }
    if (showInfoField) {
      tooltipLabel = tooltipLabel + '<b>' + data.infoTitle + ' </b>' + data.info;
    }
	if (validUrl) {
      tooltipLabel = tooltipLabel + '<p>Clic para ver más detalles ...</p>';
    }
    return tooltipLabel;
  });
  
  const getMousePos = (e) => {
    let x = e.pageX;
    let y = e.pageY;
    $('#panelTooltip').html(label);
    $('#panelTooltip').addClass(tooltipClass);
    $('#panelTooltip').css({
      'left': x + 10 + 'px',
      'top': y + 'px',
      'visibility': 'visible' 
    });
  };

  const hideTooltip = (e) => {
    $('#panelTooltip').html('');
    $('#panelTooltip').removeClass(tooltipClass);
    $('#panelTooltip').css({
      'visibility': 'hidden' 
    });
  };

  const battery = tableData.map(data => {
    const percentage = ((data.value - data.minValue) / (data.maxValue - data.minValue)) * 100;
    const segmentWide = Math.round(100 / data.segmentsLength);
    const segments = Math.round(percentage / segmentWide);
    var bateryLines: BateryLines[] = [];

    if (segments > 0) {
      for (let i = 1; i <= segments; i++) {
        let total = 0;
        for (let n = 0; n <= i; n++) {
          total = total + segmentWide;
        }
        let interval: BateryLines = {
          value: Number(total).toFixed(1),
          id: i,
        };
        bateryLines.push(interval);
      }
    }

    const batteryIntervals = createBatteryIntervals(
      bateryLines,
      data.segmentsLength,
      panelWidth,
      svgHeight,
      onfire,
      data.needleColor,
      data.warningColor
    );

    return (
      <div key={data.id} className="speedometer_box" id="speedometer_box">
        <div
          style={{
            width: panelWidth,
            height: panelHeight,
            overflow: 'hidden',
            display: 'flex',
            position: 'relative',
            marginLeft: marginLeft,
          }}
        >
          <div
            style={backgroundClass}
            onMouseMove={getMousePos}
            onMouseOut={hideTooltip}
          >
            <div
              style={{
                left: marginLines,
                top: svgHeight * 0.15,
                height: svgHeight * 0.7,
              }}
              className="linesContainer"
            >
              {batteryIntervals}
            </div>
          </div>
        </div>
        {showText && (
          <div
            style={{
              color: String(data.textColor),
              marginTop: '-' + String(data.valueTextFontSize) + 'px',
              fontSize: String(data.valueTextFontSize) + 'px',
              lineHeight: String(data.valueTextFontSize) + 'px',
              fontWeight: '700'
            }}
            className={SpeedOdometerLegend}
          >
            {data.currentValueText}
          </div>
        )}
      </div>
    );
  });
  return battery;
}

function createBatteryIntervals(
  bateryLines: BateryLines[],
  segments: number,
  width: number,
  height: number,
  onfire: boolean,
  needleColor: string,
  warningColor: string
) {
  const gaugeWidth = width * 0.8;
  const gaugeContentWidth = gaugeWidth * 0.84;
  const gaugeBarsNb = segments;
  const gaugeBarWidth = gaugeContentWidth / gaugeBarsNb;
  const gaugeBarMargin = 1;
  const gaugeBarRadius = 15;
  const paddingBottom = height * 0.015;
  var barBackgroundColor = needleColor;

  if (onfire) {
    barBackgroundColor = warningColor;
  }
  const styles = {
    barContainer: {
      width: `${gaugeBarWidth}px`,
      height: '100%',
      paddingLeft: `${gaugeBarMargin}px`,
      paddingRight: `${gaugeBarMargin}px`,
      paddingBottom: `${paddingBottom}px`,
    },
    bar: {
      width: `${gaugeBarWidth - gaugeBarMargin * 2}px`,
      height: '100%',
      backgroundColor: `${barBackgroundColor}`,
      zIndex: 1,
    },
    barFirst: {
      borderTopLeftRadius: `${gaugeBarRadius}px`,
      borderBottomLeftRadius: `${gaugeBarRadius}px`,
    },
    barLast: {
      borderTopRightRadius: `${gaugeBarRadius}px`,
      borderBottomRightRadius: `${gaugeBarRadius}px`,
    },
  };

  return bateryLines.map((step, i) => {
    return (
      <div style={styles.barContainer}>
        {i === 0 ? (
          <div key={step.id} id={step.value} style={{ ...styles.bar, ...styles.barFirst }} />
        ) : i === segments ? (
          <div key={step.id} id={step.value} style={{ ...styles.bar, ...styles.barLast }} />
        ) : (
          <div key={step.id} id={step.value} style={{ ...styles.bar }} />
        )}
      </div>
    );
  });
}

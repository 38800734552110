import React from 'react';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { TableOptions, DataTableData } from 'types';
import { config } from '@grafana/runtime';
import './css/netmonitor-infoTable.css';

interface Props extends PanelProps<TableOptions> {}

export const infoTablePanel: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {
  const isDark = config.theme.isDark;
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);

  let infoTable_container = 'infoTable_container';
  if (isDark) {
    infoTable_container = 'infoTable_container_dark';
  }
  let base_url = replaceVariables(options.drillDownLink);
  if (base_url !== null || base_url !== undefined) {
    base_url = '';
  }
  let separator = options.separator;
  if (separator === null || separator === undefined) {
    separator = '';
  }
  const tables: DataTableData[] = [];

  if (width < 150 || height < 150) {
    return (
      <div>
        <div className="gf-form-error">{error4}</div>
      </div>
    );
  } else if (data.state !== 'Error') {
    if(data.series[0].length > 0) {
      data.series.forEach(series => {
        const timeVals: GraphSeriesValue[] = series.fields[0].values.toArray();
        for (let i = 0; i < timeVals.length; i++) {
          let tableData: DataTableData = {
            id: i,
            tag: series.fields.find(field => field.name === options.tableTagOption)?.values.get(i),
            value: series.fields.find(field => field.name === options.tableValueOption)?.values.get(i),
            style: series.fields.find(field => field.name === options.tableStyleOption)?.values.get(i),
            title: series.fields.find(field => field.name === options.tableTitleOption)?.values.get(i),
            url: series.fields.find(field => field.name === options.tableUrlOption)?.values.get(i),
            target: series.fields.find(field => field.name === options.tableUrlTargetOption)?.values.get(i),
          };
          if (tableData.style === null || tableData.style === undefined || tableData.style === '') {
            tableData.style = 'default';
          }
          if (isDark && tableData.style === 'default') {
            tableData.style = 'default_dark';
          }
          if (String(separator).length > 0) {
            tableData.tag = tableData.tag + separator;
          }
          if (tableData.title === null || tableData.title === undefined) {
            tableData.title = tableData.tag;
          }
          if (tableData.target === null || tableData.target === undefined || tableData.target !== '_self') {
            tableData.target = '_blank';
          }
          if (tableData.url === null || tableData.url === undefined || String(tableData.url).length < 1) {
            tableData.url = '';
            tableData.target = '';
          }
          if (base_url !== '') {
            tableData.url = base_url + tableData.url;
          }
          tables.push(tableData);
        }
      });
      return <div className={infoTable_container}>{rowFactory(tables)}</div>;
    } else {
        return (
          <div>
            <div className="gf-form-error">{error1}</div>
          </div>
        );
    } 
  } else {
    return (
      <div>
        <div className="gf-form-error">{error3}</div>
      </div>
    );
  }
};

function rowFactory(tableData: DataTableData[]) {
  const isDark = config.theme.isDark;
  var infoTableRowTitle = 'infoTable-rowTitle';
  var infoTableRowValue = 'infoTable-rowValue';
  var infoTableRowLink = 'infoTable-rowLink';
  if (isDark) {
    infoTableRowTitle = 'infoTable-rowTitle_dark';
    infoTableRowValue = 'infoTable-rowValue_dark';
    infoTableRowLink = 'infoTable-rowLink_dark';
  }
  return tableData.map(row => (
    <div key={row.id} className="rowContainer" title={row.title}>
      <span className={infoTableRowTitle}>{row.tag}</span>
      {row.target !== '' && (
        <span className={infoTableRowValue + ' infoTable_' + row.style}>
          <a href={row.url} rel="nofollow noopener noreferrer" target={row.target} className={infoTableRowLink}>
            {row.value}
          </a>
        </span>
      )}
      {row.target === '' && <span className={infoTableRowValue + ' infoTable_' + row.style}>{row.value}</span>}
    </div>
  ));
}

import { cx } from '@emotion/css';
import React, { FC } from 'react';

import { getCellLinks } from '@grafana/ui/src/utils';
import { Icon } from '@grafana/ui';
import { DataLinksContextMenu } from '@grafana/ui/src/components/DataLinks/DataLinksContextMenu';

import { TableCellProps } from './types';

export const ImageCell: FC<TableCellProps> = (props) => {
  const { field, cell, tableStyles, row, cellProps } = props;

  const displayValue = field.display!(cell.value);
  const hasLinks = Boolean(getCellLinks(field, row)?.length);

  return (
    <div {...cellProps} className={tableStyles.cellContainer}>
      {!hasLinks && (
        <div className={tableStyles.iconCell}>
          <Icon name={displayValue.text} size="lg" />
        </div>
      )}
      {hasLinks && (
        <DataLinksContextMenu className={tableStyles.iconCell} links={() => getCellLinks(field, row) || []}>
          {(api) => {
            return (
              <div onClick={api.openMenu} className={tableStyles.iconCell}>
                <Icon name={displayValue.text} size="lg" />
              </div>
            );
          }}
        </DataLinksContextMenu>
      )}
    </div>
  );
};

import { css } from '@emotion/css';
import React, { FC } from 'react';

import { TagList, useStyles2 } from '@grafana/ui';
import { Matcher } from 'app/plugins/datasource/alertmanager/types';
import { contextSrv } from 'app/core/services/context_srv';
import { matcherToOperator } from '../../utils/alertmanager';

type MatchersProps = { matchers: Matcher[] };

export const Matchers: FC<MatchersProps> = ({ matchers }) => {
  const styles = useStyles2(getStyles);
  const isAdmin = contextSrv.isNetMonitorAdmin;
  if (isAdmin) {
    return (
      <div>
        <TagList
          className={styles.tags}
          tags={matchers.map((matcher) => `${matcher.name} ${matcherToOperator(matcher)} ${matcher.value}`)}
        />
      </div>
    );
  } else {
    return (
      <div>
        <TagList
          className={styles.tags}
          tags={matchers.map((matcher) => `${matcher.value}`)}
        />
      </div>
    );
  }
};

const getStyles = () => ({
  tags: css`
    justify-content: flex-start;
  `,
});

import { FieldType, PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { FieldSelectEditor } from './FieldSelectEditor';
import { IPCheckPanel } from './IPCheckPanel';

export const plugin = new PanelPlugin<SimpleOptions>(IPCheckPanel).setPanelOptions(builder => {
  return builder
    .addBooleanSwitch({
      path: 'urlOrBwChecker',
      name: 'URLs or Bandwidth checker',
      description: 'If it is active, shows bandwidth measures',
      category: ['General Settings'],
	  defaultValue: false,
    })
    .addCustomEditor({
      id: 'urlOption',
      path: 'urlOption',
      name: 'URL check path option',
      description: 'The url filed list to check',
      defaultValue: 'url',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'successOption',
      path: 'successOption',
      name: 'URL success check option',
      description: 'Was the check success?',
      defaultValue: 'success',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'rttOption',
      path: 'rttOption',
      name: 'RTT option',
      description: 'The time took to check the url',
      defaultValue: 'rtt',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'typeOption',
      path: 'typeOption',
      name: 'Check type option',
      description: 'Type of comprobation used',
      defaultValue: 'type',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'resultOption',
      path: 'resultOption',
      name: 'Check result option',
      description: 'Result obtained',
      defaultValue: 'result',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addNumberInput({
      path: 'iconSize',
      name: 'Icon size',
      category: ['General Settings'],
      defaultValue: 16,
    })
    .addNumberInput({
      path: 'mainFont',
      name: 'Main Title Font size',
      category: ['General Settings'],
      defaultValue: 18,
    })
    .addNumberInput({
      path: 'secFont',
      name: 'SubTitle Font size',
      category: ['General Settings'],
      defaultValue: 14,
    })
    .addColorPicker({
      path: 'mainColor',
      name: 'Main Title Font color',
      category: ['General Settings'],
      defaultValue: '#007865',
    })
    .addColorPicker({
      path: 'secColor',
      name: 'SubTitle Font color',
      category: ['General Settings'],
      defaultValue: '#888',
    })
	.addTextInput({
      path: 'errorImage',
      name: 'Query error image',
      category: ['General Settings'],
      defaultValue: 'image-block.svg',
    })
	.addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener imágenes',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'No hay imágenes para mostrar',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error: al descargar imágen',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});

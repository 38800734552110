import React, { ChangeEvent } from 'react';
import { StandardEditorContext, StandardEditorProps } from '@grafana/data';
import { IconResource, PanelSettings } from '../types';
import assetUtils from '../panel/asset_utils';
import './IconMapping.css';

interface Props extends StandardEditorProps<string, PanelSettings> {
  item: any;
  value: any;
  onChange: (value?: string) => void;
  context: StandardEditorContext<any>;
}

interface State {
  item: any;
  value: string;
  onChange: (value?: string) => void;
  context: StandardEditorContext<any>;
  icons: string[];
}

export class IconMapping extends React.PureComponent<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      ...props,
      icons: [] = [],
    };
    fetch(assetUtils.getIconUrl('icon_index.json'))
      .then(response => response.json())
      .then(data => {
        data.sort();
        this.setState({
          icons: data,
        });
      })
      .catch(() => {
        console.error(
          'Could not load service icons mapping index. Please verify the "icon_index.json" in the plugin\'s asset directory.'
        );
      });
  }

  addMapping() {
    const { path } = this.state.item;
    const icons = this.state.context.options[path];
    icons.push({
      pattern: '[a-zA-Z]+[0-9]+',
      filename: 'default',
      node_title: '-',
      node_header: '-',
      links_title: '-',
      links_header: '-',
    });
    this.state.onChange.call(path, icons);
  }

  removeMapping(index: number) {
    const { path } = this.state.item;
    const icons = this.state.context.options[path];
    icons.splice(index, 1);
    this.state.onChange.call(path, icons);
  }

  setPatternValue(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { path } = this.state.item;
    const icons = this.state.context.options[path];
    icons[index].pattern = event.currentTarget.value;
    this.state.onChange.call(path, icons);
  }

  setnode_titleValue(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { path } = this.state.item;
    const icons = this.state.context.options[path];
    icons[index].node_title = event.currentTarget.value;
    this.state.onChange.call(path, icons);
  }

  setnode_headerValue(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { path } = this.state.item;
    const icons = this.state.context.options[path];
    icons[index].node_header = event.currentTarget.value;
    this.state.onChange.call(path, icons);
  }

  setlinks_titleValue(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { path } = this.state.item;
    const icons = this.state.context.options[path];
    icons[index].links_title = event.currentTarget.value;
    this.state.onChange.call(path, icons);
  }

  setlinks_headerValue(event: React.ChangeEvent<HTMLInputElement>, index: number) {
    const { path } = this.state.item;
    const icons = this.state.context.options[path];
    icons[index].links_header = event.currentTarget.value;
    this.state.onChange.call(path, icons);
  }

  setFileNameValue(event: ChangeEvent<HTMLSelectElement>, index: number) {
    const { path } = this.state.item;
    const icons = this.state.context.options[path];
    icons[index].filename = event.currentTarget.value.toString();
    this.props.onChange.call(path, icons);
  }

  render() {
    const { path } = this.state.item;
    const { icons: iconNames } = this.state;
    var icons = this.state.context.options[path];
    if (icons === undefined) {
      icons = this.state.item.defaultValue;
      //this.setState.context.options[path] = this.state.item.defaultValue;
    }

    return (
      <div>
        <div>
          <div className="gf-form width-98">
            <label className="label width-half">Node Name (RegEx)</label>
            <label className="label width-half">Node Table Title</label>
            <label className="label width-half">Node Table Header</label>
            <label className="label width-half">Links Table Title</label>
            <label className="label width-half">Links Table Header</label>
            <label className="label width-half">Icon</label>
          </div>
        </div>
        <div>
          {icons.map((icon: IconResource, index: number) => (
            <>
              <div className="gf-form width-100">
                <input
                  type="text"
                  className="input-small gf-form-input"
                  value={icon.pattern}
                  onChange={e => this.setPatternValue(e, index)}
                />
                <input
                  type="text"
                  className="input-small gf-form-input"
                  value={icon.node_title}
                  onChange={e => this.setnode_titleValue(e, index)}
                />
                <input
                  type="text"
                  className="input-small gf-form-input"
                  value={icon.node_header}
                  onChange={e => this.setnode_headerValue(e, index)}
                />
                <input
                  type="text"
                  className="input-small gf-form-input"
                  value={icon.links_title}
                  onChange={e => this.setlinks_titleValue(e, index)}
                />
                <input
                  type="text"
                  className="input-small gf-form-input"
                  value={icon.links_header}
                  onChange={e => this.setlinks_headerValue(e, index)}
                />
                <input
                  type="text"
				  list="icons"
				  name="icons"
                  className="input-small gf-form-input"
                  placeholder={icon.filename}
                  onChange={e => this.setFileNameValue(e, index)}
                />		
				<datalist id="icons">
                  {iconNames.map((iconNames: string) => (
                    <option key={iconNames} value={iconNames}>
                      {iconNames}
                    </option>
                  ))}
                </datalist>
                <a className="gf-form-label tight-form-func no-background" onClick={() => this.removeMapping(index)}>
                  <i className="fa fa-trash"></i>
                </a>
              </div>
            </>
          ))}
        </div>
        <button
          className="btn navbar-button navbar-button--primary icon-mapping-button"
          onClick={() => this.addMapping()}
        >
          Add Icon Mapping
        </button>
      </div>
    );
  }
}

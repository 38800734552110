import { css } from '@emotion/css';
import React from 'react';
import { useStyles2, CustomScrollbar } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';
import { UsersManager } from './UsersManager';

export const UsersManagerPanel: React.FC<Props> = ({ options, replaceVariables, id }) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.section} key={String(id)}>
      <CustomScrollbar autoHeightMin="100%" autoHeightMax="100%">
        <UsersManager options={options} id={id}/>
      </CustomScrollbar>
    </div>
  );
};

const getStyles = (theme: NetMonitorTheme2) => ({
  section: css`
    margin: ${theme.spacing(2)};
  `,
});

import { FieldType, PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { FieldSelectEditor } from './FieldSelectEditor';
import { SSHPanel } from './SSHPanel';

export const plugin = new PanelPlugin<SimpleOptions>(SSHPanel).setPanelOptions(builder => {
  return builder
    .addTextInput({
      id: 'collectorOption',
      path: 'collectorOption',
      name: 'Collector IP Field',
      description: 'The Collector IP Address to use',
      defaultValue: 'collector',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addTextInput({
      id: 'collectorPortOption',
      path: 'collectorPortOption',
      name: 'Collector TCP port Field',
      description: 'The Collector TCP port in use',
      defaultValue: 'cport',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addTextInput({
      id: 'hostNameOption',
      path: 'hostNameOption',
      name: 'Host Name Field',
      description: 'The Asset ID',
      defaultValue: 'host',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addTextInput({
      id: 'hostLocationOption',
      path: 'hostLocationOption',
      name: 'Host location Field',
      description: 'The Asset location',
      defaultValue: 'host',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addTextInput({
      id: 'hostOption',
      path: 'hostOption',
      name: 'Host IP Field',
      description: 'The IP Address to open ssh session',
      defaultValue: 'host',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addTextInput({
      id: 'portOption',
      path: 'portOption',
      name: 'HTCP port Field',
      description: 'The TCP port to open ssh session',
      defaultValue: 'port',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'secureSession',
      name: 'HTTPS',
      description: 'Session type (HTTP / HTTPS) to open with collector',
      category: ['General Settings'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'displayCredentials',
      name: 'Show usr/pass dialog box',
      description: 'Show prompt to enter Sessions credentials',
      category: ['General Settings'],
      defaultValue: false,
    })
	.addTextInput({
      path: 'welcome',
      name: 'Welcome Banner',
      category: ['General Settings'],
      defaultValue: 'Por favor ingrese sus credenciales para iniciar sesión',
    })
	.addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'Datos nulos',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error: el formato de datos no es compatible con el complemento',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});

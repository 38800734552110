import React, { Component } from 'react';
import { config, locationService } from '@grafana/runtime';
import './css/navbarOptions.css';
import { Icon } from '@grafana/ui';

interface NavbarOptionProps {
  id: number;
  type: string;
  text: string;
  icon: string;
  url: string;
  primary?: boolean;
  style: string;
  target: string,
}

export class NavbarOption extends Component<NavbarOptionProps> {
  constructor(props: any) {
    super(props);
    this.handleUrl = this.handleUrl.bind(this);
  }

  handleUrl() {
    if (this.props.url !== '') {
      window.open(this.props.url, this.props.target);
    }
  }

  render() {
    let isDark = config.theme.isDark || false;
    const title = String(this.props.title) || '';
    var classOption = 'option_disable_light option_white_disable';
    if (isDark) {
      classOption = 'option_disable_dark option_black_disable';
    }
    if (this.props.primary === true) {
      classOption = 'option_enable_light option_white';
      if (isDark) {
        classOption = 'option_enable_dark option_black';
      }
    }
    return (
      <div className="option">
        <button className={classOption} title={title} onClick={this.handleUrl}>
          <div className="option_icon" >
            <Icon name={this.props.icon} size="xl" />
          </div>
        </button>
        {this.props.primary === true && <div className="option_indicator" />}
      </div>
    );
  }
}

export class GenericOption extends Component<NavbarOptionProps> {
  constructor(props: any) {
    super(props);
    this.handleUrl = this.handleUrl.bind(this);
  }

  handleUrl() {
    console.log(this.props.url + ', '+ this.props.target);
    if (this.props.url !== '') {
      window.open(this.props.url, this.props.target);
    }
  }

  render() {
    var classOption = 'option_disable_light option_white_disable';
    const title = String(this.props.title) || '';
    let isDark = config.theme.isDark || false;
    if (isDark) {
      classOption = 'option_disable_dark option_black_disable';
    }
    if (this.props.style !== '' && this.props.style !== 'default' && this.props.style !== 'grey') {
      classOption = 'option_disable_light option_' + this.props.style + '_disable';
      if (isDark) {
        classOption = 'option_disable_dark option_' + this.props.style + '_disable';
      }
    } else if (this.props.style === 'grey') {
      classOption = 'option_disable_light option_grey_light_disable';
      if (isDark) {
        classOption = 'option_disable_dark option_grey_dark_disable';
      }
    }
    return (
      <div className="generic_option">
        <button className={classOption} onClick={this.handleUrl} title={title}>
          <div className="option_icon">
            <Icon name={this.props.icon} size="lg" />
          </div>
        </button>
      </div>
    );
  }
}

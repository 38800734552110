import React, { FC, useEffect, useState } from 'react';

import { OrgRole } from '@grafana/data';
import { Icon, ConfirmModal, Switch } from '@grafana/ui';
import { UserRolePicker } from 'app/core/components/RolePicker/UserRolePicker';
import { fetchBuiltinRoles, fetchRoleOptions } from 'app/core/components/RolePicker/api';
import { contextSrv } from 'app/core/core';
import { AccessControlAction, OrgUser, Role } from 'app/types';

import { OrgRolePicker } from 'app/features/admin/OrgRolePicker';

export interface Props {
  isAdmin: boolean;
  users: OrgUser[];
  orgId?: number;
  onRoleChange: (role: OrgRole, user: OrgUser) => void;
  onRemoveUser: (user: OrgUser) => void;
}

const UsersTable: FC<Props> = (props) => {
  const { isAdmin, users, orgId, onRoleChange, onRemoveUser } = props;
  const [userToRemove, setUserToRemove] = useState<OrgUser | null>(null);
  const [roleOptions, setRoleOptions] = useState<Role[]>([]);
  const [builtinRoles, setBuiltinRoles] = useState<{ [key: string]: Role[] }>({});

  /*useEffect(() => {
    async function fetchOptions() {
      try {
        if (contextSrv.hasPermission(AccessControlAction.ActionRolesList)) {
          let options = await fetchRoleOptions(orgId);
          setRoleOptions(options);
        }
        if (
          contextSrv.accessControlBuiltInRoleAssignmentEnabled() &&
          contextSrv.hasPermission(AccessControlAction.ActionBuiltinRolesList)
        ) {
          const builtInRoles = await fetchBuiltinRoles(orgId);
          setBuiltinRoles(builtInRoles);
        }
      } catch (e) {
        console.error('Error al cargar usuarios');
      }
    }
    if (contextSrv.licensedAccessControlEnabled()) {
      fetchOptions();
    }
  }, [orgId]);*/
  useEffect(() => {
    async function fetchOptions() {
      try {
        if (contextSrv.hasPermission(AccessControlAction.ActionRolesList)) {
          let options = await fetchRoleOptions(orgId);
          setRoleOptions(options);
        }
        if (contextSrv.hasPermission(AccessControlAction.ActionBuiltinRolesList)
        ) {
          const builtInRoles = await fetchBuiltinRoles(orgId);
          setBuiltinRoles(builtInRoles);
        }
      } catch (e) {
        console.error('Error al cargar usuarios');
      }
    }
    fetchOptions();
  }, [orgId]);

  return (
    <>
      {isAdmin && (
		<table className="filter-table form-inline">
          <thead>
            <tr>
              <th>Usuario</th>
              <th>Nombre</th>
              <th>e-mail</th>
              <th>Ultima sesión</th>
              <th>Supervisor</th>
              <th style={{ width: '34px' }} />
            </tr>
          </thead>
		  <tbody>
            {users.map((user, index) => {
              if (user.role !== 'Admin') {
                return (
                  <tr key={`${user.userId}-${index}`}>
                    <td className="max-width-6">
                      <span className="ellipsis" title={user.login}>
                        {user.login}
                      </span>
                    </td>
                    <td className="max-width-5">
                      <span className="ellipsis" title={user.name}>
                        {user.name}
                      </span>
                    </td>
                    <td className="max-width-5">
                      <span className="ellipsis" title={user.email}>
                        {user.email}
                      </span>
                    </td>
                    <td className="width-1">{user.lastSeenAtAge}</td>

                    <td className="width-1">
                      <Switch
                        aria-label="Role"
                        value={user.role === 'Editor' ? true : false}
                        onChange={(value) => {
                          let newRole = 'Viewer';
                          if (value.target.checked) {
                            user.role = 'Editor';
                            newRole = 'Editor';
                          }
                          onRoleChange(newRole, user);
                        }}
                      />
                    </td>
                    <td>
                      <Icon
                        size="lg"
                        onClick={() => {
                          setUserToRemove(user);
                        }}
                        name="trash-alt"
                        title="Eliminar usuario"
                        aria-label="Delete user"
                      />
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
	  )}
      {Boolean(userToRemove) && (
        <ConfirmModal
          body={`Seguro desea borrar el usuario ${userToRemove?.login}?`}
          confirmText="borrar"
          title="Eliminar usuario de la organización"
          onDismiss={() => {
            setUserToRemove(null);
          }}
          isOpen={true}
          onConfirm={() => {
            if (!userToRemove) {
              return;
            }
            //console.log(userToRemove);
            onRemoveUser(userToRemove);
            setUserToRemove(null);
          }}
        />
      )}
    </>
  );
};

export default UsersTable;
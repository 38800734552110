// @ts-nocheck
import React from 'react';
import * as d3 from 'd3';
import { contextSrv } from 'app/core/services/context_srv';
import { PanelProps } from '@grafana/data';
import { PieChartOptions } from 'types';
import './css/PieChartPanel.css';
import { config } from '@grafana/runtime';

interface Props extends PanelProps<PieChartOptions> {}

export const PieChartPanel: React.FC<Props> = ({ options, data, width, height, replaceVariables, id }) => {
  // -----------------------    CHART CONSTANTS    -----------------------
  var CHART_REQUIRED_FIELDS = {
    category: options.categoryField,
    value: options.valueField,
    color: options.colorField,
    metric: options.metricField,
    info: options.infoField,
  };
  const LEGEND_POSITION = { bottom: 'bottom', right: 'right' };

  // -----------------------  CHART CONFIGURATION  -----------------------
  const tooltipID = '#panelTooltip';
  const tooltipValue = '#d3TooltipValue';

  const isDark = config.theme.isDark;
  var borderColor = '#5e5e5e';
  var legendBackground = '#fafafa';
  var legendTextColor = '#333';
  var backgroundColor = '#fff';
  var textColor = '#222';
  var pieChartLegendBox = 'pieChartLegendBox';
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);
  const centerTextColor = replaceVariables(options.innerRadiusFontColor);
  const innerRadiusColor = replaceVariables(options.innerRadiusColor);
  const threshold = Number(replaceVariables(options.metricThreshold)) / 100;

  if (isDark) {
    borderColor = '#444';
    legendBackground = '#919191';
    legendTextColor = '#ddd';
    backgroundColor = '#000';
    textColor = '#111';
    pieChartLegendBox = 'pieChartLegendBox_dark';
  }
  const panelMinDimension = Math.min(width, height);
  const dimensions = {
    width: panelMinDimension,
    height: panelMinDimension,
    pieDiameter: panelMinDimension,
    totalFontSize: options.legendFontSize * 2,
    overFactor: 1.05,
    marginTop: 10,
    marginRight: 5,
    marginBottom: 5,
    marginLeft: 10,
    radius: 5,
  };
  const configOptions = {
    legendPosition: options.legendPosition,
    displayLegends: options.displayLegends,
    legendMinWidth: options.legendMinWidth,
    legendMinHeight: options.legendMinHeight,
    color: options.colorScale,
    pieCentered: options.pieCentered,
    displayTotals: options.displayTotals,
    displaysliceLegends: options.displaysliceLegends,
    innerRadius: panelMinDimension * (options.innerRadius / 100),
  };
  if (configOptions.displayLegends) {
    if (width > height && configOptions.legendPosition === 'auto') {
      configOptions.legendPosition = LEGEND_POSITION.right;
    } else if (width < height && configOptions.legendPosition === 'auto') {
      configOptions.legendPosition = LEGEND_POSITION.bottom;
    }
    if (
      (width < 250 && configOptions.legendPosition === LEGEND_POSITION.right) ||
      (height < 250 && configOptions.legendPosition === LEGEND_POSITION.bottom)
    ) {
      configOptions.displayLegends = false;
    }
  }
  const slice_link = replaceVariables(options.drillDownLink);
  const center_link = replaceVariables(options.drillDownLinkCenter);
  const legend_link = replaceVariables(options.drillDownLinkLegend);
  var categoryAccesor = [];
  var categories = [];
  var valueAccesor = [];
  var values = [];
  var total = 0;
  var infoAccesor = [];
  var metricAccesor = [];
  var colorAccesor = [];
  var frame = [];
  var dataLen = 0;

  // ----------------------- BASE DATA ACQUISITION -----------------------
  if (data.state !== 'Error') {
    if(data.series[0].length > 0) {
      frame = data.series[0];
      dataLen = frame.length;
      if (options.valueField !== undefined &&
        options.valueField !== '' &&
        options.categoryField !== undefined &&
        options.categoryField !== ''
      ) {
        categoryAccesor = frame.fields.find(field => field.name === options.categoryField);
        categories = categoryAccesor.values.toArray();
        valueAccesor = frame.fields.find(field => field.name === options.valueField);
        values = valueAccesor.values.toArray();
        total = d3.sum(values);
        infoAccesor = valueAccesor;
        metricAccesor = valueAccesor;
        colorAccesor = valueAccesor;
        if (options.useInfoField && options.infoField !== undefined && options.inforField !== '') {
          infoAccesor = frame.fields.find(field => field.name === options.infoField);
        }
        if (!options.useColorScale && options.colorField !== undefined && options.colorField !== '') {
          colorAccesor = frame.fields.find(field => field.name === options.colorField);
        }
        if (options.useMetricField && options.metricField !== undefined && options.metricField !== '') {
          metricAccesor = frame.fields.find(field => field.name === options.metricField);
        }
      } else {
        return (
          <div>
            <div className="gf-form-error">{error1}</div>
          </div>
        );
      }
    } else {
        return (
          <div>
            <div className="gf-form-error">{error2}</div>
          </div>
        );
    } 
  } else {
    return (
      <div>
        <div className="gf-form-error">{error3}</div>
      </div>
    );
  }
  var indices = d3.range(dataLen);
  // -----------------------    LEGEND DIMENSIONS  -----------------------
  var textMaxWidth = 0;
  var columnNumber = 0;
  for (let i = 1; i < categories.length; ++i) {
    let textWidth = categories[i].length * options.legendFontSize;
    if (textWidth > textMaxWidth) {
      textMaxWidth = textWidth;
    }
  }
  textMaxWidth = textMaxWidth + options.legendMinHeight + 10;
  var legendColumns = Math.floor(
    (dimensions.width - dimensions.marginLeft * 2 - dimensions.marginRight) / textMaxWidth
  );
  var legendRows = Math.ceil(categories.length / legendColumns);
  if (configOptions.legendPosition === LEGEND_POSITION.right) {
    legendColumns = 1;
    legendRows = categories.length;
  }
  var legendWidth = legendColumns * textMaxWidth;
  if (legendWidth < configOptions.legendMinWidth) {
    legendWidth = configOptions.legendMinWidth;
  } else {
    if (legendWidth > configOptions.legendMaxWidth && configOptions.legendPosition === LEGEND_POSITION.right) {
      legendWidth = configOptions.legendMaxWidth;
    }
  }
  if (categories.length / legendColumns < 1) {
    legendWidth = (categories.length + 1) * textMaxWidth;
    columnNumber = categories.length;
  } else {
    columnNumber = legendColumns;
  }
  var textMaxHeight = options.legendFontSize + 5;
  var legendHeight = legendRows * textMaxHeight;
  if (legendHeight < configOptions.legendMinHeight) {
    legendHeight = configOptions.legendMinHeight;
  } else {
    if (legendHeight > configOptions.legendMaxHeight && configOptions.legendPosition === LEGEND_POSITION.bottom) {
      legendHeight = configOptions.legendMaxHeight;
    }
  }
  var legendPosx = dimensions.marginLeft;
  var legendPosy = dimensions.marginTop / 2;
  var legendBoxWidth = legendWidth + 10;
  var legendBoxHeight = legendHeight + 10;

  // -----------------------    CHART DIMENSIONS  -----------------------

  if (
    (legendBoxHeight > height && configOptions.legendPosition === LEGEND_POSITION.right) ||
    (legendBoxWidth > width && configOptions.legendPosition === LEGEND_POSITION.bottom)
  ) {
    configOptions.displayLegends = false;
  }

  if (configOptions.displayLegends && configOptions.legendPosition === LEGEND_POSITION.right && width < 400) {
    configOptions.displayLegends = false;
  }

  if (configOptions.displayLegends && configOptions.legendPosition === LEGEND_POSITION.bottom && height < 200) {
    configOptions.displayLegends = false;
  }

  if (configOptions.displayLegends) {
    if (configOptions.legendPosition === LEGEND_POSITION.bottom) {
      dimensions.pieDiameter = height - legendBoxHeight - dimensions.marginTop - dimensions.marginBottom;
      if (dimensions.pieDiameter > (width - dimensions.marginLeft - dimensions.marginRight)) {
        dimensions.pieDiameter = width - dimensions.marginLeft - dimensions.marginRight;
      }
    } else {
      dimensions.pieDiameter = width - legendBoxWidth - dimensions.marginLeft - dimensions.marginRight;
      if (dimensions.pieDiameter > (height - dimensions.marginTop - dimensions.marginBottom)) {
        dimensions.pieDiameter = height - dimensions.marginTop - dimensions.marginBottom;
      }
    }
  } else {
    if (width > height) {
      dimensions.pieDiameter = panelMinDimension - dimensions.marginTop - dimensions.marginBottom;
    } else {
      dimensions.pieDiameter = panelMinDimension - dimensions.marginLeft - dimensions.marginRight;
    }
  }

  const pieOuterRadius = dimensions.pieDiameter / 2.2;
  var pieInnerRadius = configOptions.innerRadius;
  if (configOptions.innerRadius > pieOuterRadius * 0.6) {
    pieInnerRadius = pieOuterRadius * 0.6;
  }
  
  if (options.displayTotals && pieInnerRadius < dimensions.totalFontSize) {
    dimensions.totalFontSize = pieInnerRadius / 3
    if (dimensions.totalFontSize > options.legendFontSize * 2) {
      dimensions.totalFontSize = options.legendFontSize * 2;
    }
  } 

  var pieCenter = [pieOuterRadius + dimensions.marginLeft, pieOuterRadius + dimensions.marginTop];
  var offsetx = 0;
  var offsety = 0;

  const maxArcRotationAngleRadi = 0.3;
  const minVisibleArcAngleRadi = 0.1;

  const rightFlex = configOptions.legendPosition === LEGEND_POSITION.right && !configOptions.pieCentered;

  // PIE ANNOTATIONS (VALUE %)
  const annotationRadius = (((pieOuterRadius - pieInnerRadius) / 2) + pieInnerRadius) * 1.1;
  
  // -----------------------    CHART ELEMENTS    -----------------------
  var colorByIndex = d3
    .scaleSequential()
    .domain([-1, dataLen])
    .interpolator(d3.interpolateRainbow);

  switch (configOptions.color) {
    case 'Viridis':
      colorByIndex = d3
        .scaleSequential()
        .domain([-1, dataLen])
        .interpolator(d3.interpolateViridis);
      break;
    case 'Inferno':
      colorByIndex = d3
        .scaleSequential()
        .domain([-1, dataLen])
        .interpolator(d3.interpolateInferno);
      break;
    case 'Blue':
      colorByIndex = d3
        .scaleSequential()
        .domain([-1, dataLen])
        .interpolator(d3.interpolateRdBu);
      break;
    case 'Brown':
      colorByIndex = d3
        .scaleSequential()
        .domain([-1, dataLen])
        .interpolator(d3.interpolateBrBG);
      break;
    case 'Purple':
      colorByIndex = d3
        .scaleSequential()
        .domain([-1, dataLen])
        .interpolator(d3.interpolatePRGn);
      break;
    case 'Orange':
      colorByIndex = d3
        .scaleSequential()
        .domain([-1, dataLen])
        .interpolator(d3.interpolatePuOr);
      break;
  }

  const colorByCategory = d3.scaleOrdinal(
    categories,
    indices.map(i => colorByIndex(i))
  );

  const radToDegree = d => (d * 180) / Math.PI;
  const rotateAnnotation = d => d.endAngle - d.startAngle < maxArcRotationAngleRadi;
  const displayAnnotation = d => d.endAngle - d.startAngle > minVisibleArcAngleRadi;

  const rotateRadialAnnotation = d => {
    // only rotate text if arc is to thin
    if (!rotateAnnotation(d)) {
      return 0;
    }
    const radialPieAngle = radToDegree((d.startAngle + d.endAngle) / 2);
    const pieToSvgCoordinatesFactor = 90;
    const radialSvGAngle = radialPieAngle + pieToSvgCoordinatesFactor;
    const upsideownRotation = radialSvGAngle > 90 && radialSvGAngle < 270 ? radialSvGAngle + 180 : radialSvGAngle;
    return upsideownRotation;
  };

  // VALUE FORMATING
  const formatValue = value => d3.format('.2~f')(value);
  const formatPercent = percent => d3.format('.1~%')(percent);
  const formatThousand = value => d3.format('.3~s')(value);

  const normalArc = d3
    .arc()
    .innerRadius(pieInnerRadius + 5)
    .outerRadius(pieOuterRadius)
    .cornerRadius(6);
  const bigArc = d3
    .arc()
    .innerRadius(pieInnerRadius + 10)
    .outerRadius(pieOuterRadius * dimensions.overFactor)
    .cornerRadius(8);
  const warnArc = d3
    .arc()
    .innerRadius(pieInnerRadius + 15)
    .outerRadius(pieOuterRadius * dimensions.overFactor)
    .cornerRadius(8);
  const arcAnnotation = d3
    .arc()
    .innerRadius(annotationRadius)
    .outerRadius(annotationRadius);
  var pie = d3
    .pie()
    .sort(null)
    .padAngle(0.02);
  var data_ready = pie(values);

  // ------------------------------- CHART  ------------------------------

  var chartTooltip = d3.select(`${tooltipID}`)  
    .attr('text-anchor', 'middle')
    .classed('pieChartTooltip', true)
    .classed('pieChartTooltipWarn', true)
    .classed('hidden', true)
    .html('<p><span id="' + `${tooltipValue}` + '"></span></p>');
      
  const pieChart = svg => {
    var divNode = d3.select('body').node();
    var svgPie = svg
      .append('g')
      .attr('id', 'main')
      .attr('transform', `translate(${pieCenter})`);

    var defs = svgPie.append('defs');
    var filter = defs
      .append('filter')
      .attr('id', 'drop-shadow')
      .attr('height', '130%');
    filter
      .append('feGaussianBlur')
      .attr('in', 'SourceAlpha')
      .attr('stdDeviation', 3)
      .attr('result', 'blur');
    filter
      .append('feOffset')
      .attr('in', 'blur')
      .attr('dx', 3)
      .attr('dy', 3)
      .attr('result', 'offsetBlur');

    var feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');

    var slice = svgPie
      .selectAll('.arc')
      .data(data_ready)
      .enter()
      .append('g')
      .attr('class', 'arc');
    var shape = slice
      .append('path')
      .attr('d', normalArc)
      .attr('stroke-width', '1px')
      .attr('stroke', borderColor)
      .style('fill', (d, i) => {
        if (options.useColorScale) {
          return colorByIndex(d.index);
        } else {
          return colorAccesor.values.get(i);
        }
      })
      .on('mousemove', function(d, i) {
        d3.select(this)
          .attr('stroke-width', '1px')
          .attr('d', bigArc)
          .style('filter', 'url(#drop-shadow)');
        d3.select(this)
          .transition()
          .duration(500)
          .ease(d3.easeLinear)
          .attr('transform', function(d) {
            var dist = 1.5;
            d.midAngle = d.endAngle - d.startAngle / 2 + d.startAngle;
            var x = Math.sin(d.midAngle) * dist;
            var y = Math.cos(d.midAngle) * dist;
            return 'translate(' + x + ',' + y + ')';
          });
        var label = '';
        let tooltipOffsetY = 5;
        let tooltipOffsetX = -5;
        if (contextSrv.isNetMonitorAdmin) {
          tooltipOffsetX = 50;
        }
        let uplimit = metricAccesor.values.get(i) * (1 + threshold);
        const des = String(Math.round((d.value / metricAccesor.values.get(i)) * 100 - 100));
        if (options.useMetricField && d.value > uplimit) {
          label = '<p><span id="' + `${tooltipValue}` + '"><span><p class="pieChartTooltip_center">';
          label = label + '<i class="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>';
          label = label + '<b class="pieChartTooltip_title">Alarma</b></p></span>';
          label = label + '<b>Desviación: </b>' + des + ' %</br />';
          tooltipOffsetY = 35;
        }
        label = label + '<span><b>' + options.categoryTitle + '</b> ' + categoryAccesor.values.get(i) + '<br /><b>';
        if (options.useMetricField) {
          label = label + options.metricTitle + '</b> ' + valueAccesor.values.get(i);
        }
        if (options.useInfoField) {
          label = label + '<br /><b>' + options.infoTitle + '</b> ' + infoAccesor.values.get(i);
        }
        label = label + '<br />Clic para ver más detalles ...</span></span></p>';
        if (options.useMetricField && d.value > uplimit) {
          chartTooltip.html(label)
            .style('left', d3.event.pageX - tooltipOffsetX + 'px')
            .style('top', d3.event.pageY + tooltipOffsetY + 'px')
            .classed('hidden', false)
            .classed('pieChartTooltipWarn', true);
        } else {
          chartTooltip.html(label)
            .style('left', d3.event.pageX - tooltipOffsetX + 'px')
            .style('top', d3.event.pageY + tooltipOffsetY + 'px')
            .classed('hidden', false)
            .classed('pieChartTooltipWarn', false);
        }
      })
      .on('mouseout', function() {
        d3.select(this)
          .style('filter', 'none')
          .attr('d', normalArc);
        d3.select(this)
          .transition()
          .duration(500)
          .ease(d3.easeLinear)
          .attr('transform', 'translate(0,0)');
        chartTooltip.classed('hidden', true);
      })
      .on('click', function(d, i) {
        let linkAsset = slice_link + `${categoryAccesor.values.get(i)}`;
        window.open(linkAsset, '_self');
      })
      .each((d, i, nodes) => {
        let uplimit = metricAccesor.values.get(i) * (1 + threshold);
        if (options.useMetricField && d.value > uplimit) {
          d3.select(nodes[i]).classed('pieChartWarn', true);
          d3.select(nodes[i]).attr('d', warnArc);
        }
      });

    // Leyendas
    if (configOptions.displaysliceLegends) {
      svgPie
        .append('g')
        .attr('text-anchor', 'middle')
        .attr('pointer-events', 'none')
        .selectAll('text')
        .data(data_ready)
        .join('text')
        .attr('transform', d => `translate(${arcAnnotation.centroid(d)}) rotate(${rotateRadialAnnotation(d)})`)
        .filter(displayAnnotation)
        .call(text =>
          text
            .append('tspan')
            .style('font-size', options.fontSize)
            .style('fill', textColor)
            .attr('dominant-baseline', 'middle')
            .each((d, i, nodes) => {
              if (!rotateAnnotation(d)) {
                d3.select(nodes[i]).attr('y', '-0.4em');
              }
            })
            .text((d, i) => {
              if (options.displayName) {
                return categoryAccesor.values.get(i);
              } else {
                return formatThousand(d.value);
              }
            })
        );
    }

    var centerSvg = svgPie
      .append('circle')
      .attr('fill', innerRadiusColor)
      .attr('r', String(pieInnerRadius));

    svgPie
      .append('g')
      .append('text')
      .attr('text-anchor', 'middle')
      .call(text =>
        text
          .append('tspan')
          .style('font-size', dimensions.totalFontSize)
          .style('font-weight', 600)
          .style('fill', centerTextColor)
          .attr('dominant-baseline', 'middle')
          .text(d => {
            if (configOptions.displayTotals) {
              return total;
            }
          })
      )
      .on('click', function() {
        window.open(center_link, '_self');
      });
  };

  const pieLegend = svg => {
    var legend = svg
      .append('g')
      .selectAll('g')
      .data(data_ready)
      .enter()
      .append('g')
      .attr('transform', function(d, i) {
        if (configOptions.legendPosition === LEGEND_POSITION.righ) {
          return 'translate(5,' + String(i * textMaxHeight) + ')';
        } else {
          let columnWidth = legendWidth / columnNumber;
          const y = Math.floor(i / legendColumns);
          const z = y * legendColumns;
          const col = (i - z) * columnWidth;
          const row = y * textMaxHeight;
          return 'translate(' + String(col + 10) + ',' + String(row + 5) + ')';
        }
      })
      .on('click', function(d, i) {
        let linkAsset = legend_link + `${categoryAccesor.values.get(i)}`;
        window.open(linkAsset, '_self');
      });

    legend
      .append('rect')
      .attr('width', options.legendMinHeight)
      .attr('height', options.legendMinHeight)
      .attr('stroke-width', '1px')
      .attr('stroke', borderColor)
      .style('fill', (d, i) => {
        if (options.useColorScale) {
          return colorByIndex(d.index);
        } else {
          return colorAccesor.values.get(i);
        }
      });

    legend
      .append('text')
      .style('font-size', options.legendFontSize)
      .style('font-weight', 'bold')
      .attr('dominant-baseline', 'start')
      .style('fill', legendTextColor)
      .attr('x', 24)
      .attr('y', 9)
      .text((d, i) => {
        return String(categoryAccesor.values.get(i));
      });
  };
  let svfOffsetX = offsetx;
  let svfOffsetY = offsety;
  let pieChartClass = 'pieChartMain';
  if (!configOptions.displayLegends) {
    if (configOptions.pieCentered) {
      svfOffsetX = (width - dimensions.pieDiameter) / 2;
      svfOffsetY = (height - dimensions.pieDiameter) / 2;
    }
  } else {
    if (!configOptions.pieCentered) {
      if (configOptions.legendPosition === LEGEND_POSITION.right) {
        legendPosx = width - legendBoxWidth - options.marginRight;
        legendPosy = options.marginTop;
      } else {
        legendPosx = options.marginLeft;
        legendPosy = height - legendBoxHeight - options.marginBottom;
        pieChartClass = 'pieChartMain_bottom';
      }
    } else {
      if (configOptions.legendPosition === LEGEND_POSITION.right) {
        legendPosx = width - legendBoxWidth - options.marginRight;
        legendPosy = options.marginTop;
        svfOffsetY = options.marginTop;
        legendPosy = (height / 2) - (legendBoxHeight / 2);
      } else {
        legendPosy = height - legendBoxHeight - options.marginBottom;
        pieChartClass = 'pieChartMain_bottom';
        svfOffsetX = options.marginLeft;
        legendPosx = (width / 2) - (legendBoxWidth / 2);
      }
    }
  }
  if (width >= 100 && height >= 100) {
    return (
      <div style={{ width: '100%', height: '100%' }} className={pieChartClass}>
        <div id="chart">
          <div
            style={{
              width: dimensions.width,
              height: dimensions.height,
              background: backgroundColor,
              overflow: 'hidden',
              display: 'flex',
              marginLeft: svfOffsetX,
              marginTop: svfOffsetY,
            }}
          >
            <svg
              viewBox={`0 0 ${(dimensions.pieDiameter * dimensions.overFactor) + dimensions.marginLeft} 
                ${(dimensions.pieDiameter * dimensions.overFactor) + + dimensions.marginTop}`}
              height={rightFlex ? '90%' : undefined}
              ref={node => {
                d3.select(node)
                  .selectAll('*')
                  .remove();
                d3.select(node).call(pieChart);
              }}
            ></svg>
          </div>
        </div>
        {configOptions.displayLegends && (
          <div
            style={{
              width: legendBoxWidth,
              height: legendBoxHeight,
              marginLeft: legendPosx,
              marginTop: legendPosy,
            }}
            className={pieChartLegendBox}
          >
            <svg
              viewBox={`5 3 ${legendWidth} ${legendHeight}`}
              ref={node => {
                d3.select(node)
                  .selectAll('*')
                  .remove();
                d3.select(node).call(pieLegend);
              }}
            ></svg>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <div className="gf-form-error">{error4}</div>
      </div>
    );
  }
};

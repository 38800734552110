import { css, cx } from '@emotion/css';
import { FocusScope } from '@react-aria/focus';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { NetMonitorTheme2, NavModelItem, NavSection } from '@grafana/data';
import { config, locationService, reportInteraction } from '@grafana/runtime';
import { contextSrv } from 'app/core/services/context_srv';
import { Icon, useTheme2 } from '@grafana/ui';
import { Branding } from 'app/core/components/Branding/Branding';
import { getKioskMode } from 'app/core/navigation/kiosk';
import { KioskMode, StoreState, AccessControlAction } from 'app/types';

import { OrgSwitcher } from '../OrgSwitcher';

import NavBarItem from './NavBarItem';
import { NavBarItemWithoutMenu } from './NavBarItemWithoutMenu';
import { NavBarMenu } from './NavBarMenu';
import { NavBarMenuPortalContainer } from './NavBarMenuPortalContainer';
import { NavBarScrollContainer } from './NavBarScrollContainer';
import { NavBarToggle } from './NavBarToggle';
import { NavBarContext } from './context';
import {
  enrichConfigItems,
  enrichWithInteractionTracking,
  getActiveItem,
  isMatchOrChildMatch,
  isSearchActive,
  SEARCH_ITEM_ID,
} from './utils';

/*const onOpenSearch = () => {
  locationService.partial({ search: 'open' });
};*/

export const NavBar = React.memo(() => {
  const navBarTree = useSelector((state: StoreState) => state.navBarTree);
  const theme = useTheme2();
  const styles = getStyles(theme);
  const location = useLocation();
  const kiosk = getKioskMode();
  const [showSwitcherModal, setShowSwitcherModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuAnimationInProgress, setMenuAnimationInProgress] = useState(false);
  const [menuIdOpen, setMenuIdOpen] = useState<string | undefined>(undefined);

  const toggleSwitcherModal = () => {
    setShowSwitcherModal(!showSwitcherModal);
  };

  // Here we need to hack in a "home" and "search" NavModelItem since this is constructed in the frontend
  /*const searchItem: NavModelItem = enrichWithInteractionTracking(
    {
      id: SEARCH_ITEM_ID,
      url: onOpenSearch || '/',
      text: 'Buscar Paneles',
      icon: 'search',
    },
    menuOpen
  );*/
  const assetManagerUrl = config.appUrl.split("://");
  const assetItem: NavModelItem = enrichWithInteractionTracking(
    {
      id: 'asset_manager',
      url: assetManagerUrl[0] + '://soporte-' + assetManagerUrl[1],
      text: 'Gestor de Activos',
      icon: 'asset_manager',
    },
    menuOpen
  );

  const homeItem: NavModelItem = enrichWithInteractionTracking(
    {
      id: 'home',
      text: 'Home',
      url: config.appSubUrl || '/',
      icon: 'netmonitor',
    },
    menuOpen
  );

  const navTree = cloneDeep(navBarTree);

  const coreItems = navTree
    .filter((item) => item.section === NavSection.Core)
    .map((item) => enrichWithInteractionTracking(item, menuOpen));
  const pluginItems = navTree
    .filter((item) => item.section === NavSection.Plugin)
    .map((item) => enrichWithInteractionTracking(item, menuOpen));
  const configItems = enrichConfigItems(
    navTree.filter((item) => item.section === NavSection.Config),
    location,
    toggleSwitcherModal
  ).map((item) => enrichWithInteractionTracking(item, menuOpen));

  const activeItem = isSearchActive(location) ? searchItem : getActiveItem(navTree, location.pathname);
  const isAdmin = contextSrv.isNetMonitorAdmin;

  if (kiosk !== KioskMode.Off) {
    return null;
  }
  if (isAdmin) {
    return (
      <div className={styles.navWrapper}>
        <nav className={cx(styles.sidemenu, 'sidemenu')} data-testid="sidemenu" aria-label="Main menu">
          <NavBarContext.Provider
            value={{
              menuIdOpen: menuIdOpen,
              setMenuIdOpen: setMenuIdOpen,
            }}
          >
           <FocusScope>
              <div className={styles.mobileSidemenuLogo} onClick={() => setMenuOpen(!menuOpen)} key="hamburger">
                <Icon name="bars" size="md" />
              </div>

              <NavBarMenuPortalContainer />

              <NavBarItemWithoutMenu
                elClassName={styles.netmonitorLogoInner}
                label={homeItem.text}
                className={styles.netmonitorLogo}
                onClick={() => {
                  reportInteraction('netmonitor_navigation_expanded');
                  setMenuOpen(true);
                }}
              >
                <Branding.MenuLogo />
              </NavBarItemWithoutMenu>

              <NavBarScrollContainer>
                <ul className={styles.itemList}>
                    {/*<NavBarItem 
                    className={styles.menuItem} 
                    isActive={activeItem === searchItem} 
                    link={searchItem}>
                    <Icon name="search" size="xl" />
                    </NavBarItem>*/}

                  {coreItems.map((link, index) => (
                    <NavBarItem
                      key={`${link.id}-${index}`}
                      isActive={isMatchOrChildMatch(link, activeItem)}
                      link={{ ...link, subTitle: undefined }}
                      className={styles.menuItem}
                    >
                      {link.icon && <Icon name={link.icon as IconName} size="xl" />}
                      {link.img && <img src={link.img} alt={`${link.text} logo`} />}
                    </NavBarItem>
                  ))}

                  {pluginItems.length > 0 &&
                    pluginItems.map((link, index) => (
                      <NavBarItem
                        key={`${link.id}-${index}`}
                        isActive={isMatchOrChildMatch(link, activeItem)}
                        link={link}
                        className={styles.menuItem}
                      >
                        {link.icon && <Icon name={link.icon as IconName} size="xl" />}
                        {link.img && <img src={link.img} alt={`${link.text} logo`} />}
                      </NavBarItem>
                    ))}

                  {configItems.map((link, index) => (
                    <NavBarItem
                      key={`${link.id}-${index}`}
                      isActive={isMatchOrChildMatch(link, activeItem)}
                      reverseMenuDirection
                      link={link}
                      className={styles.menuItem}
                    >
                      {link.icon && <Icon name={link.icon as IconName} size="xl" />}
                      {link.img && <img src={link.img} alt={`${link.text} logo`} />}
                    </NavBarItem>
                  ))}
                  <NavBarItemWithoutMenu
                    elClassName={styles.netmonitorLogoInner}
                    label={assetItem.text}
                    className={styles.menuItem}
                    url={assetItem.url}
                    onClick={assetItem.onClick}
                  >
                    <Icon name="asset_manager" size="xl" />
                  </NavBarItemWithoutMenu>
                </ul>
              </NavBarScrollContainer>
            </FocusScope>
          </NavBarContext.Provider>
        </nav>
        {showSwitcherModal && <OrgSwitcher onDismiss={toggleSwitcherModal} />}
        {(menuOpen || menuAnimationInProgress) && (
          <div className={styles.menuWrapper}>
            <NavBarMenu
              activeItem={activeItem}
              isOpen={menuOpen}
              setMenuAnimationInProgress={setMenuAnimationInProgress}
              navItems={[homeItem, ...coreItems, ...pluginItems, ...configItems]}
              onClose={() => setMenuOpen(false)}
            />
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
});

NavBar.displayName = 'NavBar';

const getStyles = (theme: NetMonitorTheme2) => ({
  navWrapper: css({
    position: 'relative',
    display: 'flex',

    '.sidemenu-hidden &': {
      display: 'none',
    },
  }),
  sidemenu: css({
    label: 'sidemenu',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.background.primary,
    zIndex: theme.zIndex.sidemenu,
    padding: `${theme.spacing(1)} 0`,
    position: 'relative',
    width: `calc(${theme.spacing(7)} + 1px)`,
    borderRight: `1px solid ${theme.colors.border.weak}`,

    [theme.breakpoints.down('md')]: {
      height: theme.spacing(7),
      position: 'fixed',
      paddingTop: '0px',
      backgroundColor: 'inherit',
      borderRight: 0,
    },
  }),
  mobileSidemenuLogo: css({
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2),

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }),
  itemList: css({
    backgroundColor: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    [theme.breakpoints.down('md')]: {
      visibility: 'hidden',
    },
  }),
  netmonitorLogo: css({
    alignItems: 'stretch',
    display: 'flex',
    flexShrink: 0,
    height: theme.spacing(6),
    justifyContent: 'stretch',

    [theme.breakpoints.down('md')]: {
      visibility: 'hidden',
    },
  }),
  netmonitorLogoInner: css({
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',

    '> div': {
      height: 'auto',
      width: 'auto',
    },
  }),
  menuItem: css({
    alignItems: 'center',
    display: 'flex',
    flexShrink: 0,
    height: theme.spacing(6),
    padding: theme.spacing(1),
    justifyContent: 'center',

    [theme.breakpoints.up('md')]: {
      display: 'grid',
    },
  }),
  verticalSpacer: css({
    marginTop: 'auto',
  }),
  hideFromMobile: css({
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }),
  menuWrapper: css({
    position: 'fixed',
    display: 'grid',
    gridAutoFlow: 'column',
    height: '100%',
    zIndex: theme.zIndex.sidemenu,
  }),
  menuExpandIcon: css({
    position: 'absolute',
    top: '36px',
    right: '0px',
    transform: `translateX(50%)`,
  }),
  menuPortalContainer: css({
    zIndex: theme.zIndex.sidemenu,
  }),
});

import { cx } from '@emotion/css';
import React, { FC } from 'react';

import { getCellLinks } from '@grafana/ui/src/utils';
import { Icon } from '@grafana/ui/src/components/Icon/Icon';
import { DataLinksContextMenu } from '@grafana/ui/src/components/DataLinks/DataLinksContextMenu';

import { TableCellProps } from './types';

export type buttonLink = {
  url: string;
  icon: string;
  title: string;
  target: string;

};

export const ManagementCell: FC<TableCellProps> = (props) => {
  const { field, cell, tableStyles, row, cellProps } = props;
  const buttonsLink: buttonLink[] = [];
  
  const displayValue = field.display!(cell.value);
  const openURL = (url, target) => {
    window.open(url, target, 'noopener, noreferrer');
  };

  const hasLinks = Boolean(getCellLinks(field, row)?.length);
  const iconLinks = getCellLinks(field, row) || [];

  iconLinks.forEach(iconLink => {
    let optionLink: buttonLink = {
      url: iconLink.href,
      icon: 'http',
      title: iconLink.title,
      target: iconLink.target,
    }
    let iconName = optionLink.title.toLowerCase();
    if (iconName.indexOf('ssh') > 0 || iconName.indexOf('telnet') > 0) {
      optionLink.icon = 'cli';
    } else if (iconName.indexOf('ftp') > 0 || iconName.indexOf('scp') > 0) {
      optionLink.icon = 'ftp';
    }
    if (optionLink.target === undefined) {
      optionLink.target = '_self';
    }
    buttonsLink.push(optionLink);
  });

  const iconFactory = (buttonsLink: buttonLink[]) => {
    return buttonsLink.map(optionIcon => (
      <Icon
        name={optionIcon.icon}
        className={tableStyles.iconMgmCell}
        size="lg"
        title={optionIcon.title}
        onClick={() => openURL(optionIcon.url, optionIcon.target)}
      />
    ));
  };

  return (
    <div {...cellProps} className={tableStyles.cellContainer}>
      <div className={tableStyles.iconMgmContainer}>{iconFactory(buttonsLink)}</div>
    </div>
  );
};


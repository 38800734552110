import React, { useMemo } from 'react';
import { PanelPlugin } from '@grafana/data';
import { UsersManagerPanel } from './UsersManagerPanel';
import { UsersManagerPanelOptions } from './types';

export const plugin = new PanelPlugin<UsersManagerPanelOptions>(UsersManagerPanel).setPanelOptions((builder) => {
  return builder
    .addBooleanSwitch({
      path: 'editorCanAdmin',
      name: 'Editors can admin?',
      description: 'Enable editor to add, modify and delete alert channels',
      category: ['Options'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'showTitle',
      name: 'show customizable title',
      description: 'Enable title inside plugin',
      category: ['Options'],
      defaultValue: true,
    })
    .addTextInput({
      name: 'Title',
      path: 'pluginTitle',
      defaultValue: 'Usuarios de mi Organización',
      category: ['Options'],
      showIf: (options) => options.showTitle === true,
    })
    .addTextInput({
      name: 'Success Message',
      path: 'successMessage',
      defaultValue: 'Perfil modificado exitosamente',
      category: ['Options'],
      showIf: (options) => options.showTitle === true,
    });
});

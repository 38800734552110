import React from 'react';
import { config } from '@grafana/runtime';
import { Table2Content, Table2Header } from 'types';

interface EdgeStatisticsProps {
  edgeList: Table2Content[];
  linkAsset: string;
  linkSite: string;
  noDataText: string;
  header: Table2Header[];
  edgeStatus: boolean;
}

function inAlarm(props: string) {
  let isDark = config.theme.isDark;
  var prefix = 'table--td--selection';
  switch (props) {
    case 'Alarm':
      prefix = 'table--td--selection Alarm';
      break;
    case 'Down':
      prefix = 'table--td--selection Down';
      break;
  }
  if (isDark) {
    prefix = prefix + ' table--td--selection--dark';
  }
  return prefix;
}
function showEdgeStatisticsTable(
  noDataText: string,
  edge_list: Table2Content[],
  link_asset: string,
  link_site: string,
  header: Table2Header[],
  edgeStatus: boolean
) {
  let isDark = config.theme.isDark;
  var table_header = 'table--selection';
  var table_tr = 'table--selection--head';
  var table_th = 'table--th--selectionSmall';
  if (isDark) {
    table_header = 'table--selection table--selection--dark';
    table_tr = 'table--selection--head table--selection--head--dark';
    table_th = 'table--th--selectionSmall table--th--selectionSmall--dark';
  }
  if (edge_list.length > 0) {
    return (
      <table className={table_header}>
        {header.map((link_header: Table2Header) => (
          <tr key={link_header.label_header} className={table_tr}>
            <th className={table_th}>{link_header.label_header}</th>
            <th className={table_th}>{link_header.source_port_header}</th>
            <th className={table_th}>{link_header.source_type_header}</th>
            <th className={table_th}>{link_header.target_label_header}</th>
            <th className={table_th}>{link_header.target_port_header}</th>
            <th className={table_th}>{link_header.target_type_header}</th>
            {edgeStatus && <th className={table_th}>{link_header.status_header}</th>}
          </tr>
        ))}
        {edge_list.map((edge: Table2Content) => (
          <tr key={edge.label}>
            <td className={inAlarm(edge.stat)}>{edge.label}</td>
            <td className={inAlarm(edge.stat)}>{edge.source_port}</td>
            <td className={inAlarm(edge.stat)}>{edge.source_lag}</td>
            <td className={inAlarm(edge.stat)}>{edge.target_label}</td>
            <td className={inAlarm(edge.stat)}>{edge.target_port}</td>
            <td className={inAlarm(edge.stat)}>{edge.target_lag}</td>
            {edgeStatus && <td className={inAlarm(edge.stat)}>{edge.stat}</td>}
          </tr>
        ))}
      </table>
    );
  } else {
    return <div className="no-data--selection">{noDataText}</div>;
  }
}

export const EdgeStatistics: React.FC<EdgeStatisticsProps> = ({
  edgeList,
  noDataText,
  linkAsset,
  linkSite,
  header,
  edgeStatus,
}) => {
  var link_asset = './';
  var link_site = './';
  if (linkAsset && linkAsset.length > 0) {
    link_asset = linkAsset;
  }
  if (linkSite && linkSite.length > 0) {
    link_site = linkSite;
  }
  return (
    <div className="statistics">
      {showEdgeStatisticsTable(noDataText, edgeList, link_asset, link_site, header, edgeStatus)}
    </div>
  );
};

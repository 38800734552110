import React, { useState, useEffect } from 'react';
import { PanelProps } from '@grafana/data';
import { config } from '@grafana/runtime';
import Iframe from 'react-iframe';
import { SimpleOptions, GenericSessionData } from './types';
import './css/SSHSession.css';

interface Props extends PanelProps<SimpleOptions> {}

export const SSHPanel: React.FC<Props> = ({ id, options, data, width, height, replaceVariables }) => {
  const welcomeBanner = replaceVariables(options.welcome);
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);
  const sessions: GenericSessionData[] = [];
  const isDark = config.theme.isDark || false;

  const [authenticated, setAuthenticated] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
	console.log(event);
    setAuthenticated(true);
  };

  let classText = 'session_instructions';
  if (isDark) {
	classText = 'session_instructions_dark';
  }

  if (data.state !== 'Error') {
    if(data.series[0].length > 0) {
      data.series.forEach(series => {
        const timeVals: GraphSeriesValue[] = series.fields[0].values.toArray();
        for (let i = 0; i < 1; i++) {
          let sessionData: GenericSessionData = {
            id: i,
			collector: series.fields.find(field => field.name === options.collectorOption)?.values.get(i),
            collectorPort: series.fields.find(field => field.name === options.collectorPortOption)?.values.get(i),
            hostName: series.fields.find(field => field.name === options.hostNameOption)?.values.get(i),
			hostLocation: series.fields.find(field => field.name === options.hostLocationOption)?.values.get(i),
            host: series.fields.find(field => field.name === options.hostOption)?.values.get(i),
            port: series.fields.find(field => field.name === options.portOption)?.values.get(i),
          };
          if (sessionData.collector === null || sessionData.collector === undefined || sessionData.collector === '') {
            sessionData.collector = 'none';
          }
          if (sessionData.collectorPort === null || sessionData.collectorPort=== undefined || isNaN(sessionData.collectorPort)) {
            sessionData.collectorPort = 0;
          }
          if (sessionData.hostName === null || sessionData.hostName === undefined || sessionData.hostName === '') {
            sessionData.hostName = 'none';
          }
          if (sessionData.hostLocation === null || sessionData.hostLocation === undefined || sessionData.hostLocation === '') {
            sessionData.hostLocation = 'none';
          }
          if (sessionData.host === null || sessionData.host === undefined || sessionData.host === '') {
            sessionData.host = 'none';
          }
          if (sessionData.port === null || sessionData.port === undefined || isNaN(sessionData.port)) {
            sessionData.port = 0;
          }
          sessions.push(sessionData);
        }
      });
	  let srcUrl = 'http://';
	  if (options.secureSession) {
		srcUrl = 'https://';
	  }
	  srcUrl = srcUrl + sessions[0].collector;
	  if (sessions[0].collectorPort !== 0) {
		srcUrl = srcUrl + ':' + sessions[0].collectorPort.toString();
	  }
	  srcUrl = srcUrl + '/ssh/host/' + sessions[0].host;
	  if (sessions[0].port !== 0) {
		srcUrl = srcUrl + '?port=' + sessions[0].port.toString();
	  }
	  let msgText = welcomeBanner;
      if (sessions[0].hostName !== 'none') {
	    msgText = msgText + ' en ' + sessions[0].hostName;
	  }
      if (sessions[0].hostLocation !== 'none') {
	    msgText = msgText + ' - ' + sessions[0].hostLocation;
	  }	  
	  if (width < 250 && height < 250) {
		return (
		  <div>
			<div className="gf-form-error">{error4}</div>
		  </div>
		);
	  } else if (sessions[0].collector === 'none' || sessions[0].collector === 'none') {
	  	return (
		  <div>
			<div className="gf-form-error">{error3}</div>
		  </div>
		);
	  } else if (!options.displayCredentials) {
		return (
		  <div className="session_wrapper_flex">
			<Iframe 
			  id={id}
			  src={srcUrl}
			  className="session_container"
			  height="100%"
			  width="100%"
			  overflow="hidden"
			></Iframe>
		  </div>
		);
	  } else {
		return (
		  <>
			{!authenticated && (
			  <div className="session_wrapper">
				<div className={classText}>
				  {msgText}
				</div>
				<form method="POST" action={srcUrl} className="form_container" onSubmit={handleSubmit}>
				  <div className="gf-form-button-row" className="form_center">
					<button type="submit" className="btn btn-primary ">
					  Iniciar sesión
					</button>
				  </div>
				</form>
			  </div>
			)}
			{authenticated && (
			  <div className="session_wrapper_flex">
				<Iframe 
				  id={id}
				  src={srcUrl}
				  className="session_container"
				  height="100%"
				  width="100%"
				  overflow="hidden"
				></Iframe>
			  </div>
		    )}
		  </>
		);
	  }
    } else {
        return (
          <div>
            <div className="gf-form-error">{error2}</div>
          </div>
        );
    } 
  } else {
    return (
      <div>
        <div className="gf-form-error">{error1}</div>
      </div>
    );
  }
}
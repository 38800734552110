import { css, CSSObject } from '@emotion/css';

import { NetMonitorTheme2 } from '@grafana/data';
import { config } from '@grafana/runtime';
import { getScrollbarWidth } from '@grafana/ui/src/utils';

export const getTableStyles = (theme: NetMonitorTheme2) => {
  const { colors } = theme;
  const isDark = config.theme.isDark || false;
  const headerBg = isDark ? '#262626' : '#f7f8fb';
  const borderColor = theme.colors.border.weak;
  const headerTextColor = isDark ? '#d8d9da' : '#202226';
  const cellPadding = 6;
  const lineHeight = theme.typography.body.lineHeight;
  const bodyFontSize = 12;
  const cellHeight = cellPadding * 2 + bodyFontSize * lineHeight;
  const rowHoverBg = theme.colors.emphasize(theme.colors.background.primary, 0.03);
  const lastChildExtraPadding = Math.max(getScrollbarWidth(), cellPadding);

  const buildCellContainerStyle = (color?: string, background?: string, overflowOnHover?: boolean) => {
    const cellActionsOverflow: CSSObject = {
      margin: theme.spacing(0, -0.5, 0, 0.5),
    };
    const cellActionsNoOverflow: CSSObject = {
      position: 'absolute',
      top: 0,
      right: 0,
      margin: 'auto',
    };

    const onHoverOverflow: CSSObject = {
      overflow: 'visible',
      width: 'auto !important',
      boxShadow: `0 0 2px ${theme.colors.primary.main}`,
      background: background ?? rowHoverBg,
      zIndex: 1,
    };

    return css`
      label: ${overflowOnHover ? 'cellContainerOverflow' : 'cellContainerNoOverflow'};
      padding: ${cellPadding}px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      border-right: 1px solid ${borderColor};

      ${color ? `color: ${color};` : ''};
      ${background ? `background: ${background};` : ''};
      background-clip: padding-box;

      &:last-child:not(:only-child) {
        border-right: none;
        padding-right: ${lastChildExtraPadding}px;
      }

      &:hover {
        ${overflowOnHover && onHoverOverflow};
        .cellActions {
          visibility: visible;
          opacity: 1;
          width: auto;
        }
      }

      a {
        color: inherit;
      }

      .cellActions {
        display: flex;
        ${overflowOnHover ? cellActionsOverflow : cellActionsNoOverflow}
        visibility: hidden;
        opacity: 0;
        width: 0;
        align-items: center;
        height: 100%;
        padding: ${theme.spacing(1, 0.5, 1, 0.5)};
        background: ${background ? 'none' : theme.colors.emphasize(theme.colors.background.primary, 0.03)};

        svg {
          color: ${color};
        }
      }

      .cellActionsLeft {
        right: auto !important;
        left: 0;
      }

      .cellActionsTransparent {
        background: none;
      }
    `;
  };

  return {
    theme,
    cellHeight,
    buildCellContainerStyle,
    cellPadding,
    lastChildExtraPadding,
    cellHeightInner: bodyFontSize * lineHeight,
    rowHeight: cellHeight + 2,
    container: css`
      height: calc(100% - 15px);
      width: 100%;
    `,
    table: css`
      height: auto;
      width: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      border: 1px solid ${theme.colors.border.weak};
    `,
    thead: css`
      label: thead;
      height: ${cellHeight + 6}px;
      overflow-y: auto;
      overflow-x: hidden;
      background: ${headerBg};
      border: 1px solid ${theme.colors.border.weak};
      position: relative;
      min-width: calc(100% - 1px);
    `,
    tfoot: css`
      label: tfoot;
      height: ${cellHeight}px;
      overflow-y: auto;
      overflow-x: hidden;
      background: ${headerBg};
      border: 1px solid ${theme.colors.border.weak};
      position: relative;
    `,
    headerCell: css`
      padding: ${cellPadding}px;
      overflow: hidden;
      white-space: nowrap;
      color: ${colors.primary.text};
      border-right: 1px solid ${theme.colors.border.weak};
      background: ${theme.colors.bg2};
      display: flex;

      &:last-child {
        border-right: none;
      }
    `,
    headerBox: css`
      justify-content: space-between;
    `,
    headerCellLabel: css`
      border: none;
      padding: 0;
      background: inherit;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      margin-right: ${theme.spacing(0.5)};
      color: ${headerTextColor};
      font-size: 13px;
      font-weight: 600;
    `,
     headerCellLabel2: css`
      border: none;
      padding: 0;
      background: inherit;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      margin-right: ${theme.spacing(0.5)};
      color: ${headerTextColor};
      font-size: 13px;
      font-weight: 600;
	  width: 100%;
	  justify-content: center;
    `,
    cellContainer: buildCellContainerStyle(undefined, undefined, true),
    cellContainerNoOverflow: buildCellContainerStyle(undefined, undefined, false),
    cellText: css`
      overflow: hidden;
      text-overflow: ellipsis;
      user-select: text;
      white-space: nowrap;
      font-size: ${bodyFontSize}px;
    `,
    cellLink: css`
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      user-select: text;
      white-space: nowrap;
      font-size: ${bodyFontSize}px;
      text-decoration: underline;
      color: #0d0deb;
    `,
    imageCellLink: css`
      cursor: pointer;
      overflow: hidden;
      height: 100%;
    `,
    iconCell: css`
      width: 100%;
      text-align: center;
    `,
    iconMgmContainer: css`
      display: inline-flex;
      width: 100%;
      height: 100%;
      justify-content: space-around;
      vertical-align: middle;
      color: #0d0deb;
    `,
    iconMgmCell: css`
      width: 32px;
      height: 32px;
    `,
    iconButton: css`
      background: #0d0deb;
      border-radius: 4px;
      border-top: 1px solid ${theme.colors.border.weak};
      width: 26px;
      height: 24px;
      color: white;
    `,
    iconButtonRed: css`
      background: red;
      border-radius: 4px;
      border-top: 1px solid ${theme.colors.border.weak};
      width: 26px;
      height: 24px;
      color: white;
    `,
    headerFilter: css`
      label: headerFilter;
      cursor: pointer;
    `,
    paginationWrapper: css`
      display: flex;
      background: ${headerBg};
      height: ${cellHeight}px;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-top: 1px solid ${theme.colors.border.weak};
      li {
        margin-bottom: 0;
      }
    `,
    paginationItem: css`
      flex: 20%;
    `,
    paginationCenterItem: css`
      flex: 100%;
      display: flex;
      justify-content: center;
    `,
    paginationSummary: css`
      color: ${theme.colors.text.secondary};
      font-size: 11px;
      font-weight: 500;
      display: flex;
      justify-content: flex-end;
      flex: 30%;
      padding-right: ${theme.spacing(1)};
    `,

    tableContentWrapper: (totalColumnsWidth: number) => {
      const width = totalColumnsWidth !== undefined ? `${totalColumnsWidth}px` : '100%';
      return css`
        label: tableContentWrapper;
        width: ${width};
        display: flex;
        flex-direction: column;
        border-right: 1px solid ${borderColor};
      `;
    },
    row: css`
      label: row;
      border-bottom: 1px solid ${borderColor};

      &:hover {
        background-color: ${rowHoverBg};
      }

      &:last-child {
        border-bottom: 0;
      }
    `,
    imageCell: css`
      height: 100%;
    `,
    resizeHandle: css`
      label: resizeHandle;
      cursor: col-resize !important;
      display: inline-block;
      background: #0d0deb;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      width: 8px;
      height: 100%;
      position: absolute;
      right: -4px;
      border-radius: 4px;
      top: 0;
      touch-action: none;

      &:hover {
        opacity: 1;
      }
    `,
    toolbarBox: css`
      width: 100%;
    `,
    toolBar: css`
      width: auto;
      height: 32px;
      float: left;
      text-align: right;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      margin-top: 4px;
      margin-left: 5px;
    `,
    toolbarButton: css`
      height: 26px;
      min-width: 34px;
      font-size: 16px;
      background-color: #0d0deb;
      color: #fafafa;
      opacity: .75;
      border-radius: 5px;
      border: 1px solid #202020;
      margin-right: 3px;
      text-align: center;
      vertical-align: middle;
    `,
    toolbarFilter: css`
      height: 26px;
      min-width: 28px;
      font-size: 16px;
      margin-left: 12px;
      margin-top: 3px;
      text-align: center;
      vertical-align: middle;
      color: ${headerTextColor};
      font-size: 12px;
      font-weight: 500;
    `,
    toolbarTotalBox: css`
      position: absolute;
      right: 0px;
	  width: auto;
      height: 32px;
      float: left;
      text-align: right;
      display: inline-flex;
      margin-top: 4px;
      margin-left: 5px;
    `,
    toolbarTotal: css`
	  height: 26px;
      min-width: 28px;
      font-size: 16px;
      margin-right: 12px;
      margin-top: 3px;
      text-align: center;
      vertical-align: middle;
      color: ${headerTextColor};
      font-size: 12px;
      font-weight: 500;
    `,
    toolbarIcon: css`
      margin-top: -2px;
    `,
    globalFilter: css`
      display: inline-flex;
      max-width: 50%;
      min-width: 250px;
      float: right;
      text-align: right;
      border: 1px solid ${borderColor};
      margin: 4px;
      border-radius: 5px;
      padding-left: 3px;
      background: ${headerBg};
      height: 28px;
    `,
    typeIcon: css`
      margin-right: ${theme.spacing(1)};
      color: ${theme.colors.text.secondary};
    `,
    noData: css`
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    `,
  };
};

export type TableStyles = ReturnType<typeof getTableStyles>;

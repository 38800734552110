import React, { useMemo } from 'react';

import { PanelPlugin } from '@grafana/data';
import { AlertManagerPicker } from 'app/features/alerting/unified/components/AlertManagerPicker';
import {
  getAllAlertManagerDataSources,
  NETMONITOR_RULES_SOURCE_NAME,
} from 'app/features/alerting/unified/utils/datasource';

import { AlertGroupsPanel } from './AlertGroupsPanel';
import { AlertGroupPanelOptions } from './types';

export const plugin = new PanelPlugin<AlertGroupPanelOptions>(AlertGroupsPanel).setPanelOptions((builder) => {
  return builder
    .addCustomEditor({
      name: 'Alertmanager',
      path: 'alertmanager',
      id: 'alertmanager',
      defaultValue: NETMONITOR_RULES_SOURCE_NAME,
      category: ['Options'],
      editor: function RenderAlertmanagerPicker(props) {
        const alertManagers = useMemo(getAllAlertManagerDataSources, []);
        return (
          <AlertManagerPicker
            current={props.value}
            onChange={(alertManagerSourceName) => {
              return props.onChange(alertManagerSourceName);
            }}
            dataSources={alertManagers}
          />
        );
      },
    })
    .addBooleanSwitch({
      path: 'editorCanAdmin',
      name: 'Editors can admin?',
      description: 'Enable editor to add, modify and delete alert channels',
      category: ['Options'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'showTitle',
      name: 'show customizable title',
      description: 'Enable title inside plugin',
      category: ['Options'],
      defaultValue: true,
    })
    .addTextInput({
      name: 'Title',
      path: 'pluginTitle',
      defaultValue: 'Notificaciones - Reglas configuradas',
      category: ['Options'],
      showIf: (options) => options.showTitle === true,
    })
    .addTextInput({
      name: 'Success Massage',
      path: 'successMessage',
      defaultValue: 'Regla modificada exitosamente',
      category: ['Options'],
      showIf: (options) => options.showTitle === true,
    });
});

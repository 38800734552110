import { css } from '@emotion/css';
import React, { FC, useState } from 'react';
import { useDebounce } from 'react-use';

import { NetMonitorTheme2 } from '@grafana/data';
import { Button, Icon, Input, Label, useStyles2, CustomScrollbar } from '@grafana/ui';

import { Authorize } from 'app/features/alerting/unified/components/Authorize';
import { useURLSearchParams } from 'app/features/alerting/unified/hooks/useURLSearchParams';

import { getNotificationsPermissions } from 'app/features/alerting/unified/utils/access-control';
import { emptyArrayFieldMatcher, emptyRoute } from 'app/features/alerting/unified/utils/amroutes';
import { getNotificationPoliciesFilters } from 'app/features/alerting/unified/utils/misc';
import { EmptyArea } from 'app/features/alerting/unified/components/EmptyArea';
import { EmptyAreaWithCTA } from 'app/features/alerting/unified/components/EmptyAreaWithCTA';
import { MatcherFilter } from 'app/features/alerting/unified/components/alert-groups/MatcherFilter';
import { AmRoutesTable } from 'app/features/alerting/unified/components/amroutes/AmRoutesTable';
import { AmRouteReceiver, FormAmRoute } from 'app/features/alerting/unified/types/amroutes';
import { initialAsyncRequestState } from 'app/features/alerting/unified/utils/redux';

type Props = {
  alertManagerSourceName: string;
  onChange: (routes: FormAmRoute) => void;
  onRootRouteEdit: () => void;
  receivers: AmRouteReceiver[];
  routes: FormAmRoute;
  readOnly?: boolean;
  isAdmin: boolean;
  showTitle: boolean;
  pluginTitle: string;
  renderCount: string;
}

interface Filters {
  queryString?: string;
  contactPoint?: string;
}

export const AlertGroup = ({
  renderCount,
  showTitle,
  pluginTitle,
  alertManagerSourceName,
  isAdmin,
  onChange,
  onRootRouteEdit,
  receivers,
  routes,
  readOnly,
}: Props) => {
  const [actualRoutes, setActualRoutes] = useState([...routes.routes]);
  const [isAddMode, setIsAddMode] = useState(false);
  const permissions = getNotificationsPermissions(alertManagerSourceName);
  const canCreateNotifications = isAdmin;
  const [searchParams, setSearchParams] = useURLSearchParams();
  const { queryString, contactPoint } = getNotificationPoliciesFilters(searchParams);

  const [filters, setFilters] = useState<Filters>({ queryString, contactPoint });

  useDebounce(
    () => {
      setSearchParams({ queryString: filters.queryString, contactPoint: filters.contactPoint });
    },
    400,
    [filters]
  );

  const styles = useStyles2(getStyles);

  const clearFilters = () => {
    setFilters({ queryString: undefined, contactPoint: undefined });
    setSearchParams({ queryString: undefined, contactPoint: undefined });
  };

  const addNewRoute = () => {
    clearFilters();
    setIsAddMode(true);
    setActualRoutes(() => [
      ...routes.routes,
      {
        ...emptyRoute,
        matchers: [emptyArrayFieldMatcher],
      },
    ]);
  };

  const onCancelAdd = () => {
    setIsAddMode(false);
    setActualRoutes([...routes.routes]);
  };

  const onTableRouteChange = (newRoutes: FormAmRoute[]): void => {
    onChange({
      ...routes,
      routes: newRoutes,
    });

    if (isAddMode) {
      setIsAddMode(false);
    }
  };
  return (
    <CustomScrollbar autoHeightMin="100%" autoHeightMax="100%" key={renderCount}>
      {showTitle && (
        <div className={styles.title}>
          <div className={styles.titleText}>{pluginTitle}</div>
        </div>
      )}
      <div className={styles.container}>
        {!routes.receiver ? (
          readOnly ? (
            <EmptyArea>
              <p>No esiste una regla de notificación por defecto</p>
            </EmptyArea>
          ) : (
            <EmptyAreaWithCTA
              buttonIcon="plus"
              buttonLabel="Agregar"
              onButtonClick={onRootRouteEdit}
              text="No existe un canal de notificación predeterminado"
              showButton={canCreateNotifications}
            />
          )
        ) : actualRoutes.length > 0 ? (
          <>
            <div className={styles.channelContainer}>
              {!isAddMode && (
                <div className={styles.searchContainer}>
                  <MatcherFilter
                    onFilterChange={(filter) =>
                      setFilters((currentFilters) => ({ ...currentFilters, queryString: filter }))
                    }
                    queryString={filters.queryString ?? ''}
                    className={styles.filterInput}
                  />
                  <div className={styles.filterInput}>
                    <Label>Filtrar por canal</Label>
                    <Input
                      onChange={({ currentTarget }) =>
                        setFilters((currentFilters) => ({ ...currentFilters, contactPoint: currentTarget.value }))
                      }
                      value={filters.contactPoint ?? ''}
                      placeholder="canal de notificación"
                      data-testid="search-query-input"
                      prefix={<Icon name={'search'} />}
                    />
                  </div>
                  {(queryString || contactPoint) && (
                    <div className={styles.addMatcherBtnRow}>
                      <Button
                        variant="secondary"
                        type="button"
                        icon="times"
                        size="lg"
                        onClick={clearFilters}
                        className={styles.clearFilterBtn}
                        title={'Cancelar'}
                      />
                    </div>
                  )}
                  {!readOnly && isAdmin && (
                    <Authorize actions={[permissions.create]}>
                      <div className={styles.addMatcherBtnRow}>
                        <Button
                          variant="primary"
                          type="button"
                          icon="plus"
                          size="lg"
                          className={styles.addMatcherBtn}
                          onClick={addNewRoute}
                          title={'Agregar regla especifica de notificación'}
                        />
                      </div>
                    </Authorize>
                  )}
                </div>
              )}
            </div>
            <div className={styles.channelContainer}>
              <AmRoutesTable
                isAddMode={isAddMode}
                isAdmin={isAdmin}
                readOnly={readOnly}
                onCancelAdd={onCancelAdd}
                onChange={onTableRouteChange}
                receivers={receivers}
                routes={actualRoutes}
                filters={{ queryString, contactPoint }}
                alertManagerSourceName={alertManagerSourceName}
              />
            </div>
          </>
        ) : readOnly ? (
          <EmptyArea>
            <p>No existe ninguna regla especifica configurada</p>
          </EmptyArea>
        ) : (
          <EmptyAreaWithCTA
            buttonIcon="plus"
            buttonLabel="Agregar"
            onButtonClick={addNewRoute}
            text="No existe ninguna regla especifica configurada aún"
            showButton={canCreateNotifications}
          />
        )}
      </div>
    </CustomScrollbar>
  );
};

const getStyles = (theme: NetMonitorTheme2) => {
  return {
    container: css`
      display: flex;
      flex-flow: column wrap;
      padding: 16px;
      border: 1px solid ${theme.colors.border.medium};
      border-radius: 4px;
    `,
    channelContainer: css`
      width: 100%;
    `,
    searchContainer: css`
      display: flex;
      flex-flow: row nowrap;
      padding-bottom: ${theme.spacing(2)};
      width: calc(100% - 100px);
    `,
    clearFilterBtn: css`
      align-self: flex-end;
      margin-left: ${theme.spacing(1)};
    `,
    filterInput: css`
      width: 300px;
      & + & {
        margin-left: ${theme.spacing(1)};
      }
    `,
    addMatcherBtnRow: css`
      display: flex;
      flex-flow: column nowrap;
      padding: ${theme.spacing(2)} 0;
      margin-top: 2px;
      min-width: 100px;
      }
    `,
    addMatcherBtn: css`
      align-self: flex-end;
    `,
    title: css`
      width: calc(100% - 16px);
      display: inline-flex;
      margin: 12px 20px 0px 20px;
    `,
    titleText: css`
      margin-right: ${theme.spacing(2)};
      font-size: 18px;
      font-weight: 500;
    `,
  };
};

// @ts-nocheck
import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Chrono } from 'react-chrono';
import { Modal, View } from '@material-ui/core';
import { Icon } from '@grafana/ui';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { TrapManagerOptions, trapData, traplineItems } from 'types';
import { moment } from 'moment';
import { changeUrl, getNetMonitorVariableValue } from './utils';
import './css/TrapManagerPanel.css';
import { config, SystemJS } from '@grafana/runtime';
import axios from 'axios';
import { AppEvents } from '@grafana/data';

interface Props extends PanelProps<TrapManagerOptions> {}

export const TrapManagerPanel: React.FC<Props> = ({ options, data, width, height, replaceVariables, id }) => {
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);

  const isDark = config.theme.isDark;
  const showTitle = options.showTitle;
  const pluginTitle = replaceVariables(options.pluginTitle);
  const moment = require('moment');
  var legendPosition = String(options.legendPosition);
  const lineWidth = options.lineSize;
  const hideControls = options.hideControls;
  const flipLayout = options.flipLayout;
  const trapHeight = String(options.trapMinHeight);
  var displayReadMore = options.displayReadMore;

  if (legendPosition === 'VERTICAL_ALTERNATING' && width < options.trapMinWidth * 2.5) {
    legendPosition = 'VERTICAL';
  }
  var backgroundColor = '#fff';
  var titleColor = 'black';
  var textColor = '#111';
  var primary = '#0d0deb';
  var secondary = '#ff7600';
  var trapBgColor = '#fafafa';
  var trapForeColor = '#999';

  if (isDark) {
    backgroundColor = '#000';
    titleColor = 'white';
    textColor = '#eee';
    primary = '#0d0deb';
    secondary = '#ff7600';
    trapBgColor = '#111';
    trapForeColor = '#999';
  }
  const traptitle = isDark ? 'traptitle_dark' : 'traptitle';
  var agentFilterValue = options.filterDefaultValue;
  var severityFilterValue = options.filterDefaultValue;
  var trapFilterValue = options.filterDefaultValue;
  var showFilterValue = options.filterDefaultValue;
  var agentOptions = false;
  var severityOptions = false;
  var trapOptions = false;
  var readOptions = false;
  if (options.agentVariable !== undefined && options.agentVariable !== null && options.agentVariable !== '') {
    agentFilterValue = getNetMonitorVariableValue(options.agentVariable);
    agentOptions = true;
  }
  if (options.severityVariable !== undefined && options.severityVariable !== null && options.severityVariable !== '') {
    severityFilterValue = getNetMonitorVariableValue(options.severityVariable);
    severityOptions = true;
  }
  if (options.trapVariable !== undefined && options.trapVariable !== null && options.trapVariable !== '') {
    trapFilterValue = getNetMonitorVariableValue(options.trapVariable);
    trapOptions = true;
  }
  if (options.trapReadVariable !== undefined && options.trapReadVariable !== null && options.trapReadVariable !== '') {
    let unreadValue = getNetMonitorVariableValue(options.trapReadVariable);
    if (unreadValue === 'false' || unreadValue === 'true' || unreadValue === 'saved') {
      showFilterValue = unreadValue;
    }
	readOptions = true;
  }
  var trap_link = replaceVariables(options.drillDownLink);
  if (trap_link === null || trap_link === '') {
    trap_link = '/';
  }
  var agent_link = replaceVariables(options.drillDownLinkAgent);
  if (agent_link === null || agent_link === '') {
    agent_link = '/';
  }
  var trapTitle_link = replaceVariables(options.drillDownLinkTitle);
  if (trapTitle_link === null || trapTitle_link === '') {
    trapTitle_link = '/';
  }

  const [agentFilter, setAgentFilter] = useState(agentFilterValue);
  const [trapFilter, setTrapFilter] = useState(trapFilterValue);
  const onAgentChange = (event) => {
    setAgentFilter(event.target.value);
  };

  const onTrapChange = (event) => {
    setTrapFilter(event.target.value);
  };

  const changeAgent = (event) => {
    if (event.key === 'Enter' && agentOptions) {
	  const queryMap = {
		[`var-${options.agentVariable}`]: agentFilter,
	  };
	  changeUrl(queryMap);
    }
  };

  const changeTrap = (event) => {
    if (event.key === 'Enter' && trapOptions) {
	  const queryMap = {
		[`var-${options.trapVariable}`]: trapFilter,
	  };
	  changeUrl(queryMap);
    }
  };

  // ----------------------- STYLES -----------------------
  var dimensions = {
    width: width,
    height: height,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 10,
    marginLeft: 0,
    cardWidth: width - 150,
  };
  if (options.showToolbar && !showTitle) {
    dimensions.height = height - 30;
  } else if (options.showToolbar && showTitle) {
    dimensions.height = height - 70;
  } else if (!options.showToolbar && showTitle) {
    dimensions.height = height - 40;
  }
  const fontSizes = {
    trapSubtitle: String(options.trapFontSize - 1) + 'px',
    trapText: String(options.trapFontSize - 2) + 'px',
    trapTitle: String(options.trapFontSize) + 'px',
    title: String(options.trapFontSize) + 'px',
  }
  const filterBox = isDark ? 'filterBox_dark' : 'filterBox';
  const selectBox = isDark ? 'selectBox_dark' : 'selectBox';
  const toolbarBox = isDark ? 'trapToolbar text_dark' : 'trapToolbar text_light';
  const showMore = options.useReadMore;
  const showCardInfo = options.showCardInfo;
  const trapSubtitle = 'trapSubtitle';
  const trapContainer = isDark ? 'trapManagerContainer_dark' : 'trapManagerContainer';
  const moreInfoContainerId = 'trapline-moreInfoContainer-' + String(id);
  const confirmId = 'trapline-confirm-' + String(id);
  const moreInfoId = 'trapline-moreInfo-' + String(id);
  const confirmInfoId = 'trapline-confirmInfo-' + String(id);
  const moreTitleId = 'trapline-moreInfoTitle-' + String(id);
  const confirmTitleId = 'trapline-confirmTitle-' + String(id);
  const moreCardTitleId = 'trapline-moreInfoCardTitle-' + String(id);
  const moreSubTitleId = 'trapline-moreInfoSubTitle-' + String(id);
  const moreInfoTextId = 'trapline-moreInfoText-' + String(id);
  const moreInfoUrlId = 'trapline-moreInfoUrl-' + String(id);
  const moreAgentId = 'trapline-moreInfoAgent-' + String(id);
  const moreInfoFirstTrap = 'trapline-moreInfoFirstTrap-' + String(id);
  const moreInfoQty = 'trapline-moreInfoQty-' + String(id);
  const moreInfoSeverityId = 'trapline-moreInfoSeverity-' + String(id);
  const moreInfoTypeId = 'trapline-moreInfoType-' + String(id);
  if (legendPosition === 'VERTICAL_ALTERNATING') {
    dimensions.cardWidth = (width / 2) - 75;
  }


  // ----------------------- BASE DATA ACQUISITION -----------------------
  var timeEvents: trapData[] = [];
  var timeItems: traplineItems[] = [];

  if (width < 350 || height < 250) {
    return (
      <div>
        <div className="gf-form-error">{error4}</div>
      </div>
    );
  } else if (data.state !== 'Error') {
    if(data.series[0].length > 0) {
      data.series.forEach((series) => {
        const frame: GraphSeriesValue[] = series.fields[0].values.toArray();
        const dataLen = frame.length;
        for (let i = 0; i < dataLen; i++) {
          let timeItem: traplineItems = {
            id: i,
            timest: series.fields.find(field => field.name === options.dateField)?.values.get(i),
            title: '',
          };
          timeItem.title = moment.unix(timeItem.timest).format('DD-MM-YYYY HH:mm:ss');
          let trapEvent: trapData = {
            id: i,
            idx: series.fields.find(field => field.name === options.idxField)?.values.get(i),
            counter: series.fields.find(field => field.name === options.counterField)?.values.get(i),
            timest: series.fields.find(field => field.name === options.dateField)?.values.get(i),
            title: '',
            trapTitle: series.fields.find((field) => field.name === options.trapTitleField)?.values.get(i),
            trapSubtitle: series.fields.find((field) => field.name === options.trapSubtitleField)?.values.get(i),
            trapDetailedText: '',
            severity: series.fields.find((field) => field.name === options.severityField)?.values.get(i),
            agent: series.fields.find((field) => field.name === options.agentField)?.values.get(i),
            firstTimest: series.fields.find((field) => field.name === options.firstTrapField)?.values.get(i),
            firstTrap: '',
            isRead: false,
            isSaved: false,
            url: trap_link,
            agentUrl: agent_link,
            trapTitleUrl: trapTitle_link,
            color: 'blue',
          };
          trapEvent.trapSubtitle = trapEvent.trapSubtitle.replace(/;/g, '\n');
          trapEvent.title = moment.unix(trapEvent.timest).format('DD-MM-YYYY HH:mm:ss');
          trapEvent.firstTrap = moment.unix(trapEvent.firstTimest).format('DD-MM-YYYY HH:mm:ss');
          if (options.agentUrlField && options.agentUrlField !== undefined && options.agentUrlField !== '') {
            if (agent_link !== '/') {
              trapEvent.agentUrl =
                agent_link + series.fields.find((field) => field.name === options.agentUrlField).values.get(i);
            } else {
              trapEvent.agentUrl = series.fields.find((field) => field.name === options.agentUrlField).values.get(i);
            }
          }
          if (options.usetrapDetailedText && options.trapDetailedTextField !== undefined && options.trapDetailedTextField !== '') {
            trapEvent.trapDetailedText = series.fields
              .find((field) => field.name === options.trapDetailedTextField).values.get(i);
          }
          if (options.isReadField !== undefined && options.isReadField !== '') {
            let read = series.fields.find((field) => field.name === options.isReadField)?.values.get(i);
            if (read === 'true' || read === true) {
              trapEvent.isRead = true;
            }
          }
          if (options.isSavedField !== undefined && options.isSavedField !== '') {
            let saved = series.fields.find((field) => field.name === options.isSavedField)?.values.get(i);
            if (saved === 'true' || saved === true) {
              trapEvent.isSaved = true;
            }
          }
          if (options.useUrl && options.urlField !== undefined && options.urlField !== '') {
            if (trap_link !== '/') {
              trapEvent.url =
                trap_link + series.fields.find((field) => field.name === options.urlField).values.get(i);
            } else {
              trapEvent.url = series.fields.find((field) => field.name === options.urlField).values.get(i);
            }
          }
          if (trapEvent.severity === 'Emergency' || trapEvent.severity === 'Alert' || trapEvent.severity === 'Critical') {
            trapEvent.color = 'red';
          } else if (trapEvent.severity === 'Error' || trapEvent.severity === 'Warning') {
            trapEvent.color = 'orange';
          }
          if (typeof trapEvent.idx === undefined || trapEvent.idx === null) {
            trapEvent.idx = i;
          }
          if (typeof trapEvent.severity === undefined || trapEvent.severity === null) {
            trapEvent.severity = 'normal';
          }
          if (typeof trapEvent.agent === undefined || trapEvent.agent === null) {
            trapEvent.agent = 'NetMonitor';
          }
          timeEvents.push(trapEvent);
          timeItems.push(timeItem);
        }
      });

      // -----------------------  TIMELINE FIELD VALUES  -----------------------

      return (
        <div className="trapManager" id="trapManager">
          {showTitle && (
            <div className={traptitle}>
              <div className="traptitleText">{pluginTitle}</div>
            </div>
          )}
          {options.showToolbar && (
            <div className={toolbarBox}>
              {readOptions && (
                <div className="filterContainer">
                  <span className="selectBoxText">{'Ver: '}</span>
                  <select 
                    className={selectBox}
                    onChange={(e) => {
                      if (readOptions) {
                        const queryMap = {
                          [`var-${options.trapReadVariable}`]: e.target.value,
                        };
                        changeUrl(queryMap);
                      }
                    }}
                  >
                    <option hidden selected>{showFilterValue}</option>
                    <option value={options.filterDefaultValue}>{options.filterDefaultValue}</option>
                    <option value="true">Leídos</option>
                    <option value="false">No leídos</option>
                    <option value="saved">Guardados</option>
                  </select>
                </div>
              )}
              {severityOptions && (
                <div className="filterContainer">
                  <span className="selectBoxText">{options.severityTitle + ': '}</span>
                  <select 
                    className={selectBox}
                    onChange={(e) => {
                      if (severityOptions) {
                        const queryMap = {
                          [`var-${options.severityVariable}`]: e.target.value,
                        };
                        changeUrl(queryMap);
                      }
                    }}
                  >
                    <option hidden selected>{severityFilterValue}</option>
                    <option value={options.filterDefaultValue}>{options.filterDefaultValue}</option>
                    <option value="emergency">Emergency</option>
                    <option value="alert">Alert</option>
                    <option value="critical">Critical</option>
                    <option value="error">Error</option>
                    <option value="warning">Warning</option>
                    <option value="notice">Notice</option>
                    <option value="informational">Informational</option>
                    <option value="debug">Debug</option>
                  </select>
                </div>
              )}
              {agentOptions && (
                <div className="filterContainer">
                  <span className="selectBoxText">{options.agentTitle + ': '}</span>
				  <input 
				    className={filterBox}
					onKeyPress={changeAgent}
					onChange={onAgentChange}
					value={agentFilter}
				  ></input>
                  <Icon
                    className="trapline-button"
                    name="filter-slash"
                    size="md"
                    title="Quitar filtro"
                    onClick={(e) => {
                      if (agentOptions) {
                        const queryMap = {
                          [`var-${options.agentVariable}`]: options.filterDefaultValue,
                        };
                        changeUrl(queryMap);
						setAgentFilter(options.filterDefaultValue);
                      }
                    }}
                 />
                </div>
              )}
              {trapOptions && (
                <div className="filterContainer">
                  <span className="selectBoxText">{options.subtitleTitle + ': '}</span>
				  <input 
				    className={filterBox}
					onKeyPress={changeTrap}
					onChange={onTrapChange}
					value={trapFilter}
				  ></input>
                  <Icon
                    className="trapline-button"
                    name="filter-slash"
                    size="md"
                    title="Quitar filtro"
                    onClick={(e) => {
                      if (trapOptions) {
                        const queryMap = {
                          [`var-${options.trapVariable}`]: options.filterDefaultValue,
                        };
                        changeUrl(queryMap);
						setTrapFilter(options.filterDefaultValue);
                      }
                    }}
                  />
                </div>
              )}
              {options.showReadAllOptions && (
                <div className="filterContainer">
                  <Icon
                    className="trapline-button"
                    name="book-open"
                    size="md"
                    title="Marcar todos como leidos"
                    onClick={(e) => {
                      showConfirm('Realmente desea marcar todos los mensajes como leídos?', id);
                    }}
                  />
                </div>
              )}
            </div>
          )}
          <div className={trapContainer} style={{ width: '100%', height: dimensions.height }}>
            <Chrono
              items={timeItems}
              mode={legendPosition}
              cardHeight={String(trapHeight)}
              allowDynamicUpdate={true}
              lineWidth="1"
              hideControls={hideControls}
              scrollable={{ scrollbar: true }}
              flipLayout={flipLayout}
              timelineCircleDimension={String(options.circleDimension)}
              theme={{
                primary: primary,
                secondary: secondary,
                cardBgColor: trapBgColor,
                cardForeColor: trapForeColor,
                textColor: textColor,
                titleColor: titleColor,
              }}
              fontSizes={fontSizes}
              enableOutline={false}
              useReadMore={displayReadMore}
            >
              {trapFactory(timeEvents, showMore, showCardInfo, id, options, agentOptions, severityOptions, trapOptions)}
              {options.useIcon && ( 
                <div className="chrono-icons">
                  {iconFactory(timeEvents, options.circleDimension, severityOptions, options)}
                </div>
              )}
            </Chrono>
          </div>
          <div id={moreInfoContainerId} className="trapline-moreInfoContainer trapline-hide">
            <div id={moreInfoId} className="trapline-moreInfo">
              <div className="trapTitleContainer">
                <span id={moreTitleId} className="trapline-moreInfoTitles"></span>
                <span id={moreCardTitleId} className="trapline-moreInfoTitles"></span>
              </div>
              <div className="trapline-moreDivs">
                <span className="trapline-moreTitles">{options.severityTitle + ': '}</span>
                <span id={moreInfoSeverityId} className="trapline-moreInfoText"></span>
              </div>
              <div className="trapline-moreDivs">
                <span className="trapline-moreTitles">{options.subtitleTitle + ': '}</span>
                <span id={moreSubTitleId} className="trapSubtitle"></span>
              </div>
              <div className="trapline-moreDivs">
                <span className="trapline-moreTitles-small">{options.agentTitle + ': '}</span>
                <span id={moreAgentId} className="trapline-moreInfoText-small"></span>
              </div>
              <div className="trapline-moreDivs">
                <span className="trapline-moreTitles-small">{'Fecha primer Trap: '}</span>
                <span id={moreInfoFirstTrap} className="trapline-moreInfoText-small"></span>
              </div>
              <div className="trapline-moreDivs">
                <span className="trapline-moreTitles-small">{'Cantidad de Registro/s: '}</span>
                <span id={moreInfoQty} className="trapline-moreInfoText-small"></span>
              </div>
              <div className="trapline-moreDivs-line" />
              <div className="trapline-moreDivs">
                <div className="trapline-moreTitles">{options.infoTitle + ': '}</div>
                <div id={moreInfoTextId} className="trapDetailedTextZoom"></div>
              </div>
              {options.useUrl && (
                <div className="trapline-url_button">
                  <a id={moreInfoUrlId} target="_blank" style={{ color: '#fff' }} rel="nofollow noopener noreferrer">
                    mas información ...
                  </a>
                </div>
              )}
              <button
                className="trapline-close_button"
                onClick={(e) => {
                  hideMoreInfo(e, id);
                }}
              >
                cerrar
              </button>
            </div>
          </div>
		  <div id={confirmId} className="trapline-moreInfoContainer trapline-hide">
            <div id={confirmInfoId} className="trapline-confirm">
              <div className="trapline-moreDivs">
                <div id={confirmTitleId} className="trapline-moreTitles">
				  Realmente desea marcar todos los mensajes como leídos?
				</div>
              </div>
              <button
                className="trapline-ok_button"
                onClick={(e) => {
                  checkAllAsRead(timeEvents, options.api);
				  hideConfirm(e, id);
                }}
              >
                Aceptar
              </button>
              <button
                className="trapline-cancel_button"
                onClick={(e) => {
                  hideConfirm(e, id);
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      );
    } else {
        return (
          <div className="trapManager" id="trapManager">
            {showTitle && (
              <div className={traptitle}>
                <div className="traptitleText">{pluginTitle}</div>
              </div>
            )}
            {options.showToolbar && (
              <div className={toolbarBox}>
                {readOptions && (
                  <div className="filterContainer">
                    <span className="selectBoxText">{'Ver: '}</span>
                    <select 
                      className={selectBox}
                      onChange={(e) => {
                        if (readOptions) {
                          const queryMap = {
                            [`var-${options.trapReadVariable}`]: e.target.value,
                          };
                          changeUrl(queryMap);
                        }
                      }}
                    >
                      <option hidden selected>{showFilterValue}</option>
                      <option value={options.filterDefaultValue}>{options.filterDefaultValue}</option>
                      <option value="true">Leídos</option>
                      <option value="false">No leídos</option>
                      <option value="saved">Guardados</option>
                    </select>
                  </div>
                )}
                {severityOptions && (
                  <div className="filterContainer">
                    <span className="selectBoxText">{options.severityTitle + ': '}</span>
                    <select 
                      className={selectBox}
                      onChange={(e) => {
                        if (severityOptions) {
                          const queryMap = {
                            [`var-${options.severityVariable}`]: e.target.value,
                          };
                          changeUrl(queryMap);
                        }
                      }}
                    >
                      <option hidden selected>{severityFilterValue}</option>
                      <option value={options.filterDefaultValuee}>{options.filterDefaultValue}</option>
                      <option value="emergency">Emergency</option>
                      <option value="alert">Alert</option>
                      <option value="critical">Critical</option>
                      <option value="error">Error</option>
                      <option value="warning">Warning</option>
                      <option value="notice">Notice</option>
                      <option value="informational">Informational</option>
                      <option value="debug">Debug</option>
                    </select>
                  </div>
                )}
                {agentOptions && (
                  <div className="filterContainer">
                    <span className="selectBoxText">{options.agentTitle + ': '}</span>
                    <div className={filterBox}>{agentFilter}</div>
                    <Icon
                      className="trapline-button"
                      name="filter-slash"
                      size="md"
                      title="Quitar filtro"
                      onClick={(e) => {
                        if (agentOptions) {
                          const queryMap = {
                            [`var-${options.agentVariable}`]: options.filterDefaultValue,
                          };
                          changeUrl(queryMap);
						  setAgentFilter(options.filterDefaultValue);
                        }
                      }}
                   />
                  </div>
                )}
                {trapOptions && (
                  <div className="filterContainer">
                    <span className="selectBoxText">{options.subtitleTitle + ': '}</span>
                    <div className={filterBox}>{trapFilter}</div>
                    <Icon
                      className="trapline-button"
                      name="filter-slash"
                      size="md"
                      title="Quitar filtro"
                      onClick={(e) => {
                        if (trapOptions) {
                          const queryMap = {
                            [`var-${options.trapVariable}`]: options.filterDefaultValue,
                          };
                          changeUrl(queryMap);
						  setTrapFilter(options.filterDefaultValue);
                        }
                      }}
                    />
                  </div>
                )}
                {options.showReadAllOptions && (
                  <div className="filterContainer">
                    <Icon
                      className="trapline-button"
                      name="book-open"
                      size="md"
                      title="Marcar todos como leidos"
                      onClick={(e) => {
                        showConfirm('Realmente desea marcar todos los mensajes como leídos?', id);
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            <div>
              <div className="noTrapMessage">{error1}</div>
            </div>
          </div>
        );
    } 
  } else {
    return (
      <div>
        <div className="gf-form-error">{error3}</div>
      </div>
    );
  }
};

function changeSeverity(value, severityVariable) {
  if (severityVariable !== undefined && severityVariable !== null && severityVariable !== '') {
    const queryMap = {
      [`var-${severityVariable}`]: value,
    };
    changeUrl(queryMap);
  }
}

function iconFactory(icons: trapData[], circleDimension: number, severityOptions: boolean, options) {
  let iconSize = 'xs';
  if (circleDimension > 18) {
    iconSize = 'sm';
  } else if (circleDimension > 20) {
    iconSize = 'md';
  } else if (circleDimension > 24) {
    iconSize = 'lg';
  } else if (circleDimension > 28) {
    iconSize = 'xl';
  }

  return icons.map((trapEvent) => (
    <div className="icon_background" key={trapEvent.id}>
      <Icon
        className={'color_' + trapEvent.color}
        name={trapEvent.severity.toLowerCase()}
        size={iconSize}
        title={severityOptions ? 'Aplicar como filtro' : trapEvent.severity}
        onClick={(e) => {
          if (severityOptions) {
            const queryMap = {
              [`var-${options.severityVariable}`]: trapEvent.severity,
            };
            changeUrl(queryMap);
          }
        }}
      />
    </div>
  ));
}

function save(trapEvent, api, type) {
  var apiUrl = api + '/insert_trap_toggle_read';
  var trap = '{"params" : "' + String(trapEvent.isRead) + '|' + String(trapEvent.idx);
  trap = trap + '|' + String(trapEvent.agentUrl) + '|' + String(trapEvent.timest) + '"}';
  if (type === 'save') {
    trap = '{"params" : "' + String(trapEvent.isSaved) + '|' + String(trapEvent.idx);
    trap = trap + '|' + String(trapEvent.agentUrl) + '| ' + String(trapEvent.timest) + '"}';
    apiUrl = api + '/insert_trap_toggle_store';
  }

  axios.defaults.baseURL = apiUrl;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.post(apiUrl, trap).then(
    response => {
      if (response.statusText !== 'OK') {
        SystemJS.load('app/core/app_events').then((appEvents: any) => {
        });
      }
    },
    error => {
      SystemJS.load('app/core/app_events').then((appEvents: any) => {
        console.log('Se produjo un error');
      });
    }
  );
}

function checkAllAsRead(trapEvents, api) {
  trapEvents.forEach((trapEvent, index) => {
    if (trapEvent.isRead === false) {
      trapEvent.isRead = true;
      let iconId = '#icon_uncheck_' + String(trapEvent.id);
      $(iconId).removeClass('show_icon');
      $(iconId).addClass('hide_icon');
      iconId = '#icon_check_' + String(trapEvent.id);
      $(iconId).removeClass('hide_icon');
      $(iconId).addClass('check_icon show_icon');
      save(trapEvent, api, 'check');
    }
  });
}

function showConfirm(title, id) {
  let divContainerId = '#trapline-confirm-' + String(id);
  let divTitleId = '#trapline-confirmTitle-' + String(id);

  $(divContainerId).removeClass('trapline-hide');
  $(divTitleId).text(title);
}

function showMoreInfo(e, trapEvent, id, options) {
  let divContainerId = '#trapline-moreInfoContainer-' + String(id);
  let divInfoId = '#trapline-moreInfo-' + String(id);
  let divTitleId = '#trapline-moreInfoTitle-' + String(id);
  let divCardTitleId = '#trapline-moreInfoCardTitle-' + String(id);
  let divSubTitleId = '#trapline-moreInfoSubTitle-' + String(id);
  let divInfoTextId = '#trapline-moreInfoText-' + String(id);
  let divAgentId = '#trapline-moreInfoAgent-' + String(id);
  let divFirstTrapId = '#trapline-moreInfoFirstTrap-' + String(id);
  let divQtyId = '#trapline-moreInfoQty-' + String(id);
  let divInfoSeverityId = '#trapline-moreInfoSeverity-' + String(id);
  let divInfoTypeId = '#trapline-moreInfoType-' + String(id);
  let divInfoUrlId = '#trapline-moreInfoUrl-' + String(id);

  $(divContainerId).removeClass('trapline-hide');
  $(divTitleId).text(trapEvent.title);
  $(divCardTitleId).text(trapEvent.trapTitle);
  $(divSubTitleId).text(trapEvent.trapSubtitle);
  $(divInfoTextId).text(trapEvent.trapDetailedText);
  $(divAgentId).text(trapEvent.agent);
  $(divFirstTrapId).text(trapEvent.firstTrap);
  $(divQtyId).text(trapEvent.counter);
  $(divInfoSeverityId).text(trapEvent.severity);
  $(divInfoUrlId).attr('href', trapEvent.url);
}

function handleUrl(url, target, update) {
  let urlTarget = '_blank';
  if (target === '_self') {
    urlTarget = '_self';
  }
  if (url.indexOf('http') > 0 || update === true) {
    window.open(url, target);
  } else {
    window.open(window.location.protocol + '//' + url, urlTarget);
  }
}

function hideMoreInfo(e, id) {
  let divID = '#trapline-moreInfoContainer-' + String(id);
  $(divID).addClass('trapline-hide');
}

function hideConfirm(e, id) {
  let divID = '#trapline-confirm-' + String(id);
  $(divID).addClass('trapline-hide');
}

function trapFactory(traps: trapData[], showMore, showCardInfo, id, options, agentOptions, severityOptions, trapOptions) {
  const api = options.api;
  const isDark = config.theme.isDark;
  var trapContainer = 'trapContainer';
  var trapTitle = 'trapTitle';
  var trapSubtitle = 'trapSubtitle';
  var trapDetailedText = 'trapDetailedText';
  var agent = 'trapline-agent';
  var trapTheme = '';
  if (isDark) {
    trapTitle = 'trapTitle_dark';
    trapDetailedText = 'trapDetailedText_dark';
    trapSubtitle = 'trapSubtitle_dark';
    trapTheme = '_dark';
  }

  return traps.map((trapEvent) => (
    <div key={trapEvent.id} className={trapContainer}>
      <div className="trapTitleContainer">
        <span 
          className={'trapline-trapTitle'}
          title={options.severityTitle + ': ' + trapEvent.severity}
        >
          <button
            className={trapTitle + ' trapline-' + trapEvent.severity + trapTheme + 'color_' + trapEvent.color}
            onClick={(e) => {
              if (trapOptions && options.showToolbar) {
                const queryMap = {
                  [`var-${options.trapVariable}`]: trapEvent.trapTitle,
                };
                changeUrl(queryMap);
              }
              if (agentOptions && options.showToolbar) {
                const queryMap = {
                  [`var-${options.agentVariable}`]: trapEvent.agent,
                };
                changeUrl(queryMap);
              }
            }}
            title={options.showToolbar ? 'Filtrar por tipo de TRAP y agente' : 'Trap recibido'}
          >
            {trapEvent.trapTitle}
          </button>
        </span>
        <span>
          <button
            className="trapline-indicator"
            title={'Traps absorvidos por este mensaje'}
          >
            {trapEvent.counter}
          </button>
        </span>
        <span>
          <button
            className="trapline-url_button"
            onClick={(e) => {
              handleUrl(trapEvent.agentUrl, '_blank', false);
            }}
            title={'Ir al ' + options.agentTitle.toLowerCase() + ': ' + trapEvent.agent}
          >
            {trapEvent.agent}
          </button>
        </span>
        {showMore && (
          <spam>
            <button
              className="trapline-button"
              onClick={(e) => {
                showMoreInfo(e, trapEvent, id, options);
              }}
              title="más detalles ..."
            >
              <Icon
                name="ellipsis-h"
                size="md"
              />
            </button>
          </spam>
        )}
        <span
          className={trapEvent.isRead ? 'checkBox show_icon' : 'checkBox hide_icon'}
          id={'icon_check_' + String(trapEvent.id)}
        >
          <Icon
            className="show_icon check_icon"
            name={'check-square'}
            size="md"
            title={'Trap leido'}
          />
        </span>
        <span 
          className={trapEvent.isRead ? 'checkBox hide_icon' : 'checkBox show_icon'}
          id={'icon_uncheck_' + String(trapEvent.id)}
        >
          <Icon
            className="show_icon uncheck_icon"
            name={'square'}
            size="md"
            onClick={(e) => {
              if (trapEvent.isRead === false) {
                trapEvent.isRead = true;
                let iconId = '#icon_uncheck_' + String(trapEvent.id);
                $(iconId).removeClass('show_icon');
                $(iconId).addClass('hide_icon');
                iconId = '#icon_check_' + String(trapEvent.id);
                $(iconId).removeClass('hide_icon');
                $(iconId).addClass('check_icon show_icon');
                save(trapEvent, api, 'check');
              }
            }}
            title={'Marcar como leido'}
          />
        </span>
        <span
          className={trapEvent.isSaved ? 'checkBox show_icon' : 'checkBox hide_icon'}
          id={'icon_save_' + String(trapEvent.id)}
        >
          <Icon
            className="show_icon check_icon"
            name={'save'}
            size="md"
            onClick={(e) => {
              if (trapEvent.isSaved === true) {
                trapEvent.isSaved = false;
                let iconId = '#icon_save_' + String(trapEvent.id);
                $(iconId).removeClass('show_icon');
                $(iconId).addClass('hide_icon');
                iconId = '#icon_unsave_' + String(trapEvent.id);
                $(iconId).removeClass('hide_icon');
                $(iconId).addClass('show_icon');
                save(trapEvent, api, 'save');
              }
            }}
            title={'Trap marcado para guardar indefinidamente'}
          />
        </span>
        <span
          className={trapEvent.isSaved ? 'checkBox hide_icon' : 'checkBox show_icon'}
          id={'icon_unsave_' + String(trapEvent.id)}
        >
          <Icon
            className="show_icon unselect_icon"
            name={'save'}
            size="md"
            onClick={(e) => {
              if (trapEvent.isSaved === false) {
                trapEvent.isSaved = true;
                let iconId = '#icon_save_' + String(trapEvent.id);
                $(iconId).removeClass('hide_icon');
                $(iconId).addClass('show_icon');
                iconId = '#icon_unsave_' + String(trapEvent.id);
                $(iconId).removeClass('show_icon');
                $(iconId).addClass('hide_icon');
                save(trapEvent, api, 'save');
              }
            }}
            title={'Guardar Trap'}
          />
        </span>
      </div>
      {showCardInfo && (
        <div className="trapInfoContainer">
          <div id={'timeline-wrapper' + trapEvent.id} className={trapDetailedText}>
            {trapEvent.trapDetailedText}
          </div>
        </div>
      )}
    </div>
  ));
}

import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { PanelProps, GraphSeriesValue, AppEvents } from '@grafana/data';
import { FullWidthButtonContainer, HorizontalGroup, VerticalGroup, Icon } from '@grafana/ui';

import { config } from '@grafana/runtime';
import { TimepickerData, FilterpickerData, GenericButtonData } from 'types';
import { changeUrl, getEpochWithMillis, getNetMonitorVariableValue } from './utils';
import { SimpleOptions } from './types';
import Select, { components } from 'react-select';
import './css/TimepickerButton.css';

import { TimepickerSelect } from './TimepickerSelect';
import { TimepickerButton, FilterpickerButton, GenericButton } from './TimepickerButton';

interface ButtonPanelProps extends PanelProps<SimpleOptions> {}

export const ButtonPanel: React.FC<ButtonPanelProps> = ({ options, data, width, height, replaceVariables, id }) => {
  const isDark = config.theme.isDark || false;
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);

  var dropdownTitleText = replaceVariables(options.variableText);
  const buttonTitleText = replaceVariables(options.buttonText);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [dateSelectedFromValue, setDateSelectedFromValue] = useState('');
  const [dateSelectedToValue, setDateSelectedToValue] = useState('');
  const [inputSelectedValue, setInputSelectedValue] = useState('');
  
  const allVariable = replaceVariables(options.allOptionVariable);
  var allValue = replaceVariables(options.allOptionText);
  const allVariableValue = getNetMonitorVariableValue(allVariable);
  if (options.multiValue) {
    allValue = replaceVariables(options.defaultValueDropdown);
  }

  const cleanAllValue = replaceVariables(options.defaultValuetoClean);
  var defaultVariable = replaceVariables(options.defaultVariable);
  var mapVariable = replaceVariables(options.mapVariable);
  var heatVariable = replaceVariables(options.heatVariable);
  var multiVariableValue = [];
  if (options.displayStyle === 'dropdown' && options.multiValue) {
    defaultVariable = replaceVariables(options.multiValueVariable);
    multiVariableValue = getNetMonitorVariableValue(defaultVariable);

  }
  var multiVariableValueHasAll = false;
  const previousDefVariableWasAll = useRef(false);
  let findAllValue = multiVariableValue.indexOf(allValue);
  if (findAllValue >= 0) {
    multiVariableValueHasAll = true;
  }
  const renderCount = useRef(0);
  useEffect(() => {
    renderCount.current = renderCount.current + 1;
    previousDefVariableWasAll.current = multiVariableValueHasAll;
  });
  const allOption: optionsData = {
    value: 0,
    label: allValue,
    icon: 'refresh',
  };
  var defaultOption: optionsData = allOption;
  var defaultOptions: optionsData[] = [];
  const buttons: TimepickerData[] = [];
  const buttons2: FilterpickerData[] = [];
  const buttons3: GenericButtonData[] = [];
  const buttons4: GenericButtonData[] = [];
  const buttons5: GenericButtonData[] = [];
  const buttons6: GenericButtonData[] = [];
  const dropDownOptions: optionsData[] = [];

  var buttonModeEnable = true;
  var optionsDisabled = false;
  let minWidth = 100;
  if (isNaN(Number(options.minWidth))) {
    minWidth = 100;
  } else {
    minWidth = Number(options.minWidth);
  }
  let maxOptionsLimit = 10;
  if (isNaN(Number(options.maxOptionsLimit))) {
    maxOptionsLimit = 10;
  } else {
    maxOptionsLimit = Number(options.maxOptionsLimit);
  }
  if (options.displayStyle === 'dropdown' || width < minWidth) {
    buttonModeEnable = false;
  }

  if (!buttonModeEnable && width < 150) {
    return (
      <div>
        <div className="gf-form-error">{error4}</div>
      </div>
    );
  } else if (data.state !== 'Error' || options.displayStyle === 'input') {
    if (data.state !== 'Error' && options.displayStyle !== 'input') {
      data.series.forEach(series => {
        const timeVals: GraphSeriesValue[] = series.fields[0].values.toArray();
        let primaryButton = replaceVariables(options.primaryFieldButton) || '0';
        let actualFrom = getNetMonitorVariableValue(`__from`);
        actualFrom = actualFrom.toString().substring(0, 7);
        let actualTo = getNetMonitorVariableValue(`__to`);
        actualTo = actualTo.toString().substring(0, 7);
        if (isNaN(Number(primaryButton))) {
          primaryButton = '0';
        }
        let actualIndex = 0;
        if (options.TypePickerButton === true) {
          for (let i = 0; i < timeVals.length; i++) {
            let optionButton: TimepickerData = {
              text: series.fields.find(field => field.name === options.buttonTextOption)?.values.get(i),
              time_from: series.fields.find(field => field.name === options.timeFromOption)?.values.get(i),
              time_to: series.fields.find(field => field.name === options.timeToOption)?.values.get(i),
              style: series.fields.find(field => field.name === options.buttonStyleOption)?.values.get(i),
              errors: [],
            };
            if (typeof optionButton.time_from === 'undefined' || optionButton.time_from === null) {
              optionButton.errors.push(`'${options.timeFromOption}' value is required`);
            } else {
              optionButton.time_from = Number(optionButton.time_from);
              if (isNaN(optionButton.time_from)) {
                optionButton.errors.push(`'${options.timeFromOption}' is not a valid UNIX timestamp`);
              } else if (typeof optionButton.text === 'undefined' || optionButton.text === null) {
                optionButton.text = new Date(getEpochWithMillis(optionButton.time_from)).toLocaleString();
              } else {
                optionButton.text = String(optionButton.text);
              }
            }
            if (typeof optionButton.time_to !== 'undefined' && optionButton.time_to !== null) {
              optionButton.time_to = Number(optionButton.time_to);
              if (isNaN(optionButton.time_to)) {
                optionButton.errors.push(`'${options.timeToOption}' is not a valid UNIX timestamp`);
              }
            }
            let timeFrom = getEpochWithMillis(optionButton.time_from);
            timeFrom = timeFrom.toString().substring(0, 7);
            let timeTo = getEpochWithMillis(optionButton.time_to);
            timeTo = timeTo.toString().substring(0, 7);
            if (Number(primaryButton) === i || (actualFrom === timeFrom && actualTo === timeTo)) {
              optionButton.primary = true;
            } else {
              optionButton.primary = false;
            }
            optionButton.id = i;
            optionButton.style = String(optionButton.style);
            buttons.push(optionButton);
          }
        } else {
          for (let i = -1; i < timeVals.length; i++) {
            if (options.displayStyle === 'dropdown' && options.allOption && i < 0) {
              let aux_button: FilterpickerData = {
                id: 0,
                text: allValue,
                variable: allVariable,
                value: allValue,
                result: '',
                style: 'default',
                icon: 'refresh',
                title: 'Ver todos los ' + dropdownTitleText.toLowerCase(),
                selectTitle: options.selectedTooltip || 'Filtro aplicado actualmente',
                errors: [],
              };
              buttons2.push(aux_button);
              dropDownOptions.push(allOption);
            } else if (i >= 0) {
              let optionButton: FilterpickerData = {
                text: series.fields.find(field => field.name === options.buttonTextOption)?.values.get(i),
                variable: series.fields.find(field => field.name === options.buttonVariableOption)?.values.get(i),
                value: series.fields.find(field => field.name === options.buttonValueOption)?.values.get(i),
                result: series.fields.find(field => field.name === options.buttonResultOption)?.values.get(i),
                style: series.fields.find(field => field.name === options.buttonStyleOption)?.values.get(i),
                icon: series.fields.find(field => field.name === options.buttonIconOption)?.values.get(i),
                title: series.fields.find(field => field.name === options.buttonTitleOption)?.values.get(i),
                selectTitle: options.selectedTooltip,
                errors: [],
              };
              if (Number(primaryButton) === i) {
                optionButton.primary = true;
              } else {
                optionButton.primary = false;
              }
              if (optionButton.result === undefined || optionButton.result === null) {
                optionButton.result = '';
              } else {
                optionButton.result = String(optionButton.result);
              }
              optionButton.id = i + 1;
              optionButton.text = String(optionButton.text);
              optionButton.variable = String(optionButton.variable);
              optionButton.value = String(optionButton.value);
              optionButton.style = String(optionButton.style);
              optionButton.icon = String(optionButton.icon);
              optionButton.title = String(optionButton.title);
              optionButton.selectTitle = String(optionButton.selectTitle);
              buttons2.push(optionButton);
              let dropDownOption: optionsData = {
                value: series.fields.find(field => field.name === options.buttonValueOption)?.values.get(i),
				label: series.fields.find(field => field.name === options.buttonTextOption)?.values.get(i),
                icon: series.fields.find(field => field.name === options.buttonIconOption)?.values.get(i),
              };
              dropDownOptions.push(dropDownOption);
              if (!options.multiValue) {
                let actualVariableValue = getNetMonitorVariableValue(optionButton.variable);
                if (actualVariableValue === dropDownOption.label || optionButton.primary === true) {
                  defaultOption = dropDownOption;
                }
              } else {
                let index = multiVariableValue.indexOf(optionButton.value);
				if (optionButton.variable === defaultVariable && index >= 0) {
                  defaultOptions.push(dropDownOption);
                }
              }
            }
          }
        }
        let clean_button: GenericButtonData = {
          text: 'clean',
          variable: defaultVariable,
          value: replaceVariables(options.defaultValue),
          icon: 'trash-alt',
          url: '',
          title: 'Borrar selección',
        };
        clean_button.value = replaceVariables(clean_button.value) || '';
        buttons3.push(clean_button);
        let zoom_button: GenericButtonData = {
          text: 'zoom',
          variable: defaultVariable,
          value: replaceVariables(options.defaultValue),
          icon: 'zoom',
          url: replaceVariables(options.zoomButtonUrl) || '',
          title: 'Ampliar',
        };
        zoom_button.value = replaceVariables(zoom_button.value);
        zoom_button.url = replaceVariables(zoom_button.url);
        buttons4.push(zoom_button);
        let map_button: GenericButtonData = {
          text: 'map',
          variable: mapVariable,
          value: 'streets',
          icon: 'layers',
          url: '',
          title: 'Cambiar tipo de mapa utilizado',
        };
        map_button.value = getNetMonitorVariableValue(map_button.variable);
        buttons5.push(map_button);
        let heat_button: GenericButtonData = {
          text: 'heatmap',
          variable: heatVariable,
          value: '0',
          icon: 'fire',
          url: '',
          title: 'Activar/desactivar Heatmap',
        };
        heat_button.value = getNetMonitorVariableValue(heat_button.variable);
        buttons6.push(heat_button);
      });

      if ((allVariableValue === allValue && options.allOption) || (options.multiValue && defaultOptions.length < 1)) {
		defaultOptions.push(allOption);
      }

      if (options.TypePickerButton === true) {
        return (
          <div>
            {options.displayStyle === 'dropdown' && <TimepickerSelect timepickerData={buttons} />}
            <div className="panel_container">
              <div className="pagination">
                {options.displayStyle === 'button' && !options.displayButtonsHorizontal && buttonTitleText !== '' && (
 				  <div className="dropdown_text">
				    {buttonTitleText + ':'}
				  </div>
				)}
				{options.displayStyle === 'button' && !options.displayButtonsHorizontal && (
                  <FullWidthButtonContainer>
                    <VerticalGroup spacing={'sm'}>{buttonFactory(buttons)}</VerticalGroup>
                  </FullWidthButtonContainer>
                )}
                {options.displayStyle === 'button' && options.displayButtonsHorizontal && (
                  <div className="button_container">{buttonFactory(buttons)}</div>
                )}
              </div>
            </div>
          </div>
        );
      } else {
		if (options.multiValue) {
		  if (!previousDefVariableWasAll.current && multiVariableValueHasAll) {
		    if (renderCount.current > 2) {
		      setSelectedValues([]);
              setSelectedValues([allOption]);
		      renderCount.current = 1;
			}
			previousDefVariableWasAll.current = true;
          } else if (previousDefVariableWasAll.current && !multiVariableValueHasAll && renderCount.current > 1) {
		    previousDefVariableWasAll.current = false;
          } else if (previousDefVariableWasAll.current && multiVariableValueHasAll && renderCount.current > 2) {
		    setSelectedValues([]);
            setSelectedValues([allOption]);
		    previousDefVariableWasAll.current = false;
		    renderCount.current = 1;
		  }
		}
        if (renderCount.current === 0) {
          if (!options.multiValue) {
            setSelectedValue([]);
            setSelectedValue(defaultOption);
          } else {
            setSelectedValues([]);
            setSelectedValues(defaultOptions);
          }
          renderCount.current = renderCount.current + 1;
        }

        let dropdownStyle = 'dropdown__container';
        if (options.multiValue) {
          dropdownStyle = 'dropdownMulti__container';
        }
        let dropdownTextVisible = false;
        if (dropdownTitleText.length > 0) {
          dropdownTextVisible = true;
        }

        var customStyles = {
          menu: provided => ({
            ...provided,
            color: '#000',
            background: '#fafafa',
            border: '1px solid #dde4ed',
            borderRadius: '5px',
            marginTop: '5px',
            marginBottom: '5px',
            zIndex: 9999,
          }),
          option: (base, state) => ({
            ...base,
            color: '#000',
            background: '#fafafa',
            marginTop: '-4px',
            marginBottom: '-4px',
            '&:hover': {
              color: '#fff',
              background: '#a1a1a1',
            },
          }),
          indicatorContainer: provided => ({
            ...provided,
            paddingLeft: '10px',
          }),
          menuPortal: provided => ({
            ...provided,
            zIndex: 9999,
          }),
        };
        let classSelect = 'react-select-box';
        if (isDark) {
          customStyles = {
            menu: provided => ({
              ...provided,
              color: '#fafafa',
              background: '#222426',
              border: '1px solid #5a5a5a',
              borderRadius: '5px',
              marginTop: '5px',
              marginBottom: '5px',
              zIndex: 9999,
            }),
            option: (base, state) => ({
              ...base,
              color: '#fafafa',
              background: '#222426',
              marginTop: '-4px',
              marginBottom: '-4px',
              '&:hover': {
                color: '#fff',
                background: '#5a5a5a',
              },
            }),
            indicatorContainer: provided => ({
              ...provided,
              paddingLeft: '10px',
            }),
            menuPortal: provided => ({
              ...provided,
              zIndex: 9999,
            }),
          };
          classSelect = 'react-select-box_dark';
        }

        const DropdownIndicator = (props: ElementConfig<typeof components.DropdownIndicator>) => {
          return (
            <components.DropdownIndicator {...props}>
              <Icon name="angle-down" size="xl" />
            </components.DropdownIndicator>
          );
        };

        const ClearIndicator = (props) => {
          return (
            <components.ClearIndicator {...props}>
              <Icon name="times" size="lg" />
            </components.ClearIndicator>
          );
        };

        const NoOptionsMessage = props => {
          return (
            <components.NoOptionsMessage {...props}>
              <span className="custom-css-class">{error2}</span> 
            </components.NoOptionsMessage>
          );
        };

        return (
          <div>
            {!buttonModeEnable && (
              <div 
                className={dropdownStyle} 
                title={options.multiValue 
                  ? 'Puede seleccionar hasta ' + String(maxOptionsLimit) + ' ' + dropdownTitleText.toLowerCase() + '.'
                  : 'Seleccione una opción.'
                }
              >
                {dropdownTextVisible === true && <div className="dropdown_text">{dropdownTitleText + ':'}</div>}
                {options.multiValue === false && (
                  <Select
                    instanceId={id}
                    className={classSelect}
                    classNamePrefix={classSelect}
                    components={{ DropdownIndicator, NoOptionsMessage }}
                    options={dropDownOptions}
                    value={selectedValue}
                    isSearchable={false}
                    placeholder={
                      <div className="timepicker-icon">
                        <Icon name={defaultOption.icon} size="lg" />
                        <div className="timepicker-label">{defaultOption.label}</div>
                      </div>
                    }
                    onChange={e => {
                      if (e !== selectedValue) {
                        setSelectedValue(e);
                        const valueToSet = buttons2.find(options => {
                          return options.text === e.label;
                        });
                        if (valueToSet) {
                          let queryMap = {
                            [`var-${valueToSet.variable}`]: e.value,
                          };
                          changeUrl(queryMap);
                        }
                        if (options.cleanDefaultVariable) {
                          let cleanValue = replaceVariables(options.defaultValuetoClean);
						  let queryMap = {
                            [`var-${options.variableToClean}`]: cleanValue,
                          };
                          if (options.cleanDefaultVariableIsMulti) {
                            let valueToClean = [];
                            valueToClean[0] = cleanValue;
                            queryMap = {
                              [`var-${options.variableToClean}`]: valueToClean,
                            };
                          }
                          changeUrl(queryMap);
                        }
                      }
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    styles={customStyles}
                    getOptionLabel={e => (
                      <div className="timepicker-icon">
                        <Icon name={e.icon} size="lg" />
                        <div className="timepicker-label">{e.label}</div>
                      </div>
                    )}
                  />
                )}
                {options.multiValue === true && (
                  <Select
                    instanceId={id}
                    className={classSelect}
                    classNamePrefix={classSelect}
                    components={{ DropdownIndicator, ClearIndicator, NoOptionsMessage }}
                    options={dropDownOptions}
                    value={selectedValues}
                    closeMenuOnSelect={false}
                    isSearchable={false}
                    isClearable={true}
                    isMulti={true}
                    isOptionDisabled={() => selectedValues.length >= maxOptionsLimit}
                    onChange={e => {
					  let newSelect = e.filter(item => item.label !== allValue);
                      setSelectedValues([]);
					  if (newSelect.length < 1) {
						newSelect = [allOption];
						renderCount.current = 1;
                      }
					  setSelectedValues(newSelect);
                      let isAllValue = true;
                      let selectedDefaultValue = [];
                      let n = 0;
                      selectedDefaultValue[n] = allValue;
                      let selectQuery = {};
                      for (let i = 0; i < newSelect.length; i++) {
                        const valueToSet = buttons2.find(options => {
                          return options.text === newSelect[i].label;
                        });
                        if (valueToSet) {
                          if (valueToSet.variable === defaultVariable) {
                            selectedDefaultValue[n] = String(valueToSet.value);
                            if (valueToSet.value !== allValue) {
                              isAllValue = false;
                            }
                            n = n + 1;
                          } else {
                            let queryMap = {
                              [`var-${valueToSet.variable}`]: valueToSet.value,
                            };
                            changeUrl(queryMap);
                          }
                          if (options.cleanDefaultVariable) {
                            let queryMap = {
                              [`var-${options.variableToClean}`]: cleanAllValue,
                            };
                            if (options.cleanDefaultVariableIsMulti) {
                              let valueToClean = [];
                              valueToClean[0] = cleanAllValue;
                              queryMap = {
                                [`var-${options.variableToClean}`]: valueToClean,
                              };
                            }
                            changeUrl(queryMap);
                          }
                        }
                      }
                      const queryMapMulti = {
                        [`var-${defaultVariable}`]: selectedDefaultValue,
                      };
                      changeUrl(queryMapMulti);
                    }}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                    styles={customStyles}
                    getOptionLabel={e => (
                      <div className="timepicker-icon">
                        <Icon name={e.icon} size="md" />
                        <div className="timepicker-label">{e.label}</div>
                      </div>
                    )}
                  />
                )}
              </div>
            )}
            {buttonModeEnable && options.displayStyle === 'button' && (
              <div className="panel_container">
                <div className="pagination">
                  {!options.displayButtonsHorizontal && (
                    <div className="button_container">
                      <VerticalGroup spacing={'sm'}>{buttonFactory2(buttons2)}</VerticalGroup>
                    </div>
                  )}
                  {options.displayButtonsHorizontal && buttonTitleText !== '' && (
 				    <div className="dropdown_text">
				      {buttonTitleText + ':'}
				    </div>
				  )}
				  {options.displayButtonsHorizontal && options.zoomButton && (
                    <div className="link_container">{buttonFactory3(buttons4)}</div>
                  )}
                  {options.displayButtonsHorizontal && options.mapButton && (
                    <div className="link_container">{buttonFactory3(buttons5)}</div>
                  )}
                  {options.displayButtonsHorizontal && options.heatButton && (
                    <div className="link_container">{buttonFactory3(buttons6)}</div>
                  )}
                  {options.displayButtonsHorizontal && (
                    <div className="button_container">{buttonFactory2(buttons2)}</div>
                  )}
                  {options.displayButtonsHorizontal && options.cleanButton && (
                    <div className="link_container">{buttonFactory3(buttons3)}</div>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      }
    } else if (options.displayStyle === 'input') {
	  let inputType = 'datetime-local';
	  var inputFromValue = '';
	  var inputToValue = '';
	  const inputTitleText = replaceVariables(options.inputText) || '';
	  const inputFromTitleText = replaceVariables(options.inputFromText) || '';
	  const inputToTitleText = replaceVariables(options.inputToText) || '';
	  const inputToVariable = replaceVariables(options.inputToVariable) || '';
	  const inputFromVariable = replaceVariables(options.inputFromVariable) || '';
	  let inputPlaceholder = 'Buscar...';
	  let inputTextDefaultValue = '';
	  let inputTextVariableValue = '';
	  let inputDateVariableValue = Date.now();
	  const date = new Date();
	  const offset = date.getTimezoneOffset() * 60000;
      var actualTo = inputToVariable === 'to' ? 
	    Number(getNetMonitorVariableValue(`__${inputToVariable}`)) - offset :
		Number(getNetMonitorVariableValue(`${inputToVariable}`)) - offset;
	  var actualFrom = inputFromVariable === 'from' ?
	    Number(getNetMonitorVariableValue(`__${inputFromVariable}`)) - offset :
		Number(getNetMonitorVariableValue(`${inputFromVariable}`)) - offset;

	  const TimePickerInput = options.TimePickerInput;
	  if (TimePickerInput) {
		const now = Date.now();
		if (actualTo > now) {
		  actualTo = now;
		}
        inputToValue = (new Date(getEpochWithMillis(actualTo)).toISOString()).slice(0, -8);
	    if (actualFrom > actualTo) {
		  actualFrom = actualTo - 3600000;
		}
		inputFromValue = (new Date(getEpochWithMillis(actualFrom)).toISOString()).slice(0, -8);
	  } else {
	    inputType = options.inputType;
	    if (options.inputVariable && options.inputVariable !== '') {
		  if (options.inputType === 'datetime-local') {
		    inputDateVariableValue = Number(getNetMonitorVariableValue(`${options.inputVariable}`)) - offset;
		  } else {
		    inputTextVariableValue = getNetMonitorVariableValue(options.inputVariable);
		  }
		}
	    inputPlaceholder = replaceVariables(options.placeholderText);
		inputTextDefaultValue = replaceVariables(options.inputDefaultValue);
	    if (options.inputType !== 'datetime-local') {
		  if (inputTextVariableValue === inputTextDefaultValue && inputTextVariableValue !== '' && inputTextDefaultValue !== '') {
	        inputPlaceholder = inputTextDefaultValue;
		  }
	    } else {
		  inputPlaceholder = (new Date(getEpochWithMillis(inputDateVariableValue)).toISOString()).slice(0, -8);
		}
	  }

	  const inputTextVisible = inputTitleText.length > 0 ? true : false;
	  const inputFromTextVisible = inputFromTitleText.length > 0 ? true : false;
	  const inputToTextVisible = inputToTitleText.length > 0 ? true : false;

	  const classButton = isDark ? 'timepickerButton_dark blue' : 'timepickerButton_light blue';
      const classInput = isDark ? 'input-container normalInput_dark' : 'input-container normalInput';
      const classInputPicker = isDark ? 'input-container timepickerInput_dark' : 'input-container timepickerInput';
	  var inputValue = inputTextVariableValue === '' ? inputPlaceholder : inputTextVariableValue;
	  useEffect(() => {
		setDateSelectedFromValue(inputFromValue);
		setDateSelectedToValue(inputToValue);
		setInputSelectedValue(inputValue);
	  }, []);

	  return (
        <div>
          <div className="panel_container width-100">
            <div className="pagination">
			  {inputFromTextVisible === true && TimePickerInput && (
				<div className="dropdown_text">{inputFromTitleText + ': '}</div>
			  )}
			  {TimePickerInput && (
				<div className={classInputPicker}>
				  <input
					className="no-border-time"
					type="datetime-local"
					value={dateSelectedFromValue}
					placeholder={dateSelectedFromValue}
					onChange={e => {
					  const timeValue = Math.round(new Date(e.target.value).getTime()) - offset;
					  actualFrom = timeValue;
					  setDateSelectedFromValue((new Date(getEpochWithMillis(actualFrom)).toISOString()).slice(0, -8));
					}}
				  />
				</div>
			  )}
			  {TimePickerInput && (
			    <div className="input-container inputGap"></div>
			  )}
			  {inputToTextVisible === true && TimePickerInput && (
				<div className="dropdown_text">{inputToTitleText + ': '}</div>
			  )}
			  {TimePickerInput && (
			    <div className={classInputPicker}>
				  <input
					className="no-border-time"
					type="datetime-local"
					value={dateSelectedToValue}
					placeholder={dateSelectedToValue}
					onChange={e => {
					  const timeValue = Math.round(new Date(e.target.value).getTime()) - offset;
					  actualTo = timeValue;
					  setDateSelectedToValue((new Date(getEpochWithMillis(actualTo)).toISOString()).slice(0, -8));

					}}
				  />
				</div>
			  )}
			  {TimePickerInput && (
			    <div className="input-container inputGap"></div>
			  )}
			  {TimePickerInput && (
			    <div
				  className={classButton}
				  title={'Aplicar ventana de tiempo'}
				  onClick={e => {
					const now = Date.now();
					if (actualTo > now) {
					  actualTo = now;
					}
					if (actualTo < actualFrom) {
					  actualFrom = actualTo - 3600000;
					  SystemJS.load('app/core/app_events').then((appEvents: any) => {
						appEvents.emit(AppEvents.alertError, ['El final de la ventana de tiempo es anterior a la inicio de la misma.\n Se establece una nueva ventana.']);
					  });
					}
					const timeToValue = Math.round(new Date(dateSelectedToValue).getTime());
					let queryMap = {
					  [`${options.inputToVariable}`]: timeToValue,
					};
					changeUrl(queryMap);
				  	const timeFromValue = Math.round(new Date(dateSelectedFromValue).getTime());
					queryMap = {
					  [`${options.inputFromVariable}`]: timeFromValue,
					};
					changeUrl(queryMap);
				  }}
				>
				  <Icon name="filter_dark" size="md"/>
				</div>
			  )}
              {inputTextVisible === true && !TimePickerInput && (
			    <div className="dropdown_text">{inputTitleText + ': '}</div>
			  )}
			  {!TimePickerInput && (
			    <div className={classInput}>
				  <input
				    className={options.inputType === 'datetime-local' ? 'no-border-time' : 'no-border'}
				    type={options.inputType}
				    placeholder={inputPlaceholder}
				    value={inputSelectedValue}
				    onChange={e => {
					  if (options.inputVariable && options.inputVariable !== '') {
					    if (options.inputType === 'datetime-local') {
						  const timeValue = Math.round(new Date(e.target.value).getTime());
						  let queryMap = {
						    [`var-${options.inputVariable}`]: timeValue,
					      };
					      changeUrl(queryMap);
					      setInputSelectedValue(e.target.value);
						} else {
						  let queryMap = {
						    [`var-${options.inputVariable}`]: e.target.value,
					      };
					      changeUrl(queryMap);
					      setInputSelectedValue(e.target.value);
						}
					  }
				    }}
				  />
				</div>
			  )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="gf-form-error">{error1}</div>
        </div>
      );
    } 
  } else {
    return (
      <div>
        <div className="gf-form-error">{error3}</div>
      </div>
    );
  }
};

function buttonFactory(buttons: TimepickerData[]) {
  return buttons.map(optionButton => (
    <TimepickerButton
      id={optionButton.id}
      key={optionButton.text}
      text={optionButton.text}
      time_from={optionButton.time_from}
      time_to={optionButton.time_to}
      primary={optionButton.primary || false}
      errors={optionButton.errors}
      style={optionButton.style}
    />
  ));
}

function buttonFactory2(buttons2: FilterpickerData[]) {
  return buttons2.map(optionButton => (
    <FilterpickerButton
      id={optionButton.id}
      key={optionButton.text}
      text={optionButton.text}
      icon={optionButton.icon}
      variable={optionButton.variable}
      value={optionButton.value || ''}
      result={optionButton.result || ''}
      primary={optionButton.primary || false}
      style={optionButton.style}
      title={optionButton.title}
      selectTitle={optionButton.selectTitle}
    />
  ));
}

function buttonFactory3(buttons3: GenericButtonData[]) {
  return buttons3.map(optionButton => (
    <GenericButton
      key={optionButton.text}
      icon={optionButton.icon}
      variable={optionButton.variable}
      value={optionButton.value || ''}
      title={optionButton.title}
      selectTitle={optionButton.selectTitle}
      url={optionButton.url || ''}
    />
  ));
}

import React, { Component } from 'react';
import { config } from '@grafana/runtime';
import './css/netmonitor-tabs.css';
import { changeUrl, getNetMonitorVariableValue, openUrl, backUrl, updateBackUrl, setBackUrl } from './utils';
import { Icon } from '@grafana/ui';

interface TabspickerButtonProps {
  id: number;
  text?: string;
  icon: string;
  url: string;
  updateRoute: boolean;
  variable?: string;
  value: string;
  result: string;
  primary: boolean;
  style: string;
  title: string;
  useBack: boolean;
  varBack: string;
  varBack1: string;
}

interface GenericButtonProps {
  id: number;
  icon?: string;
  url: string;
  updateRoute: boolean;
  variable?: string;
  value: string;
  title: string;
  style: string;
  useBack: boolean;
  varBack: string;
  varBack1: string;
}

interface GenericIndicatorProps {
  id: number;
  url: string;
  updateRoute: boolean;
  result?: string;
  value: string;
  title: string;
  style: string;
  useBack: boolean;
  varBack: string;
  varBack1: string;
}

interface BackButtonProps {
  id: number;
  icon?: string;
  url: string;
  updateRoute: boolean;
  variable?: string;
  value: string;
  title: string;
  style: string;
  useBack: boolean;
  varBack: string;
  varBack1: string;
}
export class TabsButton extends Component<TabspickerButtonProps> {
  constructor(props: any) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.variable !== '') {
      const queryMap = {
        [`var-${this.props.variable}`]: this.props.value,
      };
      changeUrl(queryMap, this.props.useBack, this.props.varBack, this.props.varBack1);
    }
  }

  componentDidMount() {
    if (this.props.id === 1 && this.props.varBack !== undefined && this.props.varBack !== 'null') {
      setBackUrl(this.props.varBack, this.props.varBack1);
    }
  }

  render() {
    var classIcon = this.props.icon;
    if (classIcon === undefined) {
      classIcon = 'refresh';
    }
    var tab_background = this.props.style;
    if (this.props.style === undefined || this.props.style === 'default') {
      tab_background = 'white';
    }
    var classTab = 'tab_light';
    var classButton = 'tab_unselect_light tab_' + tab_background + '_disable';
    var classSelected = 'tab_selected_light tab_' + tab_background;
    var title = this.props.title || '';
    var variable = this.props.variable;
    const actualValue = getNetMonitorVariableValue(variable);

    let isDark = config.theme.isDark || false;
    if (isDark) {
      if (this.props.style === 'default') {
        tab_background = 'black';
      }
      classTab = 'tab_dark';
      classButton = 'tab_unselect_dark tab_' + tab_background + '_disable';
      classSelected = 'tab_selected_dark tab_' + tab_background;
    }

    if (actualValue === this.props.value) {
      return (
        <div className={classTab}>
          <button className={classSelected} title={title}>
            <div className="tab_icon">
              <Icon name={classIcon} size="md" />
            </div>
            <p className="tab_title">{getTitleUrl(this.props)}</p>
          </button>
        </div>
      );
    } else {
      if (this.props.variable === 'Volver' && this.props.value === 'Volver') {
        return (
          <div className={classTab}>
            <button
              className={classButton}
              onClick={() => backUrl(this.props.varBack, this.props.varBack1)}
              title={title}
            >
              <div className="tab_icon">
                <Icon name={classIcon} size="lg" />
              </div>
              <p className="tab_title">{getTitleUrl(this.props)}</p>
            </button>
          </div>
        );
      } else {
        if (this.props.url !== 'null' && this.props.url !== '') {
          return (
            <div className={classTab}>
              <button
                className={classButton}
                onClick={() =>
                  openUrl(
                    this.props.url,
                    this.props.updateRoute,
                    this.props.useBack,
                    this.props.varBack,
                    this.props.varBack1
                  )
                }
                title={title}
              >
                <div className="tab_icon">
                  <Icon name={classIcon} size="md" />
                </div>
                <p className="tab_title">{getTitleUrl(this.props)}</p>
              </button>
            </div>
          );
        } else {
          return (
            <div className={classTab}>
              <button className={classButton} onClick={this.handleClick} title={title}>
                <div className="tab_icon">
                  <Icon name={classIcon} size="md" />
                </div>
                <p className="tab_title">{getTitleUrl(this.props)}</p>
              </button>
            </div>
          );
        }
      }
    }
  }
}

export class TabsButtonAux extends Component<TabspickerButtonProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    var classIcon = this.props.icon;
    if (classIcon === undefined) {
      classIcon = 'refresh';
    }
    var tab_background = this.props.style;
    if (this.props.style === undefined || this.props.style === 'default') {
      tab_background = 'white';
    }
    var classTab = 'tab_light';
    var classButton = 'tab_unselect_light tab_' + tab_background + '_disable';
    var classSelected = 'tab_selected_light tab_' + tab_background;
    var title = this.props.title || '';

    let isDark = config.theme.isDark || false;
    if (isDark) {
      if (this.props.style === 'default') {
        tab_background = 'black';
      }
      classTab = 'tab_dark';
      classButton = 'tab_unselect_dark tab_' + tab_background + '_disable';
      classSelected = 'tab_selected_dark tab_' + tab_background;
    }

    return (
      <div className={classTab}>
        <button className={classButton} title={title}>
          <div className="tab_icon">
            <Icon name={classIcon} size="lg" />
          </div>
        </button>
      </div>
    );
  }
}

export class GenericButton extends Component<GenericButtonProps> {
  constructor(props: any) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.variable !== '') {
      const queryMap = {
        [`var-${this.props.variable}`]: this.props.value,
      };
      changeUrl(queryMap, this.props.useBack, this.props.varBack, this.props.varBack1);
    }
  }

  render() {
    var classIcon = this.props.icon;
    if (classIcon === undefined) {
      classIcon = 'refresh';
    }
    var classTab = 'tab_button_light';
    var classButton = 'tab_button tab_' + this.props.style + '_disable';
    var classSelected = 'tab_button_selected_light tab_' + this.props.style;
    var title = this.props.title || '';
    let isDark = config.theme.isDark || false;
    if (isDark) {
      classTab = 'tab_button_dark';
      classButton = 'tab_button tab_' + this.props.style + '_disable';
      classSelected = 'tab_button_selected_dark tab_' + this.props.style;
    }

    const actualValue = getNetMonitorVariableValue(this.props.variable);
    if (actualValue === this.props.value) {
      return (
        <div className={classTab}>
          <button className={classSelected} title={title}>
            <div className="tab_button_icon">
              <Icon name={classIcon} size="md" />
            </div>
          </button>
        </div>
      );
    } else {
      if (this.props.variable === 'Volver' && this.props.value === 'Volver') {
        return (
          <div className={classTab}>
            <button
              className={classButton}
              onClick={() => backUrl(this.props.varBack, this.props.varBack1)}
              title={title}
            >
              <div className="tab_icon">
                <Icon name={classIcon} size="lg" />
              </div>
              <p className="tab_title">{getTitleUrl(this.props)}</p>
            </button>
          </div>
        );
      } else if (this.props.url !== 'null' && this.props.url !== '') {
        return (
          <div className={classTab}>
            <button
              className={classButton}
              onClick={() =>
                openUrl(
                  this.props.url,
                  this.props.updateRoute,
                  this.props.useBack,
                  this.props.varBack,
                  this.props.varBack1
                )
              }
              title={title}
            >
              <div className="tab_button_icon">
                <Icon name={classIcon} size="md" />
              </div>
            </button>
          </div>
        );
      } else {
        return (
          <div className={classTab}>
            <button className={classButton} onClick={this.handleClick} title={title}>
              <div className="tab_button_icon">
                <Icon name={classIcon} size="md" />
              </div>
            </button>
          </div>
        );
      }
    }
  }
}

export class GenericIndicator extends Component<GenericIndicatorProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    var classTab = 'tab_indicator_light';
    var classButton = 'tab_indicator tab_' + this.props.style + '_disable';
    var classSelected = 'tab_indicator_selected_light tab_' + this.props.style;
    var title = this.props.title || '';
    let isDark = config.theme.isDark || false;
    if (isDark) {
      classTab = 'tab_indicator_dark';
      classButton = 'tab_indicator tab_' + this.props.style + '_disable';
      classSelected = 'tab_indicator_selected_dark tab_' + this.props.style;
    }
    if (this.props.url !== 'null' && this.props.url !== '') {
      return (
        <div className={classTab}>
          <button
            className={classButton}
            onClick={() =>
              openUrl(
                this.props.url,
                this.props.updateRoute,
                this.props.useBack,
                this.props.varBack,
                this.props.varBack1
              )
            }
            title={title}
          >
            <p className="indicator_title">{getIndicatorUrl(this.props)}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className={classTab}>
          <button className={classSelected} title={title}>
            <p className="indicator_title">{getIndicatorUrl(this.props)}</p>
          </button>
        </div>
      );
    }
  }
}

export class BackButton extends Component<BackButtonProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    var classIcon = this.props.icon;
    if (classIcon === undefined) {
      classIcon = 'arrow-bar-left';
    }
    const actualValue = getNetMonitorVariableValue(this.props.variable);
    return (
      <div className="tab_backButton">
        <button
          className="tab_button tab_blue_disable"
          onClick={() => backUrl(this.props.varBack, this.props.varBack1)}
          title="Volver"
        >
          <div className="tab_icon">
            <Icon name={classIcon} size="lg" />
          </div>
        </button>
      </div>
    );
  }
}

function getTitleUrl(props: TabspickerButtonProps): string {
  if (props.text) {
    if (props.text === 'Rotar') {
      return '';
    } else {
      if (props.result !== '') {
        return props.text + ': ' + props.result;
      } else {
        return props.text;
      }
    }
  } else if (props.url) {
    return '';
  }
  return 'Error';
}

function getIndicatorUrl(props: GenericIndicatorProps): string {
  if (props.result) {
    return String(props.result);
  }
  return 'E';
}

// @ts-ignore
import React, { Component, useState } from 'react';
import { PanelProps, GraphSeriesValue, UrlQueryMap } from '@grafana/data';
import { Icon } from '@grafana/ui';
import { LineChartOptions, GenericLineData, GenericLineLabel, LegendLineLabel } from 'types';
import {
  ComposedChart,
  Area,
  Bar,
  ReferenceArea,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
  Line,
} from 'recharts';
import { config, getLocationSrv, getTemplateSrv } from '@grafana/runtime';
import styled from 'styled-components';
import './LineChartPanel.css';

interface Props extends PanelProps<LineChartOptions> {}

export const LineChartPanel: React.FC<Props> = ({ options, data, width, height, replaceVariables, id }) => {
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);

  const timeFrom = getTemplateSrv().replace('${__from}');
  const timeTo = getTemplateSrv().replace('${__to}');
  const timeInterval = Number(timeTo) - Number(timeFrom);
  var timeWindow = Number(timeInterval) / 3600000;
  var actualWin = 6;
  if (timeWindow <= 24) {
    actualWin = 1;
  } else if (timeWindow <= 168) {
    actualWin = 2;
  } else if (timeWindow <= 720) {
    actualWin = 3;
  } else if (timeWindow <= 2160) {
    actualWin = 4;
  } else if (timeWindow <= 4320) {
    actualWin = 5;
  }
  const [actualZoom, setActualZoom] = useState(actualWin);

  const [refAreaLeft, setRefAreaLeft] = useState('');
  const [refAreaRight, setRefAreaRight] = useState('');
  const [refAreaLeftLabel, setRefAreaLeftLabel] = useState(0);
  const [refAreaRightLabel, setRefAreaRightLabel] = useState(0);
  const [startZoom, setStartZoom] = useState(false);

  var chartMargin = { top: 5, right: 5, left: 5, bottom: 5 };
  if (options.showToolbar) {
    chartMargin = { top: 5, right: 5, left: 40, bottom: 5 };
  }

  const LineTooltip = styled.div`
    font-size: 11px;
    line-height: 20px;
    color: #333333;
    width: fit-content;
    padding: 5px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 4px 2px 5px 2px #8b98a499;
    background-clip: padding-box;
    pointer-events: none;
    text-anchor: middle;
  `;
  const LineTooltipWarn = styled.div`
    font-size: 11px;
    line-height: 20px;
    color: #000000;
    width: fit-content;
    padding: 5px;
    background-color: #e45858;
    border: 1px solid red;
    border-radius: 4px;
    box-shadow: 4px 2px 5px 2px #8b98a499;
    background-clip: padding-box;
    pointer-events: none;
    text-anchor: middle;
  `;
  const LineChartTooltipCenter = styled.p`
    text-align: center;
  `;

  const LineChartTooltipText = styled.p`
    margin-top: 0px;
    margin-bottom: 0px;
  `;

  const LineChartTooltipTitle = styled.b`
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
  `;

  const LineChartToolboxContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
  `;

  const ButtonContainer = styled.div`
    position: absolute;
    top: 10px;
    left: 2px;
    z-index: 999;
    width: 32px;
    height: calc(100% - 20px);
  `;

  const ButtonNormal = styled.button`
    background-color: #0d0deb;
    opacity: 0.75;
    display: block;
    margin-left: 2px;
    margin-bottom: 1px;
    height: 28px;
    width: 28px;
    line-height: 24px;
    color: #fff;
    border: 1px solid #5a5a5a;
    border-radius: 5px;

    &:hover {
      opacity: 1;
    }
  `;

  const ButtonTop = styled.button`
    background-color: #0d0deb;
    opacity: 0.75;
    display: block;
    margin-left: 2px;
    height: 28px;
    width: 28px;
    line-height: 24px;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #5a5a5a;
    &:hover {
      opacity: 1;
    }
  `;

  const ButtonMiddle = styled.button`
    background-color: #0d0deb;
    opacity: 0.75;
    display: block;
    margin-left: 2px;
    height: 28px;
    width: 28px;
    line-height: 24px;
    color: #fff;
    border: 1px solid #5a5a5a;
    &:hover {
      opacity: 1;
    }
  `;

  const ButtonBottom = styled.button`
    background-color: #0d0deb;
    opacity: 0.75;
    display: block;
    margin-left: 2px;
    height: 28px;
    width: 28px;
    line-height: 24px;
    color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #5a5a5a;
    &:hover {
      opacity: 1;
    }
  `;

  const ButtonText = styled.div`
    width: 16px;
    height: 16px;
    margin-top: -8px;
    margin-left: -1px;
  `;

  const absoluteThreshold = Number(replaceVariables(options.absoluteThreshold));
  var syncID = replaceVariables(options.syncId);
  if (syncID === '') {
    syncID = String(id);
  }
  const url_link = String(replaceVariables(options.drillDownLink));
  var realTimeLink = String(replaceVariables(options.realTimeButtonLink));
  var zoomLink = String(replaceVariables(options.zoomButtonLink));
  var clicEnable = false;
  if (url_link !== null && url_link.length > 0) {
    clicEnable = true;
  }

  const isDark = config.theme.isDark;
  var axisXEndIndex = 0;
  var axisXMaxIndex = 0;
  var axisXStartIndex = 0;
  var axisYEndIndex = options.maxValue;
  var axisYStartIndex = options.minValue;

  var axis2YEndIndex = options.maxValue;
  var axis2YStartIndex = options.minValue;

  var showMainLine = false;
  var showSecondLine = false;
  var showThirthLine = false;
  var showMetric = false;

  var chartLabels: GenericLineLabel[] = [];
  var chartLegends: LegendLineLabel[] = [];
  if (options.valueField !== undefined && options.valueField !== '') {
    let newlabel: GenericLineLabel = {
      color: options.lineColor,
      key: options.valueTitle,
    };
    chartLabels.push(newlabel);
    let newlegend: LegendLineLabel = {
      id: 'value',
      type: 'rect',
      value: options.valueTitle,
      color: options.lineColor,
    };
    chartLegends.push(newlegend);
    showMainLine = true;
  }
  if (options.value2Field !== undefined && options.value2Field !== '') {
    let newlabel: GenericLineLabel = {
      color: options.line2Color,
      key: options.value2Title,
    };
    chartLabels.push(newlabel);
    let newlegend: LegendLineLabel = {
      id: 'value2',
      type: 'rect',
      value: options.value2Title,
      color: options.line2Color,
    };
    chartLegends.push(newlegend);
    showSecondLine = true;
  }
  if (options.value3Field !== undefined && options.value3Field !== '') {
    let newlabel: GenericLineLabel = {
      color: options.line3Color,
      key: options.value3Title,
    };
    chartLabels.push(newlabel);
    let newlegend: LegendLineLabel = {
      id: 'value3',
      type: 'rect',
      value: options.value3Title,
      color: options.line3Color,
    };
    chartLegends.push(newlegend);
    showThirthLine = true;
  }
  if (options.showAreaOnChart && options.areaField !== undefined && options.areaField !== '') {
    let newlabel: GenericLineLabel = {
      color: options.auxAreaColor,
      key: options.auxAreaTitle,
    };
    chartLabels.push(newlabel);
    let newlegend: LegendLineLabel = {
      id: 'auxarea',
      type: 'rect',
      value: options.auxAreaTitle,
      color: options.auxAreaColor,
    };
    chartLegends.push(newlegend);
  }
  if (options.showLineOnChart && options.lineField !== undefined && options.lineField !== '') {
    let newlabel: GenericLineLabel = {
      color: options.auxLineColor,
      key: options.auxLineTitle,
    };
    chartLabels.push(newlabel);
    let newlegend: LegendLineLabel = {
      id: 'auxline',
      type: 'rect',
      value: options.auxLineTitle,
      color: options.auxLineColor,
    };
    chartLegends.push(newlegend);
  }
  if (options.useMetricField && options.metricField !== undefined && options.metricField !== '') {
    let newlabel: GenericLineLabel = {
      color: options.metricLineColor,
      key: options.metricTitle,
    };
    chartLabels.push(newlabel);
    showMetric = true;
    let newlegend: LegendLineLabel = {
      id: 'metric',
      type: 'rect',
      value: options.metricTitle,
      color: options.metricLineColor,
    };
    chartLegends.push(newlegend);
  }

  const d = Date.now();
  var chartLines: GenericLineData[] = [];

  const renderColorfulLegendText = (value: string) => {
    const textColor = isDark ? '#ffffff': '#000000';
    return <span style={{color: textColor, cursor: 'pointer' }}>{value}</span>;
  };

  if (width < 200 || height < 100) {
    return (
      <div>
        <div className="gf-form-error">{error4}</div>
      </div>
    );
  } else if (data.state !== 'Error') {
    if(data.series[0].length > 0) {
      data.series.forEach((series) => {
        const timeVals: GraphSeriesValue[] = series.fields[0].values.toArray();
        for (let i = 0; i < timeVals.length; i++) {
          if (options.showRealTimeButton && options.realTimeUrlField !== undefined && options.realTimeUrlField !== '') {
            let rtLink = series.fields.find((field) => field.name === options.realTimeUrlField)?.values.get(i);
            if (rtLink !== null && rtLink !== '') {
              realTimeLink = rtLink;
            }
          }
          if (options.showRealTimeButton && options.zoomUrlField !== undefined && options.zoomUrlField !== '') {
            let zLink = series.fields.find((field) => field.name === options.zoomUrlField)?.values.get(i);
            if (zLink !== null && zLink !== '') {
              zoomLink = zLink;
            }
          }
          var chartLine: GenericLineData = {
            id: i + 1,
            name: series.fields.find((field) => field.name === options.serieField)?.values.get(i) || '',
            date: new Date(d).toUTCString(),
            dateLocale: new Date(d).toLocaleString(),
            value: 0,
            value2: 0,
            value3: 0,
            auxarea: 0,
            auxline: 0,
            maxvalue: 0,
            label: series.fields.find((field) => field.name === options.labelField)?.values.get(i) || '',
            label2: series.fields.find((field) => field.name === options.label2Field)?.values.get(i) || '',
            label3: series.fields.find((field) => field.name === options.label3Field)?.values.get(i) || '',
            info: '',
            metric: 0,
            threshold: 0,
            onfire: false,
          };
          if (options.timeSeriesChart && options.dateField !== undefined && options.dateField !== '') {
            let unixDate = series.fields.find((field) => field.name === options.dateField)?.values.get(i);
            chartLine.date = new Date(unixDate).toUTCString();
            chartLine.dateLocale = new Date(unixDate).toLocaleString();
            if (actualZoom < 3) {
              chartLine.name = new Date(unixDate).toLocaleTimeString(options.timeZone);
            } else {
              chartLine.name = new Date(unixDate).toLocaleDateString(options.timeZone).replace(/\//g, '-');
            }
          }
          if (showMainLine) {
            chartLine.value = series.fields.find((field) => field.name === options.valueField)?.values.get(i);
          }
          if (showSecondLine) {
            chartLine.value2 = series.fields.find((field) => field.name === options.value2Field)?.values.get(i);
          }
          if (showThirthLine) {
            chartLine.value3 = series.fields.find((field) => field.name === options.value3Field)?.values.get(i);
          }
          if (options.showAreaOnChart && options.areaField !== undefined && options.areaField !== '') {
            chartLine.auxarea = series.fields.find((field) => field.name === options.areaField)?.values.get(i);
          }
          if (options.showLineOnChart && options.lineField !== undefined && options.lineField !== '') {
            chartLine.auxline = series.fields.find((field) => field.name === options.lineField)?.values.get(i);
          }
          if (options.useInfoField && options.infoField !== undefined && options.infoField !== '') {
            chartLine.info = series.fields.find((field) => field.name === options.infoField)?.values.get(i);
          }
          if (showMetric) {
            chartLine.metric = series.fields.find((field) => field.name === options.metricField)?.values.get(i);
            if (options.metricThresholdField !== undefined && options.metricThresholdField !== '') {
              let relativeThreshold = series.fields
                .find((field) => field.name === options.metricThresholdField)
                ?.values.get(i);
              chartLine.threshold = relativeThreshold / 100;
            }
          }
          if (chartLine.metric > 0 && chartLine.threshold > 0) {
            if (
              chartLine.value > chartLine.metric * (1 + chartLine.threshold) ||
              chartLine.value2 > chartLine.metric * (1 + chartLine.threshold) ||
              chartLine.value3 > chartLine.metric * (1 + chartLine.threshold)
            ) {
              chartLine.onfire = true;
            }
          }
          if (
            absoluteThreshold > 0 &&
            (chartLine.value > absoluteThreshold ||
              chartLine.value2 > absoluteThreshold ||
              chartLine.value3 > absoluteThreshold)
          ) {
            chartLine.onfire = true;
          }
          if (options.invertThreshold) {
            chartLine.onfire = !chartLine.onfire;
          }
          if (options.maxValue === 0) {
            let minValue = Math.min(chartLine.value, chartLine.value2, chartLine.value3, chartLine.metric);
            let maxValue = Math.max(chartLine.value, chartLine.value2, chartLine.value3, chartLine.metric);
            if (maxValue > axisYEndIndex) {
              axisYEndIndex = maxValue;
            }
            if (minValue < axisYStartIndex) {
              axisYStartIndex = minValue;
            }
          }
          let minValue2 = Math.min(chartLine.auxline, chartLine.auxarea);
          let maxValue2 = Math.max(chartLine.auxline, chartLine.auxarea);
          if (maxValue2 > axis2YEndIndex) {
            axis2YEndIndex = maxValue2;
          }
          if (minValue2 < axis2YStartIndex) {
            axis2YStartIndex = minValue2;
          }
          chartLine.maxvalue = axisYEndIndex;
          chartLines.push(chartLine);
          axisXMaxIndex = i;
        }
      });

      const [barProps, setBarProps] = useState(
        chartLabels.reduce(
          (a, { key }) => {
            a[key] = false;
            return a;
          },
          { hover: null }
        )
      );

      if (options.maxValue === 0) {
        axisYEndIndex = axisYEndIndex * 1.1;
        if (axisYStartIndex >= 0 && options.minValue === 0) {
          axisYStartIndex = 0;
        } else if (axisYStartIndex < 0) {
          axisYStartIndex = axisYStartIndex * 1.1;
        }
      }
      axis2YEndIndex = axis2YEndIndex * 1.1;
      axis2YStartIndex = axis2YStartIndex * 1.1;

      let tickFontColor = '#222';
      let borderColor = '#aeaeae';
      if (isDark) {
        borderColor = '#444444';
        tickFontColor = '#ddd';
      }

      const handleLegendMouseEnter = (e) => {
        if (!barProps[e.value]) {
          setBarProps({ ...barProps, hover: e.value });
        }
      };

      const handleLegendMouseLeave = (e) => {
        setBarProps({ ...barProps, hover: null });
      };

      const handleLegendClick = (e) => {
        setBarProps({
          ...barProps,
          [e.value]: !barProps[e.value],
          hover: null,
        });
      };

      const DataFormater = (number) => {
        if (number >= 1000000000) {
          return String(Math.round(number / 1000000000)) + ' G';
        } else if (number >= 1000000) {
          return String(Math.round(number / 1000000)) + ' M';
        } else if (number >= 1000) {
          return String(Math.round(number / 1000)) + ' K';
        } else {
          return String(Math.round(number)) + ' ';
        }
      };

      const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
          if (payload[0]?.payload?.onfire) {
            return (
              <LineTooltipWarn>
                <span>
                  <LineChartTooltipCenter>
                    <i className="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>
                    <LineChartTooltipTitle>Alarma</LineChartTooltipTitle>
                  </LineChartTooltipCenter>
                </span>
                {options.showMetricOnTooltip && (
                  <span>
                    {showMainLine && (
                      <LineChartTooltipText>
                        <b>
                          {options.valueTitle}
                          {options.resultSeparator + ' '}
                        </b>
                        {DataFormater(payload[0]?.payload?.value)}
                        {options.metricUnit}
                      </LineChartTooltipText>
                    )}
                    {showSecondLine && (
                      <LineChartTooltipText>
                        <b>
                          {options.value2Title}
                          {options.resultSeparator + ' '}
                        </b>
                        {DataFormater(payload[0]?.payload?.value2)}
                        {options.metricUnit2}
                      </LineChartTooltipText>
                    )}
                    {showThirthLine && (
                      <LineChartTooltipText>
                        <b>
                          {options.value3Title}
                          {options.resultSeparator + ' '}
                        </b>
                        {DataFormater(payload[0]?.payload?.value3)}
                        {options.metricUnit3}
                      </LineChartTooltipText>
                    )}
                    <LineChartTooltipText>
                      <b>
                        {options.metricTitle}
                        {options.resultSeparator + ' '}
                      </b>
                      {DataFormater(payload[0]?.payload?.metric)}
                      {options.metricUnit}
                    </LineChartTooltipText>
                  </span>
                )}
                {options.showMetricOnTooltip && options.showAreaOnChart && (
                  <span>
                    <LineChartTooltipText>
                      <b>
                        {options.auxAreaTitle}
                        {options.resultSeparator + ' '}
                      </b>
                      {DataFormater(payload[0]?.payload?.auxarea)}
                      {options.auxAreaUnit}
                    </LineChartTooltipText>
                  </span>
                )}
                {options.showMetricOnTooltip && options.showLineOnChart && (
                  <span>
                    <LineChartTooltipText>
                      <b>
                        {options.auxLineTitle}
                        {options.resultSeparator + ' '}
                      </b>
                      {DataFormater(payload[0]?.payload?.auxline)}
                      {options.auxLineUnit}
                    </LineChartTooltipText>
                  </span>
                )}
                {!options.showMetricOnTooltip && (
                  <span>
                    {showMainLine && (
                      <LineChartTooltipText>
                        <b>{payload[0]?.payload?.label}</b>
                      </LineChartTooltipText>
                    )}
                    {showSecondLine && (
                      <LineChartTooltipText>
                        <b>{payload[0]?.payload?.label2}</b>
                      </LineChartTooltipText>
                    )}
                    {showThirthLine && (
                      <LineChartTooltipText>
                        <b>{payload[0]?.payload?.label3}</b>
                      </LineChartTooltipText>
                    )}
                  </span>
                )}
                {options.timeSeriesChart && (
                  <span>
                    <LineChartTooltipText>
                      <b>
                        {options.dateTitle}
                        {options.resultSeparator + ' '}
                      </b>
                      {payload[0]?.payload?.dateLocale}
                    </LineChartTooltipText>
                  </span>
                )}
                {options.useInfoField && (
                  <span>
                    <b>{options.infoTitle}</b>
                    {payload[0]?.payload?.info}
                  </span>
                )}
                {clicEnable && (
                  <span>
                    <LineChartTooltipText>{options.clicTitle}</LineChartTooltipText>
                  </span>
                )}
              </LineTooltipWarn>
            );
          } else {
            return (
              <LineTooltip>
                {options.showMetricOnTooltip && (
                  <span>
                    {showMainLine && (
                      <LineChartTooltipText>
                        <b>
                          {payload[0]?.payload?.label}
                          {options.resultSeparator + ' '}
                        </b>
                        {DataFormater(payload[0]?.payload?.value)}
                        {options.metricUnit}
                      </LineChartTooltipText>
                    )}
                    {showSecondLine && (
                      <LineChartTooltipText>
                        <b>
                          {payload[0]?.payload?.label2}
                          {options.resultSeparator + ' '}
                        </b>
                        {DataFormater(payload[0]?.payload?.value2)}
                        {options.metricUnit2}
                      </LineChartTooltipText>
                    )}
                    {showThirthLine && (
                      <LineChartTooltipText>
                        <b>
                          {payload[0]?.payload?.label3}
                          {options.resultSeparator + ' '}
                        </b>
                        {DataFormater(payload[0]?.payload?.value3)}
                        {options.metricUnit3}
                      </LineChartTooltipText>
                    )}
                    <LineChartTooltipText>
                      <b>
                        {options.metricTitle}
                        {options.resultSeparator + ' '}
                      </b>
                      {DataFormater(payload[0]?.payload?.metric)}
                      {options.metricUnit}
                    </LineChartTooltipText>
                  </span>
                )}
                {options.showMetricOnTooltip && options.showAreaOnChart && (
                  <span>
                    <LineChartTooltipText>
                      <b>
                        {options.auxAreaTitle}
                        {options.resultSeparator + ' '}
                      </b>
                      {DataFormater(payload[0]?.payload?.auxarea)}
                      {options.auxAreaUnit}
                    </LineChartTooltipText>
                  </span>
                )}
                {options.showMetricOnTooltip && options.showLineOnChart && (
                  <span>
                    <LineChartTooltipText>
                      <b>
                        {options.auxLineTitle}
                        {options.resultSeparator + ' '}
                      </b>
                      {payload[0]?.payload?.auxline}
                      {options.auxLineUnit}
                    </LineChartTooltipText>
                  </span>
                )}
                {!options.showMetricOnTooltip && (
                  <span>
                    {showMainLine && (
                      <LineChartTooltipText>
                        <b>{payload[0]?.payload?.label}</b>
                      </LineChartTooltipText>
                    )}
                    {showSecondLine && (
                      <LineChartTooltipText>
                        <b>{payload[0]?.payload?.label2}</b>
                      </LineChartTooltipText>
                    )}
                    {showThirthLine && (
                      <LineChartTooltipText>
                        <b>{payload[0]?.payload?.label3}</b>
                      </LineChartTooltipText>
                    )}
                  </span>
                )}
                {options.timeSeriesChart && (
                  <span>
                    <LineChartTooltipText>
                      <b>
                        {options.dateTitle}
                        {options.resultSeparator + ' '}
                      </b>
                      {payload[0]?.payload?.dateLocale}
                    </LineChartTooltipText>
                  </span>
                )}
                {options.useInfoField && (
                  <span>
                    <b>{options.infoTitle}</b>
                    {payload[0]?.payload?.info}
                  </span>
                )}
                {clicEnable && (
                  <span>
                    <LineChartTooltipText>{options.clicTitle}</LineChartTooltipText>
                  </span>
                )}
              </LineTooltip>
            );
          }
        } else {
          return null;
        }
      };

      if (options.timeSeriesChart && options.endIndex > 0) {
        axisXEndIndex = options.endIndex;
      } else {
        axisXEndIndex = axisXMaxIndex;
      }
      if (options.timeSeriesChart && options.startIndex > 0) {
        axisXStartIndex = axisXEndIndex - options.startIndex;
      }

      var showDot = false;
      var showActiveDot = false;
      if (options.orientation === 'vertical') {
        if (options.scaleTypeVertical !== 'log' && options.scaleTypeVertical !== 'ordinal') {
          if (options.showDots === true) {
            showDot = true;
          }
          showActiveDot = true;
        }
      } else {
        if (options.scaleType !== 'log' && options.scaleType !== 'ordinal') {
          if (options.showDots === true) {
            showDot = true;
          }
          showActiveDot = true;
        }
      }

      let titleIn = 'Zoom In';
      let titleOut = 'Zoom Out';

      if (actualZoom === 1) {
        titleIn = 'No es posible reducir ventana de tiempo';
        titleOut = 'Ampliar ventana de tiempo a ultimas 24hs';
      } else if (actualZoom === 2) {
        titleIn = 'Reducir ventana de tiempo a ultimas 3hs';
        titleOut = 'Ampliar ventana de tiempo a ultima semana';
      } else if (actualZoom === 3) {
        titleIn = 'Reducir ventana de tiempo a ultimas 24hs';
        titleOut = 'Ampliar ventana de tiempo a ultimo mes';
      } else if (actualZoom === 4) {
        titleIn = 'Reducir ventana de tiempo a ultima semana';
        titleOut = 'Ampliar ventana de tiempo a ultimo trimetre';
      } else if (actualZoom === 5) {
        titleIn = 'Reducir ventana de tiempo a ultimo mes';
        titleOut = 'Ampliar ventana de tiempo a ultimo semestre';
      } else if (actualZoom === 6) {
        titleIn = 'Reducir ventana de tiempo a ultimo trimetre';
        titleOut = 'Ampliar ventana de tiempo a ultimo año';
      } else if (actualZoom === 7) {
        titleIn = 'Reducir ventana de tiempo a ultimo semestre';
        titleOut = 'No es posible ampliar ventana de tiempo';
      }

      if (options.timeSeriesChart) {
        return (
          <LineChartToolboxContainer id={id}>
            {options.showToolbar && (
              <ButtonContainer>
                {options.showTimeZoomButtons && options.showRealTimeButton && (
                  <ButtonTop
                    title={options.realTimeTitle}
                    onClick={() => {
                      handleClick(realTimeLink, '_blank');
                    }}
                  >
                    <ButtonText><Icon name={'realtime'} size="md" /></ButtonText>
                  </ButtonTop>
                )}
                {options.showTimeZoomButtons && options.showRealTimeButton && options.showZoomButton && (
                  <ButtonMiddle
                    title={options.zoomButtonTitle}
                    onClick={() => {
                      handleClick(zoomLink, '_blank');
                    }}
                  >
                    <ButtonText><Icon name={'brain'} size="md" /></ButtonText>
                  </ButtonMiddle>
                )}
                {options.showTimeZoomButtons && options.showRealTimeButton && (
                  <ButtonMiddle
                    title={titleIn}
                    onClick={() => {
                      if (actualZoom > 1) {
                        zoomTime(actualZoom - 1);
                        setActualZoom(actualZoom - 1);
                      }
                    }}
                  >
                    <ButtonText><Icon name={'minus'} size="sm" /></ButtonText>
                  </ButtonMiddle>
                )}
                {options.showTimeZoomButtons && options.showRealTimeButton && (
                  <ButtonBottom
                    title={titleOut}
                    onClick={() => {
                      if (actualZoom < 7) {
                        zoomTime(actualZoom + 1);
                        setActualZoom(actualZoom + 1);
                      }
                    }}
                  >
                    <ButtonText><Icon name={'plus'} size="sm" /></ButtonText>
                  </ButtonBottom>
                )}
                {options.showTimeZoomButtons && !options.showRealTimeButton && !options.showZoomButton && (
                  <ButtonTop
                    title={titleIn}
                    onClick={() => {
                      if (actualZoom > 1) {
                        zoomTime(actualZoom - 1);
                        setActualZoom(actualZoom - 1);
                      }
                    }}
                  >
                    <ButtonText><Icon name={'minus'} size="sm" /></ButtonText>
                  </ButtonTop>
                )}
                {options.showTimeZoomButtons && !options.showRealTimeButton && !options.showZoomButton && (
                  <ButtonBottom
                    title={titleOut}
                    onClick={() => {
                      if (actualZoom < 7) {
                        zoomTime(actualZoom + 1);
                        setActualZoom(actualZoom + 1);
                      }
                    }}
                  >
                    <ButtonText><Icon name={'plus'} size="sm" /></ButtonText>
                  </ButtonBottom>
                )}
                {!options.showTimeZoomButtons && options.showRealTimeButton && (
                  <ButtonNormal
                    title={options.realTimeTitle}
                    onClick={() => {
                      handleClick(realTimeLink, '_blank');
                    }}
                  >
                    <ButtonText><Icon name={'realtime'} size="md" /></ButtonText>
                  </ButtonNormal>
                )}
                {!options.showTimeZoomButtons && options.showZoomButton && (
                  <ButtonNormal
                    title={options.zoomButtonTitle}
                    onClick={() => {
                      handleClick(zoomLink, '_blank');
                    }}
                  >
                    <ButtonText><Icon name={'brain'} size="md" /></ButtonText>
                  </ButtonNormal>
                )}
              </ButtonContainer>
            )}
            <ResponsiveContainer width={width} height={height}>
              <ComposedChart
                width={width}
                height={height}
                data={chartLines}
                layout={options.orientation}
                syncId={syncID}
                margin={chartMargin}
                onMouseMove={(e) => {
                  if (startZoom) {
                    setRefAreaRight(e.activePayload[0].payload.date);
                    setRefAreaRightLabel(e.activeLabel);
                  }
                }}
                onMouseUp={(e) => {
                  if (startZoom) {
                    zoom(refAreaLeft, refAreaRight);
                  }
                  setStartZoom(false);
                  setRefAreaLeft('');
                  setRefAreaRight('');
                  setRefAreaLeftLabel(0);
                  setRefAreaRightLabel(0);
                  setActualZoom(actualZoom - 1);
                }}
              >
                {options.orientation === 'horizontal' && (
                  <defs>
                    <linearGradient id={'line1Color_horizontal' + id} x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor={options.lineColor} stopOpacity={options.fillOpacityStart} />
                      <stop offset="95%" stopColor={options.lineColor} stopOpacity={options.fillOpacityEnd} />
                    </linearGradient>
                    <linearGradient id={'line2Color_horizontal' + id} x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor={options.line2Color} stopOpacity={options.fillOpacityStart} />
                      <stop offset="95%" stopColor={options.line2Color} stopOpacity={options.fillOpacityEnd} />
                    </linearGradient>
                    <linearGradient id={'line3Color_horizontal' + id} x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor={options.line3Color} stopOpacity={options.fillOpacityStart} />
                      <stop offset="95%" stopColor={options.line3Color} stopOpacity={options.fillOpacityEnd} />
                    </linearGradient>
                    <linearGradient id={'auxAreaColor_horizontal' + id} x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityStart} />
                      <stop offset="95%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityEnd} />
                    </linearGradient>
                  </defs>
                )}
                {options.orientation === 'vertical' && (
                  <defs>
                    <linearGradient id={'line1Color_vertical' + id} x1="1" y1="0" x2="0" y2="0">
                      <stop offset="0%" stopColor={options.lineColor} stopOpacity={options.fillOpacityStart} />
                      <stop offset="95%" stopColor={options.lineColor} stopOpacity={options.fillOpacityEnd} />
                    </linearGradient>
                    <linearGradient id={'line2Color_vertical' + id} x1="1" y1="0" x2="0" y2="0">
                      <stop offset="0%" stopColor={options.line2Color} stopOpacity={options.fillOpacityStart} />
                      <stop offset="95%" stopColor={options.line2Color} stopOpacity={options.fillOpacityEnd} />
                    </linearGradient>
                    <linearGradient id={'line3Color_vertical' + id} x1="1" y1="0" x2="0" y2="0">
                      <stop offset="0%" stopColor={options.line3Color} stopOpacity={options.fillOpacityStart} />
                      <stop offset="95%" stopColor={options.line3Color} stopOpacity={options.fillOpacityEnd} />
                    </linearGradient>
                    <linearGradient id={'auxAreaColor_vertical' + id} x1="1" y1="0" x2="0" y2="0">
                      <stop offset="0%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityStart} />
                      <stop offset="95%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityEnd} />
                    </linearGradient>
                  </defs>
                )}
                {options.orientation === 'horizontal' && !options.useZoom && (
                  <XAxis 
				    dataKey="id" 
					type="number"
					hide={true}
					isAnimationActive={options.isAnimationActive}
					domain={[axisXStartIndex + 1, axisXEndIndex]}
				  />
                )}
                {options.orientation === 'horizontal' && options.useZoom && (
                  <XAxis dataKey="id" type="number" hide={true} isAnimationActive={options.isAnimationActive} />
                )}
                {options.orientation === 'horizontal' && (
                  <XAxis
                    xAxisId="name"
                    dataKey="name"
                    type="category"
                    scale={'band'}
                    ticks={chartLines[1] ? [chartLines[1].name, chartLines[axisXEndIndex - 1].name] : null}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    hide={!options.showAxisX}
					isAnimationActive={options.isAnimationActive}
                  />
                )}
                {options.orientation === 'horizontal' && (
                  <YAxis
                    type="number"
                    orientation="left"
                    allowDecimals={false}
                    scale={options.scaleType}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    tickFormatter={DataFormater}
                    interval="preserveEnd"
                    hide={!options.showAxisY}
					isAnimationActive={options.isAnimationActive}
                    domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axisYStartIndex, axisYEndIndex]}
                  />
                )}
                {options.orientation === 'horizontal' && options.showLineOnChart && (
                  <YAxis
                    yAxisId="auxline"
                    dataKey="auxline"
                    type="number"
                    orientation="right"
                    allowDecimals={false}
                    scale={options.scaleType}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    tickFormatter={DataFormater}
                    interval="preserveEnd"
                    hide={!options.showAxisY}
					isAnimationActive={options.isAnimationActive}
                    domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axis2YStartIndex, axis2YEndIndex]}
                  />
                )}
                {options.orientation === 'horizontal' && options.showAreaOnChart && !options.showLineOnChart && (
                  <YAxis
                    yAxisId="auxline"
                    dataKey="auxarea"
                    type="number"
                    orientation="right"
                    allowDecimals={false}
                    scale={options.scaleType}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    tickFormatter={DataFormater}
                    interval="preserveEnd"
                    hide={!options.showAxisY}
					isAnimationActive={options.isAnimationActive}
                    domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axis2YStartIndex, axis2YEndIndex]}
                  />
                )}
                {options.orientation === 'vertical' && (
                  <YAxis
                    dataKey="name"
                    type="category"
                    scale={'point'}
                    ticks={chartLines[1] ? [chartLines[1].name, chartLines[axisXEndIndex - 1].name] : null}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    hide={!options.showAxisY}
					isAnimationActive={options.isAnimationActive}
                  />
                )}
                {options.orientation === 'vertical' && (
                  <XAxis
                    type="number"
                    orientation="bottom"
                    allowDecimals={false}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    tickFormatter={DataFormater}
                    scale={options.scaleTypeVertical}
                    interval="preserveEnd"
                    hide={!options.showAxisX}
					isAnimationActive={options.isAnimationActive}
                    domain={options.scaleTypeVertical === 'log' ? ['auto', 'auto'] : [axisYStartIndex, axisYEndIndex]}
                  />
                )}
                {options.orientation === 'vertical' && options.showLineOnChart && (
                  <XAxis
                    xAxisId="auxline"
                    dataKey="auxline"
                    type="number"
                    orientation="top"
                    allowDecimals={false}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    tickFormatter={DataFormater}
                    scale={options.scaleTypeVertical}
                    interval="preserveEnd"
                    hide={!options.showAxisX}
					isAnimationActive={options.isAnimationActive}
                    domain={options.scaleTypeVertical === 'log' ? ['auto', 'auto'] : [axis2YStartIndex, axis2YEndIndex]}
                  />
                )}
                {options.orientation === 'vertical' && options.showAreaOnChart && !options.showLineOnChart && (
                  <XAxis
                    xAxisId="auxline"
                    dataKey="auxarea"
                    type="number"
                    orientation="top"
                    allowDecimals={false}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    tickFormatter={DataFormater}
                    scale={options.scaleTypeVertical}
                    interval="preserveEnd"
                    hide={!options.showAxisX}
					isAnimationActive={options.isAnimationActive}
                    domain={options.scaleTypeVertical === 'log' ? ['auto', 'auto'] : [axis2YStartIndex, axis2YEndIndex]}
                  />
                )}
                {options.showAreaOnChart && options.orientation === 'vertical' && (
                  <Area
                    xAxisId="auxline"
                    type={options.lineType}
                    dataKey="auxarea"
                    fill={'url(#auxAreaColor_' + options.orientation + id + ')'}
                    stroke={borderColor}
                    strokeWidth={options.lineWidth}
                    hide={barProps[options.auxAreaTitle] === true}
                    activeDot={showActiveDot === true ? true : false}
                    dot={showDot === true ? { stroke: borderColor, strokeWidth: options.lineWidth } : false}
                    isAnimationActive={options.isAnimationActive}
                  />
                )}
                {options.showAreaOnChart && options.orientation === 'horizontal' && (
                  <Area
                    yAxisId="auxline"
                    type={options.lineType}
                    dataKey="auxarea"
                    fill={'url(#auxAreaColor_' + options.orientation + id + ')'}
                    stroke={borderColor}
                    strokeWidth={options.lineWidth}
                    hide={barProps[options.auxAreaTitle] === true}
                    activeDot={showActiveDot === true ? true : false}
                    dot={showDot === true ? { stroke: borderColor, strokeWidth: options.lineWidth } : false}
                    isAnimationActive={options.isAnimationActive}
                  />
                )}
                {showMainLine && (
                  <Area
                    type={options.lineType}
                    dataKey="value"
                    stroke={options.lineColor}
                    strokeWidth={options.lineWidth}
                    hide={barProps[options.valueTitle] === true}
                    fillOpacity={1}
                    fill={options.fillLine ? 'url(#line1Color_' + options.orientation + id + ')' : '#ffffff00'}
                    activeDot={showActiveDot === true ? true : false}
                    dot={showDot === true ? { stroke: borderColor, strokeWidth: options.lineWidth } : false}
                    isAnimationActive={options.isAnimationActive}
                  />
                )}
                {showSecondLine && (
                  <Area
                    type={options.lineType}
                    dataKey="value2"
                    stroke={options.line2Color}
                    strokeWidth={options.lineWidth}
                    hide={barProps[options.value2Title] === true}
                    fillOpacity={1}
                    fill={options.fillLine2 ? 'url(#line2Color_' + options.orientation + id + ')' : '#ffffff00'}
                    activeDot={showActiveDot === true ? true : false}
                    dot={showDot === true ? { stroke: borderColor, strokeWidth: options.lineWidth } : false}
                    isAnimationActive={options.isAnimationActive}
                  />
                )}
                {showThirthLine && (
                  <Area
                    type={options.lineType}
                    dataKey="value3"
                    stroke={options.line3Color}
                    strokeWidth={options.lineWidth}
                    hide={barProps[options.value3Title] === true}
                    fillOpacity={1}
                    fill={options.fillLine3 ? 'url(#line3Color_' + options.orientation + id + ')' : '#ffffff00'}
                    activeDot={showActiveDot === true ? true : false}
                    dot={showDot === true ? { stroke: borderColor, strokeWidth: options.lineWidth } : false}
                    isAnimationActive={options.isAnimationActive}
                  />
                )}
                {options.showLineOnChart && options.orientation === 'horizontal' && (
                  <Line
                    yAxisId="auxline"
                    type={options.lineType}
                    dataKey="auxline"
                    stroke={options.auxLineColor}
                    strokeWidth={options.lineWidth}
                    hide={barProps[options.auxLineTitle] === true}
                    activeDot={showActiveDot === true ? true : false}
                    dot={showDot === true ? { stroke: options.auxLineColor, strokeWidth: options.lineWidth } : false}
                    isAnimationActive={options.isAnimationActive}
                  />
                )}
                {options.showLineOnChart && options.orientation === 'vertical' && (
                  <Line
                    xAxisId="auxline"
                    type={options.lineType}
                    dataKey="auxline"
                    stroke={options.auxLineColor}
                    strokeWidth={options.lineWidth}
                    hide={barProps[options.auxLineTitle] === true}
                    activeDot={showActiveDot === true ? true : false}
                    dot={showDot === true ? { stroke: options.auxLineColor, strokeWidth: options.lineWidth } : false}
                    isAnimationActive={options.isAnimationActive}
                  />
                )}
                {showMetric && (
                  <Line
                    type={options.lineType}
                    dataKey="metric"
                    stroke={options.metricLineColor}
                    strokeWidth={options.lineWidth}
                    hide={barProps[options.metricTitle] === true}
                    strokeDasharray="5 5"
                    activeDot={showActiveDot === true ? true : false}
                    dot={showDot === true ? { stroke: options.metricLineColor, strokeWidth: options.lineWidth } : false}
                    isAnimationActive={options.isAnimationActive}
                  />
                )}
                <Bar
                  dataKey="maxvalue"
                  fill="#ffffff00"
                  stroke="#ffffff00"
                  strokeWidth={0}
                  onMouseDown={(bar) => {
                    if (options.orientation === 'horizontal') {
                      setRefAreaLeft(bar.date);
                      setRefAreaLeftLabel(bar.id);
                      setStartZoom(true);
                    }
                  }}
                />
                {!options.useZoom && refAreaLeftLabel > 0 && refAreaRightLabel > 0 ? (
                  <ReferenceArea
                    x1={refAreaLeftLabel}
                    x2={refAreaRightLabel}
                    stroke="#0d0deb"
                    strokeWidth={1}
                    fill="#0d0deb"
                    strokeOpacity={0.3}
                    label="zoom"
                  />
                ) : null}
                {options.useCartesianGrid && <CartesianGrid strokeDasharray="3 3" />}
                <Tooltip content={CustomTooltip} cursor={{ stroke: '#0d0deb', strokeWidth: 1 }} />
                {options.showLegend && (
                  <Legend
                    onMouseUp={handleLegendClick}
                    onMouseDown={handleLegendMouseEnter}
                    onMouseLeave={handleLegendMouseLeave}
                    payload={chartLegends}
					iconSize={10}
					formatter={renderColorfulLegendText}
					wrapperStyle={{ fontSize: options.fontSize }}
                  />
                )}
                {options.orientation === 'horizontal' && options.useZoom && (
                  <Brush
                    dataKey="dateLocale"
                    height={25}
                    stroke={borderColor}
                    strokeWidth={1}
                    startIndex={axisXStartIndex}
                    endIndex={axisXEndIndex}
                  />
                )}
              </ComposedChart>
            </ResponsiveContainer>
          </LineChartToolboxContainer>
        );
      } else {
        return (
          <LineChartToolboxContainer id={id}>
            {options.showToolbar && options.showTimeZoomButtons && (
              <ButtonContainer>
                <ButtonNormal
                  title={options.zoomButtonTitle}
                  onClick={() => {
                    handleClick(zoomLink, '_self');
                  }}
                >
                  <ButtonTextZoom></ButtonTextZoom>
                </ButtonNormal>
              </ButtonContainer>
            )}
            <ResponsiveContainer width={width} height={height}>
              <ComposedChart
                width={width}
                height={height}
                data={chartLines}
                layout={options.orientation}
                syncId={syncID}
                margin={chartMargin}
                onClick={(e) => {
                  if (clicEnable) {
                    handleClick(url_link, '_self');
                  }
                }}
              >
                {options.orientation === 'horizontal' && (
                  <XAxis
                    dataKey="name"
                    type="category"
                    scale={'point'}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    tickMargin={5}
                    tickSize={5}
                    hide={!options.showAxisX}
                  />
                )}
                {options.orientation === 'horizontal' && (
                  <YAxis
                    type="number"
                    scale={options.scaleType}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    tickMargin={5}
                    hide={!options.showAxisY}
                    domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axisYStartIndex, axisYEndIndex]}
                  />
                )}
                {options.orientation === 'vertical' && (
                  <YAxis
                    dataKey="name"
                    type="category"
                    scale={'point'}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    tickMargin={5}
                    tickSize={5}
                    hide={!options.showAxisY}
                  />
                )}
                {options.orientation === 'vertical' && (
                  <XAxis
                    type="number"
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    scale={options.scaleTypeVertical}
                    tickMargin={5}
                    hide={!options.showAxisX}
                    domain={options.scaleTypeVertical === 'log' ? ['auto', 'auto'] : [axisYStartIndex, axisYEndIndex]}
                  />
                )}
                {showMainLine && (
                  <Area
                    type={options.lineType}
                    dataKey="value"
                    stroke={options.lineColor}
                    strokeWidth={options.lineWidth}
                    hide={barProps['value'] === true}
                    fill={options.fillLine ? 'url(#line1Color_' + options.orientation + id + ')' : '#ffffff00'}
                    dot={options.showDots === true ? { stroke: borderColor, strokeWidth: options.lineWidth } : false}
                  />
                )}
                {showSecondLine && (
                  <Area
                    type={options.lineType}
                    dataKey="value2"
                    stroke={options.line2Color}
                    strokeWidth={options.lineWidth}
                    hide={barProps['value2'] === true}
                    fill={options.fillLine2 ? 'url(#line2Color_' + options.orientation + id + ')' : '#ffffff00'}
                    dot={options.showDots === true ? { stroke: borderColor, strokeWidth: options.lineWidth } : false}
                  />
                )}
                {showThirthLine && (
                  <Area
                    type={options.lineType}
                    dataKey="value3"
                    stroke={options.line3Color}
                    strokeWidth={options.lineWidth}
                    hide={barProps['value3'] === true}
                    fill={options.fillLine3 ? 'url(#line3Color_' + options.orientation + id + ')' : '#ffffff00'}
                    dot={options.showDots === true ? { stroke: borderColor, strokeWidth: options.lineWidth } : false}
                  />
                )}
                {options.showMetricOnChart && (
                  <Line
                    type={options.lineType}
                    dataKey="metric"
                    stroke={options.metricLineColor}
                    strokeWidth={options.lineWidth}
                    hide={barProps['metric'] === true}
                    strokeDasharray="5 5"
                    dot={
                      options.showDots === true
                        ? { stroke: options.metricLineColor, strokeWidth: options.lineWidth }
                        : false
                    }
                  />
                )}
                {options.useCartesianGrid && <CartesianGrid strokeDasharray="3 3" vertical={false} />}
                {options.showLegend && (
                  <Legend
                    onMouseUp={handleLegendClick}
                    onMouseEnter={handleLegendMouseEnter}
                    onMouseLeave={handleLegendMouseLeave}
                    payload={chartLegends}
					iconSize={10}
					formatter={renderColorfulLegendText}
					wrapperStyle={{ fontSize: options.fontSize }}
                  />
                )}
                <Tooltip content={CustomTooltip} cursor={false} />
              </ComposedChart>
            </ResponsiveContainer>
          </LineChartToolboxContainer>
        );
      }
    } else {
        return (
          <div>
            <div className="gf-form-error">{error1}</div>
          </div>
        );
    } 
  } else {
    return (
      <div>
        <div className="gf-form-error">{error3}</div>
      </div>
    );
  }
};

function handleClick(url: string, target: string) {
  if (url !== null && url !== '') {
    window.open(url, target);
  }
}

function zoomTime(actualZoom: number) {
  const d = Date.now();
  let to = new Date(d).toUTCString();
  let from = new Date(d - 10800000).toUTCString();
  switch (actualZoom) {
    case 2:
      from = new Date(d - 86400000).toUTCString();
      break;
    case 3:
      from = new Date(d - 86400000 * 7).toUTCString();
      break;
    case 4:
      from = new Date(d - 86400000 * 30).toUTCString();
      break;
    case 5:
      from = new Date(d - 86400000 * 90).toUTCString();
      break;
    case 6:
      from = new Date(d - 86400000 * 180).toUTCString();
      break;
    case 7:
      from = new Date(d - 86400000 * 360).toUTCString();
      break;
  }
  zoom(from, to);
}

function zoom(refRight: string, refLeft: string) {
  var time_from = new Date(refLeft).getTime();
  var time_to = new Date(refRight).getTime();
  if (time_from < time_to) {
    let queryMap: UrlQueryMap = { from: time_from, to: time_to };
    getLocationSrv().update({
      partial: true,
      replace: true,
      query: queryMap,
    });
  } else if (time_from > time_to) {
    let queryMap: UrlQueryMap = { from: time_to, to: time_from };
    getLocationSrv().update({
      partial: true,
      replace: true,
      query: queryMap,
    });
  }
}

import { FormElement } from '../types';
import { FormElementType } from './form-element';

export const InitialHighlightColorDefault = 'red';

export const SubmitIconDefault = 'save';
export const SubmitBackgroundColorDefault = 'purple';
export const SubmitForegroundColorDefault = 'yellow';
export const SubmitTextDefault = 'Submit';

export const ResetIconDefault = 'process';
export const ResetBackgroundColorDefault = 'purple';
export const ResetForegroundColorDefault = 'yellow';
export const ResetTextDefault = 'Reset';

export const SliderDefault = {
  min: 0,
  max: 10,
  step: 1,
  value: 0,
};

export const FormElementDefault: FormElement = {
  id: '',
  title: '',
  type: FormElementType.STRING,
  labelWidth: 10,
  tooltip: '',
  section: '',
};

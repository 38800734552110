import { css } from '@emotion/css';
import { t, Trans } from '@lingui/macro';
import React from 'react';
import { useMount } from 'react-use';

import { VerticalGroup, Icon, useStyles2, Button } from '@grafana/ui';

import { contextSrv } from 'app/core/services/context_srv';
import { changeUserOrg, revokeUserSession, updateUserProfile } from 'app/features/profile/state/actions';

import { ProfileManagerPanelOptions } from './types';
import { ProfileManager } from './ProfileManager';

export const ProfileManagerPanel: React.FC<Props> = ({ options, replaceVariables, id }) => {
  let isAdmin = false;
  if (contextSrv.isNetMonitorAdmin) {
    isAdmin = true;
  }
  if (options.editorCanAdmin && contextSrv.isEditor) {
    isAdmin = true;
  }
  const pluginTitle = replaceVariables(options.pluginTitle);
  const showTitle = options.showTitle;
  const okMessage = replaceVariables(options.successMessage);

  const styles = useStyles2(getStyles);

  const user = contextSrv.user;

  return (
    <div className={styles.section} key={String(id)}>
      {!user && (
        <Alert severity="error" title="Error al cargar datos desde el gestor de usuarios">
          {resultError.message || 'Error desconocido'}
        </Alert>
      )}
      {user && (
        <ProfileManager
          showTitle={showTitle}
          pluginTitle={pluginTitle}
          isAdmin={isAdmin}
          user={user}
          revokeUserSession={revokeUserSession}
          changeUserOrg={changeUserOrg}
          updateUserProfile={updateUserProfile}
        />
      )}
    </div>
  );
};

const getStyles = (theme: NetMonitorTheme2) => ({
  section: css`
    margin: ${theme.spacing(2)};
  `,
});

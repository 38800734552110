// @ts-ignore
import React, { Component, useState } from 'react';
import { PanelProps, GraphSeriesValue, UrlQueryMap } from '@grafana/data';
import { Icon } from '@grafana/ui';
import { BarChartOptions, GenericBarData, GenericBarLabel, LegendBarLabel } from 'types';
import {
  BarChart,
  ComposedChart,
  Bar,
  Area,
  ReferenceArea,
  CartesianGrid,
  XAxis,
  YAxis,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  Brush,
  LabelList,
  Line,
} from 'recharts';
import { config, getLocationSrv, getTemplateSrv } from '@grafana/runtime';
import styled from 'styled-components';
import './BarChartPanel.css';

interface Props extends PanelProps<BarChartOptions> {}

export const BarChartPanel: React.FC<Props> = ({ options, data, width, height, replaceVariables, id }) => {
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);

  const timeFrom = getTemplateSrv().replace('${__from}');
  const timeTo = getTemplateSrv().replace('${__to}');
  const timeInterval = Number(timeTo) - Number(timeFrom);
  var timeWindow = Number(timeInterval) / 3600000;
  var actualWin = 6;
  if (timeWindow <= 24) {
    actualWin = 1;
  } else if (timeWindow <= 168) {
    actualWin = 2;
  } else if (timeWindow <= 720) {
    actualWin = 3;
  } else if (timeWindow <= 2160) {
    actualWin = 4;
  } else if (timeWindow <= 4320) {
    actualWin = 5;
  }
  const [actualZoom, setActualZoom] = useState(actualWin);

  const [refAreaLeft, setRefAreaLeft] = useState('');
  const [refAreaRight, setRefAreaRight] = useState('');
  const [refAreaLeftLabel, setRefAreaLeftLabel] = useState(0);
  const [refAreaRightLabel, setRefAreaRightLabel] = useState(0);
  const [startZoom, setStartZoom] = useState(false);

  var chartMargin = { top: 5, right: 5, left: 5, bottom: 5 };
  if (options.showToolbar) {
    chartMargin = { top: 5, right: 5, left: 40, bottom: 5 };
  }

  const BarTooltip = styled.div`
    font-size: 11px;
    line-height: 20px;
    color: #333333;
    width: fit-content;
    padding: 5px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 4px 2px 4px 2px #8b98a499;
    background-clip: padding-box;
    pointer-events: none;
    text-anchor: middle;
    z-index: 9999;
  `;
  const BarTooltipWarn = styled.div`
    font-size: 11px;
    line-height: 20px;
    color: #000000;
    width: fit-content;
    padding: 5px;
    background-color: #e45858;
    border: 1px solid red;
    border-radius: 4px;
    box-shadow: 4px 2px 4px 2px #8b98a499;
    background-clip: padding-box;
    pointer-events: none;
    text-anchor: middle;
    z-index: 9999;
  `;
  const BarChartTooltipCenter = styled.p`
    text-align: center;
  `;

  const BarChartTooltipText = styled.p`
    margin-top: 0px;
    margin-bottom: 0px;
  `;

  const BarChartTooltipTitle = styled.b`
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
  `;

  const BarChartToolboxContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
  `;

  const ButtonContainer = styled.div`
    position: absolute;
    top: 10px;
    left: 2px;
    z-index: 999;
    width: 32px;
    height: calc(100% - 20px);
  `;

  const ButtonNormal = styled.button`
    background-color: #0d0deb;
    opacity: 0.75;
    display: block;
    margin-left: 2px;
    margin-bottom: 1px;
    height: 28px;
    width: 28px;
    line-height: 24px;
    color: #fff;
    border: 1px solid #5a5a5a;
    border-radius: 5px;

    &:hover {
      opacity: 1;
    }
  `;

  const ButtonTop = styled.button`
    background-color: #0d0deb;
    opacity: 0.75;
    display: block;
    margin-left: 2px;
    height: 28px;
    width: 28px;
    line-height: 24px;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid #5a5a5a;
    &:hover {
      opacity: 1;
    }
  `;

  const ButtonMiddle = styled.button`
    background-color: #0d0deb;
    opacity: 0.75;
    display: block;
    margin-left: 2px;
    height: 28px;
    width: 28px;
    line-height: 24px;
    color: #fff;
    border: 1px solid #5a5a5a;
    &:hover {
      opacity: 1;
    }
  `;

  const ButtonBottom = styled.button`
    background-color: #0d0deb;
    opacity: 0.75;
    display: block;
    margin-left: 2px;
    height: 28px;
    width: 28px;
    line-height: 24px;
    color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #5a5a5a;
    &:hover {
      opacity: 1;
    }
  `;

  const ButtonText = styled.div`
    width: 16px;
    height: 16px;
    margin-top: -8px;
    margin-left: -1px;
  `;

  const absoluteThreshold = Number(replaceVariables(options.absoluteThreshold));
  var syncID = replaceVariables(options.syncId);
  if (syncID === '') {
    syncID = String(id);
  }
  const barUrl_link = String(replaceVariables(options.drillDownLinkBar));
  const url_link = String(replaceVariables(options.drillDownLink));
  var clicEnable = false;
  if (url_link !== null && url_link.length > 0 && !options.timeSeriesChart) {
    clicEnable = true;
  }
  if (barUrl_link !== null && barUrl_link.length > 0 && options.useBarUrl) {
    clicEnable = true;
  }
  var realTimeLink = String(replaceVariables(options.realTimeButtonLink));
  var zoomLink = String(replaceVariables(options.zoomButtonLink));
  const isDark = config.theme.isDark;
  var axisXEndIndex = 0;
  var axisXMaxIndex = 0;
  var axisXStartIndex = 0;
  var axisYEndIndex = options.maxValue;
  var axisYStartIndex = options.minValue;
  var axis2YEndIndex = options.maxValue;
  var axis2YStartIndex = options.minValue;

  var chartLabels: GenericBarLabel[] = [];
  var chartLegends: LegendBarLabel[] = [];

  if (options.showAreaOnChart && options.areaField !== undefined && options.areaField !== '') {
    let newlabel: GenericBarLabel = {
      color: options.auxAreaColor,
      key: options.auxAreaTitle,
    };
    chartLabels.push(newlabel);
    let newlegend: LegendBarLabel = {
      id: 'auxarea',
      type: 'rect',
      value: options.auxAreaTitle,
      color: options.auxAreaColor,
    };
    chartLegends.push(newlegend);
  }
  if (options.showLineOnChart && options.lineField !== undefined && options.lineField !== '') {
    let newlabel: GenericBarLabel = {
      color: options.auxLineColor,
      key: options.auxLineTitle,
    };
    chartLabels.push(newlabel);
    let newlegend: LegendBarLabel = {
      id: 'auxline',
      type: 'rect',
      value: options.auxLineTitle,
      color: options.auxLineColor,
    };
    chartLegends.push(newlegend);
  }
  if (options.useMetricField && options.metricField !== undefined && options.metricField !== '') {
    let newlabel: GenericBarLabel = {
      color: options.metricLineColor,
      key: options.metricTitle,
    };
    chartLabels.push(newlabel);
    let newlegend: LegendBarLabel = {
      id: 'metric',
      type: 'rect',
      value: options.metricTitle,
      color: options.metricLineColor,
    };
    chartLegends.push(newlegend);
  }

  const d = Date.now();
  var chartBars: GenericBarData[] = [];

  const renderColorfulLegendText = (value: string) => {
    const textColor = isDark ? '#ffffff': '#000000';
    return <span style={{color: textColor, cursor: 'pointer' }}>{value}</span>;
  };

  if (width < 200 || height < 100) {
    return (
      <div>
        <div className="pieChart_error">{error4}</div>
      </div>
    );
  } else if (data.state !== 'Error') {
    if(data.series[0].length > 0) {
      data.series.forEach((series) => {
        const timeVals: GraphSeriesValue[] = series.fields[0].values.toArray();
        for (let i = 0; i < timeVals.length; i++) {
          if (options.showRealTimeButton && options.realTimeUrlField !== undefined && options.realTimeUrlField !== '') {
            let rtLink = series.fields.find((field) => field.name === options.realTimeUrlField)?.values.get(i);
            if (rtLink !== null && rtLink !== '') {
              realTimeLink = rtLink;
            }
          }
          if (options.showRealTimeButton && options.zoomUrlField !== undefined && options.zoomUrlField !== '') {
            let zLink = series.fields.find((field) => field.name === options.zoomUrlField)?.values.get(i);
            if (zLink !== null && zLink !== '') {
              zoomLink = zLink;
            }
          }
          var chartBar: GenericBarData = {
            id: i + 1,
            name: series.fields.find((field) => field.name === options.serieField)?.values.get(i),
            date: new Date(d).toUTCString(),
            dateLocale: new Date(d).toLocaleString(),
            value: 0,
            auxarea: 0,
            auxline: 0,
            stackId: series.fields.find((field) => field.name === options.stackField)?.values.get(i),
            label: series.fields.find((field) => field.name === options.labelField)?.values.get(i),
            color: series.fields.find((field) => field.name === options.colorField)?.values.get(i),
            info: '',
            metric: 0,
            threshold: 0,
            onfire: false,
          };
          if (options.timeSeriesChart && options.dateField !== undefined && options.dateField !== '') {
            let unixDate = series.fields.find((field) => field.name === options.dateField)?.values.get(i);
            chartBar.date = new Date(unixDate).toUTCString();
            chartBar.dateLocale = new Date(unixDate).toLocaleString();
            if (actualWin < 2) {
              chartBar.name = new Date(unixDate).toLocaleTimeString(options.timeZone);
            } else {
              chartBar.name = new Date(unixDate).toLocaleDateString(options.timeZone).replace(/\//g, '-');
            }
          }
          if (options.valueField !== undefined && options.valueField !== '') {
            const value = series.fields.find((field) => field.name === options.valueField)?.values.get(i);
			chartBar.value = isNaN(value) ? 0 : Number(value);
          }
          if (options.timeSeriesChart && options.areaField !== undefined && options.areaField !== '') {
            const auxarea = series.fields.find((field) => field.name === options.areaField)?.values.get(i);
			chartBar.auxarea = isNaN(auxarea) ? 0 : Number(auxarea);
          }
          if (options.timeSeriesChart && options.lineField !== undefined && options.lineField !== '') {
            const auxline = series.fields.find((field) => field.name === options.lineField)?.values.get(i);
			chartBar.auxline = isNaN(auxline) ? 0 : Number(auxline);
          }
          if (options.useInfoField && options.infoField !== undefined && options.infoField !== '') {
            chartBar.info = series.fields.find((field) => field.name === options.infoField)?.values.get(i);
          }
          if (options.useMetricField) {
            if (options.metricField !== undefined && options.metricField !== '') {
              let metric = series.fields.find((field) => field.name === options.metricField)?.values.get(i);
              chartBar.metric = isNaN(metric) ? 0 : Number(metric);
            }
            if (options.metricThresholdField !== undefined && options.metricThresholdField !== '') {
              let relativeThreshold = series.fields
                .find((field) => field.name === options.metricThresholdField)
                ?.values.get(i);
              chartBar.threshold = isNaN(relativeThreshold) ? 1 : Number(relativeThreshold) / 100;
            }
          }
          if (chartBar.metric > 0 && chartBar.threshold > 0) {
            if (chartBar.value > chartBar.metric * (1 + chartBar.threshold)) {
              chartBar.onfire = true;
            }
          }
          if (absoluteThreshold > 0 && chartBar.value > absoluteThreshold) {
            chartBar.onfire = true;
          }
          if (options.invertThreshold) {
            chartBar.onfire = !chartBar.onfire;
          }
          chartBars.push(chartBar);
          axisXMaxIndex = i;
          if (options.maxValue === 0) {
            let minValue = Math.min(chartBar.value, chartBar.metric);
            let maxValue = Math.max(chartBar.value, chartBar.metric);
            if (maxValue > axisYEndIndex) {
              axisYEndIndex = maxValue;
            }
            if (minValue < axisYStartIndex) {
              axisYStartIndex = minValue;
            }
          }
          let minValue2 = Math.min(chartBar.auxline, chartBar.auxarea);
          let maxValue2 = Math.max(chartBar.auxline, chartBar.auxarea);
          if (maxValue2 > axis2YEndIndex) {
            axis2YEndIndex = maxValue2;
          }
          if (minValue2 < axis2YStartIndex) {
            axis2YStartIndex = minValue2;
          }
        }
      });

      const [barProps, setBarProps] = useState(
        chartLabels.reduce(
          (a, { key }) => {
            a[key] = false;
            return a;
          },
          { hover: null }
        )
      );

      if (options.maxValue === 0) {
        axisYEndIndex = axisYEndIndex * 1.1;
        if (axisYStartIndex >= 0 && options.minValue === 0) {
          axisYStartIndex = 0;
        } else if (axisYStartIndex < 0) {
          axisYStartIndex = axisYStartIndex * 1.1;
        }
      }
      axis2YEndIndex = axis2YEndIndex * 1.1;
      axis2YStartIndex = axis2YStartIndex * 1.1;

      let backgroundColor = '#e5e5e5';
      let tickFontColor = '#222';
      let borderColor = '#aeaeae';
      if (isDark) {
        backgroundColor = '#222426';
        borderColor = '#444444';
        tickFontColor = '#ddd';
      }

      const handleLegendMouseEnter = (e) => {
        if (!barProps[e.value]) {
          setBarProps({ ...barProps, hover: e.value });
        }
      };

      const handleLegendMouseLeave = (e) => {
        setBarProps({ ...barProps, hover: null });
      };

      const handleLegendClick = (e) => {
        setBarProps({
          ...barProps,
          [e.value]: !barProps[e.value],
          hover: null,
        });
      };

      const DataFormater = (number) => {
        if (number >= 1000000000) {
          return String(Math.round(number / 1000000000)) + ' G';
        } else if (number >= 1000000) {
          return String(Math.round(number / 1000000)) + ' M';
        } else if (number >= 1000) {
          return String(Math.round(number / 1000)) + ' K';
        } else {
          return String(Math.round(number)) + ' ';
        }
      };

      const renderCustomizedLabel = (props) => {
        const { content, value, ...rest } = props;
        return (
          <Label {...rest} fontSize={String(options.fontSize)} fill="#FFFFFF" strokeWidth={0} stroke="#ffffff">
            {value}
          </Label>
        );
      };

      const renderCustomizedLabelRight = (props) => {
        const { content, value, ...rest } = props;
        let barTextColor = '#111';
        if (isDark) {
          barTextColor = '#eee';
        }
        return (
          <Label {...rest} fontSize={String(options.fontSize)} fill={barTextColor} strokeWidth={1} stroke={barTextColor}>
            {value}
          </Label>
        );
      };

      const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
          if (payload[0]?.payload?.onfire) {
            return (
              <BarTooltipWarn>
                <span>
                  <BarChartTooltipCenter>
                    <i className="fa fa-exclamation-triangle fa-2" aria-hidden="true"></i>
                    <BarChartTooltipTitle>Alarma</BarChartTooltipTitle>
                  </BarChartTooltipCenter>
                </span>
                {options.showMetricOnTooltip && (
                  <span>
                    <BarChartTooltipText>
                      <b>
                        {payload[0]?.payload?.label}
                        {options.resultSeparator + ' '}
                      </b>
                      {DataFormater(payload[0]?.payload?.value)}
                      {options.metricUnit}
                    </BarChartTooltipText>
                    <BarChartTooltipText>
                      <b>
                        {options.metricTitle}
                        {options.resultSeparator + ' '}
                      </b>
                      {DataFormater(payload[0]?.payload?.metric)}
                      {options.metricUnit}
                    </BarChartTooltipText>
                  </span>
                )}
                {options.showMetricOnTooltip && options.showAreaOnChart && (
                  <span>
                    <BarChartTooltipText>
                      <b>
                        {options.auxAreaTitle}
                        {options.resultSeparator + ' '}
                      </b>
                      {DataFormater(payload[0]?.payload?.auxarea)}
                      {options.auxAreaUnit}
                    </BarChartTooltipText>
                  </span>
                )}
                {options.showMetricOnTooltip && options.showLineOnChart && (
                  <span>
                    <BarChartTooltipText>
                      <b>
                        {options.auxLineTitle}
                        {options.resultSeparator + ' '}
                      </b>
                      {DataFormater(payload[0]?.payload?.auxline)}
                      {options.auxLineUnit}
                    </BarChartTooltipText>
                  </span>
                )}
                {!options.showMetricOnTooltip && (
                  <span>
                    <BarChartTooltipText>
                      <b>{payload[0]?.payload?.label}</b>
                    </BarChartTooltipText>
                  </span>
                )}
                {options.timeSeriesChart && (
                  <span>
                    <BarChartTooltipText>
                      <b>
                        {options.dateTitle}
                        {options.resultSeparator + ' '}
                      </b>
                      {payload[0]?.payload?.dateLocale}
                    </BarChartTooltipText>
                  </span>
                )}
                {options.useInfoField && (
                  <span>
                    <b>
                      {options.infoTitle}
                      {options.resultSeparator + ' '}
                    </b>
                    {payload[0]?.payload?.info}
                  </span>
                )}
                {clicEnable && (
                  <span>
                    <BarChartTooltipText>{options.clicTitle}</BarChartTooltipText>
                  </span>
                )}
              </BarTooltipWarn>
            );
          } else {
            return (
              <BarTooltip>
                {options.showMetricOnTooltip && (
                  <span>
                    <BarChartTooltipText>
                      <b>
                        {payload[0]?.payload?.label}
                        {options.resultSeparator + ' '}
                      </b>
                      {DataFormater(payload[0]?.payload?.value)}
                      {options.metricUnit}
                    </BarChartTooltipText>
                    <BarChartTooltipText>
                      <b>
                        {options.metricTitle}
                        {options.resultSeparator + ' '}
                      </b>
                      {DataFormater(payload[0]?.payload?.metric)}
                      {options.metricUnit}
                    </BarChartTooltipText>
                  </span>
                )}
                {options.showMetricOnTooltip && options.showAreaOnChart && (
                  <span>
                    <BarChartTooltipText>
                      <b>
                        {options.auxAreaTitle}
                        {options.resultSeparator + ' '}
                      </b>
                      {DataFormater(payload[0]?.payload?.auxarea)}
                      {options.auxAreaUnit}
                    </BarChartTooltipText>
                  </span>
                )}
                {options.showMetricOnTooltip && options.showLineOnChart && (
                  <span>
                    <BarChartTooltipText>
                      <b>
                        {options.auxLineTitle}
                        {options.resultSeparator + ' '}
                      </b>
                      {DataFormater(payload[0]?.payload?.auxline)}
                      {options.auxLineUnit}
                    </BarChartTooltipText>
                  </span>
                )}
                {!options.showMetricOnTooltip && (
                  <span>
                    <BarChartTooltipText>
                      <b>{payload[0]?.payload?.label}</b>
                    </BarChartTooltipText>
                  </span>
                )}
                {options.timeSeriesChart && (
                  <span>
                    <BarChartTooltipText>
                      <b>
                        {options.dateTitle}
                        {options.resultSeparator + ' '}
                      </b>
                      {payload[0]?.payload?.dateLocale}
                    </BarChartTooltipText>
                  </span>
                )}
                {options.useInfoField && (
                  <span>
                    <b>
                      {options.infoTitle}
                      {options.resultSeparator + ' '}
                    </b>
                    {payload[0]?.payload?.info}
                  </span>
                )}
                {clicEnable && (
                  <span>
                    <BarChartTooltipText>{options.clicTitle}</BarChartTooltipText>
                  </span>
                )}
              </BarTooltip>
            );
          }
        } else {
          return null;
        }
      };

      if (options.useBackgroundColor) {
        backgroundColor = options.backgroundColor;
      }
	  if (backgroundColor === 'transparent') {
	    borderColor = 'transparent';
	  }
      if (options.timeSeriesChart && options.useZoom && options.endIndex > 0) {
        axisXEndIndex = options.endIndex;
      } else {
        axisXEndIndex = axisXMaxIndex;
      }
      if (options.timeSeriesChart && options.useZoom && options.startIndex > 0) {
        axisXStartIndex = axisXEndIndex - options.startIndex;
      }

      var showDot = false;
      var showActiveDot = false;
      if (options.orientation === 'vertical') {
        if (options.scaleTypeVertical !== 'log' && options.scaleTypeVertical !== 'ordinal') {
          if (options.showDots === true) {
            showDot = true;
          }
          showActiveDot = true;
        }
      } else {
        if (options.scaleType !== 'log' && options.scaleType !== 'ordinal') {
          if (options.showDots === true) {
            showDot = true;
          }
          showActiveDot = true;
        }
      }

      var barchartWidth = width - 32;
      if (!options.showToolbar) {
        barchartWidth = width;
      }

      let titleIn = 'Zoom In';
      let titleOut = 'Zoom Out';

      if (actualZoom === 1) {
        titleIn = 'No es posible reducir ventana de tiempo';
        titleOut = 'Ampliar ventana de tiempo a ultima semana';
      } else if (actualZoom === 2) {
        titleIn = 'Reducir ventana de tiempo a ultimas 24hs';
        titleOut = 'Ampliar ventana de tiempo a ultimo mes';
      } else if (actualZoom === 3) {
        titleIn = 'Reducir ventana de tiempo a ultima semana';
        titleOut = 'Ampliar ventana de tiempo a ultimo trimetre';
      } else if (actualZoom === 4) {
        titleIn = 'Reducir ventana de tiempo a ultimo mes';
        titleOut = 'Ampliar ventana de tiempo a ultimo semestre';
      } else if (actualZoom === 5) {
        titleIn = 'Reducir ventana de tiempo a ultimo trimetre';
        titleOut = 'Ampliar ventana de tiempo a ultimo año';
      } else if (actualZoom === 6) {
        titleIn = 'Reducir ventana de tiempo a ultimo semestre';
        titleOut = 'No es posible ampliar ventana de tiempo';
      }

      if (!options.timeSeriesChart && options.orientation === 'vertical') {
        return (
          <BarChartToolboxContainer id={id}>
            <ResponsiveContainer width={barchartWidth} height={height}>
              <BarChart
                width={width}
                height={height}
                data={chartBars}
                layout={options.orientation}
                barCategoryGap={options.barMargin}
                barGap={options.barMargin}
                margin={chartMargin}
                onClick={(e) => {
                  if (clicEnable && !options.useBarUrl) {
                    handleClick(url_link, '_self');
                  }
                }}
              >
                <XAxis
                  dataKey="value"
                  type="number"
                  scale={options.scaleTypeVertical}
                  tickMargin={5}
                  hide={!options.showAxisX}
                  domain={options.scaleTypeVertical === 'log' ? ['auto', 'auto'] : [axisYStartIndex, axisYEndIndex]}
                />
                <YAxis
                  dataKey="name"
                  type="category"
                  tickSize={5}
                  tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                  hide={!options.showAxisY}
                />
                <Bar
                  dataKey="value"
                  background={{ fill: backgroundColor }}
                  stroke={backgroundColor}
                  strokeWidth={0}
                  isAnimationActive={false}
                  onClick={(bar) => {
                    if (clicEnable && options.useBarUrl) {
                      handleClick(barUrl_link + bar.name, '_self');
                    }
                  }}
                >
                  {cellFactory(
                    chartBars,
                    options.warningColor,
                    borderColor,
                    backgroundColor,
                    'horizontal',
                    options.lineWidth,
                    options.barMargin,
                    false
                  )}
                  {options.showAxisY && !options.showAxisX && (
                    <LabelList dataKey="value" position="center" content={renderCustomizedLabel} />
                  )}
                  {!options.showAxisY && options.showAxisX && (
                    <LabelList dataKey="name" position="center" content={renderCustomizedLabel} />
                  )}
                  {!options.showAxisY && !options.showAxisX && (
                    <LabelList dataKey="value" position="center" content={renderCustomizedLabel} />
                  )}
                  {!options.showAxisY && !options.showAxisX && (
                    <LabelList dataKey="name" position="right" content={renderCustomizedLabelRight} />
                  )}
                </Bar>
                <Tooltip content={CustomTooltip} cursor={false} isAnimationActive={false} />
              </BarChart>
            </ResponsiveContainer>
          </BarChartToolboxContainer>
        );
      } else if (!options.timeSeriesChart && options.orientation === 'horizontal') {
        return (
          <BarChartToolboxContainer id={id}>
            <ResponsiveContainer width={barchartWidth} height={height}>
              <BarChart
                width={width}
                height={height}
                data={chartBars}
                layout={options.orientation}
                barCategoryGap={options.barMargin}
                barGap={options.barMargin}
                syncId={syncID}
                margin={chartMargin}
                onClick={(e) => {
                  if (clicEnable && !options.useBarUrl) {
                    handleClick(url_link, '_self');
                  }
                }}
              >
                <XAxis
                  dataKey="name"
                  type="category"
                  tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                  tickMargin={5}
                  tickSize={5}
                  hide={!options.showAxisX}
                />
                <YAxis
                  dataKey="value"
                  type="number"
                  scale={options.scaleType}
                  tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                  tickMargin={5}
                  hide={!options.showAxisY}
                  domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axisYStartIndex, axisYEndIndex]}
                />
                {options.showLineOnChart && (
                  <YAxis
                    yAxisId="auxline"
                    dataKey="auxline"
                    type="number"
                    orientation="right"
                    allowDecimals={false}
                    scale={options.scaleType}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    tickFormatter={DataFormater}
                    interval="preserveEnd"
                    hide={!options.showAxisY}
                    domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axis2YStartIndex, axis2YEndIndex]}
                  />
                )}
				<Bar
                  dataKey="value"
                  background={{ fill: backgroundColor }}
                  stroke={backgroundColor}
                  strokeWidth={0}
                  isAnimationActive={false}
                  onClick={(bar) => {
                    if (clicEnable && options.useBarUrl) {
                      handleClick(barUrl_link + bar.name, '_self');
                    }
                  }}
                >
                  {cellFactory(
                    chartBars,
                    options.warningColor,
                    borderColor,
                    backgroundColor,
                    'vertical',
                    options.lineWidth,
                    options.barMargin,
                    false
                  )}
                  {options.showAxisY && !options.showAxisX && (
                    <LabelList dataKey="value" position="center" content={renderCustomizedLabel} />
                  )}
                  {!options.showAxisY && options.showAxisX && (
                    <LabelList dataKey="name" position="center" content={renderCustomizedLabel} />
                  )}
                  {!options.showAxisY && !options.showAxisX && (
                    <LabelList dataKey="value" position="center" content={renderCustomizedLabel} />
                  )}
                  {!options.showAxisY && !options.showAxisX && (
                    <LabelList dataKey="name" position="top" content={renderCustomizedLabelRight} />
                  )}
                </Bar>
                {options.showLineOnChart && (
                  <Line
                    yAxisId="auxline"
                    type={options.lineType}
                    dataKey="auxline"
                    stroke={options.auxLineColor}
                    strokeWidth={options.lineWidth}
                    hide={barProps[options.auxLineTitle] === true}
                    activeDot={showActiveDot === true ? true : false}
                    dot={showDot === true ? { stroke: options.auxLineColor, strokeWidth: options.lineWidth } : false}
                    isAnimationActive={false}
                  />
                )}
                {options.useCartesianGrid && <CartesianGrid strokeDasharray="3 3" vertical={false} />}
                <Tooltip content={CustomTooltip} cursor={false} isAnimationActive={false} />
              </BarChart>
            </ResponsiveContainer>
          </BarChartToolboxContainer>
        );
      } else {
        return (
          <BarChartToolboxContainer id={id}>
            {options.showToolbar && (
              <ButtonContainer>
                {options.showTimeZoomButtons && options.showRealTimeButton && (
                  <ButtonTop
                    title={options.realTimeTitle}
                    onClick={() => {
                      handleClick(realTimeLink, '_blank');
                    }}
                  >
                    <ButtonText><Icon name={'realtime'} size="md" /></ButtonText>
                  </ButtonTop>
                )}
                {options.showTimeZoomButtons && options.showRealTimeButton && options.showZoomButton && (
                  <ButtonMiddle
                    title={options.zoomButtonTitle}
                    onClick={() => {
                      handleClick(zoomLink, '_blank');
                    }}
                  >
                    <ButtonText><Icon name={'brain'} size="md" /></ButtonText>
                  </ButtonMiddle>
                )}
                {options.showTimeZoomButtons && options.showRealTimeButton && (
                  <ButtonMiddle
                    title={titleIn}
                    onClick={() => {
                      if (actualZoom > 1) {
                        zoomTime(actualZoom - 1);
                        setActualZoom(actualZoom - 1);
                      }
                    }}
                  >
                    <ButtonText><Icon name={'plus'} size="sm" /></ButtonText>
                  </ButtonMiddle>
                )}
                {options.showTimeZoomButtons && options.showRealTimeButton && (
                  <ButtonBottom
                    title={titleOut}
                    onClick={() => {
                      if (actualZoom < 7) {
                        zoomTime(actualZoom + 1);
                        setActualZoom(actualZoom + 1);
                      }
                    }}
                  >
                    <ButtonText><Icon name={'minus'} size="sm" /></ButtonText>
                  </ButtonBottom>
                )}
                {options.showTimeZoomButtons && !options.showRealTimeButton && !options.showZoomButton && (
                  <ButtonTop
                    title={titleIn}
                    onClick={() => {
                      if (actualZoom > 1) {
                        zoomTime(actualZoom - 1);
                        setActualZoom(actualZoom - 1);
                      }
                    }}
                  >
                    <ButtonText><Icon name={'plus'} size="sm" /></ButtonText>
                  </ButtonTop>
                )}
                {options.showTimeZoomButtons && !options.showRealTimeButton && !options.showZoomButton && (
                  <ButtonBottom
                    title={titleOut}
                    onClick={() => {
                      if (actualZoom < 7) {
                        zoomTime(actualZoom + 1);
                        setActualZoom(actualZoom + 1);
                      }
                    }}
                  >
                    <ButtonText><Icon name={'minus'} size="sm" /></ButtonText>
                  </ButtonBottom>
                )}
                {!options.showTimeZoomButtons && options.showRealTimeButton && (
                  <ButtonNormal
                    title={options.realTimeTitle}
                    onClick={() => {
                      handleClick(realTimeLink, '_blank');
                    }}
                  >
                    <ButtonText><Icon name={'realtime'} size="md" /></ButtonText>
                  </ButtonNormal>
                )}
                {!options.showTimeZoomButtons && options.showZoomButton && (
                  <ButtonNormal
                    title={options.zoomButtonTitle}
                    onClick={() => {
                      handleClick(zoomLink, '_blank');
                    }}
                  >
                    <ButtonText><Icon name={'brain'} size="md" /></ButtonText>
                  </ButtonNormal>
                )}
              </ButtonContainer>
            )}
            <ResponsiveContainer width={width} height={height}>
              <ComposedChart
                width={width}
                height={height}
                data={chartBars}
                layout={options.orientation}
                barCategoryGap={options.barMargin}
                barGap={options.barMargin}
                syncId={syncID}
                margin={chartMargin}
                onMouseMove={(e) => {
                  if (startZoom) {
                    setRefAreaRight(e.activePayload[0].payload.date);
                    setRefAreaRightLabel(e.activeLabel);
                  }
                }}
                onMouseUp={(e) => {
                  if (startZoom) {
                    zoom(refAreaLeft, refAreaRight);
                  }
                  setStartZoom(false);
                  setRefAreaLeft('');
                  setRefAreaRight('');
                  setRefAreaLeftLabel(0);
                  setRefAreaRightLabel(0);
                  setActualZoom(actualZoom - 1);
                }}
              >
                {options.orientation === 'horizontal' && (
                  <defs>
                    <linearGradient id={'auxAreaColor_horizontal' + id} x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityStart} />
                      <stop offset="95%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityEnd} />
                    </linearGradient>
                  </defs>
                )}
                {options.orientation === 'vertical' && (
                  <defs>
                    <linearGradient id={'auxAreaColor_vertical' + id} x1="1" y1="0" x2="0" y2="0">
                      <stop offset="0%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityStart} />
                      <stop offset="95%" stopColor={options.auxAreaColor} stopOpacity={options.fillOpacityEnd} />
                    </linearGradient>
                  </defs>
                )}
                {options.orientation === 'horizontal' && !options.useZoom && (
                  <XAxis dataKey="id" type="number" hide={true} domain={[axisXStartIndex, axisXEndIndex - 1]} />
                )}
                {options.orientation === 'horizontal' && options.useZoom && (
                  <XAxis dataKey="id" type="number" hide={true} />
                )}
                {options.orientation === 'horizontal' && (
                  <XAxis
                    xAxisId="date"
                    dataKey="name"
                    type="category"
                    scale={'band'}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    ticks={[chartBars[axisXStartIndex + 1].name, chartBars[axisXEndIndex - 1].name]}
                    hide={!options.showAxisX}
                  />
                )}
                {options.orientation === 'horizontal' && (
                  <YAxis
                    dataKey="value"
                    type="number"
                    orientation="left"
                    allowDecimals={false}
                    scale={options.scaleType}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    tickFormatter={DataFormater}
                    interval="preserveEnd"
                    hide={!options.showAxisY}
                    domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axisYStartIndex, axisYEndIndex]}
                  />
                )}
                {options.orientation === 'horizontal' && options.showLineOnChart && (
                  <YAxis
                    yAxisId="auxline"
                    dataKey="auxline"
                    type="number"
                    orientation="right"
                    allowDecimals={false}
                    scale={options.scaleType}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    tickFormatter={DataFormater}
                    interval="preserveEnd"
                    hide={!options.showAxisY}
                    domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axis2YStartIndex, axis2YEndIndex]}
                  />
                )}
                {options.orientation === 'horizontal' && options.showAreaOnChart && !options.showLineOnChart && (
                  <YAxis
                    yAxisId="auxline"
                    dataKey="auxarea"
                    type="number"
                    orientation="right"
                    allowDecimals={false}
                    scale={options.scaleType}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    tickFormatter={DataFormater}
                    interval="preserveEnd"
                    hide={!options.showAxisY}
                    domain={options.scaleType === 'log' ? ['auto', 'auto'] : [axis2YStartIndex, axis2YEndIndex]}
                  />
                )}
                {options.orientation === 'vertical' && options.showAreaOnChart && (
                  <YAxis type="number" hide={true} domain={[axisXStartIndex, axisXEndIndex]} />
                )}
                {options.orientation === 'vertical' && (
                  <YAxis
                    yAxisId="date"
                    dataKey="name"
                    type="category"
                    scale={'band'}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    ticks={[chartBars[axisXStartIndex + 1].name, chartBars[axisXEndIndex - 1].name]}
                    hide={!options.showAxisY}
                  />
                )}
                {options.orientation === 'vertical' && (
                  <XAxis
                    dataKey="value"
                    type="number"
                    allowDecimals={false}
                    orientation="bottom"
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    tickFormatter={DataFormater}
                    scale={options.scaleTypeVertical}
                    interval="preserveEnd"
                    hide={!options.showAxisX}
                    domain={options.scaleTypeVertical === 'log' ? ['auto', 'auto'] : [axisYStartIndex, axisYEndIndex]}
                  />
                )}
                {options.orientation === 'vertical' && options.showLineOnChart && (
                  <XAxis
                    xAxisId="auxline"
                    dataKey="auxline"
                    type="number"
                    orientation="top"
                    allowDecimals={false}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    tickFormatter={DataFormater}
                    scale={options.scaleTypeVertical}
                    interval="preserveEnd"
                    hide={!options.showAxisX}
                    domain={options.scaleTypeVertical === 'log' ? ['auto', 'auto'] : [axis2YStartIndex, axis2YEndIndex]}
                  />
                )}
                {options.orientation === 'vertical' && options.showAreaOnChart && !options.showLineOnChart && (
                  <XAxis
                    xAxisId="auxline"
                    dataKey="auxarea"
                    type="number"
                    orientation="top"
                    allowDecimals={false}
                    tick={{ fontSize: options.fontSize, fill: tickFontColor }}
                    tickFormatter={DataFormater}
                    scale={options.scaleTypeVertical}
                    interval="preserveEnd"
                    hide={!options.showAxisX}
                    domain={options.scaleTypeVertical === 'log' ? ['auto', 'auto'] : [axis2YStartIndex, axis2YEndIndex]}
                  />
                )}
                {options.showAreaOnChart && options.orientation === 'vertical' && (
                  <Area
                    xAxisId="auxline"
                    type={options.lineType}
                    dataKey="auxarea"
                    fill={'url(#auxAreaColor_' + options.orientation + id + ')'}
                    stroke={borderColor}
                    strokeWidth={options.lineWidth}
                    hide={barProps[options.auxAreaTitle] === true}
                    activeDot={showActiveDot === true ? true : false}
                    dot={showDot === true ? { stroke: borderColor, strokeWidth: options.lineWidth } : false}
                    isAnimationActive={false}
                  />
                )}
                {options.showAreaOnChart && options.orientation === 'horizontal' && (
                  <Area
                    yAxisId="auxline"
                    type={options.lineType}
                    dataKey="auxarea"
                    fill={'url(#auxAreaColor_' + options.orientation + id + ')'}
                    stroke={borderColor}
                    strokeWidth={options.lineWidth}
                    hide={barProps[options.auxAreaTitle] === true}
                    activeDot={showActiveDot === true ? true : false}
                    dot={showDot === true ? { stroke: borderColor, strokeWidth: options.lineWidth } : false}
                    isAnimationActive={false}
                  />
                )}
                {options.orientation === 'vertical' && (
                  <Bar
                    dataKey="value"
                    background={{ fill: backgroundColor }}
                    stroke={backgroundColor}
                    strokeWidth={0}
                    isAnimationActive={false}
                  >
                    {cellFactory(
                      chartBars,
                      options.warningColor,
                      borderColor,
                      backgroundColor,
                      'horizontal',
                      options.lineWidth,
                      options.barMargin,
                      true
                    )}
                  </Bar>
                )}
                {options.orientation === 'horizontal' && (
                  <Bar
                    dataKey="value"
                    background={{ fill: backgroundColor }}
                    stroke={backgroundColor}
                    strokeWidth={0}
                    isAnimationActive={false}
                    onMouseDown={(bar) => {
                      if (!options.useZoom) {
                        setRefAreaLeft(bar.date);
                        setRefAreaLeftLabel(bar.id);
                        setStartZoom(true);
                      }
                    }}
                  >
                    {cellFactory(
                      chartBars,
                      options.warningColor,
                      borderColor,
                      backgroundColor,
                      'vertical',
                      options.lineWidth,
                      options.barMargin,
                      true
                    )}
                  </Bar>
                )}
                {options.showOverLine && (
                  <Line
                    type={options.lineType}
                    dataKey="value"
                    stroke={options.lineColor}
                    strokeWidth={options.lineWidth}
                    dot={showDot === true ? { stroke: borderColor, strokeWidth: options.lineWidth } : false}
                    isAnimationActive={false}
                  />
                )}
                {options.showLineOnChart && options.orientation === 'horizontal' && (
                  <Line
                    yAxisId="auxline"
                    type={options.lineType}
                    dataKey="auxline"
                    stroke={options.auxLineColor}
                    strokeWidth={options.lineWidth}
                    hide={barProps[options.auxLineTitle] === true}
                    activeDot={showActiveDot === true ? true : false}
                    dot={showDot === true ? { stroke: options.auxLineColor, strokeWidth: options.lineWidth } : false}
                    isAnimationActive={false}
                  />
                )}
                {options.showLineOnChart && options.orientation === 'vertical' && (
                  <Line
                    xAxisId="auxline"
                    type={options.lineType}
                    dataKey="auxline"
                    stroke={options.auxLineColor}
                    strokeWidth={options.lineWidth}
                    hide={barProps[options.auxLineTitle] === true}
                    activeDot={showActiveDot === true ? true : false}
                    dot={showDot === true ? { stroke: options.auxLineColor, strokeWidth: options.lineWidth } : false}
                    isAnimationActive={false}
                  />
                )}
                {options.showMetricOnChart && config.useMetricField && (
                  <Line
                    type={options.lineType}
                    dataKey="metric"
                    stroke={options.metricLineColor}
                    strokeWidth={options.lineWidth}
                    hide={barProps[options.metricTitle] === true}
                    strokeDasharray="5 5"
                    activeDot={showActiveDot === true ? true : false}
                    dot={
                      options.showDots === true
                        ? { stroke: options.metricLineColor, strokeWidth: options.lineWidth }
                        : false
                    }
                    isAnimationActive={false}
                  />
                )}
                {options.orientation === 'horizontal' && !options.useZoom && refAreaRightLabel > 0 ? (
                  <ReferenceArea
                    x1={refAreaLeftLabel}
                    x2={refAreaRightLabel}
                    stroke="#0d0deb"
                    strokeWidth={1}
                    fill="#0d0deb"
                    strokeOpacity={0.3}
                    label="zoom"
                  />
                ) : null}
                {options.useCartesianGrid && <CartesianGrid strokeDasharray="3 3" vertical={false} />}
                <Tooltip content={CustomTooltip} cursor={{ stroke: '#0d0deb', strokeWidth: 1 }} isAnimationActive={false} />
                {options.showLegend && (
                  <Legend
                    onMouseUp={handleLegendClick}
                    onMouseDown={handleLegendMouseEnter}
                    onMouseLeave={handleLegendMouseLeave}
                    payload={chartLegends}
                    wrapperStyle={{ fontSize: options.fontSize }}
					formatter={renderColorfulLegendText}
					iconSize={10}
                    margin={{ top: 5, left: 0, right: 0, bottom: 0 }}
                  />
                )}
                {options.orientation === 'horizontal' && options.useZoom && (
                  <Brush dataKey="dateLocale" height={15} stroke={borderColor} strokeWidth={1} />
                )}
              </ComposedChart>
            </ResponsiveContainer>
          </BarChartToolboxContainer>
        );
      }
    } else {
      return (
        <div>
          <div className="gf-form-error">{error1}</div>
        </div>
      );
    }
  } else {
    return (
      <div>
        <div className="gf-form-error">{error3}</div>
      </div>
    );
  }
};

function cellFactory(
  chartBars: GenericBarData[],
  warningColor: string,
  borderColor: string,
  backgroundColor: string,
  orientation: string,
  lineWidth: number,
  barGap: number,
  timeSeries: boolean
) {
  let borderWarn = '#8b8b8b';
  let radius = [4, 4, 0, 0];
  if (!timeSeries) {
    if (orientation === 'horizontal') {
      radius = [0, 4, 4, 0];
    }
  } else if (timeSeries && barGap >= 0) {
    radius = [2, 2, 0, 0];
    if (orientation === 'horizontal') {
      radius = [0, 2, 2, 0];
    }
  }
  if (!timeSeries) {
    return chartBars.map((entry, i) => (
      <Cell
        key={i}
        fill={chartBars[i].onfire === true ? warningColor : chartBars[i].color}
        radius={radius}
        stroke={chartBars[i].onfire === true ? borderWarn : borderColor}
        strokeWidth={1}
      ></Cell>
    ));
  } else if (timeSeries && barGap > 0) {
    return chartBars.map((entry, i) => (
      <Cell
        key={i}
        fill={chartBars[i].onfire === true ? warningColor : chartBars[i].color}
        radius={radius}
        stroke={backgroundColor}
        strokeWidth={lineWidth}
      ></Cell>
    ));
  } else {
    return chartBars.map((entry, i) => (
      <Cell
        key={i}
        fill={chartBars[i].onfire === true ? warningColor : chartBars[i].color}
        radius={0}
        stroke={chartBars[i].onfire === true ? warningColor : chartBars[i].color}
        strokeWidth={1}
      ></Cell>
    ));
  }
}

function handleClick(url: string, target: string) {
  if (url !== null && url !== '') {
    window.open(url, target);
  }
}

function zoomTime(actualZoom: number) {
  const d = Date.now();
  let to = new Date(d).toUTCString();
  let from = new Date(d - 86400000).toUTCString();
  switch (actualZoom) {
    case 2:
      from = new Date(d - 86400000 * 7).toUTCString();
      break;
    case 3:
      from = new Date(d - 86400000 * 30).toUTCString();
      break;
    case 4:
      from = new Date(d - 86400000 * 90).toUTCString();
      break;
    case 5:
      from = new Date(d - 86400000 * 180).toUTCString();
      break;
    case 6:
      from = new Date(d - 86400000 * 360).toUTCString();
      break;
  }
  zoom(from, to);
}

function zoom(refRight: string, refLeft: string) {
  var time_from = new Date(refLeft).getTime();
  var time_to = new Date(refRight).getTime();
  if (time_from < time_to) {
    let queryMap: UrlQueryMap = { from: time_from, to: time_to };
    getLocationSrv().update({
      partial: true,
      replace: true,
      query: queryMap,
    });
  } else if (time_from > time_to) {
    let queryMap: UrlQueryMap = { from: time_to, to: time_from };
    getLocationSrv().update({
      partial: true,
      replace: true,
      query: queryMap,
    });
  }
}

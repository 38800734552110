import { t, Trans } from '@lingui/macro';
import React from 'react';
import { useMount } from 'react-use';
import { connect, ConnectedProps } from 'react-redux';

import { CustomScrollbar } from '@grafana/ui';
import { StoreState } from 'app/types';

import UserProfileEditForm from './UserProfileEditForm';
import { UserTeams } from 'app/features/profile/UserTeams';
import {
  changeUserOrg,
  initUserProfilePage,
  revokeUserSession,
  updateUserProfile,
  changePassword
} from 'app/features/profile/state/actions';

export interface OwnProps {}

function mapStateToProps(state: StoreState) {
  const userState = state.user;
  const { user, teams, orgs, sessions, teamsAreLoading, orgsAreLoading, sessionsAreLoading, isUpdating } = userState;
  return {
    orgsAreLoading,
    sessionsAreLoading,
    teamsAreLoading,
    orgs,
    sessions,
    teams,
    isUpdating,
    user,
  };
}

const mapDispatchToProps = {
  initUserProfilePage,
  revokeUserSession,
  changeUserOrg,
  updateUserProfile,
  changePassword,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type Props = OwnProps & ConnectedProps<typeof connector>;

export const ProfileManager = ({
  showTitle,
  pluginTitle,
  isAdmin,
  orgsAreLoading,
  sessionsAreLoading,
  teamsAreLoading,
  initUserProfilePage,
  orgs,
  sessions,
  teams,
  isUpdating,
  user,
  revokeUserSession,
  changeUserOrg,
  updateUserProfile,
  changePassword,
}: Props) => {

  let username = '';
  if (user !== null) {
    username = String(user.login);
  }

  return (
    <CustomScrollbar autoHeightMin="100%" autoHeightMax="100%">
      <UserProfileEditForm
        updateProfile={updateUserProfile}
        isSavingUser={isUpdating}
        user={user}
        onChangePassword={changePassword}
        showTitle={showTitle}
        pluginTitle={pluginTitle}
        isAdmin={isAdmin}
      />
    </CustomScrollbar>
  );
};


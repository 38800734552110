import { css, cx } from '@emotion/css';
import React, { FC, useState } from 'react';

import { contextSrv } from 'app/core/services/context_srv';

import { NetMonitorTheme2 } from '@grafana/data';
import {
  Button,
  Field,
  FieldArray,
  Form,
  HorizontalGroup,
  IconButton,
  Input,
  InputControl,
  MultiSelect,
  Select,
  Switch,
  useStyles2,
  Badge,
  VerticalGroup,
} from '@grafana/ui';

import { useMuteTimingOptions } from '../../hooks/useMuteTimingOptions';
import { AmRouteReceiver, FormAmRoute } from '../../types/amroutes';
import { matcherFieldOptions } from '../../utils/alertmanager';
import {
  emptyArrayFieldMatcher,
  mapMultiSelectValueToStrings,
  mapSelectValueToString,
  optionalPositiveInteger,
  stringToSelectableValue,
  stringsToSelectableValues,
  commonGroupByOptions,
} from '../../utils/amroutes';
import { timeOptions } from '../../utils/time';

import { getFormStyles } from './formStyles';

export interface AmRoutesExpandedFormProps {
  onCancel: () => void;
  onSave: (data: FormAmRoute) => void;
  receivers: AmRouteReceiver[];
  routes: FormAmRoute;
}

export const AmRoutesExpandedForm: FC<AmRoutesExpandedFormProps> = ({ onCancel, onSave, receivers, routes }) => {
  const styles = useStyles2(getStyles);
  const isAdmin = contextSrv.isNetMonitorAdmin;
  const formStyles = useStyles2(getFormStyles);
  const [groupByOptions, setGroupByOptions] = useState(stringsToSelectableValues(routes.groupBy));
  const muteTimingOptions = useMuteTimingOptions();

  return (
    <Form defaultValues={routes} onSubmit={onSave}>
      {({ control, register, errors, setValue, watch }) => (
        <>
          {/* @ts-ignore-check: react-hook-form made me do this */}
          <input type="hidden" {...register('id')} />
          {/* @ts-ignore-check: react-hook-form made me do this */}
          <FieldArray name="object_matchers" control={control}>
            {({ fields, append, remove }) => (
              <>
                <VerticalGroup justify="flex-start" spacing="md">
                  <div className={styles.titleContainer}>
                    <div className={styles.titleTex}>Criterio de notificación</div>
                    <Button
                      variant="secondary"
                      className={styles.addMatcherBtn}
                      type="button"
                      icon="plus"
                      size="lg"
                      onClick={() => append(emptyArrayFieldMatcher)}
                      title={'Agregar criterio de notificación'}
                    />
                  </div>
                  {fields.length === 0 && (
                    <Badge
                      color="orange"
                      className={styles.noMatchersWarning}
                      icon="exclamation-circle"
                      text="Si no ingresa un criterio en particular, esta regla se utilizará en todos los identificadores."
                    />
                  )}
                  {fields.length > 0 && (
                    <div className={styles.matchersContainer}>
                      {fields.map((field, index) => {
                        const localPath = `object_matchers[${index}]`;
                        return (
                          <HorizontalGroup key={field.id} align="flex-start" height="65px">
                            <Field
                              label="Atributo"
                              invalid={!!errors.object_matchers?.[index]?.name}
                              error={errors.object_matchers?.[index]?.name?.message}
                            >
                              <Input
                                {...register(`${localPath}.name`, { required: 'Campo requerido' })}
                                defaultValue={field.name}
                                placeholder="atributo"
                              />
                            </Field>
                            <Field label={''}>
                              <InputControl
                                render={({ field: { onChange, ref, ...field } }) => (
                                  <Select
                                    {...field}
                                    className={styles.matchersOperator}
                                    onChange={(value) => onChange(value?.value)}
                                    options={matcherFieldOptions}
                                    aria-label="Operator"
                                  />
                                )}
                                defaultValue={field.operator}
                                control={control}
                                name={`${localPath}.operator` as const}
                                rules={{ required: { value: true, message: 'Operador requerido' } }}
                              />
                            </Field>
                            <Field
                              label="Valor"
                              invalid={!!errors.object_matchers?.[index]?.value}
                              error={errors.object_matchers?.[index]?.value?.message}
                            >
                              <Input
                                {...register(`${localPath}.value`, { required: 'Campo requerido' })}
                                defaultValue={field.value}
                                placeholder="valor"
                              />
                            </Field>
                            <IconButton
                              className={styles.removeButton}
                              tooltip="Eliminar criterio"
                              name={'trash'}
                              onClick={() => remove(index)}
                            />
                          </HorizontalGroup>
                        );
                      })}
                    </div>
                  )}
                </VerticalGroup>
              </>
            )}
          </FieldArray>
          <Field label="Canal de Notificación">
            {/* @ts-ignore-check: react-hook-form made me do this */}
            <InputControl
              render={({ field: { onChange, ref, ...field } }) => (
                <Select
                  aria-label="Contact point"
                  {...field}
                  className={formStyles.input}
                  onChange={(value) => onChange(mapSelectValueToString(value))}
                  options={receivers}
                />
              )}
              control={control}
              name="receiver"
            />
          </Field>
          {isAdmin && (
            <>
              <Field label="Continuar haciendo coincidir los nodos posteriores">
                <Switch id="continue-toggle" {...register('continue')} />
              </Field>
              <Field label="Sobrescribir grupo">
                <Switch id="override-grouping-toggle" {...register('overrideGrouping')} />
              </Field>
              {watch().overrideGrouping && (
                <Field
                  label="Grupo"
                  description="Este campo permite agrupar alertas cuando se recibe una notificación basada en identificador."
                >
                  <InputControl
                    render={({ field: { onChange, ref, ...field } }) => (
                      <MultiSelect
                        aria-label="Group by"
                        {...field}
                        allowCustomValue
                        className={formStyles.input}
                        onCreateOption={(opt: string) => {
                          setGroupByOptions((opts) => [...opts, stringToSelectableValue(opt)]);
                          // @ts-ignore-check: react-hook-form made me do this
                          setValue('groupBy', [...field.value, opt]);
                        }}
                        onChange={(value) => onChange(mapMultiSelectValueToStrings(value))}
                        options={[...commonGroupByOptions, groupByOptions]}
                      />
                    )}
                    control={control}
                    name="groupBy"
                  />
                </Field>
              )}
              <Field label="Sobrescribir temporizadores">
                <Switch id="override-timings-toggle" {...register('overrideTimings')} />
              </Field>
              {watch().overrideTimings && (
                <>
                  <Field
                    label="Tiempo de espera"
                    description="Tiempo de espera, asociado al grupo, para enviar la notificación inicial disparada por una nueva alerta."
                    invalid={!!errors.groupWaitValue}
                    error={errors.groupWaitValue?.message}
                  >
                    <>
                      <div className={cx(formStyles.container, formStyles.timingContainer)}>
                        <InputControl
                          render={({ field, fieldState: { invalid } }) => (
                            <Input
                              {...field}
                              className={formStyles.smallInput}
                              invalid={invalid}
                              aria-label="Group wait value"
                            />
                          )}
                          control={control}
                          name="groupWaitValue"
                          rules={{
                            validate: optionalPositiveInteger,
                          }}
                        />
                        <InputControl
                          render={({ field: { onChange, ref, ...field } }) => (
                            <Select
                              {...field}
                              className={formStyles.input}
                              onChange={(value) => onChange(mapSelectValueToString(value))}
                              options={timeOptions}
                              aria-label="Group wait type"
                            />
                          )}
                          control={control}
                          name="groupWaitValueType"
                        />
                      </div>
                    </>
                  </Field>
                  <Field
                    label="Intervalo de espera"
                    description="Tiempo de espera, asociado al grupo, para enviar un nuevo lote de alertas luego de que se envió la primera notificación con éxito."
                    invalid={!!errors.groupIntervalValue}
                    error={errors.groupIntervalValue?.message}
                  >
                    <>
                      <div className={cx(formStyles.container, formStyles.timingContainer)}>
                        <InputControl
                          render={({ field, fieldState: { invalid } }) => (
                            <Input
                              {...field}
                              className={formStyles.smallInput}
                              invalid={invalid}
                              aria-label="Group interval value"
                            />
                          )}
                          control={control}
                          name="groupIntervalValue"
                          rules={{
                            validate: optionalPositiveInteger,
                          }}
                        />
                        <InputControl
                          render={({ field: { onChange, ref, ...field } }) => (
                            <Select
                              {...field}
                              className={formStyles.input}
                              onChange={(value) => onChange(mapSelectValueToString(value))}
                              options={timeOptions}
                              aria-label="Group interval type"
                            />
                          )}
                          control={control}
                          name="groupIntervalValueType"
                        />
                      </div>
                    </>
                  </Field>
                  <Field
                    label="Intervalo de repetición"
                    description="Tiempo de espera para reenviar una alerta."
                    invalid={!!errors.repeatIntervalValue}
                    error={errors.repeatIntervalValue?.message}
                  >
                    <>
                      <div className={cx(formStyles.container, formStyles.timingContainer)}>
                        <InputControl
                          render={({ field, fieldState: { invalid } }) => (
                            <Input
                              {...field}
                              className={formStyles.smallInput}
                              invalid={invalid}
                              aria-label="Repeat interval value"
                            />
                          )}
                          control={control}
                          name="repeatIntervalValue"
                          rules={{
                            validate: optionalPositiveInteger,
                          }}
                        />
                        <InputControl
                          render={({ field: { onChange, ref, ...field } }) => (
                            <Select
                              {...field}
                              className={formStyles.input}
                              menuPlacement="top"
                              onChange={(value) => onChange(mapSelectValueToString(value))}
                              options={timeOptions}
                              aria-label="Repeat interval type"
                            />
                          )}
                          control={control}
                          name="repeatIntervalValueType"
                        />
                      </div>
                    </>
                  </Field>
                </>
              )}
            </>
          )}
          <Field
            label="Periodos sin notificación"
            data-testid="am-mute-timing-select"
            description="Permite desactivar notificaciones por un periodo de tiempo preconfigurado"
            invalid={!!errors.muteTimeIntervals}
          >
            <InputControl
              render={({ field: { onChange, ref, ...field } }) => (
                <MultiSelect
                  aria-label="Mute timings"
                  {...field}
                  className={formStyles.input}
                  onChange={(value) => onChange(mapMultiSelectValueToStrings(value))}
                  options={muteTimingOptions}
                />
              )}
              control={control}
              name="muteTimeIntervals"
            />
          </Field>
          <div className={styles.buttonGroup}>
            <Button
              variant="primary"
              type="submit"
              icon="save"
              size="lg"
              title={'Guardar modificaciones'}
            />
            <Button
              variant="secondary"
              type="button"
              icon="times"
              size="lg"
              onClick={onCancel}
              fill="outline"
              title={'Cerrar sin modificar'}
            />
          </div>
        </>
      )}
    </Form>
  );
};

const getStyles = (theme: NetMonitorTheme2) => {
  return {
    addMatcherBtn: css`
      margin-bottom: 16px;
    `,
    matchersContainer: css`
      background-color: ${theme.colors.background.secondary};
      padding: ${theme.spacing(0, 2)};
      width: 100%;
    `,
    matchersOperator: css`
      min-width: 140px;
    `,
    nestedPolicies: css`
      margin-top: 2px;
    `,
    removeButton: css`
      margin-left: ${theme.spacing(1)};
      margin-top: ${theme.spacing(2.5)};
    `,
    buttonGroup: css`
      margin: ${theme.spacing(6)} 0 ${theme.spacing(3.5)};

      & > * + * {
        margin-left: ${theme.spacing(1.5)};
      }
    `,
    noMatchersWarning: css`
      padding: ${theme.spacing(1)} ${theme.spacing(2)};
      margin-bottom: 16px;
    `,
    titleContainer: css`
      margin-top: 16px;
      display: inline-flex;
      width: 100%;
      justify-content: space-between;
      height: 24px;
    `,
    titleTex: css`
      margin-right: ${theme.spacing(2)};
      color: ${theme.colors.text.primary};
      font-size: 13px;
      font-weight: 500;
    `,
  };
};

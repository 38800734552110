const options = {
  name: 'cise',
  animate: 'end', // whether to show the layout as it's running
  refresh: 3, // number of ticks per frame; higher is faster but more jerky
  maxSimulationTime: 500, // max length in ms to run the layout
  ungrabifyWhileSimulating: false, // so you can't drag nodes during layout
  fit: false, // set by controller // on every layout reposition of nodes, fit the viewport
  padding: 20, // padding around the simulation
  idealInterClusterEdgeLengthCoefficient: 1.4,
  allowNodesInsideCircle: false,
  maxRatioOfNodesInsideCircle: 0.1,
  springCoeff: 0.45,
  nodeRepulsion: 4500,
  gravity: 0.25,
  gravityRange: 3.8,
  boundingBox: undefined as undefined, // constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
  nodeDimensionsIncludeLabels: false, // whether labels should be included in determining the space used by a node

  // layout event callbacks
  ready: function() {}, // on layoutready
  stop: function() {}, // on layoutstop

  // positioning options
  randomize: false, // use random node positions at beginning of layout
  avoidOverlap: true, // if true, prevents overlap of node bounding boxes
  handleDisconnected: true, // if true, avoids disconnected components from overlapping
  convergenceThreshold: 0.01, // when the alpha value (system energy) falls below this value, the layout stops
  nodeSeparation: 30, // extra spacing around nodes
  flow: undefined as undefined, // use DAG/tree flow layout if specified, e.g. { axis: 'y', minSeparation: 30 }
  alignment: undefined as undefined, // relative alignment constraints on nodes, e.g. function( node ){ return { x: 0, y: 1 } }
  gapInequalities: undefined as undefined, // list of inequality constraints for the gap between the nodes, e.g. [{"axis":"y", "left":node1, "right":node2, "gap":25}]

  // different methods of specifying edge length
  // each can be a constant numerical value or a function like `function( edge ){ return 2; }`
  edgeLength: undefined as undefined, // sets edge length directly in simulation
  edgeSymDiffLength: undefined as undefined, // symmetric diff edge length in simulation
  edgeJaccardLength: undefined as undefined, // jaccard edge length in simulation

  // infinite layout options
  infinite: false, // overrides all other options for a forces-all-the-time mode
  unconstrIter: 50,
  userConstIter: undefined as undefined, // initial layout iterations with user-specified constraints
  allConstIter: undefined as undefined,
  nodeSpacing: function(node: any) {
    return 10;
  },
};

export default options;

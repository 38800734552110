import { FieldSelectEditor } from './FieldSelectEditor';
import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { infoTablePanel } from './InfoTablePanel';

export const plugin = new PanelPlugin<TableOptions>(infoTablePanel).setPanelOptions(builder => {
  return builder

    .addTextInput({
      path: 'minWidth',
      name: 'Table mode min width',
      description: 'Switch to dropdown mode when panel width in minor to this threshold',
      category: ['General Settings'],
      defaultValue: '250',
    })

    .addCustomEditor({
      id: 'tableTagOption',
      path: 'tableTagOption',
      name: 'Table Tag Field',
      description: 'The field that will be used as Tag',
      defaultValue: 'tag',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'tableValueOption',
      path: 'tableValueOption',
      name: 'Table Value Field',
      description: 'The cell value to be asigned',
      defaultValue: 'value',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'tableStyleOption',
      path: 'tableStyleOption',
      name: 'Table Style Field',
      description: 'The value color: White, Grey, Black, Red, Yellow, Orange, Green and Blue',
      defaultValue: 'style',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'tableTitleOption',
      path: 'tableTitleOption',
      name: 'Cell Tooltip Field',
      description: 'The tooltip shows within the value',
      defaultValue: 'title',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'tableUrlOption',
      path: 'tableUrlOption',
      name: 'Cell Url Field',
      description: 'The Url to use in value content',
      defaultValue: 'url',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'tableUrlTargetOption',
      path: 'tableUrlTargetOption',
      name: 'Cell Url target Field',
      description: 'Target to use in value url',
      defaultValue: '_blank',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addTextInput({
      path: 'separator',
      name: 'Character to use as separator betwen tag and value',
      category: ['General Settings'],
      defaultValue: ':',
    })
    .addTextInput({
      path: 'drillDownLink',
      name: 'infoTable base URL',
      category: ['Link to other features'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'No existen opciones',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error al cargar el complemento',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});

import { css } from '@emotion/css';
import { Trans, t } from '@lingui/macro';
import React, { FC, useState } from 'react';

import { selectors } from '@grafana/e2e-selectors';
import {
  Button,
  Field,
  FieldSet,
  Form,
  Icon,
  Input,
  Tooltip,
  LinkButton,
  useStyles2,
  HorizontalGroup
} from '@grafana/ui';

import config from 'app/core/config';
import { UserDTO } from 'app/types';
import { ProfileUpdateFields } from './types';
import SharedPreferences from 'app/core/components/SharedPreferences/SharedPreferences';
import { PasswordField } from 'app/core/components/PasswordField/PasswordField';

export interface Props {
  user: UserDTO | null;
  isSavingUser: boolean;
  updateProfile: (payload: ProfileUpdateFields) => void;
  onChangePassword: (payload: ChangePasswordFields) => void;
  showTitle: boolean;
  pluginTitle: string;
  isAdmin: boolean;
}

const { disableLoginForm } = config;

export const UserProfileEditForm: FC<Props> = ({
  user,
  isSavingUser,
  updateProfile,
  onChangePassword, 
  showTitle,
  pluginTitle,
  isAdmin,
}) => {
  const onSubmitProfileUpdate = (data: ProfileUpdateFields) => {
    updateProfile(data);
  };
  const { ldapEnabled, authProxyEnabled, disableLoginForm } = config;
  const isExternalUser: boolean = (user && user.isExternal) ?? false;
  const [showChangePass, setShowChangePass] = useState<boolean>(false);
  const [showChangePref, setShowChangePref] = useState<boolean>(false);
  const authSource = isExternalUser && user && user.authLabels ? user.authLabels[0] : '';
  const lockMessage = authSource ? ` (Synced via ${authSource})` : '';
  const disabledEdit = disableLoginForm || isExternalUser;
  const styles = useStyles2(getStyles);
  const enableChangePass = (visible: boolean): void => {
    if (visible) {
      setShowChangePass(true);
      setShowChangePref(false);
    } else {
      setShowChangePass(false);
    }
  };
  const enableChangePref = (visible: boolean): void => {
    if (visible) {
      setShowChangePref(true);
      setShowChangePass(false);
    } else {
      setShowChangePref(false);
    }
  };
  const cannotChange = false;
  if (ldapEnabled || authProxyEnabled || disableLoginForm) {
    cannotChange = true;
  }

  return (
    <div>
      <div className={styles.title}>
        {showTitle && <div className={styles.titleText}>{pluginTitle}</div>}
        {!showTitle && (
          <div className={styles.titleText}>
            <Trans id="user-profile.title">Mi Perfil</Trans>
          </div>
        )}
        <div className={styles.buttonBox}>
          <Button 
            type="button"
            variant={!showChangePass ? 'primary' : 'secondary'}
            icon="keyboard"
            size="md"
            onClick={() => enableChangePass(!showChangePass)}
            title={t({ id: 'nav.profile/password', message: 'Cambiar clave' })}
          />
          <Button 
            type="button"
            variant={!showChangePref ? 'primary' : 'secondary'}
            icon="cog"
            size="md"
            onClick={() => enableChangePref(!showChangePref)}
            title={t({ id: 'nav.profile/settings', message: 'Preferencias de visualización' })}
          />
        </div>
      </div>
      {showChangePass && !cannotChange && (
        <div isLoading={!Boolean(user)}>
          {user ? (
            <>
              <h4 className="page-sub-heading">
                <Trans id="nav.profile/password">Cambiar clave</Trans>
              </h4>
              <Form onSubmit={onChangePassword}>
                {({ register, errors, getValues }) => {
                  return (
                    <>
                      <Field 
                        label={t({ id: 'common.prevkey', message: 'Clave actual' })} 
                        invalid={!!errors.oldPassword} error={errors?.oldPassword?.message}
                      >
                        <PasswordField
                          id="current-password"
                          autoComplete="current-password"
                          {...register('oldPassword', { 
                            required: `{t({ id: 'common.enterprevkey', message: 'Debe ingresar la clave anterior' })}`
                          })}
                        />
                      </Field>

                      <Field 
                        label={t({ id: 'common.newkey', message: 'Nueva clave' })} 
                        invalid={!!errors.newPassword} error={errors?.newPassword?.message}
                      >
                        <PasswordField
                          id="new-password"
                          autoComplete="new-password"
                          {...register('newPassword', {
                            required: `{t({ id: 'common.enternewkey', message: 'Debe ingresar una nueva clave' })}`,
                            validate: {
                              confirm: (v) => v === getValues().confirmNew || `{t({ id: 'common.keymismach', message: 'Las claves deben coincidir' })}`,
                              old: (v) => v !== getValues().oldPassword || `{t({ id: 'common.samekey', message: 'La nueva clave no puede ser igual a la anterior' })}`,
                            },
                          })}
                        />
                      </Field>

                      <Field 
                        label={t({ id: 'common.newkey', message: 'Nueva clave' })} 
                        invalid={!!errors.confirmNew} error={errors?.confirmNew?.message}
                      >
                        <PasswordField
                          id="confirm-new-password"
                          autoComplete="new-password"
                          {...register('confirmNew', {
                            required: `{t({ id: 'common.enternewkey', message: 'Debe ingresar una nueva clave' })}`,
                            validate: (v) => v === getValues().newPassword || `{t({ id: 'common.keymismach', message: 'Las claves deben coincidir' })}`,
                          })}
                        />
                      </Field>
                      <HorizontalGroup>
                        <Button 
                          type="button"
                          variant="primary"
                          size="md"
                          icon="save"
                          disabled={isSavingUser}
                          type="submit"
                          onClick={() => enableChangePass(!showChangePass)}
                          title={t({ id: 'common.save', message: 'Guardar' })}
                        />
                      </HorizontalGroup>
                    </>
                  );
                }}
              </Form>
            </>
          ) : null}
        </div>
      )}
      {showChangePass && cannotChange && (
        <div>
          <p>Ud no puede cambiar su password desde esta pagina. Consulte con soporte técnico.</p>
          <Button
            type="button"
            variant="secondary"
            icon="times"
            onClick={() => enableChangePass(!showChangePass)}
            fill="outline"
            title={t({ id: 'common.back', message: 'Cerrar' })}
          />
        </div>
      )}
      {!showChangePass && !showChangePref && (
        <Form onSubmit={onSubmitProfileUpdate} validateOn="onBlur">
          {({ register, errors }) => {
            return (
              <FieldSet>
                <Field
                  label={t({ id: 'user-profile.fields.name-label', message: 'Nombre' }) + lockMessage}
                  invalid={!!errors.name}
                  error={<Trans id="user-profile.fields.name-error">El campo nombre es obligatorio</Trans>}
                  disabled={disabledEdit}
                >
                  <Input
                    {...register('name', { required: true })}
                    id="edit-user-profile-name"
                    placeholder={t({ id: 'user-profile.fields.name-label', message: 'Nombre' })}
                    defaultValue={user?.name ?? ''}
                    suffix={<InputSuffix />}
                  />
                </Field>

                <Field
                  label={t({ id: 'user-profile.fields.email-label', message: 'Email' }) + lockMessage}
                  invalid={!!errors.email}
                  error={<Trans id="user-profile.fields.email-error">El campo e-mail es obligatorio</Trans>}
                  disabled={disabledEdit}
                >
                  <Input
                    {...register('email', { required: true })}
                    id="edit-user-profile-email"
                    placeholder={t({ id: 'user-profile.fields.email-label', message: 'Dirección de correo' })}
                    defaultValue={user?.email ?? ''}
                    suffix={<InputSuffix />}
                  />
                </Field>

                <Field
                  label={t({ id: 'user-profile.fields.username-label', message: 'Nombre de usuario' }) + lockMessage}
                  disabled={disabledEdit}
                >
                  <Input
                    {...register('login')}
                    id="edit-user-profile-username"
                    defaultValue={user?.login ?? ''}
                    placeholder={t({ id: 'user-profile.fields.username-label', message: 'Nombre de usuario' }) + lockMessage}
                    suffix={<InputSuffix />}
                  />
                </Field>
                <div className="gf-form-button-row">
                  <Button
                    type="button"
                    variant="primary"
                    size="md"
                    disabled={isSavingUser || disabledEdit}
                    data-testid={selectors.components.UserProfile.profileSaveButton}
                    title={t({ id: 'common.save', message: 'Guardar' })}
                    type="submit"
                    icon="save"
                  />
                </div>
              </FieldSet>
            );
          }}
        </Form>
      )}
      {!showChangePass && showChangePref && (
        <SharedPreferences resourceUri="user" />
      )}
    </div>
  );
};

export default UserProfileEditForm;

const getStyles = (theme: NetMonitorTheme2) => ({
  title: css`
    width: calc(100% - 32px);
    display: inline-flex;
    margin: 12px 0px 12px 0px;
  `,
  titleText: css`
    width: calc(100% - 90px);
    margin-right: ${theme.spacing(2)};
    font-size: 20px;
    font-weight: 500;
  `,
  buttonBox: css`
      display: flex;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    justify-content: space-around;
    margin-bottom: 0px;
    width: 90px;
  `,
});

const InputSuffix: FC = () => {
  return disableLoginForm ? (
    <Tooltip content="Los detalles de inicio de sesión no pueden ser modificados porque se administran en un sistema externo.">
      <Icon name="lock" />
    </Tooltip>
  ) : null;
};

import _ from 'lodash';
import { isPresent } from './utils/Utils';
import { PanelController } from '../panel/PanelController';
import { GraphDataElement, IntGraph, IntGraphEdge, IntGraphMetrics, IntGraphNode, GraphDataType } from '../types';

class GraphGenerator {
  controller: PanelController;

  constructor(controller: PanelController) {
    this.controller = controller;
  }

  _createNode(dataElements: GraphDataElement[]): IntGraphNode | undefined {
    if (!dataElements || dataElements.length <= 0) {
      return undefined;
    }
    var nodeX: number = dataElements[0].x;
    if (nodeX === undefined) {
      nodeX = null;
    }
    var nodeY: number = dataElements[0].y;
    if (nodeY === undefined) {
      nodeY = null;
    }
    var nodeName = dataElements[0].target;
    if (nodeName === '' || nodeName === undefined || nodeName === null) {
      nodeName = 'S/N';
    }
    var nodeLabel = dataElements[0].label;
    if (nodeLabel === '' || nodeLabel === undefined || nodeLabel === null) {
      nodeLabel = 'S/N';
    }
    var nodeSite = dataElements[0].site;
    if (nodeSite === '' || nodeSite === undefined || nodeSite === null) {
      nodeSite = 'S/D';
    }
    var nodeDescription = dataElements[0].node_description;
    if (nodeDescription === '' || nodeDescription === undefined || nodeDescription === null) {
      nodeDescription = 'S/D';
    }
    var nodeUser = dataElements[0].user;
    if (nodeUser === '' || nodeUser === undefined || nodeUser === null) {
      nodeUser = 'netmonitor';
    }
    var nodeSla = dataElements[0].node_sla;
    if (nodeSla === undefined || nodeSla === null) {
      nodeSla = 0;
    }
    var nodeGroup = dataElements[0].parent;
    if (nodeGroup === undefined || nodeGroup === null) {
      nodeGroup = '';
    }
    var nodeIcon = dataElements[0].node_icon;
    if (nodeIcon === undefined || nodeIcon === null) {
      nodeIcon = '';
    }
    var nodeType = dataElements[0].type;
    if (nodeType === undefined || nodeType === null) {
      nodeType = 'INTERNAL';
    }
    var nodeVisible: boolean = dataElements[0].node_visible;
    if (nodeVisible === undefined || nodeVisible === null) {
      nodeVisible = false;
    }
    const metrics: IntGraphMetrics = {};
    const node: IntGraphNode = {
      data: {
        id: nodeName,
        x: nodeX,
        y: nodeY,
        label: nodeLabel,
        site: nodeSite,
        type: nodeType,
        node_sla: nodeSla,
        parent: nodeGroup,
        node_icon: nodeIcon,
        node_visible: nodeVisible,
        node_description: nodeDescription,
        user: nodeUser,
        external_type: nodeType,
        metrics,
      },
    };
    return node;
  }

  _createMissingNodes(data: GraphDataElement[], nodes: IntGraphNode[]): IntGraphNode[] {
    const existingNodeNames = _.map(nodes, node => node.data.id);
    const expectedNodeNames = _.uniq(_.flatMap(data, dataElement => [dataElement.source, dataElement.target])).filter(
      isPresent
    );
    const missingNodeNames = _.difference(expectedNodeNames, existingNodeNames);
    const missingNodes = _.map(missingNodeNames, name => {
      const nodeX = 10;
      const nodeY = 10;
      let nodeLabel: string | '';
      let nodeSite: string | '';
      let nodeSla: number | 0;
      let nodeGroup: string | '';
      let nodeIcon: string | '';
      let nodeDescription: string | '';
      let nodeType: string | '';
      let nodeUser: string | 'netmonitor';
      let nodeVisible: boolean | false;
      let external_type: string | undefined;

      // derive node type
      let elementSrc = _.find(data, { source: name });
      let elementTrgt = _.find(data, { target: name });
      let elementPrnt = _.find(data, { parent: name });
      if (elementSrc && elementSrc.type === GraphDataType.EXTERNAL_IN) {
        nodeType = 'EXTERNAL_IN';
        external_type = elementSrc.data.type;
      } else if (elementTrgt && elementTrgt.type === GraphDataType.EXTERNAL_OUT) {
        nodeType = 'EXTERNAL_OUT';
        external_type = elementTrgt.data.type;
      } else if (elementSrc === elementPrnt) {
        nodeType = 'GROUP_EXP';
      } else {
        nodeType = 'INTERNAL';
      }
      var value: IntGraphNode = {
        data: {
          id: name,
          x: nodeX,
          y: nodeY,
          label: nodeLabel,
          site: nodeSite,
          type: nodeType,
          user: nodeUser,
          node_sla: nodeSla,
          parent: nodeGroup,
          node_icon: nodeIcon,
          node_visible: nodeVisible,
          node_description: nodeDescription,
          external_type: external_type,
          metrics: {},
        },
      };
      return value;
    });
    return missingNodes;
  }

  _createNodes(data: GraphDataElement[]): IntGraphNode[] {
    const filteredData = _.filter(
      data,
      dataElement =>
        dataElement.source !== dataElement.target ||
        (_.has(dataElement, 'target') && !_.has(dataElement, 'target')) ||
        (!_.has(dataElement, 'target') && _.has(dataElement, 'target'))
    );

    const targetGroups = _.groupBy(filteredData, 'target');

    const nodes = _.map(targetGroups, group => this._createNode(group)).filter(isPresent);

    // ensure that all nodes exist, even we have no data for them
    const missingNodes = this._createMissingNodes(filteredData, nodes);
    return _.concat(nodes, missingNodes);
  }

  _createEdge(dataElement: GraphDataElement): IntGraphEdge | undefined {
    const {
      x,
      y,
      label,
      site,
      source,
      target,
      node_description,
      node_sla,
      type,
      user,
      parent,
      node_icon,
      node_visible,
    } = dataElement;

    if (source === undefined || target === undefined) {
      console.error('source and target are necessary to create an edge', dataElement);
      return undefined;
    }

    const metrics: IntGraphMetrics = {};

    const edge: IntGraphEdge = {
      x: x,
      y: y,
      label: label,
      site: site,
      source: source,
      target: target,
      node_description: node_description,
      node_icon: node_icon,
      node_visible: node_visible,
      node_sla: node_sla,
      type: type,
      user: user,
      parent: parent,
      data: {
        x,
        y,
        label,
        site,
        source,
        target,
        node_description,
        node_icon,
        node_visible,
        node_sla,
        metrics,
      },
    };

    const {
      target_label,
      target_site,
      metricOne_actual,
      metricOne_max,
      metricOne_threshold,
      metricTwo_max,
      metricTwo_actual,
      metricTwo_threshold,
      traffic_in,
      traffic_out,
      description,
    } = dataElement.data;
    if (!_.isUndefined(metricOne_actual)) {
      metrics.metricOne_max = metricOne_actual;
      metrics.metricOne_actual = metricOne_actual;
    } else {
      metrics.metricOne_max = 0;
      metrics.metricOne_actual = 0;
    }
    if (!_.isUndefined(metricOne_max)) {
      metrics.metricOne_max = metricOne_max;
    }
    if (!_.isUndefined(metricOne_threshold)) {
      metrics.metricOne_threshold = metricOne_threshold;
    } else {
      metrics.metricOne_threshold = 0;
    }
    if (!_.isUndefined(metricTwo_actual)) {
      metrics.metricTwo_max = metricTwo_actual;
      metrics.metricTwo_actual = metricTwo_actual;
    } else {
      metrics.metricTwo_max = 0;
      metrics.metricTwo_actual = 0;
    }
    if (!_.isUndefined(metricTwo_max)) {
      metrics.metricTwo_max = metricTwo_max;
    }
    if (!_.isUndefined(metricTwo_threshold)) {
      metrics.metricTwo_threshold = metricTwo_threshold;
    } else {
      metrics.metricTwo_threshold = 0;
    }
    if (!_.isUndefined(traffic_in)) {
      metrics.traffic_in = traffic_in;
    } else {
      metrics.traffic_in = -1;
    }
    if (!_.isUndefined(traffic_out)) {
      metrics.traffic_out = traffic_out;
    } else {
      metrics.traffic_out = -1;
    }
    if (!_.isUndefined(label)) {
      metrics.target_label = target_label;
    }
    if (!_.isUndefined(site)) {
      metrics.target_site = target_site;
    }
    if (!_.isUndefined(description)) {
      metrics.description = description;
    }
    return edge;
  }

  _createEdges(data: GraphDataElement[]): IntGraphEdge[] {
    const filteredData = _(data)
      .filter(e => !!e.source)
      .filter(e => e.source !== e.target)
      .filter(e => e.target !== null || e.source !== null)
      .value();

    const edges = _.map(filteredData, element => this._createEdge(element));
    return edges.filter(isPresent);
  }

  _filterData(graph: IntGraph): IntGraph {
    const { filterEmptyConnections: filterData } = this.controller.getSettings(true);

    if (filterData) {
      const filteredGraph: IntGraph = {
        nodes: [],
        edges: [],
      };

      // filter empty connections
      filteredGraph.edges = _.filter(graph.edges, edge => _.size(edge.data.metrics) > 0);

      filteredGraph.nodes = _.filter(graph.nodes, node => {
        const id = node.data.id;

        // don't filter connected elements
        if (_.some(graph.edges, { source: id }) || _.some(graph.edges, { target: id })) {
          return true;
        }

        const metrics = node.data.metrics;
        if (!metrics) {
          return false; // no metrics
        }

        return (
          _.defaultTo(metrics.metricOne_actual, -1) >= 0 ||
          _.defaultTo(metrics.metricTwo_actual, -1) >= 0 ||
          _.defaultTo(metrics.traffic_in, -1) >= 0 ||
          _.defaultTo(metrics.traffic_out, -1) >= 0
        );
      });

      return filteredGraph;
    } else {
      return graph;
    }
  }

  generateGraph(graphData: GraphDataElement[]): IntGraph {
    const nodes = this._createNodes(graphData);

    const edges = this._createEdges(graphData);

    const graph: IntGraph = {
      nodes,
      edges,
    };

    const filteredGraph = this._filterData(graph);
    return filteredGraph;
  }
}

export default GraphGenerator;

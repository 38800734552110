import {
  FieldOverrideContext,
  FieldType,
  getFieldDisplayName,
  PanelPlugin,
  ReducerID,
  standardEditorsRegistry,
} from '@grafana/data';
import { TableFieldOptions } from '@grafana/schema';
import { TableCellDisplayMode } from '@grafana/ui';

import { PaginationEditor } from './PaginationEditor';
import { DataTablePanel } from './dataTablePanel';
import { PanelOptions, defaultPanelOptions, defaultPanelFieldConfig } from './models.gen';

const footerCategory = 'Datatable Options';

export const plugin = new PanelPlugin<PanelOptions, TableFieldOptions>(DataTablePanel)
  .setNoPadding()
  .useFieldConfig({
    useCustomConfig: (builder) => {
      builder
        .addBooleanSwitch({
          path: 'filterable',
          name: 'Show column filter',
          category: ['Datatable Options'],
          description: 'Enables/disables field filters in table',
          defaultValue: defaultPanelFieldConfig.filterable,
        })
        .addNumberInput({
          path: 'minWidth',
          name: 'Minimum column width',
          category: ['Default cell Options'],
          description: 'The minimum width for column auto resizing',
          settings: {
            placeholder: '100',
            min: 50,
            max: 500,
          },
          defaultValue: 100,
        })
        .addNumberInput({
          path: 'width',
          name: 'Column width',
          category: ['Default cell Options'],
          settings: {
            placeholder: 'auto',
            min: 1,
            max: 300,
          },
          defaultValue: defaultPanelFieldConfig.width,
        })
        .addNumberInput({
          path: 'fontSize',
          name: 'Text font size',
          category: ['Default cell Options'],
          settings: {
            placeholder: '12',
            min: 8,
            max: 20,
          },
          defaultValue: 12,
        })
        .addRadio({
          path: 'align',
          name: 'Column alignment',
          category: ['Default cell Options'],
          settings: {
            options: [
              { label: 'auto', value: 'auto' },
              { label: 'left', value: 'left' },
              { label: 'center', value: 'center' },
              { label: 'right', value: 'right' },
            ],
          },
          defaultValue: defaultPanelFieldConfig.align,
        })
        .addSelect({
          path: 'displayMode',
          name: 'Cell display mode',
          category: ['Default cell Options'],
          description: 'Color text, background, show as gauge, etc',
          settings: {
            options: [
              { value: TableCellDisplayMode.Auto, label: 'Auto' },
              { value: TableCellDisplayMode.ColorText, label: 'Color text' },
              { value: TableCellDisplayMode.ColorBackground, label: 'Color background (gradient)' },
              { value: TableCellDisplayMode.ColorBackgroundSolid, label: 'Color background (solid)' },
              { value: TableCellDisplayMode.GradientGauge, label: 'Gradient gauge' },
              { value: TableCellDisplayMode.LcdGauge, label: 'LCD gauge' },
              { value: TableCellDisplayMode.BasicGauge, label: 'Basic gauge' },
              { value: TableCellDisplayMode.JSONView, label: 'JSON View' },
              { value: TableCellDisplayMode.Image, label: 'Image' },
              { value: TableCellDisplayMode.Management, label: 'Management' },
            ],
          },
          defaultValue: defaultPanelFieldConfig.displayMode,
        })
        .addBooleanSwitch({
          path: 'inspect',
          name: 'Cell value inspect',
          category: ['Default cell Options'],
          description: 'Enable cell value inspection in a modal window',
          defaultValue: false,
          showIf: (cfg) => {
            return (
              cfg.displayMode === TableCellDisplayMode.Auto ||
              cfg.displayMode === TableCellDisplayMode.JSONView ||
              cfg.displayMode === TableCellDisplayMode.ColorText ||
              cfg.displayMode === TableCellDisplayMode.ColorBackground ||
              cfg.displayMode === TableCellDisplayMode.ColorBackgroundSolid
            );
          },
        })
        .addBooleanSwitch({
          path: 'hidden',
          name: 'Hide in table',
          category: ['Default cell Options'],
          defaultValue: undefined,
          hideFromDefaults: true,
        });
    },
  })
  .setPanelOptions((builder) => {
    builder
      .addBooleanSwitch({
        path: 'showToolbar',
        name: 'Show table toolbar',
        category: ['Datatable Options'],
        defaultValue: defaultPanelOptions.showToolbar,
      })
      .addBooleanSwitch({
        path: 'showTotal',
        name: 'Show number of rows in toolbar',
        category: ['Datatable Options'],
        showIf: config => config.showToolbar === true,
		defaultValue: true,
      })
      .addTextInput({
        path: 'totalTitle',
        name: 'Total title',
        category: ['Datatable Options'],
        defaultValue: 'Total:',
        showIf: config => config.showTotal === true,
      })
      .addBooleanSwitch({
        path: 'hideTable',
        name: 'Hide table',
        category: ['Datatable Options'],
        defaultValue: defaultPanelOptions.hideTable,
      })
      .addBooleanSwitch({
        path: 'showHeader',
        name: 'Show table header',
        category: ['Datatable Options'],
        defaultValue: defaultPanelOptions.showHeader,
      })
      .addBooleanSwitch({
        path: 'footer.show',
        category: ['Datatable Options'],
        name: 'Show table footer',
        defaultValue: defaultPanelOptions.footer?.show,
      })
      .addCustomEditor({
        id: 'footer.reducer',
        category: ['Datatable Options'],
        path: 'footer.reducer',
        name: 'Calculation',
        description: 'Choose a reducer function / calculation',
        editor: standardEditorsRegistry.get('stats-picker').editor as any,
        defaultValue: [ReducerID.sum],
        showIf: (cfg) => cfg.footer?.show,
      })
      .addMultiSelect({
        path: 'footer.fields',
        category: ['Datatable Options'],
        name: 'Fields',
        description: 'Select the fields that should be calculated',
        settings: {
          allowCustomValue: false,
          options: [],
          placeholder: 'All Numeric Fields',
          getOptions: async (context: FieldOverrideContext) => {
            const options = [];
            if (context && context.data && context.data.length > 0) {
              const frame = context.data[0];
              for (const field of frame.fields) {
                if (field.type === FieldType.number) {
                  const name = getFieldDisplayName(field, frame, context.data);
                  const value = field.name;
                  options.push({ value, label: name } as any);
                }
              }
            }
            return options;
          },
        },
        defaultValue: '',
        showIf: (cfg) => cfg.footer?.show,
      })
      .addCustomEditor({
        id: 'footer.enablePagination',
        path: 'footer.enablePagination',
        name: 'Hide scroll',
        description: 'Enable paging instead of scrolling',
        category: ['Datatable Options'],
        editor: PaginationEditor,
      })
      .addBooleanSwitch({
        path: 'showFooterInfo',
        name: 'Show footer info',
        description: 'Show n of n rows',
        category: ['Datatable Options'],
        showIf: config => config.footer.enablePagination === true,
        defaultValue: defaultPanelOptions.showFooterInfo,
      })

      .addTextInput({
        path: 'reportName',
        name: 'Report Name',
        description: 'Report Name use in export function. For Excel output must be 30 characters long max.',
        category: ['Report'],
        defaultValue: 'netMonitor_report',
        showIf: config => config.showToolbar === true,
      })
      .addTextInput({
        path: 'fileName',
        name: 'File Name',
        category: ['Report'],
        defaultValue: 'netMonitor_report',
        showIf: config => config.showToolbar === true,
      })
      .addRadio({
        path: 'orientation',
        name: 'Orientation',
        category: ['Report'],
        settings: {
          options: [
            { label: 'Portrait', value: 'l' },
            { label: 'Landscape', value: 'p' },
          ],
        },
        showIf: config => config.showToolbar === true,
        defaultValue: 'p',
      });
  });

import React from 'react';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { Icon } from '@grafana/ui';
import { ReportInfoOptions, ReportInfoData } from './types';
import { config } from '@grafana/runtime';
import './css/netmonitor-reportPanel.css';

interface Props extends PanelProps<ReportInfoOptions> {}

export const reportPanel: React.FC<Props> = ({ width, height, options, data, replaceVariables }) => {
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);
  const isDark = config.theme.isDark;
  const mainFont = options.mainFont;
  const secFont = options.secFont;
  const mainColor = options.mainColor;
  const secColor = options.secColor;
  let reportPanel_container = 'reportPanel_container';
  if (isDark) {
    reportPanel_container = 'reportPanel_container_dark';
  }
  let base_url = replaceVariables(options.drillDownLink);
  if (base_url !== null || base_url !== undefined) {
    base_url = '';
  }
  let separator = options.separator;
  if (separator === null || separator === undefined) {
    separator = '';
  }
  const reports: ReportData[] = [];

  if (width < 350 || height < 250) {
    return (
      <div>
        <div className="gf-form-error">{error4}</div>
      </div>
    );
  } else if (data.state !== 'Error') {
    if(data.series[0].length > 0) {
      data.series.forEach(series => {
        const timeVals: GraphSeriesValue[] = series.fields[0].values.toArray();
        for (let i = 0; i < timeVals.length; i++) {
          let reportData: ReportData = {
            id: i,
            tag: series.fields.find(field => field.name === options.reportTagOption)?.values.get(i),
            value: series.fields.find(field => field.name === options.reportValueOption)?.values.get(i),
            style: series.fields.find(field => field.name === options.reportStyleOption)?.values.get(i),
            title: series.fields.find(field => field.name === options.reportTitleOption)?.values.get(i),
            url: series.fields.find(field => field.name === options.reportUrlOption)?.values.get(i),
            target: series.fields.find(field => field.name === options.reportUrlTargetOption)?.values.get(i),
            icon: series.fields.find(field => field.name === options.reportIconOption)?.values.get(i),
			indicator: series.fields.find(field => field.name === options.reportIndicatorOption)?.values.get(i),
			change: series.fields.find(field => field.name === options.reportChangeOption)?.values.get(i),
          };
          if (reportData.style === null || reportData.style === undefined || reportData.style === '') {
            reportData.style = 'default';
          }
          if (isDark && reportData.style === 'default') {
            reportData.style = 'default_dark';
          }
          if (reportData.icon === null || reportData.icon === undefined || reportData.icon === '') {
            reportData.icon = 'file-graph';
          }
          if (reportData.indicator === null || reportData.indicator === undefined || reportData.indicator === '') {
            reportData.indicator = 'none';
          }
          if (reportData.title === null || reportData.title === undefined) {
            reportData.title = reportData.tag;
          }
          if (reportData.change === null || reportData.change === undefined || reportData.change === '') {
            reportData.change = 'none';
          }
		  if (reportData.target === null || reportData.target === undefined || reportData.target !== '_self') {
            reportData.target = '_blank';
          }
          if (reportData.url === null || reportData.url === undefined || String(reportData.url).length < 1) {
            reportData.url = '';
            reportData.target = '';
          }
          if (base_url !== '') {
            reportData.url = base_url + reportData.url;
          }
          reports.push(reportData);
        }
      });
      return <div className={reportPanel_container}>{rowFactory(reports, height, width, mainFont, secFont, mainColor, secColor)}</div>;
    } else {
        return (
          <div>
            <div className="gf-form-error">{error1}</div>
          </div>
        );
    } 
  } else {
    return (
      <div>
        <div className="gf-form-error">{error3}</div>
      </div>
    );
  }
};

function rowFactory(reportData: ReportData[], height: number, width: number, mainFont: number, secFont: number, mainColor: string, secColor: string) {
  const isDark = config.theme.isDark;
  var reportPanelRowTitle = 'reportPanel-rowTitle';
  var reportPanelRowsubTitle = 'reportPanel-rowsubTitle';
  var reportPanelRowValue = 'reportPanel-rowValue';
  var reportPanelRowContainer = 'reportPanel-rowContainer';
  const rows = reportData.length;
  const rowsHeight = height / rows;
  const containerHeight = rowsHeight - 5;
  const containerWidth = width - 5;
  
  if (isDark) {
    reportPanelRowTitle = 'reportPanel-rowTitle_dark';
    reportPanelRowsubTitle = 'reportPanel-rowsubTitle_dark'; 
    reportPanelRowValue = 'reportPanel-rowValue_dark';
    reportPanelRowContainer = 'reportPanel-rowContainer_dark';
  }

  return reportData.map(row => (
    <div key={row.id} className={reportPanelRowContainer} style={{ height: rowsHeight }}>
      <a
        rel="nofollow noopener noreferrer"
        title="Clic para generar informe"
        className="rowReportContainer"
        style={{ width: containerWidth, height: containerHeight }}
		href={row.url}
        target={row.target}
      >
        <span className="reportPanelIcon">
          <Icon
            name={row.icon}
            size="xxxl"
          />
        </span>
        <span className="reportPanelTitleContanier">
          <div className={reportPanelRowTitle} style={{ fontSize: mainFont, color: mainColor }}><b>{row.value}</b></div>
          <div className={reportPanelRowsubTitle} style={{ fontSize: secFont, color: secColor }}>{row.title}</div>
        </span>
        <span className="reportPanelTag">
          <span className={reportPanelRowValue + ' reportPanel_' + row.style} style={{ fontSize: secFont }}>{row.tag}</span>
        </span>
        {row.indicator !== 'none' && (
		  <span className="reportPanelIndicator">
            <Icon
              style={{ color: secColor }}
			  name={row.indicator}
              size="xl"
            />
          </span>
		)}
        {row.change !== 'none' && (
		  <span className="reportPanelTag2">
            <div className={reportPanelRowsubTitle} style={{ fontSize: secFont, color: secColor }}>{row.change}</div>
          </span>
		)}
      </a>
    </div>
  ));
}

import React, { Component, useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { VerticalGroup, Icon, useForceUpdate } from '@grafana/ui';
import { config, locationService, getTemplateSrv } from '@grafana/runtime';
import { SimpleOptions, NavbarData } from 'types';
import './css/navbarOptions.css';
import { toggleKioskMode } from 'app/core/navigation/kiosk';
import { contextSrv } from 'app/core/services/context_srv';
import { NavbarOption, GenericOption } from './NavbarButton';

interface NavbarPanelProps extends PanelProps<SimpleOptions> {}

export const NavbarPanel: React.FC<NavbarPanelProps> = ({ options, data, width, height, replaceVariables, id }) => {
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);

  const forceUpdate = useForceUpdate();
  const timeFrom = getTemplateSrv().replace('${__from}');
  const timeTo = getTemplateSrv().replace('${__to}');
  const timeFromString = new Date(Number(timeFrom));
  const timeToString = new Date(Number(timeTo));
  var time_window = timeFromString.toLocaleString() + ' \n' + timeToString.toLocaleString();

  const timeInterval = Number(timeTo) - Number(timeFrom);
  var timeWindow = Number(timeInterval) / 3600000;
  var actualWin = 6;
  if (timeWindow <= 24) {
    actualWin = 1;
  } else if (timeWindow <= 168) {
    actualWin = 2;
  } else if (timeWindow <= 720) {
    actualWin = 3;
  } else if (timeWindow <= 2160) {
    actualWin = 4;
  } else if (timeWindow <= 4320) {
    actualWin = 5;
  }
  const [actualZoom, setActualZoom] = useState(actualWin);
  const navbarOptions: NavbarData[] = [];
  const navbarOptions2: NavbarData[] = [];
  const currentLocation = window.location.pathname;

  const renderCount = useRef(0);
  useEffect(() => {
    renderCount.current = renderCount.current + 1;
  });
  let userRole = 'v';
  if (contextSrv.isNetMonitorAdmin) {
    userRole = 'a';
  } else if (contextSrv.isEditor) {
    userRole = 'e';
  }
  if (renderCount.current === 0 && options.useRoleVariable && options.roleVariable !== undefined && options.roleVariable !== '') {
    let queryMap = {
      [`var-${options.roleVariable}`]: userRole,
    };
    locationService.partial(queryMap, true);
    renderCount.current = renderCount.current + 1;
  }

  if (width < 250) {
    return (
      <div>
        <div className="gf-form-error">{error4}</div>
      </div>
    );
  } else if (data.state !== 'Error') {
    if(data.series[0].length > 0) {
      data.series.forEach(series => {
        const optionsVals: GraphSeriesValue[] = series.fields[0].values.toArray();
        var n = 0;
        for (let i = 0; i < optionsVals.length; i++) {
          let option: NavbarData = {
            id: i,
            type: series.fields.find(field => field.name === options.optionTypeOption)?.values.get(i),
            text: series.fields.find(field => field.name === options.optionTextOption)?.values.get(i),
            icon: series.fields.find(field => field.name === options.optionIconOption)?.values.get(i),
            url: series.fields.find(field => field.name === options.optionUrlOption)?.values.get(i),
            target: series.fields.find(field => field.name === options.optionTargetOption)?.values.get(i),
            primary: false,
            style: series.fields.find(field => field.name === options.optionStyleOption)?.values.get(i),
          };
          if (option.url === null || option.url === undefined) {
            option.url = ''
          } else {
		    option.url = replaceVariables(String(option.url));
			if (currentLocation === option.url) {
              option.primary = true;
			}
          }
          if (option.target === undefined || option.target === 'self' || option.target === '_self') {
            option.target = '_self';
          } else if (option.target === 'top' || option.target === '_top') {
            option.target = '_top';
          } else if (option.target === 'parent' || option.target === '_parent') {
            option.target = '_parent';
          } else {
            option.target = '_blank';
          }
          if (option.type === 'option') {
            navbarOptions.push(option);
          } else {
            navbarOptions2.push(option);
          }
          n = i;
        }
      });

      let isDark = config.theme.isDark || false;
      let options_container = 'options_container';
      let timeoptions_container = 'timeoptions_container';
      let classOption = 'option_disable_light option_white_disable';
      if (isDark) {
        options_container = 'options_container_dark';
        timeoptions_container = 'timeoptions_container_dark';
        classOption = 'option_disable_dark option_black_disable';
      }
      let titleIn = 'Zoom In';
      let titleOut = 'Zoom Out';
      if (actualZoom === 1) {
        titleIn = 'No es posible reducir ventana de tiempo';
        titleOut = 'Ampliar ventana de tiempo a ultimas 24hs';
      } else if (actualZoom === 2) {
        titleIn = 'Reducir ventana de tiempo a ultimas 3hs';
        titleOut = 'Ampliar ventana de tiempo a ultima semana';
      } else if (actualZoom === 3) {
        titleIn = 'Reducir ventana de tiempo a ultimas 24hs';
        titleOut = 'Ampliar ventana de tiempo a ultimo mes';
      } else if (actualZoom === 4) {
        titleIn = 'Reducir ventana de tiempo a ultima semana';
        titleOut = 'Ampliar ventana de tiempo a ultimo trimetre';
      } else if (actualZoom === 5) {
        titleIn = 'Reducir ventana de tiempo a ultimo mes';
        titleOut = 'Ampliar ventana de tiempo a ultimo semestre';
      } else if (actualZoom === 6) {
        titleIn = 'Reducir ventana de tiempo a ultimo trimetre';
        titleOut = 'Ampliar ventana de tiempo a ultimo año';
      } else if (actualZoom === 7) {
        titleIn = 'Reducir ventana de tiempo a ultimo semestre';
        titleOut = 'No es posible ampliar ventana de tiempo';
      }

      const onToggleTVMode = () => {
        toggleKioskMode();
      };

      return (
        <div>
          <div className={options_container}>
            {!options.displayOptionsHorizontal && (
              <div className="options_pagination">
                <div className="option_container_top">
                  <VerticalGroup spacing={'sm'}>{optionFactory(navbarOptions)}</VerticalGroup>
                </div>
                <div className="option_container_bottom">
                  <VerticalGroup spacing={'sm'}>{optionFactory2(navbarOptions2)}</VerticalGroup>
                </div>
              </div>
            )}
            {options.displayOptionsHorizontal && (
              <div className="options_pagination">
                {!contextSrv.isNetMonitorAdmin && (
                  <div className="logo_netmonitor">
                    <Icon name={'logo_degrades'} size="xl" />
                  </div>
                )}
                <div className="option_container_left">{optionFactory(navbarOptions)}</div>
                <div className="option_separator"></div>
                {options.showTimepickerOption && (
                  <div className={timeoptions_container}>
                    <div className="time_option">
                      <button 
                        className={classOption}
                        onClick={() => {
                          if (actualZoom > 1) {
                            zoomTime(actualZoom - 1);
                            setActualZoom(actualZoom - 1);
                          }
                        }}
                        title={titleIn}
                      >
                        <div className="option_icon">
                          <Icon name={'minus-circle'} size="md" />
                        </div>
                      </button>
                    </div>
                    <div className="time_option" title={time_window}>
                      <Icon name={'clock'} size="xl" />
                    </div>
                    <div className="time_option">
                      <button
                        className={classOption}
                        onClick={() => {
                          if (actualZoom < 7) {
                            zoomTime(actualZoom + 1);
                            setActualZoom(actualZoom + 1);
                          }
                        }}
                        title={titleOut}
                      >
                        <div className="option_icon">
                          <Icon name={'plus-circle'} size="md" />
                        </div>
                      </button>
                    </div>
                  </div>
                )}
                <div className="option_container_right">{optionFactory2(navbarOptions2)}</div>
                {options.showTimepickerOption && (
                  <div className="generic_option">
                    <button className={classOption} onClick={onToggleTVMode} title={'Cambiar modo de visualización'}>
                      <div className="option_icon">
                        <Icon name={'expand-arrows-alt'} size="lg" />
                      </div>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      );
    } else {
        return (
          <div>
            <div className="gf-form-error">{error1}</div>
          </div>
        );
    } 
  } else {
    return (
      <div>
        <div className="gf-form-error">{error3}</div>
      </div>
    );
  }
};

function optionFactory(navbarOptions: NavbarData[]) {
  return navbarOptions.map(option => (
    <NavbarOption
      id={option.id}
      key={option.text}
      title={option.text}
      icon={option.icon}
      url={option.url}
      target={option.target}
      primary={option.primary}
      style={option.style}
    />
  ));
}

function optionFactory2(navbarOptions2: NavbarData[]) {
  return navbarOptions2.map(option => (
    <GenericOption
      id={option.id}
      key={option.text}
      title={option.text}
      icon={option.icon}
      url={option.url}
      target={option.target}
      style={option.style}
    />
  ));
}

function zoomTime(actualZoom: number) {
  const d = Date.now();
  let to = new Date(d).toUTCString();
  let from = new Date(d - 10800000).toUTCString();
  switch (actualZoom) {
    case 2:
      from = new Date(d - 86400000).toUTCString();
      break;
    case 3:
      from = new Date(d - 86400000 * 7).toUTCString();
      break;
    case 4:
      from = new Date(d - 86400000 * 30).toUTCString();
      break;
    case 5:
      from = new Date(d - 86400000 * 90).toUTCString();
      break;
    case 6:
      from = new Date(d - 86400000 * 180).toUTCString();
      break;
    case 7:
      from = new Date(d - 86400000 * 360).toUTCString();
      break;
  }
  zoom(from, to);
}

function zoom(refRight: string, refLeft: string) {
  var time_from = new Date(refLeft).getTime();
  var time_to = new Date(refRight).getTime();
  if (time_from < time_to) {
    let queryMap: UrlQueryMap = { from: time_from, to: time_to };
    locationService.partial(queryMap, true);
  } else if (time_from > time_to) {
    let queryMap: UrlQueryMap = { from: time_to, to: time_from };
    locationService.partial(queryMap, true);
  }
}
import { css } from '@emotion/css';
import React, { FC, useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { NetMonitorTheme2 } from '@grafana/data';
import { Button, ConfirmModal, Modal, useStyles2, Icon } from '@grafana/ui';
import { locationService, getTemplateSrv } from '@grafana/runtime';
//import { contextSrv } from 'app/core/services/context_srv';
import { AlertManagerCortexConfig, Receiver } from 'app/plugins/datasource/alertmanager/types';

import { Authorize } from 'app/features/alerting/unified/components/Authorize';
import { useUnifiedAlertingSelector } from 'app/features/alerting/unified/hooks/useUnifiedAlertingSelector';
import { deleteReceiverAction } from 'app/features/alerting/unified/state/actions';
import { getAlertTableStyles } from 'app/features/alerting/unified/styles/table';
import { getNotificationsPermissions } from 'app/features/alerting/unified/utils/access-control';
import { isReceiverUsed } from 'app/features/alerting/unified/utils/alertmanager';
import { isVanillaPrometheusAlertManagerDataSource, NETMONITOR_RULES_SOURCE_NAME } from 'app/features/alerting/unified/utils/datasource';
import { makeAMLink } from 'app/features/alerting/unified/utils/misc';
import { extractNotifierTypeCounts } from 'app/features/alerting/unified/utils/receivers';
import { ProvisioningBadge } from 'app/features/alerting/unified/components/Provisioning';
import { ActionIcon } from 'app/features/alerting/unified/components/rules/ActionIcon';

import { NetMonitorReceiverForm } from './NetMonitorReceiverForm';
import { getNetMonitorVariableValue, setNetMonitorVariableValue } from '../utils';

interface Props {
  renderCount: string;
  showTitle: boolean;
  pluginTitle: string;
  config: AlertManagerCortexConfig;
  alertManagerName: string;
  isAdmin: boolean;
}

export const ReceiversTable: FC<Props> = ({ renderCount, showTitle, pluginTitle, config, alertManagerName, isAdmin }) => {
  const dispatch = useDispatch();
  const styles = useStyles2(getStyles);
  const tableStyles = useStyles2(getAlertTableStyles);
  const isVanillaAM = isVanillaPrometheusAlertManagerDataSource(alertManagerName);
  const permissions = getNotificationsPermissions(alertManagerName);
  const netmonitorNotifiers = useUnifiedAlertingSelector((state) => state.netmonitorNotifiers);

  const [showNewChannel, setShowNewChannel] = useState<boolean>(false);
  const [receiverToDelete, setReceiverToDelete] = useState<string>();
  const [receiverToEdit, setReceiverToEdit] = useState<Receiver>();
  const [showCannotDeleteReceiverModal, setShowCannotDeleteReceiverModal] = useState<boolean>(false);
  const [showCannotDelete, setShowCannotDelete] = useState<boolean>(false);
  const [showEditChannel, setShowEditChannel] = useState<boolean>(false);

  const rows = useMemo(
    () =>
      config.alertmanager_config.receivers?.map((receiver) => ({
        name: receiver.name,
        types: Object.entries(extractNotifierTypeCounts(receiver, netmonitorNotifiers.result ?? [])).map(
          ([type, count]) => {
            if (count > 1) {
              return `${type} (${count})`;
            }
            return type;
          }
        ),
        provisioned: receiver.netmonitor_managed_receiver_configs?.some((receiver) => receiver.provenance),
      })) ?? [],
    [config, netmonitorNotifiers.result]
  );

  const onClickDeleteReceiver = (receiverName: string): void => {
    if (isReceiverUsed(receiverName, config)) {
      setShowCannotDeleteReceiverModal(true);
    } else {
      setReceiverToDelete(receiverName);
    }
  };

  const deleteReceiver = () => {
    if (receiverToDelete) {
      dispatch(deleteReceiverAction(receiverToDelete, alertManagerName));
    } else {
      setShowCannotDelete(true);
    }
    setReceiverToDelete(undefined);
  };
  
  const enableEditChannel = (visible: boolean, idx: number): void => {
    if (visible) {
      setReceiverToEdit(config.alertmanager_config.receivers[idx]); 
      setShowEditChannel(true);
    } else {
      setReceiverToEdit(undefined);
      setShowEditChannel(false);
    }
  };

  const enableNewChannel = (visible: boolean): void => {
    if (visible) {
      setShowNewChannel(true);
    } else {
      setShowNewChannel(false);
    }
  };

  return (
    <div key={renderCount}>
      <div className={styles.section}>
        <div className={styles.header}>
          {!showEditChannel && showNewChannel && (
            <>
              <div className={styles.title}>
                <div className={styles.titleText}>Nuevo Canal de comunicación</div>
              </div>
              <Button
                type="button"
                size="md"
                variant={'destructive'}
                icon={'times'}
                title={'Cancelar'}
                onClick={() => enableNewChannel(!showNewChannel)}
              />
            </>
          )}
          {!showEditChannel && !showNewChannel && showTitle && (
            <div className={styles.title}>
              <div className={styles.titleText}>{pluginTitle}</div>
            </div>
          )}
          {!showEditChannel && !showTitle && isAdmin && (
            <Button 
              type="button"
              size="md"
              variant={!showNewChannel ? 'primary' : 'destructive'}
              icon={!showNewChannel ? 'plus' : 'times'}
              title={!showNewChannel ? 'Agregar canal' : 'Cancelar'}
              onClick={() => enableNewChannel(!showNewChannel)}
            />
          )}
          {showEditChannel && !showNewChannel && (
            <div className={styles.title}>
              <div className={styles.titleText}>
                {isAdmin ? 'Actualizar configuración' : 'Configuración del canal'}
              </div>
            </div>
          )}
          {showEditChannel && !showNewChannel && (
            <Button 
              type="button"
              size="md"
              variant={'destructive'}
              icon={'times'}
              title={'Cancelar'}
              onClick={() => enableEditChannel(!showEditChannel, undefined)}
            />
          )}
        </div>
        {config && !showEditChannel && showNewChannel && (
          <div id="NewChannel" className={styles.editWrapper}>
            <NetMonitorReceiverForm
              alertManagerSourceName={alertManagerName}
              config={config}
              isAdmin={isAdmin}
            />
          </div>
        )}
        {config && showEditChannel && !showNewChannel && (
          <div id="EditChannel" className={styles.editWrapper}>
            <NetMonitorReceiverForm
              existing={receiverToEdit}
              alertManagerSourceName={alertManagerName}
              config={config}
              isAdmin={isAdmin}
            />
          </div>
        )}
      </div>
      {config && !showEditChannel && !showNewChannel && (
        <div className={styles.tableContainer}>
          <table className={styles.table} data-testid="receivers-table">
            <colgroup>
              <col />
              <col />
              <Authorize actions={[permissions.update, permissions.delete]}>
                <col />
              </Authorize>
            </colgroup>
            <thead className={styles.thead}>
              <tr>
                <th className={styles.thTable}>Canal</th>
                <th className={styles.thTable}>Medio</th>
                <Authorize actions={[permissions.update, permissions.delete]}>
                  {isAdmin && !showEditChannel && !showTitle && (
                    <th className={styles.thTableButton}>
                      <Button 
                        type="button"
                        size="md"
                        variant={'primary'}
                        icon={'plus'}
                        title={'Agregar canal'}
                        onClick={() => enableNewChannel(!showNewChannel)}
                      />
                    </th>
                  )}
                </Authorize>
              </tr>
            </thead>
            <tbody>
              {!rows.length && (
                <tr className={styles.evenRow}>
                  <td colSpan={3}>No existen canales de comunicación definidos</td>
                </tr>
              )}
              {rows.map((receiver, idx) => (
                <tr key={receiver.name} className={idx % 2 === 0 ? styles.evenRow : styles.oddRow}>
                  <td className={styles.tdTable}>
                    {receiver.name} {receiver.provisioned && <ProvisioningBadge />}
                  </td>
                  <td className={styles.tdTable}>{receiver.types.join(', ')}</td>
                  <Authorize actions={[permissions.update, permissions.delete]}>
                    <td className={styles.actionsCell}>
                      {!isVanillaAM && !receiver.provisioned && isAdmin && (
                        <>
                          <Authorize actions={[permissions.update]}>
                            <ActionIcon
                              aria-label="edit"
                              data-testid="edit"
                              onClick={() => enableEditChannel(!showEditChannel, idx)}
                              tooltip="Editar canal de comunicación"
                              icon="pen"
                            />
                          </Authorize>
                          <Authorize actions={[permissions.delete]}>
                            <ActionIcon
                              aria-label="delete"
                              data-testid="delete"
                              onClick={() => onClickDeleteReceiver(receiver.name)}
                              tooltip="Borrar canal de comunicación"
                              icon="trash"
                           />
                          </Authorize>
                        </>
                      )}
                      {(isVanillaAM || receiver.provisioned) && !isAdmin && (
                        <Authorize actions={[permissions.update]}>
                          <ActionIcon
                            data-testid="view"
                            onClick={() => enableEditChannel(!showEditChannel, idx)}
                            tooltip="Ver canal de comunicación"
                            icon="envelope-alt"
                          />
                        </Authorize>
                      )}
                    </td>
                  </Authorize>
                  {!isVanillaAM && !isAdmin && (
                    <Authorize actions={[permissions.read]}>
                      <td className={styles.actionsCell}>
                        <ActionIcon
                          data-testid="view"
                          onClick={() => enableEditChannel(!showEditChannel, idx)}
                          tooltip="Ver canal de comunicación"
                          icon="envelope-alt"
                        />
                      </td>
                    </Authorize>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {!!showCannotDeleteReceiverModal && (
        <Modal
          isOpen={true}
          title="Imposible eliminar canal"
          onDismiss={() => setShowCannotDeleteReceiverModal(false)}
        >
          <p>
            Ud no puede eliminar este canal debido a que el mismo esta siendo utilizado por al menos una politica.
          </p>
          <Modal.ButtonRow>
            <Button 
              type="button"
              variant="secondary"
              size="md"
              onClick={() => setShowCannotDeleteReceiverModal(false)}
              fill="outline"
              icon="times"
              title={'Cerrar'}
            />
          </Modal.ButtonRow>
        </Modal>
      )}
      {!!showCannotDelete && (
        <Modal
          isOpen={true}
          title="Imposible eliminar canal"
          onDismiss={() => setShowCannotDeleteReceiverModal(false)}
        >
          <p>
            No fue posible eliminar el canal. Por favor vuelva a intentarlo recagando esta pagina.
          </p>
          <Modal.ButtonRow>
            <Button
              type="button"
              size="md"
              variant="secondary"
              onClick={() => setShowCannotDelete(false)}
              fill="outline"
              icon="times"
              title={'Cerrar'}
            />
          </Modal.ButtonRow>
        </Modal>
      )}
      {!!receiverToDelete && (
        <ConfirmModal
          isOpen={true}
          title="Eliminar canal de comunicación"
          body={`Esta seguro que desea eliminar el canal "${receiverToDelete}"?`}
          confirmText="Sí"
          dismissText="Cancelar"
          onConfirm={deleteReceiver}
          onDismiss={() => setReceiverToDelete(undefined)}
        />
      )}
    </div>
  );
};

const getStyles = (theme: NetMonitorTheme2) => ({
  section: css`
    margin: ${theme.spacing(1)};
  `,
  editWrapper: css`
    overflow-x: auto;
  `,
  header: css`
    font-size: 14px;
    display: flex;
  `,
  title: css`
    width: calc(100% - 80px);
    max-width: 665px;
    display: inline-flex;
  `,
  titleText: css`
    margin-right: ${theme.spacing(2)};
    font-size: 18px;
    font-weight: 500;
  `,
  tableContainer: css`
    width: 100%;
    padding: 1px;
    border-radius: 4px;
    border: 1px solid ${theme.colors.border.medium};
  `,
  table: css`
    width: calc(100% - 32px);
    background-color: ${theme.v1.colors.bg2};
    margin: 8px 16px 8px 16px;
  `,
  thead: css`
    height: 32px;
    font-size: 13px;
    font-weight: 600;
    background-color: ${theme.v1.colors.bg2};
    border-bottom: 1px solid ${theme.colors.border.weak};
}
  `,
  thTable: css`
    font-weight: 500;
    padding: 6px;
  `,
  thTableButton: css`
    font-weight: 500;
    padding-left: 6px;
  `,
  tdTable: css`
    font-weight: 400;
    padding: 6px;
  `,
  evenRow: css`
    background-color: ${theme.v1.colors.bg1};
    font-size: 12px;
  `,
  oddRow: css`
    background-color: ${theme.v1.colors.bg1};
    font-size: 12px;
  `,
  actionsCell: css`
    padding: 0px 8px;
    text-align: right;
    width: 1%;
    white-space: nowrap;
  `,
});

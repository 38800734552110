import { find } from 'lodash';
import { IconResource } from 'types';

export default {
  getAssetUrl(assetName: string, iconShape: string, defaultDirectory: boolean) {
    var baseUrl = 'public/img/icons';
	if (defaultDirectory) {
	  return baseUrl + '/assets/' + assetName + '.svg';
    } else {
	  return baseUrl + '/unicons/' + assetName + '.svg';
	}
  },

  getIconUrl(fileName: string) {
    var baseUrl = 'public/img/icons';
    if (fileName !== null) {
	  return baseUrl + '/assets/' + fileName.toLowerCase();
	}
  },

  getTypeSymbol(assetName: string, externalIcons: IconResource[], resolveName = true, iconShape: string) {
	if (assetName === null || assetName === '') {
      return this.getAssetUrl('default', iconShape, false);
    }

    if (!resolveName) {
      return this.getAssetUrl(assetName, iconShape, true);
    }

    const mapping = _.find(externalIcons, ({ pattern }) => {
      try {
        return new RegExp(pattern).test(assetName);
      } catch (error) {
        try {
          return new RegExp(pattern).test(assetName.toLowerCase());
        } catch (error) {
		  return false;
		}
      }
    });
    if (mapping) {
      return this.getAssetUrl(mapping.filename, iconShape, true);
    } else {
      return this.getAssetUrl(assetName, iconShape, false);
    }
  },
};

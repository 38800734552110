import { FieldType, PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { FieldSelectEditor } from './FieldSelectEditor';
import { PhotoPanel } from './PhotoPanel';

export const plugin = new PanelPlugin<SimpleOptions>(PhotoPanel).setPanelOptions(builder => {
  return builder
    .addCustomEditor({
      path: 'timeOption',
      name: 'Snap time',
      defaultValue: 'time',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'snapPathOption',
      path: 'snapPathOption',
      name: 'Snap URL path option',
      description: 'The path used to access snapshot html content',
      defaultValue: 'url',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'snapFileOption',
      path: 'snapFileOption',
      name: 'Snap local file name option',
      description: 'The file name used when save capture image',
      defaultValue: 'imagePathOrUrl',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      path: 'successOption',
      name: 'Snap success result',
      description: 'Indicates if local proxy could downloaded de image',
      defaultValue: 'success',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
	.addNumberInput({
      path: 'maxImages',
      name: 'Max images to show',
      category: ['General'],
      defaultValue: 16,
    })
	.addTextInput({
      path: 'errorImage',
      name: 'Query error image',
      category: ['General'],
      defaultValue: 'image-block.svg',
    })
	.addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener imágenes',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'No hay imágenes para mostrar',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error: al descargar imágen',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});

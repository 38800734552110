import { config } from '@grafana/runtime';

import { BlugeSearcher } from './bluge';
import { SQLSearcher } from './sql';
import { NetMonitorSearcher } from './types';

let searcher: NetMonitorSearcher | undefined = undefined;

export function getNetMonitorSearcher(): NetMonitorSearcher {
  if (!searcher) {
    const useBluge = config.featureToggles.panelTitleSearch;
    searcher = useBluge ? new BlugeSearcher() : new SQLSearcher();
  }
  return searcher!;
}

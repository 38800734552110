import { PanelPlugin } from '@grafana/data';
import { SflowDecoderOptions } from 'types';
import { FieldSelectEditor } from './FieldSelectEditor';
import { SflowDecoderPanel } from './sflowDecoderPanel';

export const plugin = new PanelPlugin<SflowDecoderOptions>(SflowDecoderPanel).setPanelOptions((builder) => {
  return builder
    // ----------------------- General Settings -----------------------
    .addBooleanSwitch({
      path: 'isNetflow',
      name: 'use NetFlow?',
      description: 'Use netFlow or Sflow protocol decoder',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showTitle',
      name: 'show customizable title',
      description: 'Enable title inside plugin',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addTextInput({
      name: 'Title',
      path: 'pluginTitle',
      defaultValue: 'Traps SNMP Recibidos',
      category: ['General Settings'],
      showIf: (options) => options.showTitle === true,
    })
    .addBooleanSwitch({
      path: 'showToolbar',
      name: 'Show filter toolbar',
      description: 'Show toolbar with filters',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showAgentFilter',
      name: 'Show Agent filter',
      category: ['General Settings'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'showL2Filter',
      name: 'Show L2 filter',
      description: 'Show L2 Source/Destination filter',
      category: ['General Settings'],
      defaultValue: true,
      showIf: (options) => options.isNetflow === false,
    })
    .addBooleanSwitch({
      path: 'hideControls',
      name: 'Hide Decoder Controls',
      category: ['General Settings'],
      defaultValue: false,
    })
    .addTextInput({
      path: 'filterDefaultValue',
      name: 'Filters default Value',
      category: ['General Settings'],
      defaultValue: 'Todos',
    })
    .addTextInput({
      path: 'agentVariable',
      name: 'Agent variable name',
      category: ['General Settings'],
      defaultValue: 'agent',
    })
    .addTextInput({
      path: 'l2SaddrVariable',
      name: 'L2 Source Address Variable',
      category: ['General Settings'],
      defaultValue: 'l2Saddr',
      showIf: (options) => options.isNetflow === false,
    })
    .addTextInput({
      path: 'l2DaddrVariable',
      name: 'L2 Destination Address Variable',
      category: ['General Settings'],
      defaultValue: 'l2Daddr',
      showIf: (options) => options.isNetflow === false,
    })
    .addTextInput({
      path: 'l3ProtoVariable',
      name: 'L3 Protol Variable',
      category: ['General Settings'],
      defaultValue: 'l3Proto',
    })
    .addTextInput({
      path: 'l4ProtoVariable',
      name: 'L4 Protol Variable',
      category: ['General Settings'],
      defaultValue: 'l4Proto',
    })
    .addTextInput({
      path: 'l3SaddrVariable',
      name: 'L3 Source Address Variable',
      category: ['General Settings'],
      defaultValue: 'l3Saddr',
    })
    .addTextInput({
      path: 'l3DaddrVariable',
      name: 'L3 Destination Address Variable',
      category: ['General Settings'],
      defaultValue: 'l3Daddr',
    })

    // ----------------------- Data Map Settings -----------------------
    .addCustomEditor({
      id: 'dateField',
      path: 'dateField',
      name: 'Date Field',
      category: ['Data Map Settings'],
      defaultValue: 'date',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'tsField',
      path: 'tsField',
      name: 'Time Stamp',
      category: ['Data Map Settings'],
      defaultValue: 'ts',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'ifidxField',
      path: 'ifidxField',
      name: 'Interface Idx Field',
      category: ['Data Map Settings'],
      defaultValue: 'ifidx',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'agentIPField',
      path: 'agentIPField',
      name: 'Agent IP Field',
      category: ['Data Map Settings'],
      defaultValue: 'agentIP',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'agentField',
      path: 'agentField',
      name: 'Agent Name Field',
      category: ['Data Map Settings'],
      defaultValue: 'agent',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'l2protoField',
      path: 'l2protoField',
      name: 'L2 protocol Field',
      category: ['Data Map Settings'],
      defaultValue: 'l2proto',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === false,
    })
    .addCustomEditor({
      id: 'l2SaddrField',
      path: 'l2SaddrField',
      name: 'L2 Source Address Field',
      category: ['Data Map Settings'],
      defaultValue: 'l2Saddr',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === false,
    })
    .addCustomEditor({
      id: 'l2SaddrOwnerField',
      path: 'l2SaddrOwnerField',
      name: 'L2 Source Address Owner Field',
      category: ['Data Map Settings'],
      defaultValue: 'l2SaddrOwner',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === false,
    })
    .addCustomEditor({
      id: 'l2DaddrField',
      path: 'l2DaddrField',
      name: 'L2 Destination Address Field',
      category: ['Data Map Settings'],
      defaultValue: 'l2Daddr',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === false,
    })
    .addCustomEditor({
      id: 'l2DaddrOwnerField',
      path: 'l2DaddrOwnerField',
      name: 'L2 Destination Address Owner Field',
      category: ['Data Map Settings'],
      defaultValue: 'l2DaddrOwner',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === false,
    })
    .addCustomEditor({
      id: 'vlanField',
      path: 'vlanField',
      name: 'VLAN Field',
      category: ['Data Map Settings'],
      defaultValue: 'vlan',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === false,
    })
    .addCustomEditor({
      id: 'ethertypeField',
      path: 'ethertypeField',
      name: 'Ethertype Field',
      category: ['Data Map Settings'],
      defaultValue: 'ethertype',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'l3protoField',
      path: 'l3protoField',
      name: 'L3 protocol Field',
      category: ['Data Map Settings'],
      defaultValue: 'l3proto',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'l3SaddrField',
      path: 'l3SaddrField',
      name: 'L3 Source Address Field',
      category: ['Data Map Settings'],
      defaultValue: 'l3Saddr',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'l3SaddrOwnerField',
      path: 'l3SaddrOwnerField',
      name: 'L3 Source Address Owner Field',
      category: ['Data Map Settings'],
      defaultValue: 'l3SaddrOwner',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === false,
    })
    .addCustomEditor({
      id: 'l3DaddrField',
      path: 'l3DaddrField',
      name: 'L3 Destination Address Field',
      category: ['Data Map Settings'],
      defaultValue: 'l3Daddr',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'l3DaddrOwnerField',
      path: 'l3DaddrOwnerField',
      name: 'L3 Destination Address Owner Field',
      category: ['Data Map Settings'],
      defaultValue: 'l3DaddrOwner',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === false,
    })
    .addCustomEditor({
      id: 'flagsField',
      path: 'flagsField',
      name: 'Flags Field',
      category: ['Data Map Settings'],
      defaultValue: 'flags',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === false,
    })
    .addCustomEditor({
      id: 'offsetField',
      path: 'offsetField',
      name: 'Offset Field',
      category: ['Data Map Settings'],
      defaultValue: 'offset',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === false,
    })
    .addCustomEditor({
      id: 'ttlField',
      path: 'ttlField',
      name: 'TTL Field',
      category: ['Data Map Settings'],
      defaultValue: 'ttl',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === false,
    })	
    .addCustomEditor({
      id: 'l4protoField',
      path: 'l4protoField',
      name: 'L4 protocol Field',
      category: ['Data Map Settings'],
      defaultValue: 'l4proto',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'l4SaddrField',
      path: 'l4SaddrField',
      name: 'L4 Source Port Field',
      category: ['Data Map Settings'],
      defaultValue: 'l4Sport',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'l4DaddrField',
      path: 'l4DaddrField',
      name: 'L4 Destination Port Field',
      category: ['Data Map Settings'],
      defaultValue: 'l4Dport',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'frameLenField',
      path: 'frameLenField',
      name: 'Frame Len Field',
      category: ['Data Map Settings'],
      defaultValue: 'framelen',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === false,
    })	
    .addCustomEditor({
      id: 'inBytesField',
      path: 'inBytesField',
      name: 'In Bytes Field',
      category: ['Data Map Settings'],
      defaultValue: 'inBytes',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === true,
    })	
    .addCustomEditor({
      id: 'outBytesField',
      path: 'outBytesField',
      name: 'Out Bytes Field',
      category: ['Data Map Settings'],
      defaultValue: 'outBytes',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === true,
    })	
    .addCustomEditor({
      id: 'inPaquetsField',
      path: 'inPaquetsField',
      name: 'In Packets Field',
      category: ['Data Map Settings'],
      defaultValue: 'inPackets',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === true,
    })
    .addCustomEditor({
      id: 'outPacketsField',
      path: 'outPacketsField',
      name: 'Out Packets Field',
      category: ['Data Map Settings'],
      defaultValue: 'outPackets',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === true,
    })
    .addCustomEditor({
      id: 'templateField',
      path: 'templateField',
      name: 'Template Field',
      category: ['Data Map Settings'],
      defaultValue: 'template',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === true,
    })
    .addCustomEditor({
      id: 'flowField',
      path: 'flowField',
      name: 'Flow ID Field',
      category: ['Data Map Settings'],
      defaultValue: 'flow',
      editor: FieldSelectEditor,
	  showIf: (config) => config.isNetflow === true,
    })
    // ----------------------- Card Settings -----------------------

    .addBooleanSwitch({
      path: 'showCardInfo',
      name: 'Display trap info',
      description: 'Show full info on trap or only Cartitle',
      category: ['Card Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'displayReadMore',
      name: 'Display Read more option',
      description: 'Show read more option',
      category: ['Card Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'useReadMore',
      name: 'Read more option',
      description: 'Show Read more option (vertical orientation)',
      showIf: (config) => config.legendPosition !== 'HORIZONTAL',
      category: ['Card Settings'],
      defaultValue: true,
    })
    .addRadio({
      path: 'legendPosition',
      defaultValue: 'bottom',
      name: 'Orientation',
      category: ['Card Settings'],
      settings: {
        options: [
          {
            value: 'HORIZONTAL',
            label: 'HORIZONTAL',
          },
          {
            value: 'VERTICAL',
            label: 'VERTICAL',
          },
          {
            value: 'VERTICAL_ALTERNATING',
            label: 'VERTICAL_ALTERNATING',
          },
        ],
      },
    }) 
    .addBooleanSwitch({
      path: 'flipLayout',
      name: 'Flip TimeLine layout',
      category: ['Card Settings'],
      defaultValue: false,
    })
    .addNumberInput({
      path: 'trapMinHeight',
      name: 'Card cage min height Size',
      category: ['Card Settings'],
      defaultValue: 15,
    })
    .addNumberInput({
      path: 'trapFontSize',
      name: 'Card title font size',
      category: ['Card Settings'],
      defaultValue: 12,
    })
    .addNumberInput({
      path: 'circleDimension',
      name: 'Card circle dimension',
      category: ['Card Settings'],
      defaultValue: 24,
    })
    .addBooleanSwitch({
      path: 'useIcon',
      name: 'Use VLAN ID (sFlow) / Template Number (Netflow) inside circle',
      category: ['Card Settings'],
      defaultValue: true,
    })
    // ----------------------- Link to other features -----------------------
    .addTextInput({
      path: 'drillDownLink',
      name: 'Device base URL',
      category: ['Link to other features'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'drillDownLinkAgent',
      name: 'Agent base URL',
      category: ['Link to other features'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'drillDownLinkIface',
      name: 'Iface base URL',
      category: ['Link to other features'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'No existen TRAPs para mostrar',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error al cargar el complemento',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});

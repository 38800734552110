import { css } from '@emotion/css';
import React, { FC } from 'react';

import { NetMonitorTheme2, intervalToAbbreviatedDurationString } from '@grafana/data';
import { Icon, useStyles2 } from '@grafana/ui';
import alertDef from 'app/features/alerting/state/alertDef';
import { alertStateToReadable, alertStateToState, getFirstActiveAt } from 'app/features/alerting/unified/utils/rules';
import { PromRuleWithLocation } from 'app/types/unified-alerting';
import { PromAlertingRuleState } from 'app/types/unified-alerting-dto';

import { AlertInstances } from '../AlertInstances';
import { getStyles } from '../UnifiedAlertList';
import { UnifiedAlertListOptions } from '../types';

import { contextSrv } from 'app/core/services/context_srv';

type UngroupedModeProps = {
  rules: PromRuleWithLocation[];
  options: UnifiedAlertListOptions;
};

const UngroupedModeView: FC<UngroupedModeProps> = ({ rules, options }) => {
  let isAdmin = false;
  if (contextSrv.isNetMonitorAdmin) {
    isAdmin = true;
  }
  if (options.editorCanAdmin && contextSrv.isEditor) {
    isAdmin = true;
  }
  const styles = useStyles2(getStyles);
  const stateStyle = useStyles2(getStateTagStyles);

  const rulesToDisplay = rules.length <= options.maxItems ? rules : rules.slice(0, options.maxItems);

  return (
    <>
      <ol className={styles.alertRuleList}>
        {rulesToDisplay.map((ruleWithLocation, index) => {
          const { rule, namespaceName, groupName } = ruleWithLocation;
          const firstActiveAt = getFirstActiveAt(rule);
          return (
            <li className={styles.alertRuleItem} key={`alert-${namespaceName}-${groupName}-${rule.name}-${index}`}>
              <div className={stateStyle.headerContainer}>
                <div className={stateStyle.header}>
                  <div className={stateStyle.icon}>
                    <Icon
                      name={alertDef.getStateDisplayModel(rule.state).iconClass}
                      className={stateStyle[alertStateToState(rule.state)]}
                      size={'xl'}
                    />
                  </div>
                  <div className={styles.alertName} title={rule.name}>
                    {rule.name}
                  </div>
                </div>
                <div className={styles.instanceContainer}>
                  <div className={styles.instanceDetails}>
                    <div className={styles.alertDuration}>
                      <span className={stateStyle[alertStateToState(rule.state)]}>
                        {alertStateToReadable(rule.state)}
                      </span>{' '}
                      {firstActiveAt && rule.state !== PromAlertingRuleState.Inactive && (
                        <>
                          por {' '}
                          <span>
                            {intervalToAbbreviatedDurationString({
                              start: firstActiveAt,
                              end: Date.now(),
                            })}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  {isAdmin && <AlertInstances alerts={ruleWithLocation.rule.alerts ?? []} options={options} />}
                </div>
              </div>
            </li>
          );
        })}
      </ol>
    </>
  );
};

const getStateTagStyles = (theme: NetMonitorTheme2) => ({
  common: css`
    width: 70px;
    text-align: center;
    align-self: stretch;

    display: inline-block;
    color: white;
    border-radius: 4px;
    border: 1px solid ${theme.colors.bg2};
    font-size: ${theme.v1.typography.size.sm};
    text-transform: capitalize;
    line-height: 1.2;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  section: css`
    border-radius: 4px;
    border: 1px solid ${theme.colors.bg2};
    margin: 12px;
  `,
  icon: css`
    margin-left: -28px;
    align-self: flex-start;
    width: 24px;
    height: 26px;
  `,
  headerContainer: css`
    width: 100%;
  `,
  header: css`
    display: inline-flex;
    margin: 0px 0px 0px 28px;
    align-self: flex-start;
    border-bottom: 1px solid ${theme.colors.border.medium};
    width: calc(100% - 28px);
    height: 24px;
  `,
  good: css`
    color: ${theme.colors.success.main};
    font-weight: 500;
  `,
  bad: css`
    color: ${theme.colors.error.main};
    font-weight: 500;
  `,
  warning: css`
    color: ${theme.colors.warning.main};
    font-weight: 500;
  `,
  neutral: css`
    color: ${theme.colors.secondary.main};
    font-weight: 500;
  `,
  info: css`
    color: ${theme.colors.primary.main};
    font-weight: 500;
  `,
});

export default UngroupedModeView;

import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from "react-dom"
import RGL, { WidthProvider } from "react-grid-layout";
import Iframe from 'react-iframe';

import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { config } from '@grafana/runtime';
import { Icon } from '@grafana/ui';
import styled from 'styled-components';
import ffmpeg from 'fluent-ffmpeg';

import { SimpleOptions, GenericSessionData, CameraData, CameraCanvas, GridItems } from './types';
import './css/VideoSession.css';

const ReactGridLayout = WidthProvider(RGL);

interface Props extends PanelProps<SimpleOptions> {}

export const VideoPanel: React.FC<Props> = ({ id, options, data, width, height, replaceVariables }) => {
  const welcomeBanner = replaceVariables(options.welcome);
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);
  const zoomLink = replaceVariables(options.zoomButtonLink);
  const zoomTitle = replaceVariables(options.zoomButtonTitle);
  const titleSart = replaceVariables(options.startButtonTitle);
  const titleStop = replaceVariables(options.stopButtonTitle);

  const isDark = config.theme.isDark || false;

  const videoID = useRef(null);
  const [renderCount, setRenderCount] = useState(1);
  const [videoRef, setVideoRef] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [widthSize, setWidthSize] = useState(width);
  const [heightSize, setHeightSize] = useState(height);
  const [videoError, setVideoError] = useState(null);
  const videoSessions: GenericSessionData[] = [];
  const cameraInfo: CameraData[] = [];

  let classContainer = 'react-player';
  let posterImg = 'public/img/loading-pulse.svg';
  if (isDark) {
	classContainer = 'react-player_dark';
	posterImg = 'public/img/loading-pulse_dark.svg';

  }
  const VideoWrapper  = styled.div`
    display: flex;
	height: 100%;
	width: 100%;
	flex-direction: column;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
  `;

  const Video = styled.video`
    flex-shrink: 1;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid #888;
	background-color: #fafafa;
  `;

  const Imagen = styled.img`
    display: block;
    -webkit-user-select: none;
    margin: auto;
    cursor: zoom-in;
	background-image: url("${posterImg}");
    background-color: hsl(0, 0%, 90%);
    transition: background-color 300ms;
	flex-shrink: 1;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid #888;
  `;

  const VideoTextError = styled.div`
    border: none;
    color: red;
    display: flex;
    font-size: 11px;
    font-weight: 500;
    height: 24px;
    justify-content: center;
    line-height: 24px;
	padding-top: 25px;
	width: 100%;
  `;

  const [loadingImage, setLoadingImage] = useState(posterImg);

  const handleLoadedData = () => {
    videoID.current.play();
  };

  const handleStop = () => {
	if (loadingImage !== posterImg) {
	  setLoadingImage(posterImg);
	}
    videoID.current.pause();
  };

  const handlePlayPauseClick = () => {
    const video = videoID.current;
	if (loadingImage !== posterImg) {
	  setLoadingImage(posterImg);
	}
    if (video.paused) {
      if (video.duration !== Infinity && !video.ended) {
	    video.play();
	  } else {
		if (widthSize !== width || heightSize !== height) {
		  setWidthSize(width);
		  setHeightSize(height);
		}
		video.load();
	  }
    } else {
      video.pause();
    }
  };

  const handleVideoError = (e) => {
    if (e.target.parentNode.networkState === 2) {
	  setVideoError('Ocurrió un error. Por favor, intenta nuevamente refrescando la pagina');
    } else {
      setVideoError('Error de conexión.');
	}
  };
  
  const changeImage = (index) => {
    const video = videoID.current;
	var newImage = new Image();
    newImage.src = cameraInfo[0].snapSrcUrl;
    newImage.onload = function() {
      video.src = newImage.src;
    };
  };

  if (data.state !== 'Error') {
    if(data.series[0].length > 0) {
	  if (videoRef !== null) {
		data.series.forEach(series => {
          const optionsVals: GraphSeriesValue[] = series.fields[0].values.toArray();
          for (let i = 0; i < optionsVals.length; i++) {
            let videoData: GenericSessionData = {
              id: i,
			  useProxy: series.fields.find(field => field.name === options.useProxyOption)?.values.get(i),
			  collector: series.fields.find(field => field.name === options.collectorOption)?.values.get(i),
              collectorVideoPort: series.fields.find(field => field.name === options.collectorPortOption)?.values.get(i),
			  requireAuthentication: series.fields.find(field => field.name === options.requireAuthenticationOption)?.values.get(i),
			  protocol: series.fields.find(field => field.name === options.proxyProtocolOption)?.values.get(i),
              hostName: series.fields.find(field => field.name === options.hostNameOption)?.values.get(i),
			  hostLocation: series.fields.find(field => field.name === options.hostLocationOption)?.values.get(i),
              host: series.fields.find(field => field.name === options.hostOption)?.values.get(i),
              port: series.fields.find(field => field.name === options.portOption)?.values.get(i),
              user: series.fields.find(field => field.name === options.userOption)?.values.get(i),
              pass: series.fields.find(field => field.name === options.passOption)?.values.get(i),
			  fps: series.fields.find(field => field.name === options.fpsOption)?.values.get(i),
			  videoPath: series.fields.find(field => field.name === options.videoPathOption)?.values.get(i),
			  snapPath: series.fields.find(field => field.name === options.snapPathOption)?.values.get(i),
			  snapFile: series.fields.find(field => field.name === options.snapFileOption)?.values.get(i),
            };
            if (videoData.useProxy === null || videoData.useProxy === undefined) {
              videoData.useProxy = false;
            }
            if (videoData.collector === null || videoData.collector === undefined || videoData.collector === '') {
              videoData.collector = 'none';
            }
            if (videoData.collectorVideoPort === null || videoData.collectorVideoPort === undefined || isNaN(videoData.collectorVideoPort)) {
              videoData.collectorVideoPort = 3030;
            }
            if (videoData.requireAuthentication === null || videoData.requireAuthentication === undefined) {
              videoData.requireAuthentication = false;
            }
            if (videoData.protocol === null || videoData.protocol === undefined || videoData.protocol === '') {
              videoData.protocol = 'http';
            } else {
		      videoData.protocol = videoData.protocol.toLowerCase();
		    }
            if (videoData.hostName === null || videoData.hostName === undefined || videoData.hostName === '') {
              videoData.hostName = 'none';
            }
            if (videoData.user === null || videoData.user === undefined || videoData.user === '') {
              videoData.user = 'none';
            } else {
			  videoData.user = encodeURIComponent(videoData.user);
			}
            if (videoData.pass === null || videoData.pass === undefined || videoData.pass === '') {
              videoData.pass = 'none';
            } else {
			  videoData.pass = encodeURIComponent(videoData.pass);
			}
            if (videoData.hostLocation === null || videoData.hostLocation === undefined || videoData.hostLocation === '') {
              videoData.hostLocation = 'none';
            }
            if (videoData.host === null || videoData.host === undefined || videoData.host === '') {
              videoData.host = 'none';
            }
            if (videoData.port === null || videoData.port === undefined || isNaN(videoData.port)) {
              videoData.port = 80;
            }
            if (videoData.fps === null || videoData.fps === undefined || isNaN(videoData.fps)) {
              videoData.fps = 1;
            }
            if (videoData.videoPath === null || videoData.videoPath === undefined || videoData.videoPath === '') {
              videoData.videoPath = 'none';
            }
            if (videoData.snapPath === null || videoData.snapPath === undefined || videoData.snapPath === '') {
              videoData.snapPath = 'none';
            }
            if (videoData.snapFile === null || videoData.snapFile === undefined || videoData.snapFile === '') {
              const timestamp = new Date().getTime();
			  videoData.snapFile = timestamp;
            }
			let videoSrcUrl = videoData.protocol + '://';
			let snapSrcUrl = videoData.protocol + '://';
			const protoLength = snapSrcUrl.length;
			let msgText = welcomeBanner;
			let videoError = null;
	        if (videoData.host !== 'none') {
		      if (videoData.useProxy && videoData.collector !== 'none') {
	  	        videoSrcUrl = videoSrcUrl + videoData.collector + ':' + videoData.collectorVideoPort.toString();
	            snapSrcUrl = snapSrcUrl + videoData.collector + ':' + videoData.collectorVideoPort.toString();
		        if (videoData.videoPath !== 'none') {
	              videoSrcUrl = videoSrcUrl + videoData.videoPath;
		        }
		        if (videoData.snapPath !== 'none') {
	              snapSrcUrl = snapSrcUrl + videoData.snapPath;
		        }
	          }
	          if (videoData.useProxy) {
	            videoSrcUrl = videoSrcUrl + '?ip=' + videoData.host + '&port=' + videoData.port.toString();
	            snapSrcUrl = snapSrcUrl + '?ip=' + videoData.host + '&port=' + videoData.port.toString();
		        if (options.videoTimeout > 0) {
	              videoSrcUrl = videoSrcUrl + '&timeout=' + options.videoTimeout.toString();
		        }
		        if (videoData.fps > 1) {
	              videoSrcUrl = videoSrcUrl + '&fps=' + videoData.fps.toString();
		       }
		        if (options.saveFile) {
		          snapSrcUrl = snapSrcUrl + '&file=' + videoData.snapFile
		        }
		      } else {
	            videoSrcUrl = videoSrcUrl + videoData.host + ':' + videoData.port.toString();
		        snapSrcUrl = snapSrcUrl + videoData.host + ':' + videoData.port.toString();
		        if (videoData.videoPath !== 'none') {
	              videoSrcUrl = videoSrcUrl + videoData.videoPath
		        }
		        if (videoData.snapPath !== 'none') {
	              snapSrcUrl = snapSrcUrl + videoData.snapPath
		        }
		        if (options.saveFile) {
		          snapSrcUrl = snapSrcUrl + '&file=' + videoData.snapFile
		        }
		      }
			  if (videoData.requireAuthentication) {
			    if (videoData.useProxy) {
	              videoSrcUrl = videoSrcUrl + '&auth=basic';
	              snapSrcUrl = snapSrcUrl + '&auth=basic';
			    } else if (videoData.user !== 'none' && videoData.pass !== 'none') {
			      videoSrcUrl = videoSrcUrl.slice(0, protoLength) + videoData.user + ':' + videoData.pass + '@' + videoSrcUrl.slice(protoLength);
				  snapSrcUrl = snapSrcUrl.slice(0, protoLength) + videoData.user + ':' + videoData.pass + '@' + snapSrcUrl.slice(protoLength);
			    }
		      }
			}
			
		    if ((videoData.collector === 'none' && videoData.useProxy) ||
 	          (videoData.host === 'none' && !videoData.useProxy)) {
		      videoError = error3;
		    }

            if (videoData.hostName !== 'none') {
	          msgText = msgText + ' en ' + videoData.hostName;
	        }
            if (videoData.hostLocation !== 'none') {
	          msgText = msgText + ' - ' + videoData.hostLocation;
	        }
			let camera: CameraData = {
              id: i,
		      videoSrcUrl: videoSrcUrl,
		      snapSrcUrl: snapSrcUrl,
			  videoError: null,
			  videoRef: false,
			  msgText: msgText,
			  auth: videoData.requireAuthentication,
			  user: videoData.user,
			  pass: videoData.pass
		    };
			if (videoSrcUrl.length > protoLength && snapSrcUrl.length > protoLength) {
			  camera.videoRef = true;
			}
			videoSessions.push(videoData);
			cameraInfo.push(camera);
          }
        });

		if (widthSize !== width || heightSize !== height) {
		  setWidthSize(width);
		  setHeightSize(height);
		}
	  }

	  if (width < 250) {
		return (
		  <div>
			<div className="gf-form-error">{error4}</div>
		  </div>
		);
	  } else if (cameraInfo.length === 1 && cameraInfo[0].videoRef) {
	    if (options.takeSnap) {
		  return (
			<VideoWrapper key={cameraInfo[0].id}>
			  {cameraInfo[0].videoError ? ( 
			    <VideoTextError><Icon name="video-slash" size="lg" />{cameraInfo[0].videoError}</VideoTextError>
			  ) : (
				<iframe 
				  id={cameraInfo[0].id}
				  url={cameraInfo.snapSrcUrl}
				  className={classContainer}
				  width={widthSize}
				  height={heightSize}
				/>
			  )}
			</VideoWrapper>
		  );
	    } else {
		    return (
			  <VideoWrapper>
			    {cameraInfo[0].videoError ? ( 
				  <VideoTextError><Icon name="video-slash" size="lg" />{cameraInfo[0].videoError}</VideoTextError>
			    ) : (
			      <Video
				    ref={videoID}
				    className={classContainer}
				    width={widthSize}
				    height={heightSize}
				    controls={options.showControls ? true : false}
				    autoplay
				    playsinline
				    poster={options.showControls ? null : loadingImage}
				    muted
				    volume={0}
				    onClick={options.showControls ? null : handlePlayPauseClick}
				    onLoadedData={handleLoadedData}
					onError={handleVideoError}
				    onProgress={(event) => {
				      const video = event.target;
					  if (video.buffered.length > 0) {
					    const loadedTime = video.buffered.end(0);
					    if (loadedTime * 1.05 >= options.videoTimeout) {
						  handleStop;
					    }
				      }
				    }}
			      >
				    <source src={cameraInfo[0].videoSrcUrl} type="video/webm" />
				    <source src={cameraInfo[0].videoSrcUrl} type="video/mp4" />
				  </Video>
			    )}
			  </VideoWrapper>
		    );
		}
	  } else if (cameraInfo.length > 1) {
		const numCols = Math.ceil(Math.sqrt(cameraInfo.length));
		const numRows = Math.ceil(cameraInfo.length / numCols);
		const rowHeight = Math.floor(heightSize / Math.ceil(cameraInfo.length / numRows));
		const colWidth = Math.floor(widthSize / numCols);
		const cameras: CameraCanvas [] = [];
		const gridItems: GridItem [] = [];
		for (let i = 0; i < cameraInfo.length; i++) {
		  if (cameraInfo[i] !== null) {
		    let camera: CameraCanvas = {
		      i: i,
		      url: cameraInfo[i].snapSrcUrl,
		    };
		    let gridItem: GridItems = {
		      i: i.toString(),
		      x: i % numRows,
			  y: Math.floor(i / numRows),
		      w: 1, 
		      h: 1,
		    };
		  cameras.push(camera);
		  gridItems.push(gridItem);
 	      }
		}
	    return (
		  <ReactGridLayout
			className="video_wrapper"
			rowHeight={rowHeight}
			width={widthSize}
			cols={numCols}
			layout={gridItems}
			isDraggable={false}
			isResizable={false}
		  >
			{cameras.map((camera, index) => (
			  <div key={camera.i}>
				<Imagen
				  className={classContainer}
				  src={camera.url}
				  alt={`Imagen ${camera.i}`}
				  onError={handleVideoError}
			    />
			  </div>
			))}
		  </ReactGridLayout>
		);
	  }
    } else {
      return (
        <div>
          <div className="gf-form-error">{error2}</div>
        </div>
      );
    } 
  } else {
    return (
      <div>
        <div className="gf-form-error">{error1}</div>
      </div>
    );
  }
}
import React, { FC, useMemo, useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { PanelProps, NetMonitorTheme2 } from '@grafana/data';
import { Alert, LoadingPlaceholder, useStyles2, ConfirmModal, Modal, Icon } from '@grafana/ui';
import { ContactOptions } from 'types';
import './css/netmonitor-contactManager.css';
import { css } from '@emotion/css';

import { contextSrv } from 'app/core/services/context_srv';
import { useUnifiedAlertingSelector } from 'app/features/alerting/unified/hooks/useUnifiedAlertingSelector';
import { fetchAlertManagerConfigAction, fetchNetMonitorNotifiersAction } from 'app/features/alerting/unified/state/actions';
import { NETMONITOR_RULES_SOURCE_NAME } from 'app/features/alerting/unified/utils/datasource';
import { initialAsyncRequestState } from 'app/features/alerting/unified/utils/redux';

import { ReceiversTable } from './receivers/ReceiversTable';

import { NetMonitorReceiverForm } from './receivers/NetMonitorReceiverForm';

export const ContactManagerPanel: React.FC<Props> = ({ options, replaceVariables, id }) => {
  const styles = useStyles2(getStyles);
  let isAdmin = false;
  if (contextSrv.isNetMonitorAdmin) {
    isAdmin = true;
  }
  if (options.editorCanAdmin && contextSrv.isEditor) {
    isAdmin = true;
  }
  const pluginTitle = replaceVariables(options.pluginTitle) || 'Canales de Notificación existentes';
  const showTitle = options.showTitle;
  const alertManagerSourceName = replaceVariables(options.alertManagerName) || 'netmonitor';
  const dispatch = useDispatch();
  const configRequests = useUnifiedAlertingSelector((state) => state.amConfigs);

  const {
    result: config,
    loading,
    error,
  } = (alertManagerSourceName && configRequests[alertManagerSourceName]) || initialAsyncRequestState;
  const netmonitorNotifiers = useUnifiedAlertingSelector((state) => state.netmonitorNotifiers);

  useEffect(() => {
    if (alertManagerSourceName) {
      dispatch(fetchAlertManagerConfigAction(alertManagerSourceName));
    }
  }, [alertManagerSourceName, dispatch, true]);

  useEffect(() => {
    if (
      alertManagerSourceName === NETMONITOR_RULES_SOURCE_NAME &&
      !(netmonitorNotifiers.result || netmonitorNotifiers.loading || netmonitorNotifiers.error)
    ) {
      dispatch(fetchNetMonitorNotifiersAction());
    }
  }, [alertManagerSourceName, dispatch, netmonitorNotifiers]);

  if (!alertManagerSourceName) {
    return <div>Error al cargar complemento</div>;
  }

  return (
    <div className={styles.section} key={String(id)}>
      {error && !loading && (
        <Alert severity="error" title="Error al cargar datos desde el gestor de alertas">
          {error.message || 'Error desconocido'}
        </Alert>
      )}
      {loading && !config && <LoadingPlaceholder text="Cargando..." />}
      {!loading && config && (
        <ReceiversTable
          renderCount={String(id)}
          showTitle={showTitle}
          pluginTitle={pluginTitle}
          config={config}
          alertManagerName={alertManagerSourceName}
          isAdmin={isAdmin}
        />
      )}
    </div>
  );
};
const getStyles = (theme: NetMonitorTheme2) => ({
  section: css`
    margin: ${theme.spacing(2)};
  `,
});

import { PanelPlugin } from '@grafana/data';
import { TimeLineOptions } from 'types';
import { FieldSelectEditor } from './FieldSelectEditor';
import { TimeLinePanel } from './TimeLinePanel';

export const plugin = new PanelPlugin<TimeLineOptions>(TimeLinePanel).setPanelOptions((builder) => {
  return builder
    .addBooleanSwitch({
      path: 'showTitle',
      name: 'show customizable title',
      description: 'Enable title inside plugin',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addTextInput({
      name: 'Title',
      path: 'pluginTitle',
      defaultValue: 'Traps SNMP Recibidos',
      category: ['General Settings'],
      showIf: (options) => options.showTitle === true,
    })
    .addCustomEditor({
      id: 'titleField',
      path: 'titleField',
      name: 'Date Field',
      category: ['Data Map Settings'],
      defaultValue: 'title',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'cardTitleField',
      path: 'cardTitleField',
      name: 'Title Field',
      category: ['Data Map Settings'],
      defaultValue: 'cardtitle',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'cardTitleUrlField',
      path: 'cardTitleUrlField',
      name: 'Title Url Field',
      category: ['Data Map Settings'],
      defaultValue: 'cardtitle_url',
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'usecardSubtitle',
      name: 'Use Subtitle',
      description: 'Use subtitle inside timeline',
      category: ['Data Map Settings'],
      defaultValue: true,
    })
    .addCustomEditor({
      id: 'cardSubtitleField',
      path: 'cardSubtitleField',
      name: 'SubTitle Field',
      category: ['Data Map Settings'],
      showIf: (config) => config.usecardSubtitle === true,
      defaultValue: 'subtitle',
      editor: FieldSelectEditor,
    })
    .addTextInput({
      path: 'subtitleTitle',
      name: 'SubTitle Title',
      category: ['Data Map Settings'],
      showIf: (config) => config.usecardSubtitle === true,
      defaultValue: 'Resumen:',
    })
    .addBooleanSwitch({
      path: 'useIcon',
      name: 'Use Icons',
      description: 'Use icons inside timeline',
      category: ['Data Map Settings'],
      defaultValue: true,
    })
    .addCustomEditor({
      id: 'iconField',
      path: 'iconField',
      name: 'Icon Field',
      category: ['Data Map Settings'],
      showIf: (config) => config.useIcon === true,
      defaultValue: 'icon',
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'useUrl',
      name: 'Use URLs',
      category: ['Data Map Settings'],
      defaultValue: false,
    })
    .addCustomEditor({
      id: 'urlField',
      path: 'urlField',
      name: 'URL Field',
      category: ['Data Map Settings'],
      showIf: (config) => config.useUrl === true,
      defaultValue: 'url',
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'usecardDetailedText',
      name: 'Card Detailed Info',
      description: 'Allow to use aditional info',
      category: ['Data Map Settings'],
      defaultValue: false,
    })
    .addCustomEditor({
      id: 'cardDetailedTextField',
      path: 'cardDetailedTextField',
      name: 'Aditional info Field',
      category: ['Data Map Settings'],
      showIf: (config) => config.usecardDetailedText === true,
      defaultValue: 'info',
      editor: FieldSelectEditor,
    })
    .addTextInput({
      path: 'infoTitle',
      name: 'Aditional info title',
      category: ['Data Map Settings'],
      showIf: (config) => config.usecardDetailedText === true,
      defaultValue: 'Información adicional:',
    })
    .addCustomEditor({
      id: 'typeField',
      path: 'typeField',
      name: 'Type Field',
      category: ['Data Map Settings'],
      defaultValue: 'type',
      editor: FieldSelectEditor,
    })
    .addTextInput({
      path: 'typeTitle',
      name: 'Message type title',
      category: ['Data Map Settings'],
      defaultValue: 'Tipo:',
    })
    .addCustomEditor({
      id: 'severityField',
      path: 'severityField',
      name: 'Severity Field',
      category: ['Data Map Settings'],
      defaultValue: 'severity',
      editor: FieldSelectEditor,
    })
    .addTextInput({
      path: 'severityTitle',
      name: 'Severity title',
      category: ['Data Map Settings'],
      defaultValue: 'Severidad:',
    })
    .addCustomEditor({
      id: 'agentField',
      path: 'agentField',
      name: 'Agent Field',
      category: ['Data Map Settings'],
      defaultValue: 'agent',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'agentUrlField',
      path: 'agentUrlField',
      name: 'Agent Url Field',
      category: ['Data Map Settings'],
      defaultValue: 'agent_url',
      editor: FieldSelectEditor,
    })
    .addTextInput({
      path: 'agentTitle',
      name: 'Agent title',
      category: ['Data Map Settings'],
      defaultValue: 'Agente:',
    })
    .addBooleanSwitch({
      path: 'displayOutline',
      name: 'Display Outline',
      category: ['Card Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showCardInfo',
      name: 'Display card info',
      description: 'Show full info on card or only Cartitle',
      category: ['Card Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'hideControls',
      name: 'Hide TimeLine Controls',
      category: ['Card Settings'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'flipLayout',
      name: 'Flip TimeLine layout',
      category: ['Card Settings'],
      defaultValue: false,
    })
    .addNumberInput({
      path: 'cardMinHeight',
      name: 'Card cage min height Size',
      category: ['Card Settings'],
      defaultValue: 15,
    })
    .addNumberInput({
      path: 'cardFontSize',
      name: 'Card title font size',
      category: ['Card Settings'],
      defaultValue: 12,
    })
    .addNumberInput({
      path: 'circleDimension',
      name: 'Card circle dimension',
      category: ['Card Settings'],
      defaultValue: 25,
    })
    .addBooleanSwitch({
      path: 'useReadMore',
      name: 'Read more option',
      description: 'Show Read more option (vertical orientation)',
      showIf: (config) => config.legendPosition !== 'HORIZONTAL',
      category: ['Card Settings'],
      defaultValue: true,
    })
    .addRadio({
      path: 'legendPosition',
      defaultValue: 'bottom',
      name: 'Orientation',
      category: ['Card Settings'],
      settings: {
        options: [
          {
            value: 'VERTICAL',
            label: 'VERTICAL',
          },
          {
            value: 'VERTICAL_ALTERNATING',
            label: 'VERTICAL_ALTERNATING',
          },
          {
            value: 'HORIZONTAL',
            label: 'HORIZONTAL',
          },
        ],
      },
    })
    .addTextInput({
      path: 'drillDownLink',
      name: 'More info link base URL',
      category: ['Link to other features'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'drillDownLinkAgent',
      name: 'Agent base URL',
      category: ['Link to other features'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'drillDownLinkTitle',
      name: 'Title card base URL',
      category: ['Link to other features'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'No existen eventos para mostrar',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error al cargar el complemento',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});

import React, { Component } from 'react';
import { config } from '@grafana/runtime';
import './css/TimepickerButton.css';
import { getPrettyDate, changeTimeRange, changeUrl, getNetMonitorVariableValue } from './utils';
import { Button, Icon } from '@grafana/ui';

interface GenericButtonProps {
  icon?: string;
  url: string;
  variable?: string;
  value: string;
  title: string;
  selectTitle: string;
}

interface TimepickerButtonProps {
  text?: string;
  time_from: number;
  time_to?: number;
  primary: boolean;
  errors: string[];
  style: string;
}

interface FilterpickerButtonProps {
  text?: string;
  variable?: string;
  value: string;
  result: string;
  primary: boolean;
  style: string;
  title: string;
  selectTitle: string;
}

export class TimepickerButton extends Component<TimepickerButtonProps> {
  constructor(props: any) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.errors.length === 0) {
      changeTimeRange(this.props.time_from, this.props.time_to);
    }
  }

  render() {
    let isDark = config.theme.isDark || false;
    let buttonStyle = String(this.props.style);

    if (buttonStyle === 'default') {
      if (isDark) {
        buttonStyle = 'black';
      } else {
        buttonStyle = 'white';
      }
    }
    let classButton = 'button_disable_light ' + buttonStyle + '_disable';
    if (isDark) {
      classButton = 'button_disable_dark ' + buttonStyle + '_disable';
    }
    return (
      <button className={classButton} title={getTitleTime(this.props)} onClick={this.handleClick}>
        <div className="icon_mark">
          <Icon name="clock-nine" size="lg" />
        </div>
        <p className="button_label width-100">{getTitleTime(this.props)}</p>
      </button>
    );
  }
}

export class FilterpickerButton extends Component<FilterpickerButtonProps> {
  constructor(props: any) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.variable !== '') {
      const queryMap = {
        [`var-${this.props.variable}`]: this.props.value,
      };
      changeUrl(queryMap);
    }
  }

  render() {
    let isDark = config.theme.isDark || false;
    let buttonStyle = String(this.props.style);

    if (buttonStyle === 'default') {
      if (isDark) {
        buttonStyle = 'black';
      } else {
        buttonStyle = 'white';
      }
    }
    let checkStyle = 'tab_check_indicator';
    let classButton = 'button_disable_light ' + buttonStyle + '_disable';
    if (isDark) {
      checkStyle = 'tab_check_indicator_dark';
      classButton = 'button_disable_dark ' + buttonStyle + '_disable';
    }
    let title = String(this.props.title) || '';
    const actualValue = getNetMonitorVariableValue(this.props.variable);
    if (actualValue === this.props.value) {
      classButton = 'button_light ' + buttonStyle;
      if (isDark) {
        classButton = 'button_dark ' + buttonStyle;
      }
      return (
        <button className={classButton} onClick={this.handleClick} title={this.props.selectTitle}>
          {this.props.icon && this.props.icon !== 'undefined' && (
            <div className="icon_mark">
              <Icon name={this.props.icon} size="lg" />
            </div>
          )}
          <p className="button_label width-100">{getTitleUrl(this.props)}</p>
          <div className="check_mark">
            <Icon name="check" size="lg" />
          </div>
        </button>
      );
    } else {
      return (
        <button className={classButton} onClick={this.handleClick} title={title}>
          {this.props.icon && this.props.icon !== 'undefined' && (
            <div className="icon_mark">
              <Icon name={this.props.icon} size="lg" />
            </div>
          )}
          <p className="button_label width-100">{getTitleUrl(this.props)}</p>
        </button>
      );
    }
  }
}

export class GenericButton extends Component<GenericButtonProps> {
  constructor(props: any) {
    super(props);
    this.changeMap = this.changeMap.bind(this);
    this.enableHeatmap = this.enableHeatmap.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleUrl = this.handleUrl.bind(this);
  }

  changeMap() {
    if (this.props.variable !== '') {
      let map = getNetMonitorVariableValue(this.props.variable);
      let newMap = 'streets';
      if (map === 'streets') {
        newMap = 'world-imagery';
      } else if (map === 'world-imagery') {
        newMap = 'world-physical';
      } else if (map === 'world-physical') {
        newMap = 'topo';
      }
      const queryMap = {
        [`var-${this.props.variable}`]: newMap,
      };
      changeUrl(queryMap);
    }
  }

  enableHeatmap() {
    if (this.props.variable !== '') {
      let heatmap = getNetMonitorVariableValue(this.props.variable);
      if (heatmap === '0') {
        heatmap = '1';
      } else {
        heatmap = '0';
      }
      const queryMap = {
        [`var-${this.props.variable}`]: heatmap,
      };
      changeUrl(queryMap);
    }
  }

  handleClick() {
    if (this.props.variable !== '') {
      const queryMap = {
        [`var-${this.props.variable}`]: this.props.value,
      };
      changeUrl(queryMap);
    }
  }

  handleUrl() {
    window.open(this.props.url);
  }

  render() {
    let classButton = 'button_disable_light blue_disable';
    const title = String(this.props.title) || '';

    let isDark = config.theme.isDark || false;
    if (isDark) {
      classButton = 'button_disable_dark blue_disable';
    }

    if (this.props.icon === 'zoom') {
      return (
        <div className="generic_button">
          <button className={classButton} onClick={this.handleUrl} title={title}>
            <div className="button_icon">
              <Icon name={this.props.icon} size="lg" />
            </div>
          </button>
        </div>
      );
    } else if (this.props.icon === 'layers') {
      return (
        <div className="generic_button">
          <button className={classButton} onClick={this.changeMap} title={title}>
            <div className="button_icon">
              <Icon name={this.props.icon} size="lg" />
            </div>
          </button>
        </div>
      );
    } else if (this.props.icon === 'fire') {
      return (
        <div className="generic_button">
          <button className={classButton} onClick={this.enableHeatmap} title={title}>
            <div className="button_icon">
              <Icon name={this.props.icon} size="lg" />
            </div>
          </button>
        </div>
      );
    } else if (this.props.icon && this.props.icon !== 'undefined') {
      return (
        <div className="generic_button">
          <button className={classButton} onClick={this.handleClick} title={title}>
            <div className="button_icon">
              <Icon name={this.props.icon} size="lg" />
            </div>
          </button>
        </div>
      );
    } else {
      return null;
    }
  }
}

function getTitleTime(props: TimepickerButtonProps): string {
  if (props.text) {
    return props.text;
  }
  const timeFrom: string = getPrettyDate(props.time_from);
  return timeFrom !== 'now' ? timeFrom : 'Error';
}
function getTitleUrl(props: FilterpickerButtonProps): string {
  if (props.text && props.value) {
    if (props.result !== '') {
      return props.text + ': ' + props.result;
    } else {
      return props.text;
    }
  }
  return 'Error';
}
function getErrors(errors: string[]) {
  if (errors.length > 0) {
    return <div>{' - Errors: ' + errors.join()}</div>;
  }
  return;
}

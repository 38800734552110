import { dateTimeFormatWithAbbrevation, UrlQueryMap } from '@grafana/data';
import { locationService, getTemplateSrv } from '@grafana/runtime';

export const getEpochWithMillis = (time: number): number => {
  return time < 100000000000 ? time * 1000 : time;
};

export function changeTimeRange(time_from: number, time_to?: number) {
  let queryMap: UrlQueryMap = { from: getEpochWithMillis(time_from), to: 'now' };
  if (typeof time_to !== 'undefined' && time_to !== null && !isNaN(time_to)) {
    queryMap.to = getEpochWithMillis(time_to);
  }
  locationService.partial(queryMap, true);
}

export function changeUrl(queryMap) {
  locationService.partial(queryMap, true);
}

export function getNetMonitorVariableValue(variableName: any) {
  return getTemplateSrv().replace(`$${variableName}`);
}

export const getPrettyDate = (time?: number): string => {
  if (typeof time !== 'undefined' && time !== null && !isNaN(time)) {
    return dateTimeFormatWithAbbrevation(getEpochWithMillis(time));
  }
  return 'now';
};

import { css } from '@emotion/css';
import { Trans, t } from '@lingui/macro';
import React, { useState } from 'react';

import { locationUtil } from '@grafana/data';
import { Stack } from '@grafana/experimental';
import { getBackendSrv, locationService } from '@grafana/runtime';
import { Button, LinkButton, Input, Switch, RadioButtonGroup, Form, Field, InputControl, FieldSet } from '@grafana/ui';
import { getConfig } from 'app/core/config';
import { OrgRole, useDispatch, Invitee } from 'app/types';
import { addInvitee } from 'app/features/invites/state/actions';
import InviteeRow from './InviteeRow';

const roles = [
  { label: 'Operador', value: OrgRole.Viewer },
  { label: 'Supervisor', value: OrgRole.Editor },
];

export interface FormModel {
  role: OrgRole;
  name: string;
  loginOrEmail?: string;
  sendEmail: boolean;
  email: string;
}

const defaultValues: FormModel = {
  name: '',
  email: '',
  role: OrgRole.Viewer,
  sendEmail: true,
};

export const UserInviteForm = (invitees: Invitee[], options) => {
  const dispatch = useDispatch();
  const [inviteesSend, setInviteesSend] = useState<Invitee[]>(invitees.invitees);
  const [isAddMode, setIsAddMode] = useState(true);
  const onSubmit = async (formData: FormModel) => {
    await dispatch(addInvitee(formData)).unwrap();
    setIsAddMode(false);
    const fetchInvitees = await getBackendSrv().get('/api/org/invites');
    setInviteesSend(fetchInvitees);
  };

  return (
    <>
      {isAddMode && (
        <div>
          <div className={styles.title}>
            <div className={styles.titleText}>
              <Trans id="new_user-orgs.title">Agregar</Trans>
            </div>
            <div className={styles.buttonBox}>
              <Button
                type="button"
                variant="secondary"
                icon="user-exclamation"
                size="md"
                title={t({ id: 'users-orgs.unactive', message: 'Usuarios no activados' })}
                onClick={() => {
                  setIsAddMode(false);
                }}
              />
            </div>
          </div>
          <Form defaultValues={defaultValues} onSubmit={onSubmit}>
            {({ register, control, errors }) => {
              return (
                <>
                  <FieldSet>
                    <Field
                      invalid={!!errors.loginOrEmail}
                      error={!!errors.loginOrEmail ? 'Una dirección de correo o nombre de usuario es requerido' : undefined}
                      label="Usuario"
                    >
                      <Input {...register('loginOrEmail', { required: true })} placeholder="email@ejemplo.com" />
                    </Field>
                    <Field invalid={!!errors.name} label="Nombre">
                      <Input {...register('name')} placeholder="(optional)" />
                    </Field>
                    <Field invalid={!!errors.role} label="Función">
                      <InputControl
                        render={({ field: { ref, ...field } }) => <RadioButtonGroup {...field} options={roles} />}
                        control={control}
                        name="role"
                      />
                     </Field>
                    <Field label="Enviar un correo" title={'El usuario deberá aceptar un correo para activar su usuario'}>
                      <Switch id="send-email-switch" {...register('sendEmail')} />
                    </Field>
                  </FieldSet>
                  <Button
                    type="submit"
                    icon="envelope-upload"
                    size="md"
                  />
                </>
              );
            }}
          </Form>
        </div>
      )}
      {!isAddMode && (
        <div>
          <div className={styles.title}>
            <div className={styles.titleText}>
              <Trans id="users-orgs.unactive">Usuarios no activos aún</Trans>
            </div>
            <div className={styles.buttonBox}>
              <Button
                type="button"
                variant="primary"
                icon="user-plus"
                size="md"
                title={t({ id: 'new_user-orgs.title', message: 'Agregar' })}
                onClick={() => {
                  setIsAddMode(true);
                }}
              />
            </div>
          </div>
          <table className="filter-table form-inline">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Correo</th>
                <th>Creado por</th>
                <th style={{ width: '80px', textAlign: 'center' }} >Función</th>
                <th style={{ width: '80px', textAlign: 'center' }} >Correo Aceptado</th>
                <th style={{ width: '34px' }} />
              </tr>
            </thead>
            <tbody data-testid="InviteesTable-body">
              {inviteesSend.map((invitee, index) => {
                return <InviteeRow key={`${invitee.id}-${index}`} invitee={invitee} />;
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default UserInviteForm;

const styles = {
  title: css`
    width: calc(100% - 24px);
    display: inline-flex;
    margin: 6px;
    margin-left: -8px;
  `,
  titleText: css`
    width: calc(100% - 90px);
    margin: 8px;
    font-size: 16px;
    font-weight: 500;
  `,
  buttonBox: css`
    display: flex;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    justify-content: space-around;
    margin-bottom: 0px;
    width: 90px;
  `,
};
import { Icon } from '@grafana/ui';
import CanvasDrawer from '../canvas/graph_canvas';
import cytoscape, { EdgeCollection, EdgeSingular, NodeSingular } from 'cytoscape';
import 'cytoscape-context-menus/cytoscape-context-menus.css';
import React, { PureComponent, useEffect } from 'react';
import { PanelController } from '../PanelController';

import cyCanvas from 'cytoscape-canvas';
import dagre from 'cytoscape-dagre';
import avsdf from 'cytoscape-avsdf';
import cise from 'cytoscape-cise';
import coseBilkent from 'cytoscape-cose-bilkent';
import fcose from 'cytoscape-fcose';
import cola from 'cytoscape-cola';
import qtip from 'cytoscape-qtip';

import expandCollapse from 'cytoscape-expand-collapse';
import contextMenus from 'cytoscape-context-menus';

import layoutOptions from '../layout_options';
import layoutOptions_dagre from '../layout_options_dagre';
import layoutOptions_avsdf from '../layout_options_avsdf';
import layoutOptions_cola from '../layout_options_cola';
import layoutOptions_cise from '../layout_options_cise';
import layoutOptions_fcose from '../layout_options_fcose';
import { Statistics } from '../statistics/Statistics';
import _ from 'lodash';

import {
  TableContent,
  TableHeader,
  Table2Content,
  Table2Header,
  Table3Content,
  Table3Header,
  IntGraphMetrics,
  IntGraph,
  GraphDataType,
  PanelSettings,
  IntSelectionStatistics,
} from '../../types';
import { TemplateSrv, getTemplateSrv, config, SystemJS } from '@grafana/runtime';
import { AppEvents } from '@grafana/data';

import './ServiceDependencyGraph.css';
import '../../css/netmonitor-topology-map-qtip.css';
import '../../css/netmonitor-topology-map-cytoscape.css';

import axios from 'axios';

interface PanelState {
  zoom: number | undefined;
  controller: PanelController;
  cy?: cytoscape.Core | undefined;
  graphCanvas?: CanvasDrawer | undefined;
  showStatistics: boolean;
  showEdgeStatistics: boolean;
  data: IntGraph;
  settings: PanelSettings;
  isLock: boolean;
  isCollapsed: boolean;
  initResize: boolean;
}

cyCanvas(cytoscape);
expandCollapse(cytoscape);
contextMenus(cytoscape);

cytoscape.use(dagre);
cytoscape.use(cola);
cytoscape.use(avsdf);
cytoscape.use(cise);
cytoscape.use(coseBilkent);
cytoscape.use(fcose);
cytoscape.use(qtip);
cytoscape.warnings(false);

export class ServiceDependencyGraph extends PureComponent<PanelState, PanelState> {
  ref: any;

  selectionId: string;

  sys_header: Table3Header[];

  currentDescription: Table3Content[];

  currentLabel: string;

  currentSite: string;

  currentType: string;

  selectionStatistics: IntSelectionStatistics;

  receiving: TableContent[];

  sending: TableContent[];

  table_header: TableHeader[];

  node_title: string;

  edge_title: string;

  links: Table2Content[];

  linksHeader: Table2Header[];

  resolvedDrillDownLink_asset: string;

  resolvedDrillDownLink_site: string;

  templateSrv: TemplateSrv;

  layout: string;

  toolbar: boolean;

  showStatTables: boolean;

  constructor(props: PanelState) {
    super(props);

    this.state = {
      ...props,
      showStatistics: false,
      showEdgeStatistics: false,
      isLock: false,
      isCollapsed: false,
      initResize: false,
    };

    this.ref = React.createRef();
    this.templateSrv = getTemplateSrv();
  }

  componentDidMount() {
    const isDark = config.theme.isDark;
    const { showToolbar, showStatTables, useTrafficMetric, useMetricOne, useMetricTwo } = this.getSettings(false);
    this.toolbar = showToolbar;
    this.showStatTables = showStatTables;
	var tooltip = document.getElementById('tooltip-edge');
	var tooltipNode = document.getElementById('tooltip-node');
	var cy: any = cytoscape({
      container: this.ref,
      zoom: this.props.zoom,
      minZoom: 0.25,
      maxZoom: 3,
      wheelSensitivity: 0.25,
      userZoomingEnabled: true,
      elements: this.props.data,
      style: [
        {
          selector: 'node',
          style: {
            'background-opacity': 0,
            visibility: 'visible',
          },
        },
        {
          selector: 'node.cy-expand-collapse-collapsed-node',
          css: {
            width: '60px',
            height: '60px',
            'border-width': '1px',
            'border-color': '#dce1e6',
            'background-color': '#fff',
          },
        },
        {
          selector: '$node > node',
          css: {
            'border-width': '1px',
            'border-color': '#dce1e6',
            'background-color': '#fff',
            color: '#111',
            shape: 'rectangle',
            'padding-top': '14px',
            'padding-left': '14px',
            'padding-bottom': '14px',
            'padding-right': '14px',
          },
        },
        {
          selector: ':parent',
          css: {
            'text-valign': 'top',
            'text-halign': 'center',
            'border-width': '1px',
            'border-color': '#dce1e6',
            'background-color': '#fff',
          },
        },
        {
          selector: 'edge',
          style: {
            width: 1,
          },
        },
        {
          selector: '.hidden',
          css: {
            display: 'none',
          },
        },
      ],
    });
    if (isDark) {
      cy = cytoscape({
        container: this.ref,
        zoom: this.props.zoom,
        minZoom: 0.15,
        maxZoom: 3,
        wheelSensitivity: 0.075,
        userZoomingEnabled: true,
        elements: this.props.data,
        style: [
          {
            selector: 'node',
            style: {
              'background-opacity': 0,
              visibility: 'visible',
            },
          },
          {
            selector: 'node.cy-expand-collapse-collapsed-node',
            css: {
              width: '60px',
              height: '60px',
              'border-width': '1px',
              'border-color': '#222',
              'background-color': '#000',
            },
          },
          {
            selector: '$node > node',
            css: {
              'border-width': '1px',
              'border-color': '#222',
              'background-color': '#000',
              color: '#f1f2f3',
              shape: 'rectangle',
              'padding-top': '10px',
              'padding-left': '10px',
              'padding-bottom': '10px',
              'padding-right': '10px',
            },
          },
          {
            selector: ':parent',
            css: {
              'text-valign': 'top',
              'text-halign': 'center',
              'border-width': '1px',
              'border-color': '#222',
              'background-color': '#000',
            },
          },
          {
            selector: 'edge',
            style: {
              width: 1,
            },
          },
          {
            selector: '.hidden',
            css: {
              display: 'none',
            },
          },
        ],
      });
    }
    const { sysHeader, showSysInfo, showConnectionStats, nodeHeader, layoutFormat } = this.getSettings(false);

	let hasPosition = layoutFormat === 'preset' ? true : false;
    cy.nodes().forEach(function(node: any) {
	  if (hasPosition) {
        if (node.data('x') !== undefined && node.data('y') !== undefined && node.data('x') !== null && node.data('y') !== null) {
	      let x = node.data('x');
          let y = node.data('y');
          node.position().x = x;
          node.position().y = y;
		} else {
		  hasPosition = false;
		}
	  }
    });
    this.layout = (!hasPosition && layoutFormat === 'preset') ? 'cola' : layoutFormat;

    const colapseOptions: any = {
      layoutBy: {
        name: this.layout,
        animate: 'end',
        randomize: false,
        fit: false,
      },
      fisheye: false,
      animate: 'end',
      animationDuration: 500,
      ready: function() {},
      undoable: false,
      cueEnabled: true,
      expandCollapseCuePosition: 'top-left',
      expandCollapseCueSize: 12,
      expandCollapseCueSensitivity: 1,
      edgeTypeInfo: 'edgeType',
      groupEdgesOfSameTypeOnCollapse: false,
      allowNestedEdgeCollapse: true,
      zIndex: 900,
    };

    const { initBlock, allCollapsed } = this.getSettings(false);
    this.setState({
      isLock: initBlock,
      isCollapsed: allCollapsed,
    });

    if (this.showStatTables) {
      if (useTrafficMetric || useMetricOne || useMetricTwo) {
        cy.on('select', 'edge', () => {
		  tooltipNode.classList.add('graph-tooltip-hidden');
		  tooltip.classList.add('graph-tooltip-hidden');
		  this.onEdgeSelectionChange();
		});
        cy.on('unselect', 'edge', () => {
		  this.onEdgeSelectionChange();
		});
      }
      cy.on('select', 'node', () => {
		tooltipNode.classList.add('graph-tooltip-hidden');
		tooltip.classList.add('graph-tooltip-hidden');
	    this.onSelectionChange();
	  });
      cy.on('unselect', 'node', () => {
	    this.onSelectionChange();
	  });
    }

    const { drillDownLink_asset, drillDownLink_site } = this.getSettings(true);
	const { healthyColor, warningColor, dangerColor, noDataColor } = this.getSettings(true).style;
	const { healthyThreshold, warningThreshold } = this.getSettings(true).style;
    const asset_link = drillDownLink_asset;
    const site_link = drillDownLink_site;
    cy.on('mouseover', 'node', function(event: any) {
      let node = event.target;
	  var backgroundColor = '#000000';
	  var pos = cy.container().getBoundingClientRect();
	  var x = event.originalEvent.clientX - pos.left;
	  var y = event.originalEvent.clientY - pos.top;

      var item_link = asset_link + node.data('id');
      const isVisible = node.hidden();
      var text_color = 'qtip-bootstrap qtip-normal';
      if (isDark) {
        text_color = 'qtip-bootstrap qtip-dark';
      }
      var isGroup = false;
      if (node.data('type') === 'GROUP_EXP' || node.data('type') === 'GROUP_COL') {
        isGroup = true;
      }
      if (!isGroup && !isVisible) {
		const slaMetric = _.defaultTo(node.data('node_sla'), -1);
		if (slaMetric > 100 || slaMetric < 0) {
		  backgroundColor =  '#333333';
		} else {
		  if (slaMetric >= healthyThreshold) {
			backgroundColor = '#000000';
		  } else {
			if (slaMetric >= warningThreshold) {
			  backgroundColor = warningColor;
			} else {
			  backgroundColor = dangerColor;
			}
		  }
		}
        var description_header = '-';
        var time_header = '-';
        var location_header = '-';

        if (sysHeader !== undefined) {
          const header3: any[] = sysHeader.split(',');
          if (header3[0] !== undefined) {
            description_header = header3[0];
          }
          if (header3[1] !== undefined) {
            time_header = header3[1];
          }
          if (header3[2] !== undefined) {
            location_header = header3[2];
          }
        }

        var tooltip = '<b>Activo: </b>' + node.data('id') + '<br/><b>Emplazamiento: </b>' + node.data('site');
        if (showSysInfo) {
		  const nodeDescription = node.data('node_description');
          const sysInfo: any[] = nodeDescription.split(',');
          tooltip =
            '<b>Activo: <a href="' +
            item_link +
            '" target="_blank">' +
            node.data('id') +
            '</a></b> - <b>' +
            time_header +
            ': </b>';
          if (sysInfo[0] !== null && sysInfo[1] !== null && sysInfo[2] !== null) {
            tooltip =
              tooltip +
              sysInfo[1] +
              '<br/><b>' +
              description_header +
              ': </b>' +
              sysInfo[0] +
              ' - <b>' +
              location_header +
              ': </b>' +
              sysInfo[2];
          }
        }
        tooltipNode.style.left = x + 'px';
		tooltipNode.style.top = y + 'px';
		tooltipNode.style.color = backgroundColor;
		tooltipNode.innerHTML = tooltip;
		tooltipNode.classList.remove('graph-tooltip-hidden');
      }
      if (isGroup && !isVisible) {
        var tooltip = '<b>Grupo: </b>' + node.data('label') + '<br/><b>Emplazamiento: </b>' + node.data('site');
        tooltipNode.style.left = x + 'px';
		tooltipNode.style.top = y + 'px';
		tooltipNode.style.color = backgroundColor;
		tooltipNode.innerHTML = tooltip;
		tooltipNode.classList.remove('graph-tooltip-hidden');
      }
    });
    cy.on('move grab cxttap click mouseout', 'node', function(event: any) {
	  tooltipNode.classList.add('graph-tooltip-hidden');
    });
    if (showConnectionStats && (useTrafficMetric || useMetricOne || useMetricTwo)) {
      cy.on('mouseover', 'edge', function(event: any) {
        const edge = event.target;
        const isVisible = edge.hidden();

		var pos = cy.container().getBoundingClientRect();
		var x = event.originalEvent.clientX - pos.left;
		var y = event.originalEvent.clientY - pos.top;

        var hasTrafficMetric = true;
        var Traffic_g = 0;
        var Traffic_m = 0;
        const edgeMetrics: IntGraphMetrics = edge.data('metrics');
        var {
          traffic_out,
          traffic_in,
          metricTwo_max,
          metricTwo_actual,
          metricTwo_threshold,
          metricOne_max,
          metricOne_actual,
          metricOne_threshold,
        } = edgeMetrics;
        if (nodeHeader !== undefined) {
          var traffic_header = '-';
          var metricOne_header = '-';
          var metricTwo_header = '-';
          const header: any[] = nodeHeader.split(',');
          if (header[3] !== undefined) {
            traffic_header = header[3];
          }
          if (header[4] !== undefined) {
            metricOne_header = header[4];
          }
          if (header[5] !== undefined) {
            metricTwo_header = header[5];
          }
        }
        var edgeLabel = '-';
        var edgeLabel2 = '-';
        var edgeLabel3 = '-';
        var text_color = 'qtip-bootstrap qtip-normal';
        if (isDark) {
          text_color = 'qtip-bootstrap qtip-blue';
        }

        if (!useTrafficMetric || isNaN(traffic_out) || traffic_out === undefined || traffic_out < 0) {
          hasTrafficMetric = false;
          traffic_out = 0;
        }
        if (hasTrafficMetric) {
          Traffic_g = _.defaultTo(traffic_out / 1000000, 0);
          Traffic_m = _.defaultTo(traffic_out / 1000, 0);
          if (traffic_out >= 1000000) {
            edgeLabel = Traffic_g.toFixed(2) + ' Gbps';
          } else {
            if (traffic_out >= 1000) {
              edgeLabel = Traffic_m.toFixed(2) + ' Mbps';
            } else {
              edgeLabel = traffic_out.toFixed(2) + ' Kbps';
            }
          }
        }

        if (!useTrafficMetric || isNaN(traffic_in) || traffic_in === undefined || traffic_in < 0) {
          hasTrafficMetric = false;
          traffic_in = 0;
        }
        if (hasTrafficMetric) {
          Traffic_g = _.defaultTo(traffic_in / 1000000, 0);
          Traffic_m = _.defaultTo(traffic_in / 1000, 0);
          if (traffic_in >= 1000000) {
            edgeLabel = edgeLabel + ' / ' + Traffic_g.toFixed(2) + ' Gbps';
          } else {
            if (traffic_in >= 1000) {
              edgeLabel = edgeLabel + ' / ' + Traffic_m.toFixed(2) + ' Mbps';
            } else {
              edgeLabel = edgeLabel + ' / ' + traffic_in.toFixed(2) + ' Kbps';
            }
          }
        }

        if (useMetricOne && !isNaN(metricOne_actual) && !isNaN(metricOne_threshold) && !isNaN(metricOne_max)) {
          if (metricOne_actual > 0 && metricOne_threshold > 0) {
            if (metricOne_actual > metricOne_threshold) {
              text_color = 'qtip-bootstrap qtip-danger';
            } else {
              if (metricOne_actual === metricOne_threshold) {
                text_color = 'qtip-bootstrap qtip-warning';
              }
            }
          }
          if (metricOne_actual >= 0 && metricOne_max >= 0) {
            edgeLabel2 = metricOne_actual.toString() + ' / ' + metricOne_max.toString();
          }
        } else if (useMetricTwo && !isNaN(metricTwo_actual) && !isNaN(metricTwo_threshold) && !isNaN(metricTwo_max)) {
          if (metricTwo_actual > 0 && metricTwo_threshold > 0) {
            if (metricTwo_actual > metricTwo_threshold) {
              text_color = 'qtip-bootstrap qtip-danger';
            } else {
              if (metricTwo_actual === metricTwo_threshold) {
                text_color = 'qtip-bootstrap qtip-warning';
              }
            }
          }
          if (metricTwo_actual >= 0 && metricTwo_max >= 0) {
            edgeLabel3 = metricTwo_actual.toString() + ' / ' + metricTwo_max.toString();
          }
        }

        var content_label = '';
        if (useTrafficMetric && traffic_header !== '-' && hasTrafficMetric) {
          content_label = '<b>' + traffic_header + ':</b> ' + edgeLabel + '<br />';
        }
        if (useMetricOne && metricOne_header !== '-') {
          content_label = content_label + '<b>' + metricOne_header + ':</b> ' + edgeLabel2;
          if (metricTwo_header !== '-') {
            content_label = content_label + '<br /><b>' + metricTwo_header + ':</b> ' + edgeLabel3;
          }
        } else {
          if (useMetricTwo && metricTwo_header !== '-') {
            content_label = content_label + '<br /><b>' + metricTwo_header + ':</b> ' + edgeLabel3;
          }
        }
        if (!isVisible) {
          tooltip.style.left = x + 'px';
		  tooltip.style.top = y + 'px';
		  tooltip.innerHTML = content_label;
		  tooltip.classList.remove('graph-tooltip-hidden');
        }
      });
	  cy.on('mouseout', 'edge', function(event: any) {
	    tooltip.classList.add('graph-tooltip-hidden');
	  });
	  cy.on('click', 'edge', function(event: any) {
	    tooltip.classList.add('graph-tooltip-hidden');
	  });
      cy.on('cxttap', 'edge', function(event: any) {
        tooltip.classList.add('graph-tooltip-hidden');
      });
    }
    var graphCanvas = new CanvasDrawer(
      this,
      cy,
      cy.cyCanvas({
        zIndex: 1,
      })
    );
    this.setState({
      cy: cy,
      graphCanvas: graphCanvas,
    });

    cy.on('render', () => {
      graphCanvas.repaint(true);
    });

    cy.on('zoom', () => {
      tooltip.classList.add('graph-tooltip-hidden');
	  tooltipNode.classList.add('graph-tooltip-hidden');
      this.setState({
        zoom: this.state.cy.zoom(),
      });
    });

    cy.expandCollapse(colapseOptions);
    var api = cy.expandCollapse('get');

    cy.on('expandcollapse.afterexpand', 'node', () => this.onTapNode());
    cy.on('expandcollapse.aftercollapse', 'node', () => this.onTapNode());

    cy.on('expandcollapse.beforeexpand', 'node', function(event: any) {
      let node = event.target;
      node.data('type', 'GROUP_EXP');
    });

    cy.on('expandcollapse.beforecollapse', 'node', function(event: any) {
      let node = event.target;
      node.data('type', 'GROUP_COL');
    });

    cy.on('nodesCollapsed', () => {
      if (this.state.isCollapsed) {
        api.collapseAll();
      } else {
        api.expandAll();
      }
    });

    cy.one('render', function(event: any) {
      cy.nodes().forEach(function(node: any) {
        if (node.data('node_visible') === true) {
          cy.elements(node.union(node.siblings()).addClass('hidden'));
          let menuItem3 = {
            id: node.data('id'),
            content: node.data('id'),
            show: true,
            onClickFunction: function(event: any) {
              node = cy.getElementById(node.data('id'));
              cy.elements(node.union(node.siblings()).removeClass('hidden'));
              let group = node.data('parent');
              cy.getElementById(group).removeClass('hidden');
              contextMenu.removeMenuItem(node.data('id'));
              const eles = cy.$('.hidden');
              if (eles.empty()) {
                contextMenu.hideMenuItem('add-node');
              }
            },
          };
          contextMenu.appendMenuItem(menuItem3, 'add-node');
          contextMenu.showMenuItem('add-node');
        }
      });
      api.collapse(cy.collection('[type = "GROUP_COL"]'));
    });

    cy.on('dbltap', 'node', function(event: any) {
	  tooltipNode.classList.add('graph-tooltip-hidden');
	  let node = event.target;
      if (node.data('type') === 'GROUP_EXP') {
        if (api.isCollapsible(node)) {
          api.collapse(node);
        }
      } else if (node.data('type') === 'GROUP_COL') {
        if (api.isExpandable(node)) {
          api.expand(node);
        }
      }
    });

    var contextMenu = cy.contextMenus({
      evtType: 'cxttap',
      menuItems: [
        {
          id: 'add-node',
          content: 'Agregar activo o grupo',
          tooltipText: 'Agregar un activo o grupo que fue removido',
          show: false,
          coreAsWell: true,
          submenu: [],
          onDisplayFunction: function(event: any) {
            cy.nodes().qtip({
              show: {
                when: false,
              },
            });
          },
        },
        {
          id: 'remove',
          content: 'Eliminar activo o grupo',
          selector: 'node',
          show: true,
          onClickFunction: function(event: any) {
            let node = event.target;
            cy.elements(node.union(node.siblings()).addClass('hidden'));
            let group = node.data('parent');
            cy.getElementById(group).addClass('hidden');
            let menuItem3 = {
              id: node.data('id'),
              content: node.data('id'),
              show: true,
              onClickFunction: function(event: any) {
                node = cy.getElementById(node.data('id'));
                cy.elements(node.union(node.siblings()).removeClass('hidden'));
                let group = node.data('parent');
                cy.getElementById(group).removeClass('hidden');
                contextMenu.removeMenuItem(node.data('id'));
                const eles = cy.$('.hidden');
                if (eles.empty()) {
                  contextMenu.hideMenuItem('add-node');
                }
              },
            };
            contextMenu.appendMenuItem(menuItem3, 'add-node');
            contextMenu.showMenuItem('add-node');
          },
          onDisplayFunction: function(event: any) {
            cy.nodes().qtip({
              show: {
                when: false,
              },
            });
          },
        },
        {
          id: 'open',
          content: 'Más información...',
          tooltipText: 'Acceder a informacion detallada',
          selector: 'node',
          show: true,
          coreAsWell: true,
          onClickFunction: function(event: any) {
            let node = event.target;
            let linkAsset = site_link + node.data('id');
            if (node.data('type') === 'GROUP_EXP' || node.data('type') === 'GROUP_COL') {
              window.open(linkAsset, '_blank');
            } else if (node.data('type') === GraphDataType.INTERNAL) {
              linkAsset = asset_link + node.data('id');
              window.open(linkAsset, '_blank');
            }
          },
          onDisplayFunction: function(event: any) {
            cy.nodes().qtip({
              show: {
                when: false,
              },
            });
          },
        },
        {
          id: 'save-map',
          content: 'Guardar mapa',
          tooltipText: 'Guardar mapa de Topología',
          show: true,
          coreAsWell: true,
          onClickFunction: () => this.saveLayout(),
          onDisplayFunction: function(event: any) {
            cy.nodes().qtip({
              show: {
                when: false,
              },
            });
          },
        },
        {
          id: 'exp-map',
          content: 'Expandir/Contraer grupos',
          tooltipText: 'Expande o Contrae el grupo',
          show: true,
          coreAsWell: true,
          onClickFunction: () => this.muxCollapse(),
          onDisplayFunction: function(event: any) {
            cy.nodes().qtip({
              show: {
                when: false,
              },
            });
          },
        },
      ],
      menuItemClasses: ['custom-menu-item'],
      contextMenuClasses: ['custom-context-menu'],
      submenuIndicator: { src: 'public/img/icons/unicons/subject.svg', width: 14, height: 14 },
    });
    this.setState({
      zoom: this.props.zoom,
	  initResize: this.props.initResize,
    });
  }

  componentDidUpdate(prevProps: Props) {
	let actualEdges = prevProps.data.edges;
	let newEdges = this.props.data.edges;
	let actualNodes = prevProps.data.nodes;
	let newNodes = this.props.data.nodes;
	let equalEdges = actualEdges.length === newEdges.length ? true : false;
	let equalNodes = actualEdges.length === newEdges.length ? true : false;

	if (equalNodes && equalEdges) {
	  for (let i = 0; i < newNodes.length; i++) {
	    if (actualNodes[i].data.id !== newNodes[i].data.id || actualNodes[i].data.sla !== newNodes[i].data.sla) {
		  equalNodes = false;
		  break;
	    }
	  }
	  if (equalNodes) {
	    for (let i = 0; i < newEdges.length; i++) {
	      if (actualEdges[i].data.id !== newEdges[i].data.id || actualEdges[i].data.sla !== newEdges[i].data.sla) {
		    equalEdges = false;
		    break;
	      }
		}
	  }

	}

	if (!equalNodes || !equalEdges) {
      this._updateGraph(this.props.data, true);
	}
  }

  getSettings(resolveVariables: boolean): PanelSettings {
    return this.state.controller.getSettings(resolveVariables);
  }

  _updateGraph(graph: IntGraph, reload: boolean) {
    const cy2: any = cytoscape({
      elements: graph,
      headless: true,
    });
	const previous = this.state.cy.elements();
    const newone = cy2.elements();
	const differences = previous.symmetricDifference(newone)
    if (this.state.initResize) {
      this.state.cy.emit('nodesCollapsed');
      this.state.cy.resize();
      this.setState({
        initResize: false,
      });
	  this.runLayout();
    } else if (differences.length > 0) {
	  if (reload) {
	    this.state.cy.remove(previous);
	    this.state.cy.add(newone);
	  } else {
        const diff = previous.difference(newone);
        const del = newone.difference(previous);
        this.state.cy.remove(del);
        this.state.cy.add(diff);
	  }
      this.runLayout();
	}
  }

  runLayout(unlockNodes = false) {
	const that = this;
    var options = {
      ...layoutOptions,
        stop: function() {
          if (unlockNodes) {
            that.unlockNodes();
          }
        },
		ready: function() {
		  that.state.cy.center();
        },
    };
	let hasPosition = this.layout === 'preset' ? true : false;
    this.state.cy.nodes().forEach(function(node: any) {
	  if (hasPosition) {
        if (node.data('x') !== undefined && node.data('y') !== undefined && node.data('x') !== null && node.data('y') !== null) {
		  if (node.data('x') === -1 && node.data('y') === -1) {
            hasPosition = false;
		  } else {
	        let x = node.data('x');
            let y = node.data('y');
            node.position().x = x;
            node.position().y = y;
		  }
		} else {
		  hasPosition = false;
		}
	  }
    });
    const Layout = (!hasPosition && this.layout === 'preset') ? 'cola' : this.layout;
    switch (Layout) {
      case 'cola':
        options = {
          ...layoutOptions_cola,
          stop: function() {
            if (unlockNodes) {
              that.unlockNodes();
            }
          },
		  ready: function() {
			that.state.cy.center();
          },
        };
        break;
      case 'cise':
        options = {
          ...layoutOptions_cise,
          stop: function() {
            if (unlockNodes) {
              that.unlockNodes();
            }
          },
		  ready: function() {
			that.state.cy.center();
          },
        };
        break;
      case 'avsdf':
        options = {
          ...layoutOptions_avsdf,
          stop: function() {
            if (unlockNodes) {
              that.unlockNodes();
            }
          },
		  ready: function() {
			that.state.cy.center();
          },
        };
        break;
      case 'dagre':
        options = {
          ...layoutOptions_dagre,
          stop: function() {
            if (unlockNodes) {
              that.unlockNodes();
            }
          },
		  ready: function() {
			that.state.cy.center();
          },
        };
        break;
      case 'fcose':
        options = {
          ...layoutOptions_fcose,
          stop: function() {
            if (unlockNodes) {
              that.unlockNodes();
            }
          },
		  ready: function() {
			that.state.cy.center();
          },
        };
        break;
    }
    this.state.cy.layout(options).run();
    if (this.state.isLock) {
      this.lockNodes();
    }
	this.state.graphCanvas.repaint(true);
  }

  onTapNode() {
	this.state.cy.fit();
  }

  onSelectionChange() {
    const selection = this.state.cy.$(':selected');
    if (selection.length === 1) {
      const currentNode: NodeSingular = selection[0];
      const nodeLabel = currentNode.data('label');
      if (selection.data('type') !== 'GROUP_EXP' && selection.data('type') !== 'GROUP_COL' && nodeLabel !== undefined) {
        this.updateStatisticTable();
        this.setState({
          showStatistics: true,
        });
		this.state.cy.center(selection);
      } else {
        this.setState({
          showStatistics: false,
        });
      }
    } else {
      this.setState({
        showStatistics: false,
      });
    }
  }

  onEdgeSelectionChange() {
    const selection = this.state.cy.$(':selected');
    if (selection.length === 1) {
      const actualEdge: EdgeSingular = selection[0];
      const edgeLabel = actualEdge.data('label');
      if (edgeLabel !== undefined && edgeLabel !== null) {
        this.updateEdgeStatisticTable();
        this.setState({
          showEdgeStatistics: true,
        });
		this.state.cy.center(selection);
      } else {
        this.setState({
          showEdgeStatistics: false,
        });
      }
    } else {
      this.setState({
        showEdgeStatistics: false,
      });
    }
  }

  unlockNodes() {
    this.state.cy.nodes().forEach((node: { unlock: () => void }) => {
      node.unlock();
    });
  }

  lockNodes() {
    this.state.cy.nodes().forEach((node: { lock: () => void }) => {
      node.lock();
    });
  }

  saveLayout() {
    const { map, api } = this.getSettings(true);
    var position = '{"dest": "topology", "params":"';
    var elem = 0;
    this.state.cy.nodes().forEach(function(n) {
      elem = elem + 1;
      const asset = n.data('id');
      const type = n.data('type');
      const user = n.data('user');
      const visible = n.hasClass('hidden');
      const x = n.position().x.toFixed(2);
      const y = n.position().y.toFixed(2);
      if (elem < 2) {
        position =
          position +
          asset.toString() +
          ';' +
          x.toString() +
          ';' +
          y.toString() +
          ';' +
          type.toString() +
          ';' +
          user +
          ';' +
          map +
          ';' +
          visible.toString();
      } else {
        position =
          position +
          ',' +
          asset.toString() +
          ';' +
          x.toString() +
          ';' +
          y.toString() +
          ';' +
          type.toString() +
          ';' +
          user +
          ';' +
          map +
          ';' +
          visible.toString();
      }
    });
    position = position + '"}';

    axios.defaults.baseURL = api;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.post(api, position).then(
      response => {
        if (response.statusText === 'OK') {
          SystemJS.load('app/core/app_events').then((appEvents: any) => {
            appEvents.emit(AppEvents.alertSuccess, ['Mapa guardado correctamente']);
          });
        } else {
          SystemJS.load('app/core/app_events').then((appEvents: any) => {
            appEvents.emit(AppEvents.alertSuccess, [response.statusText]);
          });
        }
      },
      error => {
        SystemJS.load('app/core/app_events').then((appEvents: any) => {
          appEvents.emit(AppEvents.alertError, ['Error al guardar el mapa' + error.response.status]);
        });
      }
    );
  }

  zoom(factor: number) {
    const zoomStep = 0.25 * factor;
    const actualZoom: number = this.state.zoom;
    const zoomLevel: number = actualZoom + zoomStep;
    this.setState({
      zoom: zoomLevel,
    });
    this.state.cy.zoom(zoomLevel);
  }

  updateStatisticTable() {
    const selection = this.state.cy.$(':selected');
    const { useTrafficMetric, useMetricOne, useMetricTwo } = this.getSettings(false);

    if (selection.length === 1) {
      const currentNode: NodeSingular = selection[0];
      this.selectionId = currentNode.id().toString();
      this.currentLabel = currentNode.data('label');
      this.currentSite = currentNode.data('site');
      this.currentType = currentNode.data('type');
      const nodeDescription = currentNode.data('node_description');
      const receiving: TableContent[] = [];
      const sending: TableContent[] = [];
      const table_header: TableHeader[] = [];
      const systemDescription: Table3Content[] = [];
      const sys_header: Table3Header[] = [];
      const edges: EdgeCollection = selection.connectedEdges();
      let targetLabel = currentNode.data('target');
      let targetSite = currentNode.data('site');
      let metricOneMax = -1;
      let metricOneActual = -1;
      let metricOneThreshold = -1;
      let metricTwoActual = -1;
      let traffic_in = -1;
      let traffic_out = -1;
      if (useTrafficMetric || useMetricOne || useMetricTwo) {
        let metrics: IntGraphMetrics = selection.nodes()[0].data('metrics');
        targetLabel = metrics.target_label;
        targetSite = metrics.target_site;
        if (useMetricOne) {
          metricOneMax = metrics.metricOne_max;
          metricOneActual = metrics.metricOne_actual;
          metricOneThreshold = metrics.metricOne_threshold;
        }
        if (useMetricTwo) {
          metricTwoActual = metrics.metricTwo_actual;
        }
        if (useTrafficMetric) {
          traffic_in = metrics.traffic_in;
          traffic_out = metrics.traffic_out;
        }
      }
      this.selectionStatistics = {};

      this.selectionStatistics.target_label = targetLabel;
      this.selectionStatistics.target_site = targetSite;

      if (metricOneMax >= 0) {
        this.selectionStatistics.metricOne_max = Math.floor(metricOneMax);
      }
      if (metricOneThreshold >= 0) {
        this.selectionStatistics.metricOne_threshold = Math.floor(metricOneThreshold);
        this.selectionStatistics.thresholdViolation = metricOneActual > metricOneThreshold;
      }
      if (metricOneActual >= 0) {
        this.selectionStatistics.metricOne_actual = Math.floor(metricOneActual);
      } else {
        this.selectionStatistics.metricOne_actual = 0;
      }
      if (metricTwoActual >= 0) {
        this.selectionStatistics.metricTwo_actual = Math.floor(metricTwoActual);
      } else {
        this.selectionStatistics.metricTwo_actual = 0;
      }
      if (traffic_in >= 0) {
        this.selectionStatistics.traffic_in = Math.floor(traffic_in);
      }
      if (traffic_out >= 0) {
        this.selectionStatistics.traffic_out = Math.floor(traffic_out);
      }

      if (nodeDescription !== undefined && nodeDescription !== null && nodeDescription !== '') {
        const sendingsysDescription: Table3Content = {
          sysDescription: '-',
          sysTime: '-',
          sysLocation: '-',
        };
        const system: any[] = nodeDescription.split(',');
        if (system[0] !== undefined) {
          sendingsysDescription.sysDescription = system[0];
        } else {
          sendingsysDescription.sysDescription = '-';
        }
        if (system[1] !== undefined) {
          sendingsysDescription.sysTime = system[1];
        } else {
          sendingsysDescription.sysTime = '-';
        }
        if (system[2] !== undefined) {
          sendingsysDescription.sysLocation = system[2];
        } else {
          sendingsysDescription.sysLocation = '-';
        }
        systemDescription.push(sendingsysDescription);
      }

      const { sysHeader } = this.getSettings(false);
      if (sysHeader !== undefined) {
        const sendingSysHeader: Table3Header = {
          description_header: '-',
          time_header: '-',
          location_header: '-',
        };
        const header3: any[] = sysHeader.split(',');
        if (header3[0] !== undefined) {
          sendingSysHeader.description_header = header3[0];
        } else {
          sendingSysHeader.description_header = '-';
        }
        if (header3[1] !== undefined) {
          sendingSysHeader.time_header = header3[1];
        } else {
          sendingSysHeader.time_header = '-';
        }
        if (header3[2] !== undefined) {
          sendingSysHeader.location_header = header3[2];
        } else {
          sendingSysHeader.location_header = '-';
        }
        sys_header.push(sendingSysHeader);
      }

      for (let i = 0; i < edges.length; i++) {
        const actualEdge: EdgeSingular = edges[i];
        const sendingCheck: boolean = actualEdge.source().id() === this.selectionId;
        let node: NodeSingular;

        if (sendingCheck) {
          node = actualEdge.target();
        } else {
          node = actualEdge.source();
        }

        const sendingObject: TableContent = {
          name: node.id(),
          label: '-',
          site: '-',
          traffic_in: '-',
          traffic_out: '-',
          metricOneMax: '-',
          metricTwoMax: '-',
          metricTwoActual: '-',
          metricOneActual: '-',
        };

        const edgeMetrics: IntGraphMetrics = actualEdge.data('metrics');

        if (edgeMetrics !== undefined) {
          const {
            target_label,
            target_site,
            traffic_out,
            traffic_in,
            metricOne_max,
            metricTwo_max,
            metricTwo_actual,
            metricOne_actual,
          } = edgeMetrics;
          const source_label = actualEdge.data('label');
          const source_site = actualEdge.data('site');

          if (sendingCheck) {
            sendingObject.label = source_label;
            sendingObject.site = source_site;
          } else {
            sendingObject.label = target_label;
            sendingObject.site = target_site;
          }
          if (metricOne_max !== undefined) {
            sendingObject.metricOneMax = Math.floor(metricOne_max).toString();
          }
          if (metricTwo_max !== undefined) {
            sendingObject.metricTwoMax = Math.floor(metricTwo_max).toString();
          }
          if (traffic_in !== undefined) {
            const TrafficIn_g = traffic_in / 1000000;
            const TrafficIn_m = traffic_in / 1000;
            if (sendingCheck) {
              if (traffic_in >= 1000000) {
                sendingObject.traffic_in = Math.floor(TrafficIn_g) + ' Gbps';
              } else {
                if (traffic_in >= 1000) {
                  sendingObject.traffic_in = Math.floor(TrafficIn_m) + ' Mbps';
                } else {
                  sendingObject.traffic_in = Math.floor(traffic_in) + ' Kbps';
                }
              }
            } else {
              if (traffic_in >= 1000000) {
                sendingObject.traffic_out = Math.floor(TrafficIn_g) + ' Gbps';
              } else {
                if (traffic_in >= 1000) {
                  sendingObject.traffic_out = Math.floor(TrafficIn_m) + ' Mbps';
                } else {
                  sendingObject.traffic_out = Math.floor(traffic_in) + ' Kbps';
                }
              }
            }
          }
          if (traffic_out !== undefined) {
            const Traffic_g = traffic_out / 1000000;
            const Traffic_m = traffic_out / 1000;
            if (sendingCheck) {
              if (traffic_out >= 1000000) {
                sendingObject.traffic_out = Math.floor(Traffic_g) + ' Gbps';
              } else {
                if (traffic_out >= 1000) {
                  sendingObject.traffic_out = Math.floor(Traffic_m) + ' Mbps';
                } else {
                  sendingObject.traffic_out = Math.floor(traffic_out) + ' Kbps';
                }
              }
            } else {
              if (traffic_out >= 1000000) {
                sendingObject.traffic_in = Math.floor(Traffic_g) + ' Gbps';
              } else {
                if (traffic_out >= 1000) {
                  sendingObject.traffic_in = Math.floor(Traffic_m) + ' Mbps';
                } else {
                  sendingObject.traffic_in = Math.floor(traffic_out) + ' Kbps';
                }
              }
            }
          }
          if (metricTwo_actual !== undefined) {
            sendingObject.metricTwoActual = Math.floor(metricTwo_actual).toString();
          } else {
            sendingObject.metricTwoActual = '0';
          }
          if (metricOne_actual !== undefined) {
            sendingObject.metricOneActual = Math.floor(metricOne_actual).toString();
          } else {
            sendingObject.metricOneActual = '0';
          }
        }
        if (sendingCheck) {
          sending.push(sendingObject);
        } else {
          receiving.push(sendingObject);
        }
      }
      const { nodeHeader, nodeTitle } = this.getSettings(false);
      var node_header = nodeHeader;
      var node_title = nodeTitle;
      const iconMappings = this.getSettings(true).icons;
      const mapping = _.find(iconMappings, ({ pattern }) => {
        try {
          return new RegExp(pattern).test(selection.id().toString());
        } catch (error) {
          return false;
        }
      });

      if (mapping) {
        if (mapping.node_title !== '-') {
          node_title = mapping.node_title;
        }
        if (mapping.node_header !== '-') {
          node_header = mapping.node_header;
        }
      }
      const sendingHeader: TableHeader = {
        name_header: '-',
        label_header: '-',
        site_header: '-',
        traffic_header: '-',
        metricOne_header: '-',
        metricTwo_header: '-',
      };
      if (node_header !== undefined) {
        const header: any[] = node_header.split(',');
        if (header[0] !== undefined && header[0] !== '') {
          sendingHeader.name_header = header[0];
        } else {
          sendingHeader.name_header = '-';
        }
        if (header[1] !== undefined && header[1] !== '') {
          sendingHeader.label_header = header[1];
        } else {
          sendingHeader.label_header = '-';
        }
        if (header[2] !== undefined && header[2] !== '') {
          sendingHeader.site_header = header[2];
        } else {
          sendingHeader.site_header = '-';
        }
        if (header[3] !== undefined && header[3] !== '') {
          sendingHeader.traffic_header = header[3];
        } else {
          sendingHeader.traffic_header = '-';
        }
        if (header[4] !== undefined && header[4] !== '') {
          sendingHeader.metricOne_header = header[4];
        } else {
          sendingHeader.metricOne_header = '-';
        }
        if (header[5] !== undefined && header[5] !== '' && header[5] !== null) {
          sendingHeader.metricTwo_header = header[5];
        } else {
          sendingHeader.metricTwo_header = '-';
        }
      }
      table_header.push(sendingHeader);

      this.currentDescription = systemDescription;
      this.sys_header = sys_header;
      this.node_title = node_title;
      this.table_header = table_header;
      this.receiving = receiving;
      this.sending = sending;
      this.generateDrillDownLink('asset');
      this.generateDrillDownLink('site');
    }
  }

  updateEdgeStatisticTable() {
    const selection = this.state.cy.$(':selected');

    if (selection.length === 1) {
      const actualEdge: EdgeSingular = selection[0];
      const links: Table2Content[] = [];
      const linksHeader: Table2Header[] = [];
      const edgeMetrics: IntGraphMetrics = actualEdge.data('metrics');

      if (edgeMetrics !== undefined) {
        const { target_label, description } = edgeMetrics;
        var temp: any[] = [];
		if (description !== undefined && description !== null) {
          temp = description.split(',');
        }
        const source_Label = actualEdge.data('label');
        const target_Label = target_label;
        let i = 0;
        const length = temp.length;

        for (; i < length; ) {
          const sendingObject: Table2Content = {
            name: '-',
            label: '-',
            source_port: '-',
            source_lag: '-',
            target_label: '-',
            target_port: '-',
            target_lag: '-',
            stat: '-',
          };
          const port: any[] = temp[i].split('|');
          sendingObject.name = actualEdge.source().id();
          sendingObject.label = source_Label;
          if (port[0] !== undefined) {
            sendingObject.source_port = port[0];
          } else {
            sendingObject.source_port = 'S/D';
          }
          if (port[1] !== undefined) {
            sendingObject.source_lag = port[1];
          } else {
            sendingObject.source_lag = 'S/D';
          }
          sendingObject.target_label = target_Label;
          if (port[2] !== undefined) {
            sendingObject.target_port = port[2];
          } else {
            sendingObject.target_port = 'S/D';
          }
          if (port[3] !== undefined) {
            sendingObject.target_lag = port[3];
          } else {
            sendingObject.target_lag = 'S/D';
          }
          if (port[4] !== undefined) {
            switch (port[4]) {
              case '0':
                sendingObject.stat = 'S/D';
                break;
              case '1':
                sendingObject.stat = 'Up';
                break;
              case '2':
                sendingObject.stat = 'Down';
                break;
              case '3':
                sendingObject.stat = 'Alarm';
                break;
              default:
                sendingObject.stat = 'S/D';
            }
          } else {
            sendingObject.stat = 'Down';
          }
          links.push(sendingObject);
          i++;
        }
        this.links = links;
        const { edgeHeader, edgeTitle } = this.getSettings(false);
        var edge_header = edgeHeader;
        var edge_title = edgeTitle;
        const iconMappings = this.getSettings(true).icons;
        const mapping = _.find(iconMappings, ({ pattern }) => {
          try {
            return new RegExp(pattern).test(selection.id().toString());
          } catch (error) {
            return false;
          }
        });

        if (mapping) {
          if (mapping.links_title !== '-') {
            edge_title = mapping.links_title;
          }
          if (mapping.links_header !== '-') {
            edge_header = mapping.links_header;
          }
        }
        const sendingHeader: Table2Header = {
          label_header: '-',
          source_port_header: '-',
          source_type_header: '-',
          target_label_header: '-',
          target_port_header: '-',
          target_type_header: '-',
          status_header: '-',
        };
        if (edge_header !== undefined) {
		  const header: any[] = edge_header.split(',');
          if (header[0] !== undefined || header[0] !== '') {
            sendingHeader.label_header = header[0];
          } else {
            sendingHeader.label_header = '-';
          }
          if (header[1] !== undefined || header[1] !== '') {
            sendingHeader.source_port_header = header[1];
          } else {
            sendingHeader.source_port_header = '-';
          }
          if (header[2] !== undefined || header[2] !== '') {
            sendingHeader.source_type_header = header[2];
          } else {
            sendingHeader.source_type_header = '-';
          }
          if (header[3] !== undefined || header[3] !== '') {
            sendingHeader.target_label_header = header[3];
          } else {
            sendingHeader.target_label_header = '-';
          }
          if (header[4] !== undefined || header[4] !== '') {
            sendingHeader.target_port_header = header[4];
          } else {
            sendingHeader.target_port_header = '-';
          }
          if (header[5] !== undefined || header[5] !== '') {
            sendingHeader.target_type_header = header[5];
          } else {
            sendingHeader.target_type_header = '-';
          }
          if (header[6] !== undefined || header[6] !== '') {
            sendingHeader.status_header = header[6];
          } else {
            sendingHeader.status_header = '-';
          }
        }
        linksHeader.push(sendingHeader);
        this.linksHeader = linksHeader;
        this.edge_title = edge_title;
      }
    }
  }

  muxCollapse() {
    if (this.state.isCollapsed) {
      this.handleCollapse('GROUP_EXP');
    } else {
      this.handleCollapse('GROUP_COL');
    }
  }

  handleCollapse(action: string) {
    this.unlockNodes();
    if (action === 'GROUP_EXP') {
      this.setState({
        isCollapsed: false,
      });
    } else {
      this.setState({
        isCollapsed: true,
      });
    }
    this.state.cy.emit('nodesCollapsed');
    if (this.state.isLock) {
      this.lockNodes();
    }
  }

  handleLock() {
    if (this.state.isLock) {
      this.unlockNodes();
      this.setState({
        isLock: false,
      });
    } else {
      this.lockNodes();
      this.setState({
        isLock: true,
      });
    }
  }

  generateMapname(type: string) {
    const { map } = this.getSettings(true);
    if (map !== undefined && map !== null && map !== '') {
      const link = map.replace('{}', this.selectionId);
      this.resolvedDrillDownLink_asset = this.templateSrv.replace(link);
      return this.templateSrv.replace(link);
    } else {
      return 'default';
    }
  }
  
  generateDrillDownLink(type: string) {
    if (type === 'asset') {
      const { drillDownLink_asset } = this.getSettings(true);
      if (drillDownLink_asset !== undefined) {
        const link = drillDownLink_asset.replace('{}', this.selectionId);
        this.resolvedDrillDownLink_asset = this.templateSrv.replace(link);
        return this.templateSrv.replace(link);
      } else {
        return null;
      }
    } else {
      const { drillDownLink_site } = this.getSettings(true);
      if (drillDownLink_site !== undefined) {
        const link = drillDownLink_site.replace('{}', this.selectionId);
        this.resolvedDrillDownLink_site = this.templateSrv.replace(link);
        return this.templateSrv.replace(link);
      } else {
        return null;
      }
    }
  }

  render() {
    var show_toolbar = 'zoom-button-container';
    if (!this.toolbar) {
      show_toolbar = 'zoom-button-container-hide';
    }
	if (this.state.cy !== undefined && this.state.initResize) {
      this.zoom(0);
	  this._updateGraph(this.props.data, false);
    }
    const lockButton = () => {
      if (this.state.isLock) {
        return (
          <button className="btn button-map" title="Desbloquear mapa" onClick={() => this.handleLock()}>
            <Icon name={'lock'} size="md" />
          </button>
        );
      } else {
        return (
          <button className="btn button-map" title="Bloquear mapa" onClick={() => this.handleLock()}>
            <Icon name={'unlock'} size="md" />
          </button>
        );
      }
    };

    return (
      <>
	  <div id="tooltip-node" className="graph-tooltip graph-tooltip-hidden"></div>
	  <div id="tooltip-edge" className="graph-tooltip graph-tooltip-hidden"></div>
	  <div className="graph-container">
        <div className="netmonitor-topology-map">
          <div 
		    className="canvas-container" 
			ref={ref => (this.ref = ref)}
		  ></div>
          <div className={show_toolbar}>
            <button
              className="btn button-map_top"
              title="Guardar mapa de Topología"
              onClick={() => this.saveLayout()}
            >
              <Icon name={'save'} size="md" />
            </button>
            <button
              className="btn button-map"
              title="Refrescar mapa de Topología"
              onClick={() => {
			    this.setState({
                  zoom: this.state.cy.zoom(),
                });
				this.runLayout();
			  }}
            >
              <Icon name={'refresh'} size="md" />
            </button>
            {lockButton()}
            <button className="btn button-map" title="Zoom In" onClick={() => this.zoom(+1)}>
              <Icon name={'plus'} size="md" />
            </button>
            <button className="btn button-map_bottom" title="Zoom Out" onClick={() => this.zoom(-1)}>
              <Icon name={'minus'} size="md" />
            </button>
          </div>
        </div>
        <Statistics
          show={this.state.showStatistics}
          showEdge={this.state.showEdgeStatistics}
          selectionId={this.selectionId}
          currentDescription={this.currentDescription}
          sysHeader={this.sys_header}
          resolvedDrillDownLink_asset={this.resolvedDrillDownLink_asset}
          resolvedDrillDownLink_site={this.resolvedDrillDownLink_site}
          selectionStatistics={this.selectionStatistics}
          currentType={this.currentType}
          showBaselines={this.getSettings(true).showBaselines}
          receiving={this.receiving}
          sending={this.sending}
          nodeTitle={this.node_title}
          edgeTitle={this.edge_title}
          nodeHeader={this.table_header}
          links={this.links}
          linksHeader={this.linksHeader}
          showEdgeStatus={this.getSettings(true).showEdgeStatus}
        />
      </div>
	  </>
    );
  }
}

import React from 'react';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { Icon } from '@grafana/ui';
import { ReportOptions, ReportData } from 'types';
import { config } from '@grafana/runtime';
import './css/netmonitor-reportManager.css';

interface Props extends PanelProps<ReportOptions> {}

export const reportManagerPanel: React.FC<Props> = ({ width, height, options, data, replaceVariables }) => {
  const isDark = config.theme.isDark;
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);
  let reportManager_container = 'reportManager_container';
  if (isDark) {
    reportManager_container = 'reportManager_container_dark';
  }
  let base_url = replaceVariables(options.drillDownLink);
  if (base_url !== null || base_url !== undefined) {
    base_url = '';
  }
  let separator = options.separator;
  if (separator === null || separator === undefined) {
    separator = '';
  }
  const reports: ReportData[] = [];

  if (width < 350 || height < 250) {
    return (
      <div>
        <div className="gf-form-error">{error4}</div>
      </div>
    );
  } else if (data.state !== 'Error') {
    if(data.series[0].length > 0) {
      data.series.forEach(series => {
        const timeVals: GraphSeriesValue[] = series.fields[0].values.toArray();
        for (let i = 0; i < timeVals.length; i++) {
          let reportData: ReportData = {
            id: i,
            tag: series.fields.find(field => field.name === options.reportTagOption)?.values.get(i),
            value: series.fields.find(field => field.name === options.reportValueOption)?.values.get(i),
            style: series.fields.find(field => field.name === options.reportStyleOption)?.values.get(i),
            title: series.fields.find(field => field.name === options.reportTitleOption)?.values.get(i),
            url: series.fields.find(field => field.name === options.reportUrlOption)?.values.get(i),
            icon: series.fields.find(field => field.name === options.reportIconOption)?.values.get(i),
            target: series.fields.find(field => field.name === options.reportUrlTargetOption)?.values.get(i),
          };
          if (reportData.style === null || reportData.style === undefined || reportData.style === '') {
            reportData.style = 'default';
          }
          if (isDark && reportData.style === 'default') {
            reportData.style = 'default_dark';
          }
          if (reportData.icon === null || reportData.icon === undefined || reportData.icon === '') {
            reportData.icon = 'file-graph';
          }
          if (reportData.title === null || reportData.title === undefined) {
            reportData.title = reportData.tag;
          }
          if (reportData.target === null || reportData.target === undefined || reportData.target !== '_self') {
            reportData.target = '_blank';
          }
          if (reportData.url === null || reportData.url === undefined || String(reportData.url).length < 1) {
            reportData.url = '';
            reportData.target = '';
          }
          if (base_url !== '') {
            reportData.url = base_url + reportData.url;
          }
          reports.push(reportData);
        }
      });
      return <div className={reportManager_container}>{rowFactory(reports)}</div>;
    } else {
        return (
          <div>
            <div className="gf-form-error">{error1}</div>
          </div>
        );
    } 
  } else {
    return (
      <div>
        <div className="gf-form-error">{error3}</div>
      </div>
    );
  }
};

function rowFactory(reportData: ReportData[]) {
  const isDark = config.theme.isDark;
  var reportManagerRowTitle = 'reportManager-rowTitle';
  var reportManagerRowsubTitle = 'reportManager-rowsubTitle';
  var reportManagerRowValue = 'reportManager-rowValue';
  var reportManagerRowContainer = 'reportManager-rowContainer';
  if (isDark) {
    reportManagerRowTitle = 'reportManager-rowTitle_dark';
    reportManagerRowsubTitle = 'reportManager-rowsubTitle_dark'; 
    reportManagerRowValue = 'reportManager-rowValue_dark';
    reportManagerRowContainer = 'reportManager-rowContainer_dark';
  }
  return reportData.map(row => (
    <div key={row.id} className={reportManagerRowContainer}>
      <a
        rel="nofollow noopener noreferrer"
        title="Clic para generar informe"
        className="rowReportContainer"
        href={row.url}
        target={row.target}
      >
        <span className="reportManagerIcon">
          <Icon
            name={row.icon}
            size="xl"
          />
        </span>
        <span className="reportManagerTitleContanier">
          <div className={reportManagerRowTitle}><b>{row.value}</b></div>
          <div className={reportManagerRowsubTitle}>{row.title}</div>
        </span>
        <span className="reportManagerTag">
          <span className={reportManagerRowValue + ' reportManager_' + row.style}>{row.tag}</span>
        </span>
      </a>
    </div>
  ));
}

import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getBackendSrv } from '@grafana/runtime';
import { Icon, ClipboardButton } from '@grafana/ui';
import { createAsyncThunk, Invitee } from 'app/types';
import { revokeInvite } from 'app/features/invites/state/actions';
import { contextSrv } from 'app/core/core';

export const InviteeRow = (invitee: Invitee) => {
  const [inviteeRevoked, setInviteeRevoked] = useState<Invitee>(invitee);
  const isAdmin = contextSrv.isNetMonitorAdmin;

  const role = invitee.invitee.role === 'Editor' ? 'supervisor' : 'tecnico';
  const invitedBy = invitee.invitee.invitedByLogin;
  const confirmed = invitee.invitee.status === 'InvitePending' ? 'envelope-block' : 'envelope-check';
  const roleTitle = invitee.invitee.role === 'Editor' ? 'Usuario con funciones de Supervisor' : 'Usuario de operaciones';
  const invitedByTitle = 'Supervisor que dio de alta el usuario';
  const confirmedTitle = invitee.invitee.status === 'InvitePending' ? 'El usuario aun no fue activado' : 'El usuario fue activado';

  if (inviteeRevoked !== null) {
    return (
      <tr>
        <td>{invitee.invitee.name}</td>
        <td>{invitee.invitee.email}</td>
        <td title={invitedByTitle}>{invitedBy}</td>
        <td style={{ textAlign: 'center' }}>
          <Icon
            size="lg"
            name={role}
            title={roleTitle}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <Icon
            size="lg"
            name={confirmed}
            title={confirmedTitle}
          />
        </td>
      </tr>
    );
  } else {
    return null;
  }
}

export default InviteeRow;

import React, { Component } from 'react';
import './css/netmonitor-tabs.css';
import './css/tabs-multiSelect.css';
import { TabsData } from './types';
import { changeUrl, openUrl, setBackUrl } from './utils';
import { config } from '@grafana/runtime';
import Select, { components } from 'react-select';
import { Icon } from '@grafana/ui';

interface TabsSelectProps {
  TabsData: TabsData[];
}

export class TabsSelect extends Component<TabsSelectProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedValue: {},
      selectOptions: [],
    };
  }

  async getOptions() {
    const options = this.props.TabsData.map((option, index) => ({
      value: index,
      label: option.text,
      icon: option.icon,
    }));
    this.setState({ selectOptions: options });
  }

  handleChange(item) {
    this.setState({ selectedValue: item });
    const valueToSet = this.props.TabsData.find(row => {
      return row.text === item.label;
    });
    if (valueToSet) {
      if (valueToSet.url !== 'null' && valueToSet.url !== '') {
        openUrl(valueToSet.url, valueToSet.updateRoute, valueToSet.useBack, valueToSet.varBack, valueToSet.varBack1);
      } else {
        const item = {
          label: valueToSet.text,
          value: String(valueToSet.id),
          icon: valueToSet.icon,
        };
        this.setState({ selectedValue: item });
        const queryMap = {
          [`var-${valueToSet.variable}`]: valueToSet.value,
        };
        changeUrl(queryMap, valueToSet.useBack, valueToSet.varBack, valueToSet.varBack1);
      }
    }
  }

  componentDidMount() {
    this.getOptions();
    const valueToSet = this.props.TabsData.find(row => {
      return row.primary === true;
    });
    if (valueToSet) {
      const item = {
        label: valueToSet.text,
        value: String(valueToSet.id),
        icon: valueToSet.icon,
      };
      this.setState({ selectedValue: item });
      const queryMap = {
        [`var-${valueToSet.variable}`]: valueToSet.value,
      };
      setBackUrl(valueToSet.varBack, valueToSet.varBack1);
      changeUrl(queryMap, valueToSet.useBack, valueToSet.varBack, valueToSet.varBack1);
    }
  }

  render() {
    const { selectedValue = {} } = this.state;
    const customStyles = {
      menu: provided => ({
        ...provided,
        color: '#000',
        background: '#fafafa',
        border: '1px solid #dde4ed',
        borderRadius: '5px',
        marginTop: '5px',
        marginBottom: '5px',
        zIndex: 9999,
      }),
      menuPortal: provided => ({
        ...provided,
        zIndex: 9999,
      }),
    };
    let classSelect = 'react-select-tab';
    let isDark = config.theme.isDark || false;
    if (isDark) {
      classSelect = 'react-select-tab_dark';
      customStyles = {
        menu: provided => ({
          ...provided,
          color: '#fafafa',
          background: '#222426',
          border: '1px solid #5a5a5a',
          borderRadius: '5px',
          marginTop: '5px',
          marginBottom: '5px',
          zIndex: 9999,
        }),
        option: (base, state) => ({
          ...base,
          color: '#fafafa',
          background: '#222426',
          marginTop: '-4px',
          marginBottom: '-4px',
          '&:hover': {
            color: '#fff',
            background: '#5a5a5a',
          },
        }),
        menuPortal: provided => ({
          ...provided,
          zIndex: 9999,
        }),
      };
    }
    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <Icon name="menu_dark" size="lg" />
        </components.DropdownIndicator>
      );
    };
    return (
      <Select
        className={classSelect}
        classNamePrefix={classSelect}
        components={{ DropdownIndicator }}
        value={selectedValue}
        onChange={this.handleChange.bind(this)}
        options={this.state.selectOptions}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        styles={customStyles}
        getOptionLabel={e => (
          <div style={{ display: 'flex', height: '20px' }}>
            <Icon name={e.icon} size="md"></Icon>
            <p style={{ marginLeft: 10, marginTop: 1 }}>{e.label}</p>
          </div>
        )}
      />
    );
  }
}

import { css, cx } from '@emotion/css';
import React from 'react';

import { NetMonitorTheme2, GraphSeriesValue } from '@grafana/data';

import { useStyles2 } from '../../themes';
//import { SeriesIcon } from '../VizLegend/SeriesIcon';

/**
 * @public
 */
export interface SeriesTableRowProps {
  color?: string;
  label?: React.ReactNode;
  value?: string | GraphSeriesValue;
  isActive?: boolean;
}

const getSeriesTableRowStyles = (theme: NetMonitorTheme2) => {
  return {
    icon: css`
      margin-right: ${theme.spacing(1)};
      vertical-align: middle;
    `,
    seriesTable: css`
      display: table;
	  min-width: 80px;
	  padding: ${theme.spacing(2)};
    `,
    seriesTableRow: css`
      display: table-row;
      font-size: 11px;
	  padding: ${theme.spacing(1)};
    `,
    seriesTableCell: css`
      display: table-cell;
    `,
    label: css`
      word-break: break-all;
	  font-weight: ${theme.typography.fontWeightBold};
    `,
    value: css`
      padding-left: ${theme.spacing(1)};
    `,
    activeSeries: css`
      color: ${theme.colors.text.maxContrast};
    `,
    timestamp: css`
      padding-top: ${theme.spacing(1)};
      font-size: 11px;
    `,
  };
};

/**
 * @public
 */
export const SeriesTableRow: React.FC<SeriesTableRowProps> = ({ color, label, value, isActive }) => {
  const styles = useStyles2(getSeriesTableRowStyles);

  return (
    <div data-testid="SeriesTableRow" className={cx(styles.seriesTableRow, isActive && styles.activeSeries)}>
      {label && <div className={cx(styles.seriesTableCell, styles.label)}>{label}:</div>}
      {value && <div className={cx(styles.seriesTableCell, styles.value)}>{value}</div>}
    </div>
  );
};

/**
 * @public
 */
export interface SeriesTableProps {
  timestamp?: string | GraphSeriesValue;
  series: SeriesTableRowProps[];
}

/**
 * @public
 */
export const SeriesTable: React.FC<SeriesTableProps> = ({ timestamp, series }) => {
  const styles = useStyles2(getSeriesTableRowStyles);

  return (
    <>
      {series.map((s, i) => {
        return (
          <SeriesTableRow
            isActive={s.isActive}
            label={s.label}
            color={s.color}
            value={s.value}
            key={`${s.label}-${i}`}
          />
        );
      })}
      {timestamp && (
        <div className={cx(styles.seriesTableRow)}>
		  <div className={cx(styles.seriesTableCell, styles.label)}>Muestra:</div>
		  <div className={cx(styles.seriesTableCell, styles.value)} aria-label="Timestamp">{timestamp}</div>
		</div>
      )}
    </>
  );
};

import { FieldSelectEditor } from './FieldSelectEditor';
import { PanelPlugin } from '@grafana/data';
import { ReportInfoOptions } from './types';
import { reportPanel } from './ReportPanel';

export const plugin = new PanelPlugin<ReportInfoOptions>(reportPanel).setPanelOptions(builder => {
  return builder

    .addTextInput({
      path: 'minWidth',
      name: 'Report mode min width',
      description: 'Switch to dropdown mode when panel width in minor to this threshold',
      category: ['General Settings'],
      defaultValue: '250',
    })
    .addCustomEditor({
      id: 'reportTitleOption',
      path: 'reportTitleOption',
      name: 'Cell Tooltip Field',
      description: 'The tooltip shows within the value',
      defaultValue: 'title',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'reportTagOption',
      path: 'reportTagOption',
      name: 'Report Tag Field',
      description: 'The field that will be used as Tag',
      defaultValue: 'tag',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'reportValueOption',
      path: 'reportValueOption',
      name: 'Report Value Field',
      description: 'The cell value to be asigned',
      defaultValue: 'value',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'reportStyleOption',
      path: 'reportStyleOption',
      name: 'Report Style Field',
      description: 'The value color: White, Grey, Black, Red, Yellow, Orange, Green and Blue',
      defaultValue: 'style',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'reportIconOption',
      path: 'reportIconOption',
      name: 'Report Icon Field',
      defaultValue: 'icon',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'reportIndicatorOption',
      path: 'reportIndicatorOption',
      name: 'Report Indicator Field',
      defaultValue: 'indicator',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'reportChangeOption',
      path: 'reportChangeOption',
      name: 'Report Change Text Field',
      defaultValue: 'change',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'reportUrlOption',
      path: 'reportUrlOption',
      name: 'Cell Url Field',
      description: 'The Url to use in value content',
      defaultValue: 'url',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'reportUrlTargetOption',
      path: 'reportUrlTargetOption',
      name: 'Cell Url target Field',
      description: 'Target to use in value url',
      defaultValue: '_blank',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })

    .addNumberInput({
      path: 'iconSize',
      name: 'Icon size',
      category: ['General Settings'],
      defaultValue: 16,
    })
    .addNumberInput({
      path: 'mainFont',
      name: 'Main Title Font size',
      category: ['General Settings'],
      defaultValue: 18,
    })
    .addNumberInput({
      path: 'secFont',
      name: 'SubTitle Font size',
      category: ['General Settings'],
      defaultValue: 14,
    })
    .addColorPicker({
      path: 'mainColor',
      name: 'Main Title Font color',
      category: ['General Settings'],
      defaultValue: '#007865',
    })
    .addColorPicker({
      path: 'secColor',
      name: 'SubTitle Font color',
      category: ['General Settings'],
      defaultValue: '#888',
    })
    .addTextInput({
      path: 'drillDownLink',
      name: 'infoReport base URL',
      category: ['Link to other features'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'No existen opciones',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error al cargar el complemento',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});

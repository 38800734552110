import { css } from '@emotion/css';
import { Trans, t } from '@lingui/macro';
import React, { PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import UserInviteForm from './UserInvite';
import { PanelProps } from '@grafana/data';
import { 
  VerticalGroup,
  Button,
  LinkButton,
  Input,
  Switch,
  RadioButtonGroup,
  Form,
  Field,
  InputControl,
  FieldSet
} from '@grafana/ui';
import { getBackendSrv, locationService } from '@grafana/runtime';
import { UsersManagerPanelOptions } from './types';
import { OrgUser, OrgRole, Invitee } from 'app/types';
import { contextSrv } from 'app/core/core';
import { accessControlQueryParam } from 'app/core/utils/accessControl';
import UsersTable from './UsersTable';

interface Props extends PanelProps<UsersManagerPanelOptions> {}

export interface State {
  showInvites: boolean;
  userList: OrgUser[];
  invitees: Invitee[];
  isAdmin: boolean;
  showNewUser: boolean;
}

export class UsersManager extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showInvites: false,
      userList: [],
      invitees: [],
      isAdmin: false,
      showNewUser: false,
    };
  }

  componentDidMount() {
    const { options } = this.props;
    if (contextSrv.isNetMonitorAdmin || (options.editorCanAdmin && contextSrv.isEditor)) {
      this.setState((prevState) => ({
        isAdmin: true,
      }));
	  this.updateUsersList();
    }
  }

  updateUsersList() {
    this.fetchUsers().then((result) => {
      this.setState((prevState) => ({
        userList: result,
      }));
    });
    this.fetchInvitees().then((result) => {
      this.setState((prevState) => ({
        invitees: result,
      }));
    });
  }

  fetchInvitees() {
    return getBackendSrv().get('/api/org/invites');
  }

  fetchUsers() {
    //return getBackendSrv().get('/api/org/users', accessControlQueryParam());
    return getBackendSrv().get('/api/org/users');
  }

  /*onShowInvites = () => {
    this.setState((prevState) => ({
      showInvites: !prevState.showInvites,
    }));
  };*/

  onRoleChange = (role: OrgRole, user: OrgUser) => {
    const updatedUser = { ...user, role: role };
    const update = getBackendSrv().patch(`/api/org/users/${updatedUser.userId}`, { role: role });
    this.updateUsersList();
    this.render();
  };

  onRemoveUser = (userId: number) => {
    const update = getBackendSrv().delete(`/api/org/users/${userId}`);
    this.updateUsersList();
    this.render();
  };

  /*onSubmit = (formData: FormModel) => {
    this.setState((prevState) => ({
      showNewUser: false,
    }));
  };*/

  renderTable(users: OrgUser[]) {
    return (
      <VerticalGroup spacing="md">
        <UsersTable
          isAdmin={this.state.isAdmin}
          users={users}
          orgId={contextSrv.user.orgId}
          onRoleChange={(role, user) => this.onRoleChange(role, user)}
          onRemoveUser={(user) => this.onRemoveUser(user.userId)}
        />
      </VerticalGroup>
    );
  }

  render() {
    const { options } = this.props;
    let titleTextToUse = 'Usuarios de la Organización';
    if (options.pluginTitle !== undefined && options.pluginTitle !== null && options.pluginTitle !== '') {
      titleTextToUse = options.pluginTitle;
    }
    let hasUsers = false;
    if (this.state.userList.length > 0) {
      hasUsers = true;
    }
	console.log(this.state.isAdmin);

    return (
      <div>
        <div isLoading={!hasUsers}>
          <div className={styles.title}>
            {options.showTitle && (
              <div className={styles.titleText}>{titleTextToUse}</div>
            )}
            {!options.showTitle && (
              <div className={styles.titleText}>
                <Trans id="users-orgs.title">Usuarios en mi Organización</Trans>
              </div>
            )}
            {this.state.isAdmin && (
              <div className={styles.buttonBox}>
                <Button
                  type="button"
                  variant={this.state.showNewUser ? 'secondary' : 'primary'}
                  icon={this.state.showNewUser ? 'users-alt' : 'user-plus'}
                  size="md"
                  title={this.state.showNewUser
                    ? t({ id: 'users-orgs.close', message: 'Ver usuarios activos' }) 
                    : t({ id: 'users-orgs.add', message: 'Agregar usuario' })
                  }
                  onClick={() => {
                    this.setState((prevState) => ({
                      showNewUser: !prevState.showNewUser,
                    }));
                  }}
                />
              </div>
            )}
          </div>          
          {!this.state.showNewUser && this.state.isAdmin && (
            <div>
              {hasUsers && this.state.isAdmin && this.renderTable(this.state.userList)}
            </div>
          )}
		  {!this.state.isAdmin && (
			<div className={styles.titleText}>
			  <Trans id="users-orgs.nopermision">Ud no posee privilegios para ver los usuarios del sistema</Trans>
			</div>
		  )}
        </div>
        {this.state.showNewUser && this.state.isAdmin && (
          <UserInviteForm invitees={this.state.invitees} options={options} />
        )}
      </div>
    );
  }
}

const styles = {
  title: css`
    width: calc(100% - 32px);
    display: inline-flex;
    margin: 12px 0px 12px 0px;
  `,
  titleText: css`
    width: calc(100% - 90px);
    margin-right: 8px;
    font-size: 20px;
    font-weight: 500;
  `,
  buttonBox: css`
      display: flex;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    justify-content: space-around;
    margin-bottom: 0px;
    width: 90px;
  `,
};

import React, { useState } from 'react';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { config } from '@grafana/runtime';
import { TabsData, GenericButtonData, GenericIndicatorData, SimpleOptions } from 'types';
import { TabsSelect } from './TabsSelect';
import { TabsButton, TabsButtonAux, GenericButton, GenericIndicator, BackButton } from './TabsButton';

interface TabPanelProps extends PanelProps<SimpleOptions> {}

export const TabPanel: React.FC<TabPanelProps> = ({ options, data, width, height, replaceVariables }) => {
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);

  const buttons: TabsData[] = [];
  const [initialTab, setInitialTab] = useState<Number>(0);
  const backAuxTab: TabsData[] = [];
  const backAuxButton: GenericButtonData[] = [];
  const leftAuxButton: GenericButtonData[] = [];
  const rightAuxButton: GenericButtonData[] = [];
  const primary = Number(replaceVariables(options.defaultPrimary));

  var primaryButton = 0;
  if (primary !== null || primary !== undefined) {
    primaryButton = primary;
  }
  const buttons2: GenericButtonData[] = [];
  const buttons3: GenericIndicatorData[] = [];
  var tabModeEnable = true;
  if (options.displayStyle === 'dropdown' || width < 480) {
    tabModeEnable = false;
  }

  var useBack = false;
  var varBack = 'null';
  var varBack1 = 'null';
  if (options.useVarBackVariables === true) {
    useBack = true;
    varBack = replaceVariables(options.varBackVariable);
    varBack1 = replaceVariables(options.varBack1Variable);
  }
  var tabsCount = 0;
  var tabsWindow = -1;
  var finalTab = 0;
  var showLeftTab = false;
  var showRightTab = false;
  var maxTabContainerWidth = width * 0.92;
  var tabsWidth = 0;
  const font = '13px times new roman';

  if (width < 200) {
    return (
      <div>
        <div className="gf-form-error">{error4}</div>
      </div>
    );
  } else if (data.state !== 'Error') {
    if(data.series) {
      data.series.forEach(series => {
        const tabsVals: GraphSeriesValue[] = series.fields[0].values.toArray();
        finalTab = tabsVals.length;
        maxTabContainerWidth = maxTabContainerWidth - 80;
        if (useBack) {
          maxTabContainerWidth = maxTabContainerWidth - 105 - options.tabMinWidth;
        }
        for (let i = 0; i < tabsVals.length; i++) {
          let elementType = series.fields.find(field => field.name === options.buttonTypeOption)?.values.get(i);
          let elementText = series.fields.find(field => field.name === options.buttonTextOption)?.values.get(i);
          if (elementType === 'tab') {
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            context.font = font;
            let newTabWidth = context.measureText(elementText).width;
            tabsWidth = tabsWidth + Math.ceil(newTabWidth)  + 54;
			tabsCount = tabsCount + 1;
          } else if (elementType === 'button') {
            tabsWidth = tabsWidth + 40;
          } else {
			let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            context.font = font;
            let newTabWidth = context.measureText(elementText).width;
            tabsWidth = tabsWidth + Math.ceil(newTabWidth)  + 22;
          }
          if (tabsWidth < maxTabContainerWidth && elementType === 'tab') {
            tabsWindow = tabsWindow + 1;
          }
        }
        if (tabsWidth > maxTabContainerWidth && tabsCount > tabsWindow) {
          finalTab = initialTab + tabsWindow;
          if (finalTab <= tabsCount) {
            tabsWindow = tabsWindow - 1;
            finalTab = finalTab - 1;
            showRightTab = true;
            if (initialTab > 0) {
              showLeftTab = true;
            }
          } else {
            if (initialTab > 0) {
              tabsWindow = tabsWindow - 1;
              finalTab = finalTab - 1; 
              showLeftTab = true;
            }
          }
        }
        if (options.backButton && tabModeEnable) {
          let back_tab: TabsData = {
            text: replaceVariables(options.backButtonText),
            variable: 'Volver',
            value: 'Volver',
            icon: 'arrow-bar-left',
            url: '/d/home',
            title: replaceVariables(options.backButtonText),
            style: replaceVariables(options.backButtonStyle),
            useBack: false,
            varBack: 'null',
            varBack1: 'null',
          };
          back_tab.url = replaceVariables(options.backButtonUrl);
          back_tab.useBack = useBack;
          back_tab.varBack = String(varBack);
          back_tab.varBack1 = String(varBack1);
          backAuxTab.push(back_tab);
        } else {
          var aux_backButton: GenericButtonData = {
            text: 'Volver',
            variable: 'Volver',
            value: 'Volver',
            icon: 'arrow-bar-left',
            url: '/d/home',
            title: replaceVariables(options.backButtonText),
            style: replaceVariables(options.backButtonStyle),
            useBack: false,
            varBack: 'null',
            varBack1: 'null',
          };
          aux_backButton.url = replaceVariables(options.backButtonUrl);
          aux_backButton.useBack = useBack;
          aux_backButton.varBack = String(varBack);
          aux_backButton.varBack1 = String(varBack1);
          backAuxButton.push(aux_backButton);
        }
        if (tabModeEnable && showLeftTab) {
          let left_tab: TabsData = {
            text: '...',
            variable: '',
            value: 'Left',
            icon: 'angle-double-left',
            url: '',
            title: 'Ver ' + String(initialTab) + ' pestañas más',
            style: '',
            useBack: false,
            varBack: 'null',
            varBack1: 'null',
          };
          leftAuxButton.push(left_tab);
        }
        if (tabModeEnable && showRightTab) {
          let right_tab: TabsData = {
            text: '...',
            variable: '',
            value: 'Rigth',
            icon: 'angle-double-right',
            url: '',
            title: 'Ver ' + String(tabsCount - finalTab) + ' pestañas más',
            style: '',
            useBack: false,
            varBack: 'null',
            varBack1: 'null',
          };
          rightAuxButton.push(right_tab);
        }
        for (let i = -1; i < tabsVals.length; i++) {
          if (options.displayStyle === 'dropdown' && i < 0) {
            let aux_button: TabsData = {
              id: 0,
              text: options.defaultValue || 'Todos',
              variable: options.defaultVariable,
              value: options.defaultValue || 'Todos',
              result: '',
              style: options.backButtonStyle || 'default',
              title: 'Ver Todos',
              url: 'null',
              icon: 'refresh',
              useBack: false,
              varBack: 'null',
              varBack1: 'null',
            };
            buttons.push(aux_button);
          } else {
            let type = series.fields.find(field => field.name === options.buttonTypeOption)?.values.get(i);
            if (typeof type !== 'undefined' && type === 'tab') {
              let tab: TabsData = {
                text: series.fields.find(field => field.name === options.buttonTextOption)?.values.get(i),
                variable: series.fields.find(field => field.name === options.buttonVariableOption)?.values.get(i),
                value: series.fields.find(field => field.name === options.buttonValueOption)?.values.get(i),
                result: series.fields.find(field => field.name === options.buttonResultOption)?.values.get(i),
                style: series.fields.find(field => field.name === options.buttonStyleOption)?.values.get(i),
                title: series.fields.find(field => field.name === options.buttonTitleOption)?.values.get(i),
                url: series.fields.find(field => field.name === options.buttonUrlOption)?.values.get(i),
                updateRoute: series.fields.find(field => field.name === options.buttonUpdateUrlRoute)?.values.get(i),
                icon: series.fields.find(field => field.name === options.buttonIconOption)?.values.get(i),
                useBack: false,
                varBack: 'null',
                varBack1: 'null',
              };
			  let primarySelect = Number(tab.value);
			  if (primarySelect === NaN) {
			    if (primaryButton === i) {
				  tab.primary = true;
				}
			  } else if (primaryButton === primarySelect) {
                tab.primary = true;
              } else {
                tab.primary = false;
              }
              tab.id = i + 1;
              tab.text = String(tab.text);
              tab.variable = String(tab.variable);
              tab.value = String(tab.value);
              tab.result = String(tab.result);
              tab.style = String(tab.style);
              tab.title = String(tab.title);
              tab.icon = String(tab.icon);
              if (tab.url !== undefined && tab.url !== null) {
                tab.url = replaceVariables(String(tab.url));
              } else {
                tab.url = 'null';
              }
              if (tab.updateRoute === undefined) {
                tab.updateRoute = false;
              }
              tab.useBack = useBack;
              tab.varBack = String(varBack);
              tab.varBack1 = String(varBack1);
              if (tabModeEnable) {
                if (i >= initialTab && i < finalTab) {
                  buttons.push(tab);
                }
              } else {
                buttons.push(tab);
              }
            } else if (typeof type !== 'undefined' && type === 'button') {
              var button: GenericButtonData = {
                text: series.fields.find(field => field.name === options.buttonTextOption)?.values.get(i),
                variable: series.fields.find(field => field.name === options.buttonVariableOption)?.values.get(i),
                value: series.fields.find(field => field.name === options.buttonValueOption)?.values.get(i),
                icon: series.fields.find(field => field.name === options.buttonIconOption)?.values.get(i),
                url: series.fields.find(field => field.name === options.buttonUrlOption)?.values.get(i),
                updateRoute: series.fields.find(field => field.name === options.buttonUpdateUrlRoute)?.values.get(i),
                title: series.fields.find(field => field.name === options.buttonTitleOption)?.values.get(i),
                style: series.fields.find(field => field.name === options.buttonStyleOption)?.values.get(i),
                useBack: false,
                varBack: 'null',
                varBack1: 'null',
              };
              button.id = i + 1;
              button.text = String(button.text);
              button.variable = String(button.variable);
              button.value = String(button.value);
              button.style = String(button.style);
              button.title = String(button.title);
              button.icon = String(button.icon);
              if (button.url !== undefined && button.url !== null) {
                button.url = replaceVariables(String(button.url));
              } else {
                button.url = 'null';
              }
              if (button.updateRoute === undefined) {
                button.updateRoute = false;
              }
              button.useBack = useBack;
              button.varBack = String(varBack);
              button.varBack1 = String(varBack1);
              buttons2.push(button);
            } else if (typeof type !== 'undefined' && type === 'indicator') {
              let indicator: GenericIndicatorData = {
                text: series.fields.find(field => field.name === options.buttonTextOption)?.values.get(i),
                result: series.fields.find(field => field.name === options.buttonResultOption)?.values.get(i),
                value: series.fields.find(field => field.name === options.buttonValueOption)?.values.get(i),
                url: series.fields.find(field => field.name === options.buttonUrlOption)?.values.get(i),
                updateRoute: series.fields.find(field => field.name === options.buttonUpdateUrlRoute)?.values.get(i),
                title: series.fields.find(field => field.name === options.buttonTitleOption)?.values.get(i),
                style: series.fields.find(field => field.name === options.buttonStyleOption)?.values.get(i),
                useBack: false,
                varBack: 'null',
                varBack1: 'null',
              };
              indicator.id = i + 1;
              indicator.text = String(indicator.text);
              indicator.result = String(indicator.result);
              indicator.value = String(indicator.value);
              indicator.style = String(indicator.style);
              indicator.title = String(indicator.title);
              if (indicator.url !== 'undefined' && indicator.url !== null) {
                indicator.url = replaceVariables(String(indicator.url));
              } else {
                indicator.url = 'null';
              }
              if (indicator.updateRoute === undefined) {
                indicator.updateRoute = false;
              }
              indicator.useBack = useBack;
              indicator.varBack = String(varBack);
              indicator.varBack1 = String(varBack1);
              buttons3.push(indicator);
            }
          }
        }
        if (options.rotateButton) {
          var rotate_button: GenericButtonData = {
            text: 'Rotar',
            variable: options.defaultVariable,
            value: options.defaultValue || 'Rotar',
            icon: 'rotate-360',
            url: replaceVariables(options.rotateButtonUrl) || '#',
            title: 'Rotar entre pestañas',
            style: options.backButtonStyle || 'default',
            useBack: false,
            varBack: 'null',
            varBack1: 'null',
          };
          rotate_button.variable = replaceVariables(rotate_button.variable);
          rotate_button.value = replaceVariables(rotate_button.value) || '';
          rotate_button.useBack = useBack;
          rotate_button.varBack = String(varBack);
          rotate_button.varBack1 = String(varBack1);
          buttons2.push(rotate_button);
        }
      });
	  if (tabsWidth < maxTabContainerWidth && initialTab > 0) {
		setInitialTab(0);
		finalTab = initialTab + tabsWindow;
		showLeftTab = false;
		showRightTab = false;
	  }

      var tab_container = 'tab_container_light';
      var separador_v = 'separadorV_light';
      var separador_h = 'separadorH_light';
      let isDark = config.theme.isDark || false;
      if (isDark) {
        tab_container = 'tab_container_dark';
        separador_v = 'separadorV_dark';
        separador_h = 'separadorH_dark';
      }

      const separador_h_short = separador_h + ' width_30px';
      return (
        <div>
          {!tabModeEnable && (
            <div className="tab_dropdown__container">
              {options.backButton && <div className="tab_backButton_container">{buttonFactory4(backAuxButton)}</div>}
              <TabsSelect TabsData={buttons} />
            </div>
          )}
          {tabModeEnable && (
            <div className="tab_panel_container">
              <div className="tab_pagination">
                <div className={tab_container}>
                  {options.backButton && (
                    <>
                      {buttonFactory1(backAuxTab)}
                    </>
                  )}
                  {showLeftTab && (
                    <div
                      className="tab_button_container"
                      onClick={() => {
                        if (initialTab > 0) {
                          setInitialTab(initialTab - 1);
                        }
                      }}
                    >
                      {buttonFactory5(leftAuxButton)}
                    </div>
                  )}
                  <>
                    {buttonFactory1(buttons)}
                  </>
                  {showRightTab && (
                    <div
                      className="tab_button_container"
                      onClick={() => {
                        if (finalTab < tabsCount) {
                          setInitialTab(initialTab + 1);
                        }
                      }}
                    >
                      {buttonFactory5(rightAuxButton)}
                    </div>
                  )}
                  <div className={separador_h}></div>
                </div>
                <div className="tab_button_container">
                  {buttonFactory2(buttons2)}
                  <div className={separador_h_short}></div>
                </div>
                <div className="tab_button_container">{buttonFactory3(buttons3)}</div>
              </div>
              <div className={separador_v}></div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <div className="gf-form-error">{error2}</div>
        </div>
      );
    }
  } else {
    return (
      <div>
        <div className="gf-form-error">{error3}</div>
      </div>
    );
  }
};

function buttonFactory1(buttons: TabsData[]) {
  return buttons.map(tab => (
    <TabsButton
      id={tab.id}
      key={tab.text}
      text={tab.text}
      icon={tab.icon}
      variable={tab.variable}
      value={tab.value || ''}
      result={tab.result || ''}
      primary={tab.primary}
      style={tab.style}
      url={tab.url || 'null'}
      updateRoute={tab.updateRoute}
      title={tab.title}
      useBack={tab.useBack}
      varBack={tab.varBack}
      varBack1={tab.varBack1}
    />
  ));
}

function buttonFactory2(buttons2: GenericButtonData[]) {
  return buttons2.map(button => (
    <GenericButton
      id={button.id}
      key={button.text}
      icon={button.icon}
      variable={button.variable}
      value={button.value || ''}
      title={button.title}
      style={button.style}
      url={button.url || 'null'}
      updateRoute={button.updateRoute}
      useBack={button.useBack}
      varBack={button.varBack}
      varBack1={button.varBack1}
    />
  ));
}

function buttonFactory3(buttons3: GenericIndicatorData[]) {
  return buttons3.map(indicator => (
    <GenericIndicator
      key={indicator.text}
      result={indicator.result}
      value={indicator.value || ''}
      title={indicator.title}
      style={indicator.style}
      url={indicator.url || 'null'}
      updateRoute={indicator.updateRoute}
      useBack={indicator.useBack}
      varBack={indicator.varBack}
      varBack1={indicator.varBack1}
    />
  ));
}

function buttonFactory4(buttons4: GenericButtonData[]) {
  return buttons4.map(button => (
    <BackButton
      id={button.id}
      key={button.text}
      icon={button.icon}
      variable={button.variable}
      value={button.value || ''}
      title={button.title}
      style={button.style}
      url={button.url || 'null'}
      updateRoute={button.updateRoute}
      useBack={button.useBack}
      varBack={button.varBack}
      varBack1={button.varBack1}
    />
  ));
}

function buttonFactory5(buttons: TabsData[]) {
  return buttons.map(tab => (
    <TabsButtonAux
      id={tab.id}
      key={tab.text}
      text={tab.text}
      icon={tab.icon}
      variable={tab.variable}
      value={tab.value || ''}
      result={tab.result || ''}
      primary={tab.primary}
      style={tab.style}
      url={tab.url || 'null'}
      updateRoute={tab.updateRoute}
      title={tab.title}
      useBack={tab.useBack}
      varBack={tab.varBack}
      varBack1={tab.varBack1}
    />
  ));
}
import React, { useEffect, useState } from 'react';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { config } from '@grafana/runtime';
import { Icon } from '@grafana/ui';
import { SimpleOptions, GenericUrlData} from './types';
import './css/IPCheckPanel.css';

interface Props extends PanelProps<SimpleOptions> {}

export const IPCheckPanel: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {
  const isDark = config.theme.isDark || false;
  const rootUrl = config.appUrl;
  const defaultImage =  rootUrl + 'public/img/login_background_light.svg';
  const d = new Date();
  const mainFont = options.mainFont;
  const secFont = options.secFont;
  const mainColor = options.mainColor;
  const secColor = options.secColor;
  let checkPanel_container = 'checkPanel_container';
  if (isDark) {
    checkPanel_container = 'checkPanel_container_dark';
  }
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);
  const banner = 'Finding internet speed.';
  const [checkSpeed, setCheckSpeed] = useState(banner);
  const [loading, setLoading] = useState(true);
  var ipAddress = '';

  const urls: UrlQueryResult[] = [];

  if (width < 350) {
    return (
      <div>
        <div className="gf-form-error">{error4}</div>
      </div>
    );
  } else if (data.state !== 'Error') {
    if (data.series[0].length > 0) {
	  data.series.forEach(serie => {
		const timeVals: GraphSeriesValue[] = serie.fields[0].values.toArray();
		for (let i = 0; i < timeVals.length; i++) {
		  const urlData: UrlQueryResult = {
			id: i,
			url: serie.fields.find(field => field.name === options.urlOption)?.values.get(i),
			success: serie.fields.find(field => field.name === options.successOption)?.values.get(i),
			rtt: serie.fields.find(field => field.name === options.rttOption)?.values.get(i),
			checkType: serie.fields.find(field => field.name === options.typeOption)?.values.get(i),
			result: serie.fields.find(field => field.name === options.resultOption)?.values.get(i),
		  };
		  urls.push(urlData);
		}
	  });
	  if (urls.length > 0) {
		return (
		  <div className={checkPanel_container}>
		    {rowFactory(urls, height, width, mainFont, secFont, mainColor, secColor)}
		  </div>
		);
      } else {
        return (
          <div>
            <div className="gf-form-error">{error2}</div>
          </div>
        );
      }
    } else {
      return (
        <div>
          <div className="gf-form-error">{error2}</div>
        </div>
      );
    }
  } else {
    return (
      <div>
        <div className="gf-form-error">{error1}</div>
      </div>
    );
  }
}

function rowFactory(urlData: UrlQueryResult[], height: number, width: number, mainFont: number, secFont: number, mainColor: string, secColor: string) {
  const isDark = config.theme.isDark;
  const panelRowTitle = isDark ? 'panel-rowTitle_dark' : 'panel-rowTitle';
  const panelRowsubTitle = isDark ? 'panel-rowsubTitle_dark' : 'panel-rowsubTitle';
  const panelRowValue = isDark ? 'panel-rowValue_dark' : 'panel-rowValue';
  const panelRowContainer = isDark ? 'panel-rowContainer_dark' : 'panel-rowContainer';
  const rows = urlData.length;
  const rowsHeight = (height / rows) - 5;
  const containerHeight = rowsHeight - 5;
  const containerWidth = width - 5;

  return urlData.map(row => (
    <div key={row.id} className={panelRowContainer} style={{ height: rowsHeight }}>
      <a
        rel="nofollow noopener noreferrer"
        className="rowReportContainer"
        style={{ width: containerWidth, height: containerHeight }}
		href={row.url}
        target={row.target}
      >
        {row.checkType === 'url' && (
		  <span className="panelIcon"><Icon name={row.success ? 'en_servicio' : 'caido'} size="xxxl" /></span>
		)}
        {row.checkType === 'ip' && (
		  <span className="panelIcon"><Icon name={'network_report'} size="xxxl" /></span>
		)}
        {row.checkType === 'dns' && (
		  <span className="panelIcon"><Icon name={'interne_report'} size="xxxl" /></span>
		)}
        {row.checkType === 'bw' && (
		  <span className="panelIcon"><Icon name={'sla_report'} size="xxxl" /></span>
		)}
        <span className="panelTitleContanier">
          <div
		    className={panelRowTitle}
			style={{ fontSize: mainFont, color: mainColor }}
			title={row.success ? 'Comprobación exitosa' : 'Comprobación fallida'}
		  >
		    {row.checkType === 'url' && <b>{row.url}</b>}
			{row.checkType === 'ip' && <b>IP Externa: {row.url}</b>}
			{row.checkType === 'dns' && <b>DNS: {row.url} [ {row.result} ]</b>}
			{row.checkType === 'bw' && <b>Ancho de banda disponible: {row.result} Mbps</b>}
		  </div>
          <div 
		    className={panelRowsubTitle}
			style={{ fontSize: secFont, color: secColor }}
		  >
		    {row.checkType === 'url' && row.rtt > 0 && <b>Tiempo de respuesta: {row.rtt.toFixed(2)} ms</b>}
			{row.checkType === 'url' && row.rtt < 0 && <b>Error al comprobar servicio</b>}
			{row.checkType === 'ip' && <b>Calidad de conexión: {row.result}</b>}
			{row.checkType === 'dns' && <b>Tiempo de respuesta: {row.rtt.toFixed(2)} ms</b>}
			{row.checkType === 'bw' && <b>Tiempo de medición: {(row.rtt / 1000).toFixed(2)} seg</b>}
		  </div>
        </span>
      </a>
    </div>
  ));
}
import { FieldType, PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { FieldSelectEditor } from './FieldSelectEditor';
import { NavbarPanel } from './NavbarPanel';

export const plugin = new PanelPlugin<SimpleOptions>(NavbarPanel).setPanelOptions(builder => {
  return builder
    .addCustomEditor({
      id: 'optionTypeOption',
      path: 'optionTypeOption',
      name: 'Option Type Field',
      description: 'The Type of option. Could be option or button.',
      defaultValue: 'option_type',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'optionStyleOption',
      path: 'optionStyleOption',
      name: 'Option Style Field',
      description: 'The option color: White, Grey, Black, Red, Yellow, Orange, Green and Blue',
      defaultValue: 'option_style',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'optionIconOption',
      path: 'optionIconOption',
      name: 'Option Icon Field',
      description: 'The field that will be used as the Icon for the option',
      defaultValue: 'option_icon',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'optionTextOption',
      path: 'optionTextOption',
      name: 'Option Text Field',
      description: 'The field that will be used as the Text for the option',
      defaultValue: 'option_text',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'optionUrlOption',
      path: 'optionUrlOption',
      name: 'Option Url Field',
      description: 'The Url open by the option',
      defaultValue: 'option_url',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'optionTargetOption',
      path: 'optionTargetOption',
      name: 'Option Target Field',
      description: 'The Url target',
      defaultValue: 'option_target',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })

    .addTextInput({
      path: 'minWidth',
      name: 'Plugin min width',
      description: 'Switch to hide generic options when panel width cross below this threshold',
      category: ['General Settings'],
      defaultValue: '250',
    })
    .addBooleanSwitch({
      path: 'displayOptionsHorizontal',
      name: 'Horizontal Options',
      description: 'Display options horizontally.',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showTimepickerOption',
      name: 'Time Picker Options',
      description: 'Display time picker options',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showKioskOption',
      name: 'Kiosk Options',
      description: 'Display Kiosk options.',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'useRoleVariable',
      name: 'Update role variable',
      description: 'Allow to update a variable with the user role',
      category: ['General Settings'],
      defaultValue: false,
    })
    .addTextInput({
      path: 'roleVariable',
      name: 'role Variable',
      description: 'Variable to update',
      category: ['General Settings'],
      defaultValue: 'role',
    })
    .addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'Datos nulos',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error al cargar el complemento',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});

import React, { useMemo } from 'react';
import { FieldSelectEditor } from './FieldSelectEditor';
import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { ContactManagerPanel } from './ContactManagerPanel';
import { AlertManagerPicker } from 'app/features/alerting/unified/components/AlertManagerPicker';
import {
  getAllAlertManagerDataSources,
  NETMONITOR_RULES_SOURCE_NAME,
} from 'app/features/alerting/unified/utils/datasource';

export const plugin = new PanelPlugin<ContactOptions>(ContactManagerPanel).setPanelOptions(builder => {
  return builder
    .addCustomEditor({
      path: 'alertManagerName',
      id: 'alertManagerName',
      defaultValue: NETMONITOR_RULES_SOURCE_NAME,
      category: ['General Settings'],
      editor: function RenderAlertmanagerPicker(props) {
        const alertManagers = useMemo(getAllAlertManagerDataSources, []);
        return (
          <AlertManagerPicker
            current={props.value}
            onChange={(alertManagerSourceName) => {
              return props.onChange(alertManagerSourceName);
            }}
            dataSources={alertManagers}
          />
        );
      },
    })
    .addBooleanSwitch({
      path: 'showTitle',
      name: 'show customizable title',
      description: 'Enable title inside plugin',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addTextInput({
      name: 'Title',
      path: 'pluginTitle',
      defaultValue: 'Notificaciones - Reglas configuradas',
      category: ['General Settings'],
      showIf: (options) => options.showTitle === true,
    })
    .addBooleanSwitch({
      path: 'editorCanAdmin',
      name: 'Editors can admin?',
      description: 'Enable editor to add, modify and delete alert channels',
      category: ['General Settings'],
      defaultValue: false,
    });
});

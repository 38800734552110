import { Trans, t } from '@lingui/macro';
import React, { PureComponent } from 'react';

import { SelectableValue } from '@grafana/data';
import { getBackendSrv, reportInteraction } from '@grafana/runtime';
import { Button, ClipboardButton, Field, Input, LinkButton, Modal, Select, Spinner } from '@grafana/ui';
import { getTimeSrv } from 'app/features/dashboard/services/TimeSrv';
import { DashboardModel, PanelModel } from 'app/features/dashboard/state';

import { VariableRefresh } from '../../../variables/types';

import { ShareModalTabProps } from './types';

const snapshotApiUrl = '/api/snapshots';

interface Props extends ShareModalTabProps {}

interface State {
  isLoading: boolean;
  step: number;
  snapshotName: string;
  selectedExpireOption: SelectableValue<number>;
  snapshotExpires?: number;
  snapshotUrl: string;
  deleteUrl: string;
  timeoutSeconds: number;
  externalEnabled: boolean;
  sharingButtonText: string;
}

export class ShareSnapshot extends PureComponent<Props, State> {
  private dashboard: DashboardModel;
  private expireOptions: Array<SelectableValue<number>>;

  constructor(props: Props) {
    super(props);
    this.dashboard = props.dashboard;
    this.expireOptions = [
      {
        label: t({
          id: 'share-modal.snapshot.expire-never',
          message: `Nunca`,
        }),
        value: 0,
      },
      {
        label: t({
          id: 'share-modal.snapshot.expire-hour',
          message: `1 hora`,
        }),
        value: 60 * 60,
      },
      {
        label: t({
          id: 'share-modal.snapshot.expire-day',
          message: `1 día`,
        }),
        value: 60 * 60 * 24,
      },
      {
        label: t({
          id: 'share-modal.snapshot.expire-week',
          message: `7 días`,
        }),
        value: 60 * 60 * 24 * 7,
      },
    ];
    this.state = {
      isLoading: false,
      step: 1,
      selectedExpireOption: this.expireOptions[0],
      snapshotExpires: this.expireOptions[0].value,
      snapshotName: props.dashboard.title,
      timeoutSeconds: 4,
      snapshotUrl: '',
      deleteUrl: '',
      externalEnabled: false,
      sharingButtonText: '',
    };
  }

  componentDidMount() {
    reportInteraction('netmonitor_dashboards_snapshot_share_viewed');
    this.getSnaphotShareOptions();
  }

  async getSnaphotShareOptions() {
    const shareOptions = await getBackendSrv().get('/api/snapshot/shared-options');
    this.setState({
      sharingButtonText: shareOptions['externalSnapshotName'],
      externalEnabled: shareOptions['externalEnabled'],
    });
  }

  createSnapshot = (external?: boolean) => () => {
    const { timeoutSeconds } = this.state;
    this.dashboard.snapshot = {
      timestamp: new Date(),
    };

    if (!external) {
      this.dashboard.snapshot.originalUrl = window.location.href;
    }

    this.setState({ isLoading: true });
    this.dashboard.startRefresh();

    setTimeout(() => {
      this.saveSnapshot(this.dashboard, external);
    }, timeoutSeconds * 1000);
  };

  saveSnapshot = async (dashboard: DashboardModel, external?: boolean) => {
    const { snapshotExpires } = this.state;
    const dash = this.dashboard.getSaveModelClone();
    this.scrubDashboard(dash);

    const cmdData = {
      dashboard: dash,
      name: dash.title,
      expires: snapshotExpires,
      external: external,
    };

    try {
      const results: { deleteUrl: string; url: string } = await getBackendSrv().post(snapshotApiUrl, cmdData);
      this.setState({
        deleteUrl: results.deleteUrl,
        snapshotUrl: results.url,
        step: 2,
      });
    } finally {
      reportInteraction('netmonitor_dashboards_snapshot_created', {
        location: external ? 'raintank' : 'local',
      });
      this.setState({ isLoading: false });
    }
  };

  scrubDashboard = (dash: DashboardModel) => {
    const { panel } = this.props;
    const { snapshotName } = this.state;
    // change title
    dash.title = snapshotName;

    // make relative times absolute
    dash.time = getTimeSrv().timeRange();

    // Remove links
    dash.links = [];

    // remove panel queries & links
    dash.panels.forEach((panel) => {
      panel.targets = [];
      panel.links = [];
      panel.datasource = null;
    });

    // remove annotation queries
    const annotations = dash.annotations.list.filter((annotation) => annotation.enable);
    dash.annotations.list = annotations.map((annotation) => {
      return {
        name: annotation.name,
        enable: annotation.enable,
        iconColor: annotation.iconColor,
        snapshotData: annotation.snapshotData,
        type: annotation.type,
        builtIn: annotation.builtIn,
        hide: annotation.hide,
      };
    });

    // remove template queries
    dash.getVariables().forEach((variable: any) => {
      variable.query = '';
      variable.options = variable.current ? [variable.current] : [];
      variable.refresh = VariableRefresh.never;
    });

    // snapshot single panel
    if (panel) {
      const singlePanel = panel.getSaveModel();
      singlePanel.gridPos.w = 24;
      singlePanel.gridPos.x = 0;
      singlePanel.gridPos.y = 0;
      singlePanel.gridPos.h = 20;
      dash.panels = [singlePanel];
    }

    // cleanup snapshotData
    delete this.dashboard.snapshot;
    this.dashboard.forEachPanel((panel: PanelModel) => {
      delete panel.snapshotData;
    });
    this.dashboard.annotations.list.forEach((annotation) => {
      delete annotation.snapshotData;
    });
  };

  deleteSnapshot = async () => {
    const { deleteUrl } = this.state;
    await getBackendSrv().get(deleteUrl);
    this.setState({ step: 3 });
  };

  getSnapshotUrl = () => {
    return this.state.snapshotUrl;
  };

  onSnapshotNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ snapshotName: event.target.value });
  };

  onTimeoutChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ timeoutSeconds: Number(event.target.value) });
  };

  onExpireChange = (option: SelectableValue<number>) => {
    this.setState({
      selectedExpireOption: option,
      snapshotExpires: option.value,
    });
  };

  renderStep1() {
    const { onDismiss } = this.props;
    const { snapshotName, selectedExpireOption, timeoutSeconds, isLoading, sharingButtonText, externalEnabled } =
      this.state;

    const snapshotNameTranslation = t({
      id: 'share-modal.snapshot.name',
      message: `Nombre`,
    });

    const expireTranslation = t({
      id: 'share-modal.snapshot.expire',
      message: `Expira en`,
    });

    const timeoutTranslation = t({
      id: 'share-modal.snapshot.timeout',
      message: `Vencen en (seg)`,
    });

    const timeoutDescriptionTranslation = t({
      id: 'share-modal.snapshot.timeout-description',
      message: `Es posible que deba configurar un valor de tiempo de espera si lleva mucho tiempo recopilar las métricas de su panel.`,
    });

    return (
      <>
        <div>
          <p className="share-modal-info-text">
            <Trans id="share-modal.snapshot.info-text-1">
              Una captura es una forma instantánea de compartir un panel interactivo.
            </Trans>
          </p>
          <p className="share-modal-info-text">
            <Trans id="share-modal.snapshot.info-text-2">
              Tenga en cuenta que una captura puede ser vista por cualquier persona que tenga el enlace generado.
            </Trans>
          </p>
        </div>
        <Field label={snapshotNameTranslation}>
          <Input id="snapshot-name-input" width={30} value={snapshotName} onChange={this.onSnapshotNameChange} />
        </Field>
        <Field label={expireTranslation}>
          <Select
            inputId="expire-select-input"
            width={30}
            options={this.expireOptions}
            value={selectedExpireOption}
            onChange={this.onExpireChange}
          />
        </Field>
        <Field label={timeoutTranslation} description={timeoutDescriptionTranslation}>
          <Input id="timeout-input" type="number" width={21} value={timeoutSeconds} onChange={this.onTimeoutChange} />
        </Field>

        <Modal.ButtonRow>
          <Button variant="secondary" onClick={onDismiss} fill="outline">
            <Trans id="share-modal.snapshot.cancel-button">Cancelar</Trans>
          </Button>
          {externalEnabled && (
            <Button variant="secondary" disabled={isLoading} onClick={this.createSnapshot(true)}>
              {sharingButtonText}
            </Button>
          )}
          <Button variant="primary" disabled={isLoading} onClick={this.createSnapshot()}>
            <Trans id="share-modal.snapshot.local-button">Captura local</Trans>
          </Button>
        </Modal.ButtonRow>
      </>
    );
  }

  renderStep2() {
    const { snapshotUrl } = this.state;

    return (
      <>
        <Field label="Snapshot URL">
          <Input
            id="snapshot-url-input"
            value={snapshotUrl}
            readOnly
            addonAfter={
              <ClipboardButton icon="copy" variant="primary" getText={this.getSnapshotUrl}>
                <Trans id="share-modal.snapshot.copy-link-button">Copiar</Trans>
              </ClipboardButton>
            }
          />
        </Field>

        <div className="pull-right" style={{ padding: '5px' }}>
          <Trans id="share-modal.snapshot.mistake-message">Cometió algun error? </Trans>&nbsp;
          <LinkButton fill="text" target="_blank" onClick={this.deleteSnapshot}>
            <Trans id="share-modal.snapshot.delete-button">Borrar captura</Trans>
          </LinkButton>
        </div>
      </>
    );
  }

  renderStep3() {
    return (
      <div className="share-modal-header">
        <p className="share-modal-info-text">
          <Trans id="share-modal.snapshot.deleted-message">
            La captura fue eliminada.
          </Trans>
        </p>
      </div>
    );
  }

  render() {
    const { isLoading, step } = this.state;

    return (
      <>
        {step === 1 && this.renderStep1()}
        {step === 2 && this.renderStep2()}
        {step === 3 && this.renderStep3()}
        {isLoading && <Spinner inline={true} />}
      </>
    );
  }
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// This file is autogenerated. DO NOT EDIT.
//
// To regenerate, run "make gen-cue" from the repository root.
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~


export const modelVersion = Object.freeze([0, 0]);


export enum TextMode {
  HTML = 'html',
  Markdown = 'markdown',
  Separator = 'Separator',
}

export interface PanelOptions {
  content: string;
  mode: TextMode;
}

export const defaultPanelOptions: Partial<PanelOptions> = {
  content: `# Title

For markdown syntax help: [commonmark.org/help](https://commonmark.org/help/)`,
  mode: TextMode.Markdown,
};

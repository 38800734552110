// @ts-nocheck
import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { AppEvents } from '@grafana/data';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { Icon } from '@grafana/ui';
import { config, SystemJS } from '@grafana/runtime';
import { Modal, View } from '@material-ui/core';
import { SflowDecoderOptions, SflowData, SflowItems } from './types';
import { changeUrl, getNetMonitorVariableValue } from './utils';
import etherTypes from './proto';
import { moment } from 'moment';
import { Chrono } from 'react-chrono';
import axios from 'axios';
import ports from "port-numbers";
import { findByNumber } from 'ip-protocols';
import { toVendor } from '@network-utils/vendor-lookup';
import is_ip_private from 'private-ip';

import './css/sflowDecoderPanel.css';

interface Props extends PanelProps<SflowDecoderOptions> {}

export const SflowDecoderPanel: React.FC<Props> = ({ options, data, width, height, replaceVariables, id }) => {
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);

  const isDark = config.theme.isDark;
  const showTitle = options.showTitle;
  const pluginTitle = replaceVariables(options.pluginTitle);
  const moment = require('moment');
  var legendPosition = String(options.legendPosition);
  const hideControls = options.hideControls;
  const flipLayout = options.flipLayout;
  const frameHeight = String(options.frameMinHeight);
  var displayReadMore = options.displayReadMore;

  if (legendPosition === 'VERTICAL_ALTERNATING' && width < options.frameMinWidth * 2.5) {
    legendPosition = 'VERTICAL';
  }
  var backgroundColor = '#fff';
  var titleColor = 'black';
  var textColor = '#111';
  var primary = '#0d0deb';
  var secondary = '#ff7600';
  var frameBgColor = '#fafafa';
  var frameForeColor = '#999';

  if (isDark) {
    backgroundColor = '#000';
    titleColor = 'white';
    textColor = '#eee';
    primary = '#0d0deb';
    secondary = '#ff7600';
    frameBgColor = '#111';
    frameForeColor = '#999';
  }
  const frametitle = isDark ? 'frametitle_dark' : 'frametitle';
  var agentFilterValue = options.filterDefaultValue;
  var L2SourceFilterValue = options.filterDefaultValue;
  var L2DestFilterValue = options.filterDefaultValue;
  var L3FilterValue = options.filterDefaultValue;
  var L4FilterValue = options.filterDefaultValue;
  var L3SourceFilterValue = options.filterDefaultValue;
  var L3DestFilterValue = options.filterDefaultValue;
  var agentOptions = false;
  var L2SourceOptions = false;
  var L2DestOptions = false;
  var L3Options = false;
  var L4Options = false;
  var L3SourceOptions = false;
  var L3DestOptions = false;
  if (options.agentVariable !== undefined && options.agentVariable !== null && options.agentVariable !== '') {
    agentFilterValue = getNetMonitorVariableValue(options.agentVariable);
    agentOptions = true;
  }
  if (options.l2SaddrVariable !== undefined && options.l2SaddrVariable !== null && options.l2SaddrVariable!== '') {
    L2SourceFilterValue = getNetMonitorVariableValue(options.l2SaddrVariable);
    L2SourceOptions = true;
  }
  if (options.l2DaddrVariable !== undefined && options.l2DaddrVariable !== null && options.l2DaddrVariable !== '') {
    L2DestFilterValue = getNetMonitorVariableValue(options.l2DaddrVariable);
    L2DestOptions = true;
  }
  if (options.l3ProtoVariable !== undefined && options.l3ProtoVariable !== null && options.l3ProtoVariable !== '') {
    L3FilterValue = getNetMonitorVariableValue(options.l3ProtoVariable);
    L3Options = true;
  }
  if (options.l4ProtoVariable !== undefined && options.l4ProtoVariable !== null && options.l4ProtoVariable !== '') {
    L4FilterValue = getNetMonitorVariableValue(options.l4ProtoVariable);
    L4Options = true;
  }
  if (options.l3SaddrVariable !== undefined && options.l3SaddrVariable !== null && options.l3SaddrVariable!== '') {
    L3SourceFilterValue = getNetMonitorVariableValue(options.l3SaddrVariable);
    L3SourceOptions = true;
  }
  if (options.l3DaddrVariable !== undefined && options.l3DaddrVariable !== null && options.l3DaddrVariable !== '') {
    L3DestFilterValue = getNetMonitorVariableValue(options.l3DaddrVariable);
    L3DestOptions = true;
  }

  var device_link = replaceVariables(options.drillDownLink);
  if (device_link === null || device_link === '') {
    device_link = '/';
  }
  var agent_link = replaceVariables(options.drillDownLinkAgent);
  if (agent_link === null || agent_link === '') {
    agent_link = '/';
  }
  var iface_link = replaceVariables(options.drillDownLinkIface);
  if (iface_link === null || iface_link === '') {
    iface_link = '/';
  }

  // ----------------------- STYLES -----------------------
  var dimensions = {
    width: width,
    height: height,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 10,
    marginLeft: 0,
    cardWidth: width - 150,
  };
  if (options.showToolbar && !showTitle) {
    dimensions.height = height - 30;
  } else if (options.showToolbar && showTitle) {
    dimensions.height = height - 70;
  } else if (!options.showToolbar && showTitle) {
    dimensions.height = height - 40;
  }
  const fontSizes = {
    cardSubtitle: String(options.frameFontSize - 1) + 'px',
    cardText: String(options.frameFontSize - 2) + 'px',
    cardTitle: String(options.frameFontSize) + 'px',
    title: String(options.frameFontSize) + 'px',
  }

  const [agentFilter, setAgentFilter] = useState(agentFilterValue);
  const [l2SourceFilter, setL2SourceFilter] = useState(L2SourceFilterValue);
  const [l2DestintaionFilter, setL2DestinationFilter] = useState(L2DestFilterValue);
  const [l3SourceFilter, setL3SourceFilter] = useState(L3SourceFilterValue);
  const [l3DestintaionFilter, setL3DestinationFilter] = useState(L3DestFilterValue);

  const onAgentChange = (event) => {
    setAgentFilter(event.target.value);
  };

  const onL2SourceChange = (event) => {
    setL2SourceFilter(event.target.value);
  };

  const onL2DestinationChange = (event) => {
    setL2DestinationFilter(event.target.value);
  };

  const onL3SourceChange = (event) => {
    setL3SourceFilter(event.target.value);
  };

  const onL3DestinationChange = (event) => {
    setL3DestinationFilter(event.target.value);
  };

  const changeAgent = (event) => {
    if (event.key === 'Enter') {
	  const queryMap = {
		[`var-${options.agentVariable}`]: agentFilter,
	  };
	  changeUrl(queryMap);
    }
  };

  const changeL2Source = (event) => {
    if (event.key === 'Enter' && L2SourceOptions) {
	  const queryMap = {
		[`var-${options.l2SaddrVariable}`]: l2SourceFilter,
	  };
	  changeUrl(queryMap);
    }
  };

  const changeL2Destination = (event) => {
    if (event.key === 'Enter' && L2DestOptions) {
	  const queryMap = {
		[`var-${options.l2DaddrVariable}`]: l2DestintaionFilter,
	  };
	  changeUrl(queryMap);
    }
  };

  const changeL3Source = (event) => {
    if (event.key === 'Enter' && L3SourceOptions) {
	  const queryMap = {
		[`var-${options.l3SaddrVariable}`]: l3SourceFilter,
	  };
	  changeUrl(queryMap);
    }
  };

  const changeL3Destination = (event) => {
    if (event.key === 'Enter' && L3DestOptions) {
	  const queryMap = {
		[`var-${options.l3DaddrVariable}`]: l3DestintaionFilter,
	  };
	  changeUrl(queryMap);
    }
  };

  const filterBox = isDark ? 'sflowFilterBox_dark' : 'sflowFilterBox';
  const selectBox = isDark ? 'sflowSelectBox_dark' : 'sflowSelectBox';
  const toolbarBox = isDark ? 'frameToolbar text_dark' : 'frameToolbar text_light';
  const showMore = options.useReadMore;
  const showCardInfo = options.showCardInfo;
  const frameContainer = isDark ? 'sflowDecoderContainer_dark' : 'sflowDecoderContainer';
  
  const moreTitleId = 'sflowFrame-moreInfoTitle-' + String(id);
  const moreSubTitleId = 'sflowFrame-moreInfoSubTitle-' + String(id);
  const moreInfoContainerId = 'sflowFrame-moreInfoContainer-' + String(id);
  const moreInfoId = 'sflowFrame-moreInfo-' + String(id);
  const moreDateId = 'sflowFrame-moreInfoDate-' + String(id);
  const moreTsId = 'sflowFrame-moreTsId-' + String(id);
  const moreL2ProtoLabel = 'sflowFrame-moreL2ProtoLabel-' + String(id);
  const moreL2ProtoId = 'sflowFrame-moreL2ProtoId-' + String(id);
  const moreTypeLabel = 'sflowFrame-moreTypeLabel-' + String(id);
  const moreTypeId = 'sflowFrame-moreTypeId-' + String(id);
  const moreL2SaddrId = 'sflowFrame-moreL2SaddrId-' + String(id);
  const moreL2DaddrId = 'sflowFrame-moreL2DaddrId-' + String(id);
  const moreVlanId = 'sflowFrame-moreVlanId-' + String(id);
  const moreL3ProtoId = 'sflowFrame-moreL3ProtoId-' + String(id);
  const moreL3SaddrId = 'sflowFrame-moreL3SaddrId-' + String(id);
  const moreL3DaddrId = 'sflowFrame-moreL3DaddrId-' + String(id);
  const moreFlagsId = 'sflowFrame-moreFlagsId-' + String(id);
  const moreOffsetId = 'sflowFrame-moreOffsetId-' + String(id);
  const moreTtlId = 'sflowFrame-moreTtlId-' + String(id);
  const moreL4ProtoId = 'sflowFrame-moreL4ProtoId-' + String(id);
  const moreL4SaddrId = 'sflowFrame-moreL4SaddrId-' + String(id);
  const moreL4DaddrId = 'sflowFrame-moreL4DaddrId-' + String(id);
  const moreFrameLenId = 'sflowFrame-moreFrameLenId-' + String(id);
  const moreTemplateId = 'sflowFrame-moreTemplateId-' + String(id);
  const moreBytesId = 'sflowFrame-moreBytesId-' + String(id);
  const morePacketsId = 'sflowFrame-morePacketsId-' + String(id);  
 
  if (legendPosition === 'VERTICAL_ALTERNATING') {
    dimensions.cardWidth = (width / 2) - 75;
  }

  const lowResolution = (!options.isNetflow && width < 1150) || (options.isNetflow && width < 950) ? true : false;
  const containerClass = lowResolution ? 'sflowFilterContainerLow' : 'sflowFilterContainer';

  // ----------------------- BASE DATA ACQUISITION -----------------------
  var framesReceived: SflowData[] = [];
  var flowItems: SflowItems[] = [];
  var l3ProtoList: l3ProtoItems[] = [];
  let l3Proto: l3ProtoItems = {
	id: 0,
	value: options.filterDefaultValue,
	label: options.filterDefaultValue,
  };
  l3ProtoList.push(l3Proto);
  var l4ProtoList: l4ProtoItems[] = [];
  let l4Proto: l3ProtoItems = {
	id: 0,
	value: options.filterDefaultValue,
	label: options.filterDefaultValue,
  };
  l4ProtoList.push(l4Proto);

  if ((!options.isNetflow && width < 850) || (options.isNetflow && width < 650) || height < 250) {
    return (
      <div>
        <div className="gf-form-error">{error4}</div>
      </div>
    );
  } else if (data.state !== 'Error') {
    if(data.series[0].length > 0) {
      data.series.forEach((series) => {
        const frame: GraphSeriesValue[] = series.fields[0].values.toArray();
        const dataLen = frame.length;
        for (let i = 0; i < dataLen; i++) {
          let frameReceived: SflowData = {
            id: i,
            date: series.fields.find(field => field.name === options.dateField)?.values.get(i),
			timest: series.fields.find(field => field.name === options.tsField)?.values.get(i),
            title: '',
			agentIP: series.fields.find((field) => field.name === options.agentIPField)?.values.get(i),
			agent: series.fields.find((field) => field.name === options.agentField)?.values.get(i),
            ifidx: series.fields.find(field => field.name === options.ifidxField)?.values.get(i),
            l2protoLabel: options.isNetflow ? 'Ethernet' : series.fields.find(field => field.name === options.l2protoField)?.values.get(i),
            l3proto: series.fields.find((field) => field.name === options.l3protoField)?.values.get(i),
            l3protoLabel: '',
            l4proto: series.fields.find((field) => field.name === options.l4protoField)?.values.get(i),
            l4protoLabel: '',
            l2Saddr: options.isNetflow ? '-' : series.fields.find(field => field.name === options.l2SaddrField)?.values.get(i),
            l2SaddrOwner: options.isNetflow ? '-' : series.fields.find(field => field.name === options.l2SaddrOwnerField)?.values.get(i),
			l2SaddrVendor: '',
            l3Saddr: series.fields.find((field) => field.name === options.l3SaddrField)?.values.get(i),
            l3SaddrOwner: series.fields.find(field => field.name === options.l3SaddrOwnerField)?.values.get(i),
            l4Saddr: series.fields.find((field) => field.name === options.l4SaddrField)?.values.get(i),
            l4SaddrName: '',
			l4SaddrLabel: '',
			l2Daddr: options.isNetflow ? '-' : series.fields.find(field => field.name === options.l2DaddrField)?.values.get(i),
            l2DaddrOwner: options.isNetflow ? '-' : series.fields.find(field => field.name === options.l2DaddrOwnerField)?.values.get(i),
			l2DaddrVendor: '',
            l3Daddr: series.fields.find((field) => field.name === options.l3DaddrField)?.values.get(i),
            l3DaddrOwner: series.fields.find(field => field.name === options.l3DaddrOwnerField)?.values.get(i),
            l4Daddr: series.fields.find((field) => field.name === options.l4DaddrField)?.values.get(i),
            l4DaddrName: '',
			l4DaddrLabel: '',
			vlan: options.isNetflow ? '1' : series.fields.find(field => field.name === options.vlanField)?.values.get(i),
			ethertype: series.fields.find(field => field.name === options.ethertypeField)?.values.get(i),
			flags: options.isNetflow ? '-' : series.fields.find(field => field.name === options.flagsField)?.values.get(i),
			offset: options.isNetflow ? '0' : series.fields.find(field => field.name === options.offsetField)?.values.get(i),
			ttl: options.isNetflow ? '0' : series.fields.find(field => field.name === options.ttlField)?.values.get(i),
            frameLen: options.isNetflow ? '0' : series.fields.find(field => field.name === options.frameLenField)?.values.get(i),
			inBytes: options.isNetflow ? series.fields.find(field => field.name === options.inBytesField)?.values.get(i) : 0,
			outBytes: options.isNetflow ? series.fields.find(field => field.name === options.outBytesField)?.values.get(i) : 0,
			inPackets: options.isNetflow ? series.fields.find(field => field.name === options.inPacketsField)?.values.get(i) : 0,
			outPackets: options.isNetflow ? series.fields.find(field => field.name === options.outPacketsField)?.values.get(i) : 0,
			template: options.isNetflow ? series.fields.find(field => field.name === options.templateField)?.values.get(i) : 0,
          };
          frameReceived.title = moment.unix(frameReceived.date).format('DD-MM-YYYY HH:mm:ss');
		  if (typeof frameReceived.ifidx === undefined || frameReceived.ifidx === null) {
            frameReceived.ifidx = 0;
          }
          if (typeof frameReceived.agent === undefined || frameReceived.agent === null || frameReceived.agent === '') {
            frameReceived.agent = 'NetMonitor';
          }
		  if (frameReceived.ethertype !== 0) {
		  	if (frameReceived.l2protoLabel === 'ethernet') {
			  frameReceived.l2protoLabel = 'Ethernet II';
			} else {
			  frameReceived.l2protoLabel = 'Ethernet';
			}
			const l3Proto = etherTypes[`${frameReceived.ethertype}`];
			frameReceived.l3protoLabel = l3Proto !== undefined ? l3Proto : frameReceived.l3proto;
		  }
		  if (frameReceived.l2Saddr !== '') {
		    const vendor = toVendor(frameReceived.l2Saddr);
			frameReceived.l2SaddrVendor = vendor !== undefined ? vendor : '';
			if (frameReceived.l2SaddrOwner === undefined || frameReceived.l2Saddr === frameReceived.l2SaddrOwner) {
			  frameReceived.l2SaddrOwner = '';
			}
		  }
		  if (frameReceived.l2Daddr !== '') {
		    const vendor = toVendor(frameReceived.l2Daddr);
			frameReceived.l2DaddrVendor = vendor !== undefined ? vendor : '';
			if (frameReceived.l2DaddrOwner === undefined || frameReceived.l2Daddr === frameReceived.l2DaddrOwner) {
			  frameReceived.l2DaddrOwner = '';
			}
		  }
		  if (frameReceived.l3SaddrOwner === undefined || frameReceived.l3Saddr === frameReceived.l3SaddrOwner) {
			frameReceived.l3SaddrOwner = '';
		  }
		  if (frameReceived.l3DaddrOwner === undefined || frameReceived.l3Daddr === frameReceived.l3DaddrOwner) {
			frameReceived.l3DaddrOwner = '';
		  }
		  if (frameReceived.l4proto !== 0) {
		    const l4Proto = findByNumber(frameReceived.l4proto);
            frameReceived.l4protoLabel = l4Proto.Description;
          }
          if (frameReceived.l4proto === 6 || frameReceived.l4proto === 17) {
            if (frameReceived.l4proto === 6) {
			  const service = ports[`${frameReceived.l4Saddr}/tcp`];
			  frameReceived.l4SaddrName = service !== undefined ? service[0] : frameReceived.l4Saddr.toString();
			  frameReceived.l4SaddrLabel = service !== undefined ? service[1] : frameReceived.l4Saddr.toString();
			  const service2 = ports[`${frameReceived.l4Daddr}/tcp`];
			  frameReceived.l4DaddrName = service2 !== undefined ? service2[0] : frameReceived.l4Daddr.toString();
			  frameReceived.l4DaddrLabel = service2 !== undefined ? service2[1] : frameReceived.l4Daddr.toString();
			} else if (frameReceived.l4proto === '17') {
			  const service = ports[`${frameReceived.l4Saddr}/udp`];
			  frameReceived.l4SaddrName = service !== undefined ? service[0] : frameReceived.l4Saddr.toString();
			  frameReceived.l4SaddrLabel = service !== undefined ? service[1] : frameReceived.l4Saddr.toString();
			  const service2 = ports[`${frameReceived.l4Daddr}/udp`];
			  frameReceived.l4DaddrName = service2 !== undefined ? service2[0] : frameReceived.l4Daddr.toString();
			  frameReceived.l4DaddrLabel = service2 !== undefined ? service2[1] : frameReceived.l4Daddr.toString();
			}
		  }
		  framesReceived.push(frameReceived);
          let timeItem: SflowItems = {
            id: i,
            title: frameReceived.title,
          };
          flowItems.push(timeItem);
		  if (isValueUnique(frameReceived.ethertype, l3ProtoList)) {
		    let l3Proto: l3ProtoItems = {
		      id: i + 1,
			  value: frameReceived.ethertype,
			  label: frameReceived.l3protoLabel,
		    };
			l3ProtoList.push(l3Proto);
		  }
		  if (isValueUnique(frameReceived.l4proto, l4ProtoList)) {
		    let l4Proto: l3ProtoItems = {
		      id: i + 1,
			  value: frameReceived.l4proto,
			  label: frameReceived.l4protoLabel,
		    };
		    l4ProtoList.push(l4Proto);
		  }
        }
      });

      // -----------------------  TIMELINE FIELD VALUES  -----------------------

      return (
        <div className="sflowDecoderChart" id="sflowDecoderChart">
          {showTitle && (
            <div className={frametitle}>
              <div className="frametitleText">{pluginTitle}</div>
            </div>
          )}
          {options.showToolbar && (
            <div className={toolbarBox}>
              {agentOptions && options.showAgentFilter && (
                <div className={containerClass}>
                  <span className="sflowSelectBoxText">{lowResolution ? 'Agente' : 'Agente: '}</span>
				  <input 
				    className={filterBox}
					onKeyPress={changeAgent}
					onChange={onAgentChange}
					value={agentFilter}
				  ></input>
                </div>
              )}
              {L2SourceOptions && options.showL2Filter && !options.isNetflow && (
                <div className={containerClass}>
                  <span className="sflowSelectBoxText">{lowResolution ? 'Origen L2' : 'Src L2: '}</span>
                  <input 
				    className={filterBox}
					onKeyPress={changeL2Source}
					onChange={onL2SourceChange}
					value={l2SourceFilter}
				  ></input>
                </div>
              )}
              {L2DestOptions && options.showL2Filter && !options.isNetflow &&  (
                <div className={containerClass}>
                  <span className="sflowSelectBoxText">{lowResolution ? 'Destino L2' : 'Dst L2: '}</span>
                  <input 
				    className={filterBox}
					onKeyPress={changeL2Destination}
					onChange={onL2DestinationChange}
					value={l2DestintaionFilter}
				  ></input>
                </div>
              )}
              {L3Options && l3ProtoList.length > 2 && (
                <div className={containerClass}>
                  <span className="sflowSelectBoxText">{lowResolution ? 'Protocolo L3' : 'L3: '}</span>
                  <select 
                    className={selectBox}
                    onChange={(e) => {
                      if (L3Options) {
                        const queryMap = {
                          [`var-${options.l3ProtoVariable}`]: e.target.value,
                        };
                        changeUrl(queryMap);
                      }
                    }}
                  >
                    <option hidden selected>{etherTypes[`${L3FilterValue}`]}</option>
				    {l3ProtoList.map((l3Proto) => (
					  <option key={l3Proto.id} value={l3Proto.value}>{l3Proto.label}</option>
				    ))}
                  </select>
                </div>
              )}
              {L3SourceOptions && (
                <div className={containerClass}>
                  <span className="sflowSelectBoxText">{lowResolution ? 'Origen L3' : 'Src L3: '}</span>
                  <input 
				    className={filterBox}
					onKeyPress={changeL3Source}
					onChange={onL3SourceChange}
					value={l3SourceFilter}
				  ></input>
                </div>
              )}
              {L3DestOptions && (
                <div className={containerClass}>
                  <span className="sflowSelectBoxText">{lowResolution ? 'Destino L3' : 'Dst L3: '}</span>
                  <input 
				    className={filterBox}
					onKeyPress={changeL3Destination}
					onChange={onL3DestinationChange}
					value={l3DestintaionFilter}
				  ></input>
                </div>
              )}
              {L4Options && !options.showL2Filter && l4ProtoList.length > 2 && (
                <div className={containerClass}>
                  <span className="sflowSelectBoxText">{lowResolution ? 'Protocolo L4' : 'L4: '}</span>
                  <select 
                    className={selectBox}
                    onChange={(e) => {
                      if (L4Options) {
                        const queryMap = {
                          [`var-${options.l4ProtoVariable}`]: e.target.value,
                        };
                        changeUrl(queryMap);
                      }
                    }}
                  >
                    <option hidden selected>{findByNumber(L4FilterValue)}</option>
				    {l4ProtoList.map((l4Proto) => (
					  <option key={l4Proto.id} value={l4Proto.value}>{l4Proto.label}</option>
				    ))}
                  </select>
                </div>
              )}
			  <div className={containerClass}>
                <Icon
                  className="sflowFrame-button"
                  name="filter-slash"
                  size="md"
                  title="Limpiar todos los filtros"
                  onClick={(e) => {
                    if (agentOptions) {
                      const queryMap = {
                        [`var-${options.agentVariable}`]: options.filterDefaultValue,
                      };
                      changeUrl(queryMap);
					  setAgentFilter(options.filterDefaultValue);
                    }
                    if (L2SourceOptions) {
                      const queryMap = {
                        [`var-${options.l2SaddrVariable}`]: options.filterDefaultValue,
                      };
                      changeUrl(queryMap);
					  setL2SourceFilter(options.filterDefaultValue);
                    }  
                    if (L2DestOptions) {
                      const queryMap = {
                        [`var-${options.l2DaddrVariable}`]: options.filterDefaultValue,
                      };
                      changeUrl(queryMap);
					  setL2DestinationFilter(options.filterDefaultValue);
                    }
                    if (L3Options) {
                      const queryMap = {
                        [`var-${options.l3ProtoVariable}`]: options.filterDefaultValue,
                      };
                      changeUrl(queryMap);
                    }
                    if (L3SourceOptions) {
                      const queryMap = {
                        [`var-${options.l3SaddrVariable}`]: options.filterDefaultValue,
                      };
                      changeUrl(queryMap);
					  setL3SourceFilter(options.filterDefaultValue);
                    }
                    if (L3DestOptions) {
                      const queryMap = {
                        [`var-${options.l3DaddrVariable}`]: options.filterDefaultValue,
                      };
                      changeUrl(queryMap);
					  setL3DestinationFilter(options.filterDefaultValue);
                    }
                    if (L4Options) {
                      const queryMap = {
                        [`var-${options.l4ProtoVariable}`]: options.filterDefaultValue,
                      };
                      changeUrl(queryMap);
                    }
                  }}
                />
              </div>
            </div>
          )}
          <div className={frameContainer} style={{ width: '100%', height: dimensions.height }}>
            <Chrono
              items={flowItems}
              mode={legendPosition}
              cardHeight={frameHeight}
			  cardWidth={dimensions.cardWidth}
              allowDynamicUpdate={true}
              lineWidth={'1'}
              hideControls={hideControls}
              scrollable={{ scrollbar: true }}
              flipLayout={flipLayout}
              timelineCircleDimension={String(options.circleDimension)}
			  disableClickOnCircle={true}
			  theme={{
                primary: primary,
                secondary: secondary,
                cardBgColor: frameBgColor,
                cardForeColor: frameForeColor,
                textColor: textColor,
                titleColor: titleColor,
              }}
              fontSizes={fontSizes}
              enableOutline={false}
              useReadMore={displayReadMore}
            >
              {frameFactory(
			    dimensions,
				framesReceived,
				showMore,
				showCardInfo,
				id,
				options,
				agent_link,
				device_link,
				iface_link,
				setAgentFilter,
				setL2SourceFilter,
				setL2DestinationFilter,
				setL3SourceFilter,
				setL3DestinationFilter
			  )}
              {options.useIcon && ( 
                <div className="chrono-icons">
                  {iconFactory(framesReceived, options.circleDimension, options)}
                </div>
              )}
            </Chrono>
          </div>
          <div id={moreInfoContainerId} className="sflowFrame-moreInfoContainer sflowFrame-hide">
            <div id={moreInfoId} className="sflowFrame-moreInfo">
              <div className="frameTitleContainer">
                <span className="frameTitleSpan">
				  <a id={moreTitleId} className="sflowFrame-moreInfoTitles" target="_blank"></a>
				</span>
                <span className="frameTitleSpan">
				  <a id={moreSubTitleId} className="sflowFrame-moreInfoSubTitle" target="_blank"></a>
				</span>
              </div>
              <div className="sflowFrame-moreDivs">
                <span className="sflowFrame-moreTitles">{'Frame Recepción: '}</span>  
                <span id={moreDateId} className="sflowFrame-moreInfoText-small"></span>
			  </div>
			  <div className="sflowFrame-moreDivs">
                <span className="sflowFrame-moreTitles-small">{'TimeStamp: '}</span>
                <span id={moreTsId} className="sflowFrame-moreInfoText-small"></span>
                {!options.isNetflow && ( <span className="sflowFrame-moreTitles-small">{'Longitud: '}</span> )}
                {!options.isNetflow && ( <span id={moreFrameLenId} className="sflowFrame-moreInfoText-small"></span> )}
              </div>
              {!options.isNetflow && (
                <>
				  <div className="sflowFrame-moreDivs">
					<span className="sflowFrame-moreTitles">{'Protocolo L2: '}</span>  
					<span id={moreL2ProtoLabel} className="sflowFrame-moreInfoText"></span>                
                  </div>
                  <div className="sflowFrame-moreDivs">
				    <span className="sflowFrame-moreTitles-small">{'Origen: '}</span>
                    <span><a id={moreL2SaddrId} className="sflowFrame-moreInfoText-small" target="_blank"></a></span>
                  </div>
                  <div className="sflowFrame-moreDivs">
                    <span className="sflowFrame-moreTitles-small">{'Destino: '}</span>
                    <span><a id={moreL2DaddrId} className="sflowFrame-moreInfoText-small" target="_blank"></a></span>
                  </div>
                  <div className="sflowFrame-moreDivs">
                    <span id={moreTypeLabel} className="sflowFrame-moreTitles-small"></span>
                    <span id={moreTypeId} className="sflowFrame-moreInfoText-small"></span>
                  </div>
                  <div className="sflowFrame-moreDivs">
                    <span className="sflowFrame-moreTitles-small">{'VLAN ID: '}</span>
                    <span id={moreVlanId} className="sflowFrame-moreInfoText-small"></span>
                    <span className="sflowFrame-moreTitles-small">{'Ethertype: '}</span>
                    <span id={moreL2ProtoId} className="sflowFrame-moreInfoText-small"></span>
                  </div>
				</>
			  )}
			  <div className="sflowFrame-moreDivs">
                <span className="sflowFrame-moreTitles">{'Protocolo L3: '}</span>
                <span id={moreL3ProtoId} className="sflowFrame-moreInfoText"></span>
              </div>
              <div className="sflowFrame-moreDivs">
                <span className="sflowFrame-moreTitles-small">{'Origen: '}</span>
                <span><a id={moreL3SaddrId} className="sflowFrame-moreInfoText-small" target="_blank"></a></span>
              </div>
              <div className="sflowFrame-moreDivs">
                <span className="sflowFrame-moreTitles-small">{'Destino: '}</span>
                <span><a id={moreL3DaddrId} className="sflowFrame-moreInfoText-small" target="_blank"></a></span>
              </div>
              {!options.isNetflow && (
                <>
				  <div className="sflowFrame-moreDivs">
                    <span className="sflowFrame-moreTitles-small">{'Flags: '}</span>
                    <span id={moreFlagsId} className="sflowFrame-moreInfoText-small"></span>
                    <span className="sflowFrame-moreTitles-small">{'Offset: '}</span>
                    <span id={moreOffsetId} className="sflowFrame-moreInfoText-small"></span>
                    <span className="sflowFrame-moreTitles-small">{'TTL: '}</span>
                    <span id={moreTtlId} className="sflowFrame-moreInfoText-small"></span>
                  </div>
				</>
			  )}
              <div className="sflowFrame-moreDivs">
                <span className="sflowFrame-moreTitles">{'Protocolo L4: '}</span>
                <span id={moreL4ProtoId} className="sflowFrame-moreInfoText"></span>
              </div>
              <div className="sflowFrame-moreDivs">
                <span className="sflowFrame-moreTitles-small">{'Origen: '}</span>
                <span id={moreL4SaddrId} className="sflowFrame-moreInfoText-small"></span>
              </div>
              <div className="sflowFrame-moreDivs">
                <span className="sflowFrame-moreTitles-small">{'Destino: '}</span>
                <span id={moreL4DaddrId} className="sflowFrame-moreInfoText-small"></span>
              </div>
              {options.isNetflow && (
                <>
				  <div className="sflowFrame-moreDivs">
                    <span className="sflowFrame-moreTitles">{'Flujo: '}</span>
					<span id={moreTemplateId} className="sflowFrame-moreInfoText"></span>
				  </div>
				  <div className="sflowFrame-moreDivs">
                    <span className="sflowFrame-moreTitles-small">{'Trafico: '}</span>
                    <span id={moreBytesId} className="sflowFrame-moreInfoText-small"></span>
				  </div>
				  <div className="sflowFrame-moreDivs"> 
					<span className="sflowFrame-moreTitles-small">{'Paquetes: '}</span>
                    <span id={morePacketsId} className="sflowFrame-moreInfoText-small"></span>
                  </div>
				</>
			  )}
              <button
                className="sflowFrame-close_button"
                onClick={(e) => {
                  hideMoreInfo(e, id);
                }}
              >
                cerrar
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="sflowDecoderChart" id="sflowDecoderChart">
          {showTitle && (
            <div className={frametitle}>
              <div className="frametitleText">{pluginTitle}</div>
            </div>
          )}
          {options.showToolbar && (
            <div className={toolbarBox}>
              {agentOptions && options.showAgentFilter && (
                <div className={containerClass}>
                  <span className="sflowSelectBoxText">{lowResolution ? 'Agente' : 'Agente: '}</span>
				  <input 
				    className={filterBox}
					onKeyPress={changeAgent}
					onChange={onAgentChange}
					value={agentFilter}
				  ></input>
                </div>
              )}
              {L2SourceOptions && options.showL2Filter && !options.isNetflow && (
                <div className={containerClass}>
                  <span className="sflowSelectBoxText">{lowResolution ? 'Origen L2' : 'Src L2: '}</span>
                  <input 
				    className={filterBox}
					onKeyPress={changeL2Source}
					onChange={onL2SourceChange}
					value={l2SourceFilter}
				  ></input>
                </div>
              )}
              {L2DestOptions && options.showL2Filter && !options.isNetflow &&  (
                <div className={containerClass}>
                  <span className="sflowSelectBoxText">{lowResolution ? 'Destino L2' : 'Dst L2: '}</span>
                  <input 
				    className={filterBox}
					onKeyPress={changeL2Destination}
					onChange={onL2DestinationChange}
					value={l2DestintaionFilter}
				  ></input>
                </div>
              )}
              {L3Options && l3ProtoList.length > 2 && (
                <div className={containerClass}>
                  <span className="sflowSelectBoxText">{lowResolution ? 'Protocolo L3' : 'L3: '}</span>
                  <select 
                    className={selectBox}
                    onChange={(e) => {
                      if (L3Options) {
                        const queryMap = {
                          [`var-${options.l3ProtoVariable}`]: e.target.value,
                        };
                        changeUrl(queryMap);
                      }
                    }}
                  >
                    <option hidden selected>{etherTypes[`${L3FilterValue}`]}</option>
				    {l3ProtoList.map((l3Proto) => (
					  <option key={l3Proto.id} value={l3Proto.value}>{l3Proto.label}</option>
				    ))}
                  </select>
                </div>
              )}
              {L3SourceOptions && (
                <div className={containerClass}>
                  <span className="sflowSelectBoxText">{lowResolution ? 'Origen L3' : 'Src L3: '}</span>
                  <input 
				    className={filterBox}
					onKeyPress={changeL3Source}
					onChange={onL3SourceChange}
					value={l3SourceFilter}
				  ></input>
                </div>
              )}
              {L3DestOptions && (
                <div className={containerClass}>
                  <span className="sflowSelectBoxText">{lowResolution ? 'Destino L3' : 'Dst L3: '}</span>
                  <input 
				    className={filterBox}
					onKeyPress={changeL3Destination}
					onChange={onL3DestinationChange}
					value={l3DestintaionFilter}
				  ></input>
                </div>
              )}
              {L4Options && !options.showL2Filter && l4ProtoList.length > 2 && (
                <div className={containerClass}>
                  <span className="sflowSelectBoxText">{lowResolution ? 'Protocolo L4' : 'L4: '}</span>
                  <select 
                    className={selectBox}
                    onChange={(e) => {
                      if (L4Options) {
                        const queryMap = {
                          [`var-${options.l4ProtoVariable}`]: e.target.value,
                        };
                        changeUrl(queryMap);
                      }
                    }}
                  >
                    <option hidden selected>{findByNumber(L4FilterValue)}</option>
				    {l4ProtoList.map((l4Proto) => (
					  <option key={l4Proto.id} value={l4Proto.value}>{l4Proto.label}</option>
				    ))}
                  </select>
                </div>
              )}
			  <div className={containerClass}>
                <Icon
                  className="sflowFrame-button"
                  name="filter-slash"
                  size="md"
                  title="Limpiar todos los filtros"
                  onClick={(e) => {
                    if (agentOptions) {
                      const queryMap = {
                        [`var-${options.agentVariable}`]: options.filterDefaultValue,
                      };
                      changeUrl(queryMap);
					  setAgentFilter(options.filterDefaultValue);
                    }
                    if (L2SourceOptions) {
                      const queryMap = {
                        [`var-${options.l2SaddrVariable}`]: options.filterDefaultValue,
                      };
                      changeUrl(queryMap);
					  setL2SourceFilter(options.filterDefaultValue);
                    }  
                    if (L2DestOptions) {
                      const queryMap = {
                        [`var-${options.l2DaddrVariable}`]: options.filterDefaultValue,
                      };
                      changeUrl(queryMap);
					  setL2DestinationFilter(options.filterDefaultValue);
                    }
                    if (L3Options) {
                      const queryMap = {
                        [`var-${options.l3ProtoVariable}`]: options.filterDefaultValue,
                      };
                      changeUrl(queryMap);
                    }
                    if (L3SourceOptions) {
                      const queryMap = {
                        [`var-${options.l3SaddrVariable}`]: options.filterDefaultValue,
                      };
                      changeUrl(queryMap);
					  setL3SourceFilter(options.filterDefaultValue);
                    }
                    if (L3DestOptions) {
                      const queryMap = {
                        [`var-${options.l3DaddrVariable}`]: options.filterDefaultValue,
                      };
                      changeUrl(queryMap);
					  setL3DestinationFilter(options.filterDefaultValue);
                    }
                    if (L4Options) {
                      const queryMap = {
                        [`var-${options.l4ProtoVariable}`]: options.filterDefaultValue,
                      };
                      changeUrl(queryMap);
                    }
                  }}
                />
              </div>
            </div>
          )}
		  <div>
            <div className="noFrameMessage">{error1}</div>
          </div>
        </div>
      );
    } 
  } else {
    return (
      <div>
        <div className="gf-form-error">{error3}</div>
      </div>
    );
  }
};

function iconFactory(frames: SflowData[], circleDimension: number, options) {
  let fontSize = circleDimension - 16;

  if (options.isNetflow) {
    return frames.map((frameReceived) => (
      <div 
	    className="icon_background" 
	    key={frameReceived.id}
	    style={{ fontSize: `${fontSize}px`, cursor: 'text' }}
	    title={'Template ID'}
	  >
	    {frameReceived.template}
      </div>
    ));
  } else {
    return frames.map((frameReceived) => (
      <div 
	    className="icon_background" 
	    key={frameReceived.id}
	    style={{ fontSize: `${fontSize}px`, cursor: 'text'  }}
	    title={'VLAN ID'}
	  >
	    {frameReceived.vlan}
      </div>
    ));
  }
}

function hashCode(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return hash;
}

function getColor(input, withStyleFormat) {
  const backgroundColor = [
    'red', 'green', 'cornflowerblue', 'yellow', 'orange', 'purple', 'pink', 'cyan',
    'magenta', 'lime', 'teal', 'lavender', 'brown', 'beige', 'maroon', 'mint',
    'olive', 'coral', 'navy', 'grey', 'white', 'black', 'silver', 'gold',
    'indigo', 'cornsilk', 'violet', 'tan', 'orchid', 'plum', 'turquoise', 'salmon'
  ];
  const textColor = [
    'black', 'white', 'white', 'black', 'black', 'white', 'black', 'black',
    'black', 'black', 'white', 'black', 'white', 'black', 'white', 'black',
    'black', 'black', 'white', 'black', 'black', 'white', 'black', 'black',
    'white', 'black', 'white', 'black', 'black', 'black', 'black', 'black'
  ];

  const index = Math.abs(hashCode(String(input))) % backgroundColor.length;
  if (withStyleFormat === 'text') {
	return textColor[index];
  } else if (withStyleFormat === 'back') {
    return backgroundColor[index];
  } else {
    return {
      backgroundColor: backgroundColor[index],
	  textColor: textColor[index],
    };
  }
}

function showMoreInfo(e, frameReceived, id, options, agent_link, device_link, iface_link) {
  let color = '';
  let divContainerId = '#sflowFrame-moreInfoContainer-' + String(id);
  let divTitleId = '#sflowFrame-moreInfoTitle-' + String(id);
  let divSubTitleId = '#sflowFrame-moreInfoSubTitle-' + String(id);
  let divDateId = '#sflowFrame-moreInfoDate-' + String(id);
  let divTsId = '#sflowFrame-moreTsId-' + String(id);
  let divTypeLabel = '#sflowFrame-moreTypeLabel-' + String(id);
  let divTypeId = '#sflowFrame-moreTypeId-' + String(id);
  let divL2ProtoLabel = '#sflowFrame-moreL2ProtoLabel-' + String(id);
  let divL2ProtoId = '#sflowFrame-moreL2ProtoId-' + String(id);
  let divL2SaddrId = '#sflowFrame-moreL2SaddrId-' + String(id);
  let divL2DaddrId = '#sflowFrame-moreL2DaddrId-' + String(id);
  let divVlanId = '#sflowFrame-moreVlanId-' + String(id);
  let divL3ProtoId = '#sflowFrame-moreL3ProtoId-' + String(id);
  let divL3SaddrId = '#sflowFrame-moreL3SaddrId-' + String(id);
  let divL3DaddrId = '#sflowFrame-moreL3DaddrId-' + String(id);
  let divFlagsId = '#sflowFrame-moreFlagsId-' + String(id);
  let divOffsetId = '#sflowFrame-moreOffsetId-' + String(id);
  let divTtlId = '#sflowFrame-moreTtlId-' + String(id);
  let divL4ProtoId = '#sflowFrame-moreL4ProtoId-' + String(id);
  let divL4SaddrId = '#sflowFrame-moreL4SaddrId-' + String(id);
  let divL4DaddrId = '#sflowFrame-moreL4DaddrId-' + String(id);
  let divFrameLenId = '#sflowFrame-moreFrameLenId-' + String(id);
  let divBytesId = '#sflowFrame-moreBytesId-' + String(id);
  let divPacketsId = '#sflowFrame-morePacketsId-' + String(id);  

  let timeStamp = frameReceived.timest;
  let agent = '[Agente]: ' + frameReceived.agent;
  let ifaceIdx = '[Interface]: ' + frameReceived.ifidx;
  let l3Saddr = frameReceived.l3Saddr + ' (Publica)';
  if (is_ip_private(frameReceived.l3Saddr)) {
    l3Saddr = frameReceived.l3SaddrOwner !== '' ? frameReceived.l3SaddrOwner : frameReceived.l3Saddr + ' (Privada)';
  }
  let sourceIpUrl = frameReceived.l3SaddrOwner !== '' ? device_link + frameReceived.l3SaddrOwner : '';
  let l3Daddr = frameReceived.l3Daddr + ' (Publica)';
  if (is_ip_private(frameReceived.l3Daddr)) {
    l3Daddr = frameReceived.l3DaddrOwner !== '' ? frameReceived.l3DaddrOwner : frameReceived.l3Daddr + ' (Privada)';
  }
  let destIpUrl = frameReceived.l3DaddrOwner !== '' ? device_link + frameReceived.l3DaddrOwner : '';
  let l4Proto = frameReceived.l4protoLabel + ' (' + (frameReceived.l4proto).toString() + ')';
  let l4Sport = frameReceived.l4SaddrLabel + ' (' + (frameReceived.l4Saddr).toString() + ')';
  let l4Dport = frameReceived.l4DaddrLabel + ' (' + (frameReceived.l4Daddr).toString() + ')';

  if (options.isNetflow) {
    let divTemplateId = 'sflowFrame-moreTemplateId-' + String(id);
	let bytes = frameReceived.inBytes !== undefined ? 
	  frameReceived.inBytes + ' Bytes' : frameReceived.outBytes + ' Bytes';
    let packets = frameReceived.inPackets !== undefined ? 
	  frameReceived.inPackets + ' Paquetes' : frameReceived.outPackets + ' Paquetes';
	$(divTemplateId).text(frameReceived.template);
	$(divBytesId).text(bytes);
	$(divPacketsId).text(packets);
  } else {
    let typeLabel = frameReceived.vlan ? 'EtherType' : '';
    let typeId = frameReceived.vlan ? '0x8100' : '';
    let l2Saddr = frameReceived.l2SaddrOwner !== '' ? 
	  frameReceived.l2SaddrOwner + ' (' + frameReceived.l2SaddrVendor + ')' :
	  frameReceived.l2Saddr + ' (' + frameReceived.l2SaddrVendor + ')';
    let l2Daddr = frameReceived.l2DaddrOwner !== '' ? 
	  frameReceived.l2DaddrOwner + ' (' + frameReceived.l2DaddrVendor + ')' :
	  frameReceived.l2Daddr + ' (' + frameReceived.l2DaddrVendor + ')';
	let sourceMacUrl = frameReceived.l2SaddrOwner !== '' ? device_link + frameReceived.l2SaddrOwner : '';
	let destMacUrl = frameReceived.l2DaddrOwner !== '' ? device_link + frameReceived.l2DaddrOwner : '';
    let l3Flags = frameReceived.flags !== '' ? frameReceived.flags : 'Ninguno';
	let frameLen = frameReceived.frameLen + ' (Bytes)';
    $(divTypeLabel).text(typeLabel);
    $(divTypeId).text(typeId);
    color = getColor(frameReceived.l2Saddr, 'all');
	$(divL2SaddrId).css('background-color', color.backgroundColor);
	$(divL2SaddrId).css('color', color.textColor);
    $(divL2SaddrId).text(l2Saddr);
    if (sourceMacUrl !== '') {
	  $(divL2SaddrId).attr('href', sourceMacUrl);
      $(divL2SaddrId).css('cursor', 'pointer');
	} else {
      $(divL2SaddrId).css('cursor', 'text');
    }
    color = getColor(frameReceived.l2Daddr, 'all');
	$(divL2DaddrId).css('background-color', color.backgroundColor);
	$(divL2DaddrId).css('color', color.textColor);
    $(divL2DaddrId).text(l2Daddr);
    if (destMacUrl !== '') {
	  $(divL2DaddrId).attr('href', destMacUrl);
      $(divL2DaddrId).css('cursor', 'pointer');
	}  else {
      $(divL2DaddrId).css('cursor', 'text');
    }
    $(divVlanId).text(frameReceived.vlan);  
    $(divFlagsId).text(l3Flags);
    $(divOffsetId).text(frameReceived.offset);
    $(divTtlId).text(frameReceived.ttl);
    $(divFrameLenId).text(frameLen);
  }

  $(divContainerId).removeClass('sflowFrame-hide');
  $(divTitleId).text(agent);
  if (agent_link !== '/') {
    $(divTitleId).attr('href', agent_link + frameReceived.agent);
    $(divTitleId).css('cursor', 'pointer');
  } else {
    $(divTitleId).css('cursor', 'text');
  }
  $(divSubTitleId).text(ifaceIdx);
  if (iface_link !== '/') {
    $(divSubTitleId).attr('href', iface_link + frameReceived.ifidx + '&var-agent=' + frameReceived.agent);
    $(divSubTitleId).css('cursor', 'pointer');
  } else {
    $(divSubTitleId).css('cursor', 'text');
  }
  $(divDateId).text(frameReceived.title);
  $(divTsId).text(timeStamp);
  $(divL2ProtoLabel).text(frameReceived.l2protoLabel);
  $(divL2ProtoId).text(frameReceived.ethertype);
  $(divL3ProtoId).text(frameReceived.l3protoLabel);
  color = getColor(frameReceived.l3Saddr, 'all');
  $(divL3SaddrId).css('background-color', color.backgroundColor);
  $(divL3SaddrId).css('color', color.textColor);
  $(divL3SaddrId).text(l3Saddr);
  if (sourceIpUrl !== '') {
	$(divL3SaddrId).attr('href', sourceIpUrl);
    $(divL3SaddrId).css('cursor', 'pointer');
  } else {
    $(divL3SaddrId).css('cursor', 'text');
  }
  color = getColor(frameReceived.l3Saddr, 'all');
  $(divL3DaddrId).css('background-color', color.backgroundColor);
  $(divL3DaddrId).css('color', color.textColor);
  $(divL3DaddrId).text(l3Daddr);
  if (destIpUrl !== '') {
	$(divL3DaddrId).attr('href', destIpUrl);
	$(divL3DaddrId).css('cursor', 'pointer');
  } else {
    $(divL3DaddrId).css('cursor', 'text');
  }
  $(divL4ProtoId).text(l4Proto);
  color = getColor(frameReceived.l4Saddr, 'all');
  $(divL4SaddrId).css('background-color', color.backgroundColor);
  $(divL4SaddrId).css('color', color.textColor);
  $(divL4SaddrId).text(l4Sport);
  color = getColor(frameReceived.l4Daddr, 'all');
  $(divL4DaddrId).css('background-color', color.backgroundColor);
  $(divL4DaddrId).css('color', color.textColor);
  $(divL4DaddrId).text(l4Dport);
}

function handleUrl(url, target, update) {
  let urlTarget = '_blank';
  if (target === '_self') {
    urlTarget = '_self';
  }
  if (url.indexOf('http') > 0 || update === true) {
    window.open(url, target);
  } else {
    window.open(window.location.protocol + '//' + url, urlTarget);
  }
}

function hideMoreInfo(e, id) {
  let divID = '#sflowFrame-moreInfoContainer-' + String(id);
  $(divID).addClass('sflowFrame-hide');
}

function isValueUnique(value, object): boolean {
  return !object.some((item) => item.value === value);
}

function frameFactory(
  dimensions,
  frames: SflowData[],
  showMore,
  showCardInfo,
  id,
  options,
  agent_link,
  device_link, 
  iface_link,
  setAgentFilter,
  setL2SourceFilter,
  setL2DestinationFilter,
  setL3SourceFilter,
  setL3DestinationFilter
) {
  const isDark = config.theme.isDark;
  var l3proto = 'sflowFrame-l3Proto';
  var l4proto = 'sflowFrame-l4Proto';
  var frameDetailedText = 'frameDetailedText';
  var agent = 'sflowFrame-agent';
  var frameTheme = '';
  if (isDark) {
    frameDetailedText = 'frameDetailedText_dark';
    frameTheme = '_dark';
	agent = 'sflowFrame-agent_dark';
  }

  return frames.map((frame) => (
    <div className="frameEnvelope">
	<div className="frametitle">
	  <div key={frame.id + '_l'} className="frameContainer-left">
        <div className="frameTitleContainer">
          {options.showAgentFilter && (
		    <>
			<span>
              <button
                className={agent}
                title={'Agente que envio el reporte. Clic para filtar por este campo.'}
				style={{ backgroundColor: `${getColor(frame.agent, 'back')}`, color: `${getColor(frame.agent, 'text')}` }}
				onClick={(e) => {
                  if (options.showToolbar) {
                    const queryMap = {
                      [`var-${options.agentVariable}`]: frame.agent,
                    };
					changeUrl(queryMap);
					setAgentFilter(frame.agent);
                  }  
				}}
              >
                {frame.agent}
              </button>
            </span>
		    <span className="frameSeparator"></span>
			</>
		  )}
          {!options.isNetflow && (
		    <>
			<span>
              <button
                className="sflowFrame-indicator-left"
                title={'Origen L2. Clic para filtar por este campo.'}
				style={{ backgroundColor: `${getColor(frame.l2Saddr, 'back')}`, color: `${getColor(frame.l2Saddr, 'text')}` }}
				onClick={(e) => {
                  if (options.showToolbar) {
                    const queryMap = {
                      [`var-${options.l2SaddrVariable}`]: frame.l2Saddr,
                    };
					changeUrl(queryMap);
					setL2SourceFilter(frame.l2Saddr);
                  }  
				}}
              >
                {frame.l2SaddrOwner !== '' ? frame.l2SaddrOwner : frame.l2Saddr}
              </button>
            </span>
            <span>
              <button
                className="sflowFrame-indicator-left"
                title={'Destino L2. Clic para filtar por este campo.'}
				style={{ backgroundColor: `${getColor(frame.l2Daddr, 'back')}`, color: `${getColor(frame.l2Daddr, 'text')}` }}
				onClick={(e) => {
                  if (options.showToolbar) {
                    const queryMap = {
                      [`var-${options.l2DaddrVariable}`]: frame.l2Daddr,
                    };
				    changeUrl(queryMap);
					setL2DestinationFilter(frame.l2Daddr);
                  }  
				}}
              >
                {frame.l2DaddrOwner !== '' ? frame.l2DaddrOwner : frame.l2Daddr}
              </button>
            </span>
			<span className="frameSeparator"></span>
			</>
		  )}
          <span>
            <button
              className={l3proto}
              title={'Protocolo L3. Clic para filtar por este campo.'}
			  style={{ backgroundColor: `${getColor(frame.ethertype, 'back')}`, color: `${getColor(frame.ethertype, 'text')}` }}
			  onClick={(e) => {
				if (options.showToolbar) {
				  const queryMap = {
					[`var-${options.l3ProtoVariable}`]: frame.l3proto,
				  };
				  changeUrl(queryMap);
				}
			  }}
            >
              {frame.l3proto}
            </button>
          </span>
          <span>
            <button
              className="sflowFrame-indicator-left"
              title={'Origen L3. Clic para filtar por este campo.'}
			  style={{ backgroundColor: `${getColor(frame.l3Saddr, 'back')}`, color: `${getColor(frame.l3Saddr, 'text')}` }}
			  onClick={(e) => {
                if (options.showToolbar) {
                  const queryMap = {
                    [`var-${options.l3SaddrVariable}`]: frame.l3Saddr,
                  };
				  changeUrl(queryMap);
				  setL3SourceFilter(frame.l3Saddr);
				}  
			  }}
            >
              {frame.l3Saddr}
            </button>
          </span>
          <span>
            <button
              className="sflowFrame-indicator-left"
              title={'Destino L3. Clic para filtar por este campo.'}
			  style={{ backgroundColor: `${getColor(frame.l3Daddr, 'back')}`, color: `${getColor(frame.l3Daddr, 'text')}` }}
			  onClick={(e) => {
                if (options.showToolbar) {
                  const queryMap = {
                    [`var-${options.l3DaddrVariable}`]: frame.l3Daddr,
                  };
				  changeUrl(queryMap);
				  setL3DestinationFilter(frame.l3Daddr);
				}  
			  }}
            >
              {frame.l3Daddr}
            </button>
          </span>
	    </div>
  	  </div>
	</div>
	<div className="frameSeparator"></div>
    <div className="frametitle">
	  <div key={frame.id + '_r'} className="frameContainer">    
	    {frame.l4Daddr !== 0 && frame.l4Saddr !== 0 && (
          <div className="frameTitleContainer">
            <span>
              <button
                className={l4proto}
                title={'Protocolo L4. Clic para filtar por este campo.'}
			    style={
			      { backgroundColor: `${getColor(frame.l4proto, 'back')}`, color: `${getColor(frame.l4proto, 'text')}` }
			    }
			    onClick={(e) => {
				  if (options.showToolbar) {
					const queryMap = {
					  [`var-${options.l4ProtoVariable}`]: frame.l4proto,
					};
					changeUrl(queryMap);
				  }
				}}
              >
                {frame.l4protoLabel !== '' ? frame.l4protoLabel : frame.l4proto}
              </button>
            </span>
            <span>
              <button
                className="sflowFrame-indicator"
                title={'Puerto Origen'}
			    style={
			      { backgroundColor: `${getColor(frame.l4SaddrName, 'back')}`, color: `${getColor(frame.l4SaddrName, 'text')}` }
			    }
              >
                {frame.l4SaddrName !== '' ? frame.l4SaddrName : frame.l4Saddr}
              </button>
            </span>
            <span>
              <button
                className="sflowFrame-indicator"
                title={'Puerto Destino'}
			    style={
			      { backgroundColor: `${getColor(frame.l4DaddrName, 'back')}`, color: `${getColor(frame.l4DaddrName, 'text')}` }
			    }
              >
                {frame.l4DaddrName !== '' ? frame.l4DaddrName : frame.l4Daddr}
              </button>
            </span>
          </div>
		)}
		{showMore && (
          <div className="frameTitleContainer">
		    <spam>
              <button
                className="sflowFrame-button"
                onClick={(e) => {
                  showMoreInfo(e, frame, id, options, agent_link, device_link, iface_link);
                }}
                title="más detalles ..."
              >
                <Icon
                  name="ellipsis-h"
                  size="md"
                />
              </button>
            </spam>
          </div>
        )}
      </div>
	</div>
	</div>
  ));
}

import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { Checkbox, Field } from '@grafana/ui';

import { CommonSettingsComponentProps } from 'app/features/alerting/unified/types/receiver-form';

export const NetMonitorCommonChannelSettings: FC<CommonSettingsComponentProps> = ({
  pathPrefix,
  className,
  readOnly = false,
}) => {
  const { register } = useFormContext();
  return (
    <div className={className}>
      <Field>
        <Checkbox
          {...register(`${pathPrefix}disableResolveMessage`)}
          label="No enviar mensajes de resolución"
          description="Deshabilita el envio de mensajes informando la falla fué solucionada"
          disabled={readOnly}
        />
      </Field>
    </div>
  );
};

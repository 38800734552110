import { locationService, getTemplateSrv } from '@grafana/runtime';

export function changeUrl(queryMap: {}, useBack: boolean, varBackVariable: string, varBack1Variable: string) {
  locationService.partial(queryMap, true);
  if (useBack) {
    updateBackUrl(varBackVariable, varBack1Variable);
  }
}

function removeURLParameter(url: string, parameter: string) {
  var urlparts = url.split('?');
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);
    //reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0; ) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }
    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

export function updateBackUrl(varBackVariable: string, varBack1Variable: string) {
  var previousUrl = '';
  let varBack1 = getNetMonitorVariableValue(varBack1Variable);
  varBack1 = atob(varBack1);
  let cleanURL = removeURLParameter(window.location.search, 'var-' + varBackVariable);
  cleanURL = removeURLParameter(cleanURL, 'var-' + varBack1Variable);
  cleanURL = removeURLParameter(cleanURL, 'orgId');
  cleanURL = removeURLParameter(cleanURL, 'refresh');
  let newVarBack = varBack1 + ',' + window.location.pathname + cleanURL;
  newVarBack = btoa(newVarBack);

  locationService.partial({[`var-${varBackVariable}`]: newVarBack}, true);
}

export function setBackUrl(varBackVariable: string, varBack1Variable: string) {
  let previousUrl = '';
  let update = false;
  let varBack = getNetMonitorVariableValue(varBackVariable);
  if (varBack.length < 1 || varBack === '-' || varBack === null) {
    //varBack = getNetMonitorVariableValue(varBack1Variable);
    varBack = 'L2QvaG9tZS8=';
  }

  let check = cheURL(varBack);
  let temp = '/d/home';
  if (check) {
    temp = atob(varBack);
  }

  let endString = temp.toString().lastIndexOf(',') + 1;
  if (endString >= 0) {
    previousUrl = temp.toString().slice(endString, temp.length);
  } else {
    previousUrl = temp;
  }
  endString = previousUrl.toString().lastIndexOf('?');
  if (endString >= 0) {
    previousUrl = previousUrl.toString().slice(0, endString);
  }

  if (previousUrl !== String(window.location.pathname)) {
    //let varBack1 = getNetMonitorVariableValue(varBack1Variable);
    let cleanURL = removeURLParameter(window.location.search, 'var-' + varBackVariable);
    cleanURL = removeURLParameter(cleanURL, 'var-' + varBack1Variable);
    cleanURL = removeURLParameter(cleanURL, 'orgId');
    cleanURL = removeURLParameter(cleanURL, 'refresh');
    let actual = window.location.pathname + cleanURL;
    if (varBack.length > 0 && varBack !== '-') {
      let check = cheURL(varBack);
      if (check) {
        previousUrl = atob(varBack);
      } else {
        previousUrl = '/d/home/';
      }
      varBack = btoa(previousUrl + ',' + actual);
    } else {
      varBack = btoa(actual);
    }
    //Actualizo el ruta incluyendo la url actual (back)
    locationService.partial({[`var-${varBackVariable}`]: varBack}, true);
    //Actualizo la ruta a la url anterior (back1)
    previousUrl = btoa(previousUrl);
    if (varBack1Variable !== 'null') {
      locationService.partial({[`var-${varBack1Variable}`]: previousUrl}, true);
    }
  }
}

export function openUrl(
  url: string,
  updateBackUrl: boolean,
  useBack: boolean,
  varBackVariable: string,
  varBack1Variable: string
) {
  if (useBack) {
    let varBack = getNetMonitorVariableValue(varBack1Variable);
    if (updateBackUrl) {
      varBack = getNetMonitorVariableValue(varBackVariable);
    }
    if (varBack.length > 1 && varBack !== '-' && varBack !== null) {
      if (url.includes('?')) {
        url = url + '&var-' + varBackVariable + '=' + varBack;
      } else {
        url = url + '?var-' + varBackVariable + '=' + varBack;
      }
    }
  }
  if (url !== null) {
    window.open(url, '_self');
  }
}

export function backUrl(varBackVariable: string, varBack1Variable: string) {
  let varBack1 = getNetMonitorVariableValue(varBack1Variable);
  if (varBack1.length > 1 && varBack1 !== '-' && varBack1 !== null) {
    let check = cheURL(varBack1);
    if (check) {
      varBack1 = atob(varBack1);
    } else {
      varBack1 = '/d/home';
    }
    let previousUrl1 = varBack1;
    let residualvarBack1 = '';
    let endString1 = varBack1.toString().lastIndexOf(',') + 1;
    if (endString1 >= 0) {
      previousUrl1 = varBack1.toString().slice(endString1, varBack1.length);
      residualvarBack1 = varBack1.toString().slice(0, endString1 - 1);
      residualvarBack1 = btoa(residualvarBack1);
    }
    if (previousUrl1.includes('?')) {
      if (residualvarBack1.length > 0) {
        window.open(previousUrl1 + '&var-' + varBackVariable + '=' + residualvarBack1, '_self');
      } else {
        window.open(previousUrl1, '_self');
      }
    } else {
      if (residualvarBack1.length > 0) {
        window.open(previousUrl1 + '?var-' + varBackVariable + '=' + residualvarBack1, '_self');
      } else {
        window.open(previousUrl1, '_self');
      }
    }
  } else {
    window.open('/d/home', '_self');
  }
}

export function getNetMonitorVariableValue(variableName: any) {
  return getTemplateSrv().replace(`$${variableName}`);
}

export function cheURL(url: any) {
  var regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  let result = regex.test(url);
  return result;
}

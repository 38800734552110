import { PanelPlugin } from '@grafana/data';
import { FieldSelectEditor } from './FieldSelectEditor';
import { PieChartOptions } from './types';
import { PieChartPanel } from './PieChartPanel';

export const plugin = new PanelPlugin<PieChartOptions>(PieChartPanel).setPanelOptions(builder => {
  return builder
    .addCustomEditor({
      path: 'categoryField',
      name: 'Category Field',
      category: ['Data Map Settings'],
      defaultValue: 'category',
      editor: FieldSelectEditor,
    })
    .addTextInput({
      path: 'categoryTitle',
      name: 'Category title',
      category: ['Data Map Settings'],
      defaultValue: 'Activo:',
    })
    .addCustomEditor({
      path: 'valueField',
      name: 'Value Field',
      category: ['Data Map Settings'],
      defaultValue: 'value',
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'useColorScale',
      name: 'Use Color Scales',
      description: 'Use color scales to represent values',
      category: ['Data Map Settings'],
      defaultValue: true,
    })
    .addSelect({
      path: 'colorScale',
      name: 'Color Scale',
      category: ['General Settings'],
      defaultValue: 'Rainbow',
      showIf: config => config.useColorScale === true,
      settings: {
        options: [
          {
            value: 'Orange',
            label: 'Orange',
          },
          {
            value: 'Blue',
            label: 'Blue',
          },
          {
            value: 'Brown',
            label: 'Brown',
          },
          {
            value: 'Purple',
            label: 'Purple',
          },
          {
            value: 'Inferno',
            label: 'Inferno',
          },
          {
            value: 'Viridis',
            label: 'Viridis',
          },
          {
            value: 'Rainbow',
            label: 'Rainbow',
          },
        ],
      },
    })
    .addCustomEditor({
      path: 'colorField',
      name: 'Color Field',
      category: ['Data Map Settings'],
      showIf: config => config.useColorScale === false,
      defaultValue: 'color',
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'useMetricField',
      name: 'Use Threshold',
      description: 'Use a metric Threshold to highlight slices in PieChart',
      category: ['Data Map Settings'],
      defaultValue: false,
    })
    .addCustomEditor({
      path: 'metricField',
      name: 'Threshold Field',
      category: ['Data Map Settings'],
      showIf: config => config.useMetricField === true,
      defaultValue: 'metric',
      editor: FieldSelectEditor,
    })
    .addTextInput({
      path: 'metricThreshold',
      name: 'Threshold',
      description: 'Threshold (expressed in %) use to alarm slices (ex: 20)',
      category: ['Data Map Settings'],
      showIf: config => config.useMetricField === true,
      defaultValue: '20',
    })
    .addTextInput({
      path: 'metricTitle',
      name: 'Metric title',
      category: ['Data Map Settings'],
      showIf: config => config.useMetricField === true,
      defaultValue: 'Activos en servicio:',
    })
    .addBooleanSwitch({
      path: 'useInfoField',
      name: 'Aditional info Scales',
      description: 'Allow to use aditional info in tooltip',
      category: ['Data Map Settings'],
      defaultValue: false,
    })
    .addCustomEditor({
      path: 'infoField',
      name: 'Aditional info Field',
      category: ['Data Map Settings'],
      showIf: config => config.useInfoField === true,
      defaultValue: 'info',
      editor: FieldSelectEditor,
    })
    .addTextInput({
      path: 'infoTitle',
      name: 'Aditional info title',
      category: ['Data Map Settings'],
      showIf: config => config.useInfoField === true,
      defaultValue: '',
    })

    .addBooleanSwitch({
      path: 'pieCentered',
      name: 'Pie Chart Centered',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addNumberInput({
      path: 'innerRadius',
      name: 'Donut inner radius',
      description: 'Inner raius expressed as % of pie diameter (Ex: 40)',
      category: ['General Settings'],
      defaultValue: 25,
    })
    .addColorPicker({
      path: 'innerRadiusColor',
      name: 'Donut inner radius Color',
      category: ['General Settings'],
      defaultValue: 'transparent',
    })
    .addColorPicker({
      path: 'innerRadiusFontColor',
      name: 'Donut inner radius Font Color',
      category: ['General Settings'],
      defaultValue: '#0c36cf',
    })
    .addNumberInput({
      path: 'fontSize',
      name: 'On Chart Text Font Size',
      category: ['General Settings'],
      defaultValue: 12,
    })
    .addBooleanSwitch({
      path: 'displaysliceLegends',
      name: 'Display slices legends',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'displayTotals',
      name: 'Display Totals',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'displayName',
      name: 'Display Name or Total',
      category: ['General Settings'],
      defaultValue: true,
    })

    .addBooleanSwitch({
      path: 'displayLegends',
      name: 'Display Legends',
      category: ['Legend Settings'],
      defaultValue: true,
    })
    .addNumberInput({
      path: 'legendMinWidth',
      name: 'Legend cage min width Size',
      category: ['Legend Settings'],
      defaultValue: 150,
    })
    .addNumberInput({
      path: 'legendMaxWidth',
      name: 'Legend cage max width Size',
      category: ['Legend Settings'],
      defaultValue: 250,
    })
    .addNumberInput({
      path: 'legendMinHeight',
      name: 'Legend cage min height Size',
      category: ['Legend Settings'],
      defaultValue: 15,
    })
    .addNumberInput({
      path: 'legendMaxHeight',
      name: 'Legend cage max height Size',
      category: ['Legend Settings'],
      defaultValue: 45,
    })
    .addNumberInput({
      path: 'legendFontSize',
      name: 'Legend Font Size',
      category: ['Legend Settings'],
      defaultValue: 12,
    })
    .addRadio({
      path: 'legendPosition',
      defaultValue: 'auto',
      name: 'Legend Position',
      category: ['Legend Settings'],
      settings: {
        options: [
          {
            value: 'bottom',
            label: 'Bottom',
          },
          {
            value: 'right',
            label: 'Right',
          },
          {
            value: 'auto',
            label: 'Auto',
          },
        ],
      },
    })
    .addTextInput({
      path: 'drillDownLink',
      name: 'PieChart slice base URL',
      category: ['Link to other features'],
      defaultValue: '/d/home',
    })
    .addTextInput({
      path: 'drillDownLinkCenter',
      name: 'PieChart center base URL',
      category: ['Link to other features'],
      defaultValue: '/d/home',
    })
    .addTextInput({
      path: 'drillDownLinkLegend',
      name: 'PieChart legend item base URL',
      category: ['Link to other features'],
      defaultValue: '/d/home',
    })
    .addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'Datos nulos',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error al cargar el complemento',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});

import React, { Component } from 'react';
import { config } from '@grafana/runtime';
import './css/indicators.css';
import { Icon } from '@grafana/ui';

interface GenericIndicatorProps {
  id: string;
  width: number;
  height: number;
  fontSize: number;
  iconSize: number;
  textCentered: boolean;
  iconCentered: boolean;
  style: string;
  icon: string;
  text: string;
  result: string;
  title: string;
  url: string;
}

export class GenericIndicator extends Component<GenericIndicatorProps> {
  constructor(props: any) {
    super(props);
    this.handleUrl = this.handleUrl.bind(this);
  }

  handleUrl() {
    if (this.props.url !== null && this.props.url !== '') {
      window.open(this.props.url, '_self');
    }
  }

  render() {
    let isDark = config.theme.isDark || false;
    let buttonStyle = 'indicator_' + String(this.props.style);
	let iconClass = 'icon_indicator_centered';

    if (buttonStyle === 'indicator_default') {
      if (isDark) {
        buttonStyle = 'indicator_black';
      } else {
        buttonStyle = 'indicator_white';
      }
    }
    let classButton = 'indicator_light ' + buttonStyle;
    if (isDark) {
      classButton = 'indicator_dark ' + buttonStyle;
	  iconClass = 'icon_indicator_centered_dark';
    }
    if (this.props.iconCentered) {
      classButton = 'indicator2_light ' + buttonStyle;
      if (isDark) {
        classButton = 'indicator2_dark ' + buttonStyle;
      }
	}
    let title = String(this.props.title) || '';
    let buttonText = this.props.text;
    let indicator_label = 'indicator_label';
    if (this.props.textCentered) {
      indicator_label = 'indicator_label indicator_label_centered';
    }

	let iconSize = 12;
    if (this.props.iconCentered) {
      indicator_label = 'indicator2_label';
	  iconSize = this.props.height - (this.props.fontSize * 4);
    } else {
	  switch (this.props.iconSize) {
		case 'sm':
		  iconSize = 14;
		case 'md':
		  iconSize = 16;
		case 'lg':
		  iconSize = 18;
		case 'xl':
		  iconSize = 24;
		case 'xxl':
		  iconSize = 36;
		case 'xxxl':
		  iconSize = 48;
	  }
	}

    if (this.props.iconCentered) {
      if (this.props.icon !== '-') {
		return (
          <button
            style={{
              width: this.props.width,
              height: this.props.height,
              fontSize: this.props.fontSize,
            }}
            key={this.props.id}
            className={classButton}
            onClick={this.handleUrl}
            title={title}
          >
            <div className={indicator_label}>
              <b>{buttonText}</b>
            </div>
            <div
              style={{ height: iconSize }}
              className={iconClass}
            >
              <Icon name={this.props.icon} size={this.props.iconSize} />
            </div>
            <div className={'indicator_sublabel'}>
              {this.props.result}
            </div>
          </button>
        );
	  }
	} else {
      if (this.props.icon === '-') {
        return (
          <button
            style={{
              width: this.props.width,
              height: this.props.height,
              fontSize: this.props.fontSize,
            }}
            key={this.props.id}
            className={classButton}
            onClick={this.handleUrl}
            title={title}
          >
            <div 
			  style={{ width: this.props.width }}
			  className={indicator_label}
			>
              <span 
			    style={{
				  width: this.props.width - 30,
				  fontWeight: 500,
				  overflow: 'hidden',
				  textOverflow: 'ellipsis',
				}}
			  >
			    {buttonText}
			  </span>
              <span 
			    style={{ 
				  fontSize: this.props.fontSize * 0.9, 
				  width: this.props.width - 30,
				  overflow: 'hidden',
				  textOverflow: 'ellipsis',
				}}
			  >
			    {this.props.result}
			  </span>
            </div>
          </button>
        );
      } else {
        return (
          <button
            style={{
              width: this.props.width,
              height: this.props.height,
              fontSize: this.props.fontSize,
            }}
            key={this.props.id}
            className={classButton}
            onClick={this.handleUrl}
            title={title}
          >
            <div
              className="icon_indicator"
            >
              <Icon name={this.props.icon} size={this.props.iconSize} />
            </div>
            <div 
			  style={{ width: this.props.width - iconSize }}
			  className={indicator_label}
			>
              <span 
			    style={{
				  width: this.props.width - iconSize - 10,
				  fontWeight: 500,
				  overflow: 'hidden',
				  textOverflow: 'ellipsis',
				}}
			  >
			    {buttonText}
			  </span>
              <span 
			    style={{ 
				  fontSize: this.props.fontSize * 0.9, 
				  width: this.props.width - iconSize - 10,
				  overflow: 'hidden',
				  textOverflow: 'ellipsis',
				}}
			  >
			    {this.props.result}
			  </span>
            </div>
          </button>
        );
	  }
    }
  }
}

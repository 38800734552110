import { FieldType, PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { FieldSelectEditor } from './FieldSelectEditor';
import { VideoPanel } from './VideoPanel';

export const plugin = new PanelPlugin<SimpleOptions>(VideoPanel).setPanelOptions(builder => {
  return builder
    .addCustomEditor({
      id: 'useProxyOption',
      path: 'useProxyOption',
      name: 'Use Proxy Field',
      description: 'The camera is behind a proxy or get the video directly from de camera?',
      defaultValue: 'useproxy',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'collectorOption',
      path: 'collectorOption',
      name: 'Collector IP Field',
      description: 'The Collector IP Address to use',
      defaultValue: 'collector',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'collectorPortOption',
      path: 'collectorPortOption',
      name: 'Video TCP port Field',
      description: 'The Collector Video TCP port in use. Default: 3020.',
      defaultValue: 'vport',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'proxyProtocolOption',
      path: 'proxyProtocolOption',
      name: 'Proxy Protocol',
      description: 'Protocol used by the proxy o host. Could by HTTP, HTTPS, RSTP, WS, RTP, MMS',
      defaultValue: 'proto',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'requireAuthenticationOption',
      path: 'requireAuthenticationOption',
      name: 'Requiere Authentication',
      description: 'The video camera requiere authentication to send video frames?',
      defaultValue: 'auth',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'hostNameOption',
      path: 'hostNameOption',
      name: 'Host Name Field',
      description: 'The Asset ID',
      defaultValue: 'name',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'hostLocationOption',
      path: 'hostLocationOption',
      name: 'Host location Field',
      description: 'The Asset location',
      defaultValue: 'location',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'hostOption',
      path: 'hostOption',
      name: 'Host IP Field',
      description: 'The IP Address to open video session',
      defaultValue: 'host',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'portOption',
      path: 'portOption',
      name: 'TCP port Field',
      description: 'The TCP port to open video session',
      defaultValue: 'port',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'userOption',
      path: 'userOption',
      name: 'Camera user Field',
      description: 'The user used to authenticate se session in the camera',
      defaultValue: 'user',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'passOption',
      path: 'passOption',
      name: 'Camera Password Field',
      description: 'The password used to authenticate se session in the camera',
      defaultValue: 'pass',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'fpsOption',
      path: 'fpsOption',
      name: 'FPS video Field',
      description: 'The FPS video rate',
      defaultValue: 'fps',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'videoPathOption',
      path: 'videoPathOption',
      name: 'Video URL path option',
      description: 'The path used to access video html content',
      defaultValue: 'vpath',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'snapPathOption',
      path: 'snapPathOption',
      name: 'Snap URL path option',
      description: 'The path used to access snapshot html content',
      defaultValue: 'spath',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'snapFileOption',
      path: 'snapFileOption',
      name: 'Snap file name option',
      description: 'The file name used when save capture image',
      defaultValue: 'file',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'takeSnap',
      name: 'Take only a Snap',
      description: 'Show a photo instead of reproduce video stream',
      category: ['General Settings'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'showControls',
      name: 'Show video controls',
      category: ['General Settings'],
      defaultValue: false,
	  showIf: config => config.takeSnap === false,
    })
    .addBooleanSwitch({
      path: 'saveFile',
      name: 'Save a file',
      category: ['General Settings'],
      defaultValue: false,
	  showIf: config => config.takeSnap === true,
    })
    .addNumberInput({
      path: 'videoTimeout',
      name: 'Video auto-stop time',
      category: ['General Settings'],
      description: 'Video sesion timeout when using Proxy server (NetCamera). Must be 5 seg or more',
	  defaultValue: 60,
      showIf: config => config.takeSnap === false,
    })
	.addTextInput({
      path: 'welcome',
      name: 'Welcome Banner',
      category: ['General Settings'],
      defaultValue: 'Por favor ingrese sus credenciales para iniciar sesión',
    })
	.addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'Datos nulos',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error: el formato de datos no es compatible con el complemento',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});

import React, { useEffect, useState } from 'react';
import RGL, { WidthProvider } from "react-grid-layout";

import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { config } from '@grafana/runtime';
import { Icon } from '@grafana/ui';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { Img } from 'react-image'

import { SimpleOptions, GenericImageData, CameraData, CameraCanvas, GridItems } from './types';
import './css/ImageSession.css';

const ReactGridLayout = WidthProvider(RGL);

interface Props extends PanelProps<SimpleOptions> {}

export const PhotoPanel: React.FC<Props> = ({ options, data, width, height, replaceVariables }) => {
  const isDark = config.theme.isDark || false;
  const rootUrl = config.appUrl;
  const error1 = replaceVariables(options.error1);
  const error2 = replaceVariables(options.error2);
  const error3 = replaceVariables(options.error3);
  const error4 = replaceVariables(options.error4);
  const defaultImage =  rootUrl + 'public/img/icons/unicons/' + replaceVariables(options.errorImage);
  const borderColor = isDark ? '#222426' : '#dce1e6';

  const ImageWrapper  = styled.div`
    display: flex;
	height: 100%;
	width: 100%;
	flex-direction: column;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
  `;

  const ImageFrame = styled.div`
	height: 100%;
	width: 100%;
	align-items: center;
    justify-content: center;
    display: flex;
	cursor: grab;
    border-radius: 4px;
    border: 1px solid ${borderColor};
  `;

  const imagesInfo: GenericImageData[] = [];

  if (width < 350) {
    return (
      <div>
        <div className="gf-form-error">{error4}</div>
      </div>
    );
  } else if (data.state !== 'Error') {
    if (data.series[0].length > 0) {
	  data.series.forEach(serie => {
		const timeVals: GraphSeriesValue[] = serie.fields[0].values.toArray();
		for (let i = 0; i < timeVals.length; i++) {
		  let imageName = serie.fields.find(field => field.name === options.snapFileOption)?.values.get(i);
		  let isValid = serie.fields.find(field => field.name === options.successOption)?.values.get(i)
		  if (!isValid) {
	        imageName = defaultImage;
          }
		  const imageData: GenericImageData = {
			id: i,
			time: serie.fields.find(field => field.name === options.timeOption)?.values.get(i),
			success: isValid,
			snapPath: serie.fields.find(field => field.name === options.snapPathOption)?.values.get(i),
			snapFile: imageName,
		  };
		  imagesInfo.push(imageData);
		}
	  });

	  if (imagesInfo.length > 0) {
	    const numImages = imagesInfo.length > options.maxImages ? options.maxImages : imagesInfo.length;
		const numCols = Math.ceil(Math.sqrt(numImages));
		const numRows = Math.ceil(numImages / numCols);
		const rowHeight = 1;
		const colWidth = 1;
		const imagesWidth = Math.ceil(width / numCols) - 8;
		const imagesHeight = Math.ceil(height / numRows) - 8;
		const images: ImagesCanvas [] = [];
		const gridItems: GridItem [] = [];
		for (let i = 0; i < numImages; i++) {
		  if (imagesInfo[i] !== null) {
		    let image: ImagesCanvas = {
		      i: i,
			  time: imagesInfo[i].time,
		      url: imagesInfo[i].success ? imagesInfo[i].snapFile : defaultImage,
			  title: imagesInfo[i].success ? imagesInfo[i].snapPath : error3,
		    };
		    let gridItem: GridItems = {
		      i: i.toString(),
		      x: Math.floor(i / numRows),
			  y: i % numRows,
		      w: 1, 
		      h: 1,
		    };
		  images.push(image);
		  gridItems.push(gridItem);
 	      }
		}
	    return (
		  <ImageWrapper>
		    <ReactGridLayout
			  className="image_wrapper"
			  cols={numCols}
			  layout={gridItems}
			  autoSize={true}
			  width={width}
			  rowHeight={height/numRows}
			  isDraggable={true}
			  isResizable={false}
		    >
			  {images.map((image, index) => (
			    <ImageFrame key={image.i} title={image.title}>
				  {image.url.slice(-3) !== 'svg' ? (
				    <Img
				      src={`${image.url}&time=${image.time}`}
					  loader={<div><Icon name={'loading'} size="lg" /></div>}
					  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
			        />
				  ) : (
				    <ReactSVG
					  src={`${image.url}&time=${image.time}`}
					  loading={() => <Icon name={'loading'} size="lg" />}
					  beforeInjection={svg => {
						if (imagesWidth > imagesHeight) {
						  svg.setAttribute('width', `${imagesHeight}`);
						  svg.setAttribute('height', `${imagesHeight}`);
						} else {
						  svg.setAttribute('width', `${imagesWidth}`);
						  svg.setAttribute('height', `${imagesWidth}`);
						}
						svg.setAttribute('preserveAspectRatio', 'xMidYMid meet');
						if (image.url === defaultImage) {
						  svg.setAttribute('fill', 'orange');
						}
					  }}
					/>
				  )}
			    </ImageFrame>
			  ))}
		    </ReactGridLayout>
		  </ImageWrapper>
		);
      } else {
        return (
          <div>
            <div className="gf-form-error">{error2}</div>
          </div>
        );
      }
    } else {
      return (
        <div>
          <div className="gf-form-error">{error2}</div>
        </div>
      );
    }
  } else {
    return (
      <div>
        <div className="gf-form-error">{error1}</div>
      </div>
    );
  }
}
import { PluginState } from '@grafana/data';
import { config, NetMonitorBootConfig } from '@grafana/runtime';
// Legacy binding paths
export { config, NetMonitorBootConfig as Settings };

let netmonitorConfig: NetMonitorBootConfig = config;

export default netmonitorConfig;

export const getConfig = () => {
  return netmonitorConfig;
};

export const updateConfig = (update: Partial<NetMonitorBootConfig>) => {
  netmonitorConfig = {
    ...netmonitorConfig,
    ...update,
  };
};

// The `enable_alpha` flag is not exposed directly, this is equivalent
export const hasAlphaPanels = Boolean(config.panels?.debug?.state === PluginState.alpha);
